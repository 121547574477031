<div class="a-list {{styleClass}}">
  <ng-content select="[header]"></ng-content>
  <div class="a-list-header p-2 pb-0" [ngClass]="headerStyleClass" *ngIf="showHeader || searchable">
    <a-search-box #searchbox *ngIf="searchable" [items]="items || []" [searchFields]="searchFields || []"
      [showClear]="true" [filter]="filter" (onItemFiltered)="filterItems = $event"></a-search-box>
    <div class="p-2 flex items-center justify-between" *ngIf="showSelectAll && selectionMode=='multiple'">
      <mat-checkbox [checked]="allSelected" [indeterminate]="partialSelected" [disabled]="disabled"
        (change)="selectAll($event.checked)">Select All
      </mat-checkbox>
      <div *ngIf="showSelectedCount && selectedCount && items && items.length>0 && items[0]">{{ selectedCount + ' / ' +
        items.length}}
      </div>
    </div>
  </div>
  <div class="a-list-body">
    <cdk-virtual-scroll-viewport [itemDimension]="itemDimension" class="a-list-viewport" [minBufferPx]="minBufferPx"
      [maxBufferPx]="maxBufferPx" [ngStyle]="visibleItemSize?{'height':visibleItemSize*itemDimension.height+'px'}:{}">
      <div class="a-list-item {{itemStyleClass}}" (click)="clickItem($event,item)"
        [ngClass]="{'selected': isSelected(item)}"
        [ngStyle]="{width:itemDimension.width>0? (itemDimension.width+ 'px'):itemDimension.width,height:itemDimension.height +'px', overflow:'hidden'}"
        *cdkVirtualFor="let item of filterItems || items">
        <ng-template
          *ngTemplateOutlet="(itemTemplate || defaultItemTemplate); context: { $implicit : item, model: selectionMode }">
        </ng-template>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
<ng-template #defaultItemTemplate let-item>
  <div class="w-full h-full flex items-center px-2">{{item}}</div>
</ng-template>
