import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SideNavComponent } from './side-nav.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [SideNavComponent],
  exports: [SideNavComponent],
})
export class SideNavModule { }
