import { slideDown } from '@alterdomus/common-ui/animation';
import { Component, Inject, Renderer2 } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { LOADING_KEY, loadingData } from '../loading-data';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'a-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [slideDown(0, 1000)]
})
export class LoadingComponent {
  // progressKey?: string;
  loading$?: Observable<loadingData>;

  constructor(
    @Inject(LOADING_KEY) public loadingKey: string,
    private render: Renderer2,
    private loadingService: LoadingService) {

    this.loading$ = this.loadingService.progress$(this.loadingKey).pipe(tap(loadingData => {
      // if (!loadingData.spin || loadingData.progress == 100) {
      //   this.render.removeClass(document.body, 'overflow-hidden');
      // }
      // else {
      //   this.render.addClass(document.body, 'overflow-hidden');
      // }
    }));

  }

  get SpinIcon() {
    return this.loadingService.spinIcon ?? 'spin';
  }

  get SpinStyleClass() {
    return this.SpinIcon === 'spin' ?
      `spin-rotate text-primary` :
      `logo-rotate text-primary`;
  }

  get SpinStyle() {
    const width = this.SpinIcon === 'spin' ? '128px' : '60px';
    const height = this.SpinIcon === 'spin' ? '128px' : '60px';
    return { width, height };
  }

}
