import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import { MatCalendar, MatDatepickerIntl, yearsPerPage } from "@angular/material/datepicker";
import { Subject, takeUntil } from "rxjs";

/* copy unexported functions and vars from material source code */

let calendarHeaderId = 1;

function euclideanModulo(a: number, b: number): number {
  return ((a % b) + b) % b;
}

function getActiveOffset<D>(
  dateAdapter: DateAdapter<D>,
  activeDate: D,
  minDate: D | null,
  maxDate: D | null,
): number {
  const activeYear = dateAdapter.getYear(activeDate);
  return euclideanModulo(activeYear - getStartingYear(dateAdapter, minDate, maxDate), yearsPerPage);
}

/**
 * We pick a "starting" year such that either the maximum year would be at the end
 * or the minimum year would be at the beginning of a page.
 */
function getStartingYear<D>(
  dateAdapter: DateAdapter<D>,
  minDate: D | null,
  maxDate: D | null,
): number {
  let startingYear = 0;
  if (maxDate) {
    const maxYear = dateAdapter.getYear(maxDate);
    startingYear = maxYear - yearsPerPage + 1;
  } else if (minDate) {
    startingYear = dateAdapter.getYear(minDate);
  }
  return startingYear;
}
function isSameMultiYearView<D>(
  dateAdapter: DateAdapter<D>,
  date1: D,
  date2: D,
  minDate: D | null,
  maxDate: D | null,
): boolean {
  const year1 = dateAdapter.getYear(date1);
  const year2 = dateAdapter.getYear(date2);
  const startingYear = getStartingYear(dateAdapter, minDate, maxDate);
  return (
    Math.floor((year1 - startingYear) / yearsPerPage) ===
    Math.floor((year2 - startingYear) / yearsPerPage)
  );
}

/* End of copy */

/** Custom header component for datepicker. */
@Component({
  selector: 'a-calendar-header',
  templateUrl: 'calendar-header.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarHeader<D> implements OnDestroy {

  private _destroyed = new Subject<void>();
  private _id = `mat-calendar-header-${calendarHeaderId++}`;

  _periodButtonLabelId = `${this._id}-period-label`;

  constructor(
    private _intl: MatDatepickerIntl,
    public _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  previousEnabled(mode: 'month' | 'year'): boolean {
    if (!this._calendar.minDate) {
      return true;
    }
    return (
      !this._calendar.minDate || !this._isSameView(this._calendar.activeDate, this._calendar.minDate)
    );
  }

  previousClicked(mode: 'month' | 'year') {
    if (this._calendar.currentView === 'month' || this._calendar.currentView === 'year') {
      this._calendar.activeDate =
        mode === 'month' && this._calendar.currentView === 'month' ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
          : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    }
    else {
      this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, -yearsPerPage);
    }
  }

  /** Whether the next period button is enabled. */
  nextEnabled(mode: 'month' | 'year'): boolean {
    return (
      !this._calendar.maxDate || !this._isSameView(this._calendar.activeDate, this._calendar.maxDate)
    );
  }

  nextClicked(mode: 'month' | 'year') {
    if (this._calendar.currentView === 'month' || this._calendar.currentView === 'year') {
      this._calendar.activeDate =
        mode === 'month' && this._calendar.currentView === 'month'
          ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
          : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    }
    else {
      this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, yearsPerPage);
    }

  }

  currentPeriodClicked(): void {
    this._calendar.currentView = this._calendar.currentView == 'month' ? 'multi-year' : 'month';
  }

  get periodButtonLabel(): string {
    return {
      'month': this._intl.prevMonthLabel,
      'year': this._intl.prevYearLabel,
      'multi-year': this._intl.prevMultiYearLabel,
    }[this._calendar.currentView];
  }

  /** The label for the next button. */
  get nextButtonLabel(): string {
    return {
      'month': this._intl.nextMonthLabel,
      'year': this._intl.nextYearLabel,
      'multi-year': this._intl.nextMultiYearLabel,
    }[this._calendar.currentView];
  }

  get periodButtonText(): string {
    if (this._calendar.currentView == 'month') {
      return this._dateAdapter
        .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
        .toLocaleUpperCase();
    }
    if (this._calendar.currentView == 'year') {
      return this._dateAdapter.getYearName(this._calendar.activeDate);
    }

    return this._intl.formatYearRange(...this._formatMinAndMaxYearLabels());
  }

  private _formatMinAndMaxYearLabels(): [minYearLabel: string, maxYearLabel: string] {
    // The offset from the active year to the "slot" for the starting year is the
    // *actual* first rendered year in the multi-year view, and the last year is
    // just yearsPerPage - 1 away.
    const activeYear = this._dateAdapter.getYear(this._calendar.activeDate);
    const minYearOfPage =
      activeYear -
      getActiveOffset(
        this._dateAdapter,
        this._calendar.activeDate,
        this._calendar.minDate,
        this._calendar.maxDate,
      );
    const maxYearOfPage = minYearOfPage + yearsPerPage - 1;
    const minYearLabel = this._dateAdapter.getYearName(
      this._dateAdapter.createDate(minYearOfPage, 0, 1),
    );
    const maxYearLabel = this._dateAdapter.getYearName(
      this._dateAdapter.createDate(maxYearOfPage, 0, 1),
    );

    return [minYearLabel, maxYearLabel];
  }

  private _isSameView(date1: D, date2: D): boolean {
    if (this._calendar.currentView == 'month') {
      return (
        this._dateAdapter.getYear(date1) == this._dateAdapter.getYear(date2) &&
        this._dateAdapter.getMonth(date1) == this._dateAdapter.getMonth(date2)
      );
    }
    if (this._calendar.currentView == 'year') {
      return this._dateAdapter.getYear(date1) == this._dateAdapter.getYear(date2);
    }
    // Otherwise we are in 'multi-year' view.
    return isSameMultiYearView(
      this._dateAdapter,
      date1,
      date2,
      this._calendar.minDate,
      this._calendar.maxDate,
    );
  }

}
