<div class="bg-zinc-800 w-14 h-full">
  <ul class="pt-2">
    <li class="menu-item" *ngFor="let menu of items">
      <a class="menu-link" *ngIf="menu.visible!==false" [routerLink]="[menu.url]" routerLinkActive="router-link-active">
        <ng-container *ngIf="!menu.state?.['stackIcon']; else stackIcon">
          <i [ngClass]="menu.icon"></i>
        </ng-container>
        <ng-template #stackIcon>
          <span class="fa-stack fa-2x">
            <i class="{{menu.icon}} fa-stack-1x"></i>
            <i class="{{menu.state!['stackIcon']}} fa-stack-1x"></i>
          </span>
        </ng-template>
        <span class="menu-label">{{menu.label}}</span>
      </a>
    </li>
  </ul>
</div>