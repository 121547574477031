// data from common name of this open source API, nor sorted
// https://restcountries.com/v3.1/region/europe
export const europeCountries = [
  "Ukraine",
  "Slovakia",
  "Svalbard and Jan Mayen",
  "Italy",
  "Montenegro",
  "Estonia",
  "Netherlands",
  "Monaco",
  "Sweden",
  "Guernsey",
  "Iceland",
  "Norway",
  "San Marino",
  "Andorra",
  "Bulgaria",
  "United Kingdom",
  "Kosovo",
  "Czechia",
  "Gibraltar",
  "Romania",
  "Lithuania",
  "Russia",
  "Bosnia and Herzegovina",
  "Luxembourg",
  "Poland",
  "Faroe Islands",
  "Moldova",
  "Croatia",
  "Serbia",
  "Hungary",
  "Malta",
  "Latvia",
  "Isle of Man",
  "Finland",
  "Germany",
  "Cyprus",
  "Vatican City",
  "France",
  "Spain",
  "Slovenia",
  "Jersey",
  "Austria",
  "Belgium",
  "Belarus",
  "Portugal",
  "Greece",
  "Åland Islands",
  "Liechtenstein",
  "Albania",
  "Switzerland",
  "North Macedonia",
  "Ireland",
  "Denmark"
];