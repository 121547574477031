import { AuthCoreModule } from "@alterdomus/auth/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule, RouterStateSnapshot } from "@angular/router";
import { AUTH_CONFIG, defaultAuthConfig } from "./auth.config";
import { AuthenticateInterceptor } from "./auth.interceptor";
import { AuthService } from "./auth.service";
import { TokenStore } from "./token.store";

function initAuthModuleFactory(authService: AuthService) {
  return () => {
    console.log(`loading auth config`);
    const init$ = authService.loadAuthConfig();
    console.log(`initAuthModuleFactory called`);
    return init$;
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AuthCoreModule
  ],
  providers: [
    { provide: AUTH_CONFIG, useValue: defaultAuthConfig }
  ]
})
export class AuthModule {
  static forRoot(tokenStore?: TokenStore, loadAuthConfig?: boolean): ModuleWithProviders<AuthModule> {
    let providers = [];
    if (tokenStore) {
      providers.push({ provide: TokenStore, useValue: tokenStore });
    }
    if (loadAuthConfig) {
      providers.push({ provide: APP_INITIALIZER, useFactory: initAuthModuleFactory, deps: [AuthService], multi: true });
    }
    providers = [
      ...providers,
      { provide: HTTP_INTERCEPTORS, useClass: AuthenticateInterceptor, multi: true }
    ];
    return {
      ngModule: AuthModule,
      providers: [providers]
    }
  }
}
