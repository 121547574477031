import { HttpRequest } from "@angular/common/http";
import { Injectable, effect, signal } from "@angular/core";
import { HttpError } from "./http-error";
import { RemoteError } from "./remote-error";
import { RemoteErrorHandler } from "./remote-error.handler";

@Injectable({ providedIn: 'root' })
export class RemoteErrorSignals {
  readonly error = signal<RemoteError | null>(null);
  constructor(private erroHandler: RemoteErrorHandler) {
    effect(() => {
      const error = this.error();
      if (error) {
        this.erroHandler.handleError(error);
      }
    });
  }

  setError(error: RemoteError) {
    this.error.update(cur => ({ ...error }));
  }
}
