import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'a-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideNavComponent {
  slide = 'close';

  @Input()
  items: MenuItem[] = [
    {
      icon: 'fas fa-th-large',
      label: 'Dashbaord',
      url: '/dashboard',
      queryParams: {},
    },
    {
      icon: 'fas fa-address-book',
      label: 'Contacts',
      url: '/contact',
      queryParams: {},
    },
    {
      icon: 'fas fa-chart-line',
      label: 'Funds',
      url: '/fund',
      queryParams: {},
    },
    {
      icon: 'fas fa-coins',
      label: 'Accounts',
      url: '/account',
      queryParams: {},
    },
    {
      icon: 'fas fa-shopping-bag text-white',
      label: 'Data Rooms',
      url: '/dataroom',
      queryParams: {},
      state: {
        stackIcon: 'fas fa-dollar-sign mt-3 text-xs text-charcoal'
      }
    },
    {
      icon: 'fas fa-tasks',
      label: 'Assignments',
      url: '/assignment',
      queryParams: {},
    },
    {
      icon: 'fas fa-chart-pie',
      label: 'Report',
      url: '/report',
      queryParams: {},
    },
    {
      icon: 'fas fa-mail-bulk',
      label: 'Documents/Emails',
      url: '/email',
      queryParams: {},
    },
    {
      icon: 'fas fa-user-shield',
      label: 'Admin',
      url: '/admin',
      queryParams: {},
    },
    {
      icon: 'fas fa-box-open',
      label: 'Bulk Operations',
      url: '/bulk',
      queryParams: {},
    },
  ];
}
