import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EmbeddedViewRef, Inject, Optional, QueryList, ViewChildren, ViewEncapsulation } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { DateRange, ExtractDateTypeFromSelection, MAT_DATE_RANGE_SELECTION_STRATEGY, MatCalendar, MatCalendarUserEvent, MatDateRangeSelectionStrategy, MatDateSelectionModel, MatDatepickerContent, MatDatepickerIntl, MatRangeDateSelectionModel, matDatepickerAnimations } from "@angular/material/datepicker";
import { DateTime } from "luxon";
import { DATE_RANGE_PICKER_OPTION, DateRangePickerOption } from "./date-range-picker-option";
import { Observable, distinctUntilChanged, map, merge, tap } from "rxjs";
import { E } from "@angular/cdk/keycodes";

@Component({
  selector: 'a-date-range-picker-content',
  templateUrl: 'date-range-picker-content.html',
  // styleUrls: ['datepicker-content.css'],
  host: {
    'class': 'mat-datepicker-content',
    '[@transformPanel]': '_animationState',
    '(@transformPanel.start)': '_handleAnimationEvent($event)',
    '(@transformPanel.done)': '_handleAnimationEvent($event)',
    '[class.mat-datepicker-content-touch]': 'datepicker.touchUi',
  },
  animations: [matDatepickerAnimations.transformPanel, matDatepickerAnimations.fadeInCalendar],
  exportAs: 'dateRangePickerContent',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ['color'],
})
export class DateRangePickerContent<D>
  extends MatDatepickerContent<DateRange<D>, D> implements AfterViewInit {

  sameMonthView$: Observable<boolean>;

  private _adaptor: DateAdapter<D>;

  constructor(elementRef: ElementRef,
    _changeDetectorRef: ChangeDetectorRef,
    _globalModel: MatDateSelectionModel<DateRange<D>, D>,
    _dateAdapter: DateAdapter<D>,
    @Optional()
    @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
    _rangeSelectionStrategy: MatDateRangeSelectionStrategy<D>,
    intl: MatDatepickerIntl,
    @Inject(DATE_RANGE_PICKER_OPTION) private option: DateRangePickerOption) {
    super(elementRef, _changeDetectorRef, _globalModel, _dateAdapter, _rangeSelectionStrategy, intl);
    this._adaptor = _dateAdapter;
  }

  get end() {
    const selection = this._getSelected();
    // The default next month based on current day
    let _end = DateTime.now().startOf('day').plus({ months: 1 });
    if (selection && selection instanceof DateRange) {
      // if has selection start, next month based on selection start
      if (selection.start && selection.start instanceof Date) {
        _end = DateTime.fromJSDate(selection.start).startOf('day').plus({ months: 1 });
      }
      // if has selection end, pick the max end for second calendar
      if (selection.end && selection.end instanceof Date) {
        _end = DateTime.fromJSDate(selection.end);// DateTime.max(DateTime.fromJSDate(selection.end), _end);
      }
    }
    return _end.toJSDate();
  }

  @ViewChildren(MatCalendar)
  calendars?: QueryList<MatCalendar<any>>;

  apply(event: MouseEvent) {
    this.datepicker.close();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    if (this.calendars) {
      this.monitorCalendars(this.calendars);
    }
  }

  handleUserSelection(event: MatCalendarUserEvent<D | null>, data: { id: 'first' | 'last' }) {
    if (!this.option.duoDirectionSelectionStrategy) {
      super._handleUserSelection(event);
      return;
    }
    const model: MatRangeDateSelectionModel<D> = this['_model'];
    const dateAdapter: DateAdapter<D> = this['_dateAdapter'];
    const selection = model.selection;
    const value = event.value;
    const isRange = selection instanceof DateRange;

    if (
      value &&
      (isRange || !dateAdapter.sameDate(value, selection as unknown as D))
    ) {
      let start = selection.start;
      let end = selection.end;
      // pick from start calendar
      // scenario1: select a range from start calendar
      if (data.id == 'first') {
        if (!end || value <= end) {
          // start is valid when not choose end or start is not laterthan end
          start = value;
        }
        else {
          start = value;
          end = null;
        }
      }
      // scenario2: select a date from end
      else {
        if (!start || value >= start) {
          end = value;
        }
        else {
          start = null;
          end = null;
        }
      }
      model.updateSelection(new DateRange<D>(start, end), this);
    }
    else {
      super._handleUserSelection(event);
    }
  }

  override _handleUserDragDrop(event: MatCalendarUserEvent<DateRange<D>>) {
    console.log(event);
    super._handleUserDragDrop(event);
  }

  private monitorCalendars(calendars: QueryList<MatCalendar<any>>) {
    // calendars.toArray() not working
    if (calendars.length == 2) {
      this.sameMonthView$ = merge(calendars.first.stateChanges, calendars.last.stateChanges).pipe(
        map(() => {
          if (calendars.first?.activeDate && calendars.last?.activeDate) {
            const startMonth: any = new Date(calendars.first.activeDate.getFullYear(), calendars.first.activeDate.getMonth(), 1);
            const endMonth: any = new Date(calendars.last.activeDate.getFullYear(), calendars.last.activeDate.getMonth(), 1);
            return calendars.first.currentView == 'month' && calendars.last.currentView == 'month'
              && this._adaptor.compareDate(startMonth, endMonth) == 0
          }
          return false;
        }),
        distinctUntilChanged(),
        tap(val => {
        })
      )
    }
    calendars.toArray().forEach(changedCalendar => {
      monitorMonthViews(changedCalendar);
      changedCalendar.stateChanges.subscribe(() => {
        monitorMonthViews(changedCalendar);
      })
    })

    function monitorMonthViews(changedCalendar: MatCalendar<any>) {
      if (changedCalendar.monthView) {
        changedCalendar.monthView._matCalendarBody.previewChange.subscribe(change => {
          calendars.toArray().forEach(calendar => {
            if (calendar !== changedCalendar && calendar.monthView) {
              calendar.monthView._previewStart = changedCalendar.monthView._previewStart;
              calendar.monthView._previewEnd = changedCalendar.monthView._previewEnd;
              calendar.monthView._changeDetectorRef.detectChanges();
            }
          });
        });



        // changedCalendar.monthView._matCalendarBody.selectedValueChange.subscribe(change => {
        //   calendars.toArray().forEach(calendar => {
        //     // Clear the preview status for other calendars
        //     if (calendar !== changedCalendar && calendar.monthView) {
        //       // private _clearPreview() {
        //       calendar.monthView._previewStart = calendar.monthView._previewEnd = null;
        //       // }
        //       calendar.monthView._changeDetectorRef.detectChanges();
        //     }
        //   });
        // });

        changedCalendar.monthView._userSelection.subscribe(change => {
          calendars.toArray().forEach(calendar => {
            // Clear the preview status for other calendars
            if (calendar !== changedCalendar && calendar.monthView) {
              // private _clearPreview() {
              calendar.monthView._previewStart = calendar.monthView._previewEnd = null;
              // }
              calendar.monthView._changeDetectorRef.detectChanges();
            }
          });
        });
      }
    }
  }
}
