import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SearchBoxComponent } from './components/search-box/search-box.component';


@NgModule({
  declarations: [SearchBoxComponent],
  exports: [SearchBoxComponent],
  imports: [
    CommonModule,
    FormsModule,
    AutoCompleteModule
  ]
})
export class SearchBoxModule { }
