import { LoadingService } from '@alterdomus/common-ui/loading';
import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { RequestInfo } from './request-info';

@Injectable({
  providedIn: 'root'
})
export class RequestQueue {
  private requestQueue: RequestInfo[] = [];

  private timeOutHandler: any;
  private intervalHandler: any;

  private filteredRequest: RequestInfo[] = [];
  private filteredRequestFunc: (req: RequestInfo) => boolean = (req =>
    req.url.toLocaleLowerCase().endsWith('oauth2/v2.0/token') ||
    req.url.toLocaleLowerCase().indexOf('microsoftonline.com') >= 0
  );

  push(request: RequestInfo) {
    if (this.filteredRequestFunc(request)) {
      return;
    }
    this.requestQueue.push(request);
    this.updateProgress(request);
  }

  complete(request: RequestInfo) {
    request.completed = true;
    this.updateProgress(request);
  }
  constructor(private loadingService: LoadingService) {

  }

  private updateProgress(curRequest: RequestInfo) {
    // if (this.timeOutHandler) {
    //   clearTimeout(this.timeOutHandler);
    //   clearInterval(this.intervalHandler);
    //   this.timeOutHandler = null;
    //   this.intervalHandler = null;
    // }
    let batchedRequets: { [key: string]: RequestInfo[] } = {};
    const queue = this.requestQueue.filter(p => !this.filteredRequestFunc(p));
    queue.forEach(request => {
      if (!batchedRequets[request.batch || 'default']) {
        batchedRequets[request.batch || 'default'] = [];
      }
      batchedRequets[request.batch || 'default'].push(request);
    });
    // display loading by element
    // for (var batch in batchedRequets) {

    // user can specific request batch by set batchsize in the first request of the batch
    // in some case the batched request not tie very tighten
    const batch = curRequest.batch || 'default';
    if (batchedRequets[batch]) {
      const batchSize = Math.max(batchedRequets[batch].length, _.max(batchedRequets[batch].map(r => r.total)) || 0)
      const isHasSpin = batchedRequets[batch].filter(it => it.showLoading === 'spin').length > 0

      if (batchSize > 1 && !isHasSpin) {
        const pct = Math.round(batchedRequets[batch].filter(p => p.completed).length * 100 / batchSize);
        this.loadingService.showProgress(pct, curRequest.element || '');
        if (pct == 100) {
          // all request completed
          this.requestQueue = queue.filter(p => !batchedRequets[batch].find(c => c == p));
          // this.loadingService.reset(curRequest.element || '');
        }
      }
      else {
        if (batchedRequets[batch].some(req => !req.completed && req.showLoading !== 'false')) {
          this.loadingService.showProgress(-1, curRequest.element || '');
          // this.loadingService.reset(curRequest.element || '');
        }
        else {
          this.requestQueue = queue.filter(p => !batchedRequets[batch].some(b => b.completed && b == p));
          this.loadingService.reset(curRequest.element || '');
        }
      }
    }
    else {
      // console.log('no request in queue batch')
    }

    // }
    // a batch must be
    // for (var batch in batchedRequets) {
    //   // user can specific request batch by set batchsize in the first request of the batch
    //   // in some case the batched request not tie very tighten
    //   const batchSize = Math.max(batchedRequets[batch].length, _.max(batchedRequets[batch].map(r => r.total)) || 0)

    //   if (batchSize > 1) {
    //     const pct = Math.round(batchedRequets[batch].filter(p => p.completed).length * 100 / batchSize);
    //     this.loadingService.showProgress(pct, curRequest.element || '');
    //     if (pct == 100) {
    //       // all request completed
    //       this.requestQueue = queue.filter(p => !batchedRequets[batch].find(c => c == p));
    //     }
    //   }
    //   else {
    //     if (batchedRequets[batch].some(req => !req.completed)) {
    //       this.loadingService.showProgress(-1, curRequest.element || '');
    //     }
    //     else {
    //       this.requestQueue = queue.filter(p => !batchedRequets[batch].some(b => b.completed && b == p));
    //       this.loadingService.reset(curRequest.element || '');
    //     }
    //   }
    //   // if (curRequest.batch) {
    //   //   if (batchSize > 1) {
    //   //     const pct = Math.round(batchedRequets[batch].filter(p => p.completed).length * 100 / batchSize);
    //   //     this.loadingService.showProgress(pct, curRequest.element || '');
    //   //     if (pct == 100) {
    //   //       // all request completed
    //   //       this.requestQueue = queue.filter(p => !batchedRequets[batch].find(c => c == p));
    //   //     }
    //   //   }
    //   // }
    //   // else if (batchedRequets[batch].length >= 1) {
    //   //   if (batchedRequets[batch].some(req => !req.completed)) {
    //   //     this.loadingService.showProgress(-1, curRequest.element || '');
    //   //   }
    //   //   else {
    //   //     this.requestQueue = queue.filter(p => !batchedRequets[batch].some(b => b.completed && b == p));
    //   //     this.loadingService.reset(curRequest.element || '');
    //   //   }
    //   // }
    // }
    // if (!this.timeOutHandler) {
    //   this.intervalHandler = setInterval(() => this.loadingService.grow(curRequest.element || ''), 50);
    //   this.timeOutHandler = setTimeout(() => this.loadingService.reset(curRequest.element || ''), 1000);
    // }


    // loading can be on different element
    // a batch request should only show single loading
    // let grouped: { [key: string]: RequestInfo[] } = {};
    // const queue = this.requestQueue.filter(p => !this.filteredRequestFunc(p));
    // queue.forEach(request => {
    //   if (!grouped[request.element || 'default']) {
    //     grouped[request.element || 'default'] = [];
    //   }
    //   grouped[request.element || 'default'].push(request);
    // });
    // // display loading by element
    // // a batch must be
    // for (var element in grouped) {
    //   if (grouped[element].length > 1) {
    //     const pct = Math.round(grouped[element].filter(p => p.completed).length * 100 / grouped[element].length);
    //     if (pct == 100) {
    //       // all request completed
    //       this.requestQueue = queue.filter(p => !grouped[element].find(c => c == p));
    //     }
    //     else {
    //       this.loadingService.showProgress(pct, element == 'default' ? '' : element);
    //     }
    //   }
    //   else if (grouped[element].length == 1) {
    //     if (!grouped[element][0].completed) {
    //       this.loadingService.showProgress(-1, element == 'default' ? '' : element);
    //     }
    //     else {
    //       this.requestQueue = queue.filter(p => p != grouped[element][0]);
    //       this.loadingService.reset(element == 'default' ? '' : element);
    //     }
    //   }
    // }
  }
}
