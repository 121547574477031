import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, finalize, map, throwError } from 'rxjs';
import { END_POINTS, EndPoints } from './end-points';
import { RequestInfo } from './request-info';
import { RequestQueue } from './request.queue';

@Injectable()
export class RestInterceptor implements HttpInterceptor {
  constructor(private requestQueue: RequestQueue, @Inject(END_POINTS) private endPoints: EndPoints) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request: RequestInfo = {
      method: req.method,
      url: req.urlWithParams,
      batch: req.params.get('batch'),
      element: req.params.get('elementId'),
      total: parseInt(req.params.get('total') || '0'),
      showLoading: req.params.get('loading') || 'spin',
    };
    this.requestQueue.push(request);
    const newReq = req.clone({
      url: this.getTargetUrl(req.url),
      params: this.removeInternalParams(req.params)
    });
    return next.handle(newReq).pipe(
      map(response => this.processResponse(response)),
      catchError(err => {
        // request.error = new Error(err);
        return throwError(() => err);
      }),
      finalize(() => {
        this.completeRequest(request);
      })
    );

  }
  processResponse(response: HttpEvent<any>): any {
    if (response.type === HttpEventType.Response) {
      const { body } = response;
      if (body != null && typeof body === 'object' && ('__abp' in body || '__chao' in body) && body.success === true) {
        return response.clone({ body: body.result })
      }
    }

    return response;
  }

  private completeRequest(request: RequestInfo) {
    this.requestQueue.complete(request);
  }


  private removeInternalParams(params: HttpParams) {
    return params.delete('batch').delete('elementId').delete('total').delete('loading').delete('handleError');
  }
  private getTargetUrl(url: string) {
    if (url.startsWith('http')) {
      return url;
    }
    else {
      // url mapping if have
      // naming url is the highest level
      const urlMapping = url.split(':');
      if (urlMapping.length >= 2) {
        // time query may contains ':' as well
        if (this.endPoints[urlMapping[0]]) {
          return `${this.endPoints[urlMapping[0]]}${url.substring(urlMapping[0].length + 1, url.length)}`;
        }
      }
      else {
        // default and common processor
        if (url.startsWith('/') && this.endPoints['/']) {
          return `${this.endPoints['/']}${url.substring(1)}`
        }
        if (this.endPoints.default) {
          return `${this.endPoints.default}${url}`;
        }
      }
      return url;
    }
  }
}
