import { noLoading } from "@alterdomus/rest/interceptor";
import { setGlobalDateFormat } from "@alterdomus/shared/pipes";
import { HttpClient } from "@angular/common/http";
import { Injectable, Optional } from "@angular/core";
import { BehaviorSubject, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SettingService {
  private _settings: { [key: string]: any } = {};
  private _settingUpdateSub = new BehaviorSubject<{ [key: string]: any }>({});
  private _settings$ = this._settingUpdateSub.asObservable();
  constructor(@Optional() private http: HttpClient) {

  }
  set(key: string, value: any) {
    this._settings[key] = value;
    this._settingUpdateSub.next(this._settings);
    if (this.http) {
      this.http.put(`crm:api/v1/setting`, { key, value }, noLoading).subscribe();
    }
  }
  extend(settings: { [key: string]: any }) {
    this._settings = {
      ...this._settings,
      ...settings
    };
    if (settings['dateFormat']) {
      setGlobalDateFormat(settings['dateFormat']);
    }
    this._settingUpdateSub.next(this._settings);
  }
  get(key: string) {
    return this._settings[key];
  }
  getByKeys(...keys: string[]): { [key: string]: any } {
    return (keys || []).reduce((acc, cur) => ({ ...acc, [cur]: this._settings[cur] }), {});
  }
  setting$(key: string) {
    return this.settings$([key]).pipe(map(val => val[key]));
  }
  settings$(...keys: any[]) {
    return this._settings$.pipe(
      map(settings => {
        if (keys) {
          return (keys).reduce((acc, cur) => ({ ...acc, [cur]: this._settings[cur] }), {});
        }
        else {
          return settings;
        }
      }),
      distinctUntilChanged((prev, cur) => {
        const isEqual = Object.keys(cur).every(key => prev[key] == cur[key]);
        return isEqual;
      })
    )
  }
}
