import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { RemoteErrorSignals } from './remote-error.signal';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorSignals: RemoteErrorSignals) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const handleError = request.params.get('handleError') !== 'false';
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (handleError) {
          if (error.error instanceof Blob && (error.error.type === 'application/json' || error.error.type === 'application/problem+json')) {
            return this.parseBlob(error.error).pipe(
              tap(e => {
                this.handleError({ ...error, error: e }, request);
              }),
              catchError((parseError: any) => {
                const errorMessage = 'Parsing error message from blob failed.';
                const errorObject = new Error(errorMessage);
                this.handleError({ ...error, error: { code: 0, message: errorMessage } }, request);
                return throwError(() => errorObject);
              })
            );
          } else {
            this.handleError(error, request);
            return throwError(() => error);
          }
        }
        else {
          console.error('bypass global error handler');
          return throwError(() => error);
        }
      })
    );
  }

  private handleError(errorResp: HttpErrorResponse, request?: HttpRequest<any>) {
    const error = errorResp.error;
    const err = {
      status: error?.error?.code || errorResp.status,
      message: error?.error?.details ? error?.error?.message : 'Error',
      detail: error?.error?.details || error?.error?.message || 'Request failed.'
    }

    if (err.status != 401) {
      this.errorSignals.setError({
        error: err,
        request: request
      });
    }
  }

  private parseBlob(blob: Blob): Observable<any> {
    return new Observable((observer) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        try {
          const result = JSON.parse(reader.result as string);
          observer.next(result);
          observer.complete();
        } catch (error) {
          observer.error(error);
        }
      };
      reader.onerror = (error) => {
        observer.error(error);
      };
      reader.readAsText(blob);
    });
  }
}
