import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { App } from "./app.model";
import { AppStore } from "./app.store";

@Injectable({ providedIn: 'root' })
export class AppQuery extends Query<App>{
  constructor(protected override store: AppStore) {
    super(store);
  }
}
