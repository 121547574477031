import { DOWN_ARROW, LEFT_ARROW, PAGE_DOWN, PAGE_UP, RIGHT_ARROW, UP_ARROW } from '@angular/cdk/keycodes';
import { OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, Inject, forwardRef } from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { take } from 'rxjs';
import { DateRangePickerContent } from './date-range-picker-content';

@Directive({ selector: '[duo-calendar]' })
export class DuoCalendarDirective<D> {


  constructor(@Inject(forwardRef(() => MatDateRangePicker<D>)) private picker: MatDateRangePicker<D>) {

    const legacyPrivateOpen = this.picker['_openOverlay'];

    this.picker['_openOverlay'] = () => {
      const p = this.picker;
      this.newOpenOverlay.call(p, this.picker);
    };
  }

  newOpenOverlay(picker: MatDateRangePicker<D>) {
    const p = picker;
    p['_destroyOverlay']();

    const isDialog = p.touchUi;
    const portal = new ComponentPortal<DateRangePickerContent<D>>(
      DateRangePickerContent,
      p['_viewContainerRef'],
    );
    const overlayRef = (p['_overlayRef'] = p['_overlay'].create(
      new OverlayConfig({
        positionStrategy: isDialog ? p['_getDialogStrategy']() : p['_getDropdownStrategy'](),
        hasBackdrop: true,
        backdropClass: [
          isDialog ? 'cdk-overlay-dark-backdrop' : 'mat-overlay-transparent-backdrop',
          p['_backdropHarnessClass'],
        ],
        direction: p['_dir'],
        scrollStrategy: isDialog ? p['_overlay'].scrollStrategies.block() : p['_scrollStrategy'](),
        panelClass: `mat-datepicker-${isDialog ? 'dialog' : 'popup'}`,
      }),
    ));

    p['_getCloseStream'](overlayRef).subscribe((event: any) => {
      if (event) {
        event.preventDefault();
      }
      p.close();
    });

    // The `preventDefault` call happens inside the calendar as well, however focus moves into
    // it inside a timeout which can give browsers a chance to fire off a keyboard event in-between
    // that can scroll the page (see #24969). Always block default actions of arrow keys for the
    // entire overlay so the page doesn't get scrolled by accident.
    overlayRef.keydownEvents().subscribe((event: any) => {
      const keyCode = event.keyCode;

      if (
        keyCode === UP_ARROW ||
        keyCode === DOWN_ARROW ||
        keyCode === LEFT_ARROW ||
        keyCode === RIGHT_ARROW ||
        keyCode === PAGE_UP ||
        keyCode === PAGE_DOWN
      ) {
        event.preventDefault();
      }
    });

    p['_componentRef'] = overlayRef.attach(portal);
    p['_forwardContentValues'](p['_componentRef'].instance);

    // Update the position once the calendar has rendered. Only relevant in dropdown mode.
    if (!isDialog) {
      p['_ngZone'].onStable.pipe(take(1)).subscribe(() => overlayRef.updatePosition());
    }
  }

}