import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MenuItem } from "primeng/api";
import { Observable, debounceTime, map, shareReplay } from "rxjs";
import { Session } from "./models/session";

@Injectable({ providedIn: 'root' })
export class SessionProvider {
  constructor(private http: HttpClient) {

  }
  loadSession(): Observable<Session> {
    return this.http.get<Session>(`crm:api/abp/application-configuration`)
    // .pipe(
    //   map((response: any) => (<Session>{
    //     user: {
    //       ...response.currentUser,
    //       firstName: response.currentUser.firstName || response.currentUser['name'],
    //       lastName: response.currentUser.lastName || response.currentUser['name']
    //     },
    //     menus: this.mapToMenuItem(response?.extraProperties?.menus)
    //   }))
    // );
  }


}
