<div class="date-picker-common date-picker-container flex items-center gap-1">
  <span class="date-picker-label">{{label}}</span>
  <input matInput [min]="minDate" [max]="maxDate" [readonly]="readonly" [placeholder]="placeholder"
    [disabled]="disabled" (click)="clickInput($event,picker)" [matDatepicker]="picker" [value]="value"
    (dateChange)="onDateChange($event)">
  <i class="fa-solid fa-xmark text-base cursor-pointer" [ngClass]="{
    'text-transparent':!value,
    'text-charcoal-500':value,
    'hover:text-blue-500':value}" (click)="clear($event)"></i>
  <mat-datepicker-toggle matIconSuffix [for]="picker">
    <i matDatepickerToggleIcon
      class="fa-solid fa-calendar text-base leading-4 absolute top-0 left-0 p-1 data-picker-common-toggle-icon"></i>
  </mat-datepicker-toggle>
  <mat-datepicker #picker [calendarHeaderComponent]="customHeader" class="mat-datepicker-content-only"
    panelClass="date-picker-common-panel">
    <mat-datepicker-actions *ngIf="showApply">
      <button mat-raised-button color="primary" class="w-48" matDatepickerApply>Apply</button>
    </mat-datepicker-actions>
  </mat-datepicker>
</div>
