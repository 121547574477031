import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";
import { DateRange } from "@angular/material/datepicker";

export function DateRangeValidator(startRequired = true, endRequired = true, minDate: Date | null = null, maxData: Date | null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!startRequired && !endRequired) {
      return null;
    }
    const range = control.value as DateRange<Date>;
    if (!range) {
      return {
        required: 'Date range is required'
      }
    }
    else {
      if (startRequired && !range.start) {
        return {
          startRequired: 'Start date is required'
        }
      }
      if (endRequired && !range.end) {
        return {
          endRequired: 'End date is required'
        }
      }
      if (range.start && range.end && range.start > range.end) {
        return {
          invalid: 'Start date must be before End date'
        }
      }
      if (minDate && range.start && range.start < minDate) {
        return {
          minData: true
        }
      }
      if (maxData && range.end && range.end > maxData) {
        return {
          maxData: true
        }
      }
    }

    return null;
  };
}

@Directive({
  selector: '[date-range-validator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateRangeValidatorDirective, multi: true }]
})
export class DateRangeValidatorDirective implements Validator {
  @Input('start-required') startRequired = true;
  @Input('end-required') endRequired = true;
  @Input() minDate: Date | null = null;
  @Input() maxDate: Date | null = null;

  validate(control: AbstractControl): ValidationErrors | null {
    return DateRangeValidator(this.startRequired, this.endRequired, this.minDate, this.maxDate)(control);
  }
}