import { Injectable } from '@angular/core';
import { RemoteError } from './remote-error';

@Injectable({ providedIn: 'root' })
export class RemoteErrorHandler {
  constructor() {
  }

  handleError(error: RemoteError | null) {
    // don't handle
    console.log(error);
  }
}