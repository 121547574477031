import { Inject, Injectable } from "@angular/core";
import { AUTH_CONFIG, AuthConfig } from "./auth.config";
import { TokenStore } from "./token.store";

@Injectable({ providedIn: 'root' })
export class TokenService {
  constructor(
    @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
    private tokenStore: TokenStore) {
  }
  getToken() {
    return this.tokenStore.getItem(this.authConfig.tokenKey);
  }
  setToken(token: string) {
    this.tokenStore.setItem(this.authConfig.tokenKey, token);
  }
  clearToken(){
    localStorage.removeItem(this.authConfig.tokenKey);
  }
}
