export enum AuthEventType {
  RequireLogin,
  NoPermissions,
  Authenticated,    // Simply by check if token exist or not
  SessionLoaded     // This is the true event when user is logged in
}

export interface AuthEvent {
  type: AuthEventType,
  data?: any
}
