import { ProfileType } from "@alterdomus/crm-shared/core";

export interface FilterCat {
  label: string;
  path: string;
  iconClass: string;
  icon: string;
  type: ProfileType;
  checked: boolean;
  fields: string[];
}

export interface Filter {
  key?: string;
  cat: FilterCat[];
}
