import { SessionService } from '@alterdomus/auth/session';
import { Account, AccountQuery, AccountStore } from '@alterdomus/crm-shared/account-store';
import { Company, CompanyQuery, CompanyService, CompanyStore } from '@alterdomus/crm-shared/company-store';
import { Fund, FundQuery, FundStore } from '@alterdomus/crm-shared/fund-store';
// import { User, UserQuery, UserService, UserStore } from '@alterdomus/crm-shared/user-store';
import { AuthService } from '@alterdomus/auth/oauth2';
import { OperationWatcher } from '@alterdomus/common-ui/operation-watcher';
import { withBatch } from '@alterdomus/rest/interceptor';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { combineQueries } from '@datorama/akita';
import { OAuthErrorEvent } from 'angular-oauth2-oidc';
import { MenuItem, PrimeNGConfig, TreeNode } from 'primeng/api';
import { Observable, delay, filter, map, switchMap, tap } from 'rxjs';
import { SearchService } from './search/search.service';
import { AppQuery } from './store/app.query';

@Component({
  selector: 'a-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [PrimeNGConfig]
})
export class AppComponent implements OnInit {

  title = 'crm';

  version$;
  packMenu$ = this.sessionService.menus$('pack');
  mainMenu$ = this.sessionService.menus$('main');
  fabs$ = this.sessionService.menus$('float');
  profile$ = this.sessionService.menus$('profile');
  loginUser$ = this.sessionService.session$.pipe(filter(p => p.user?.isAuthenticated == true), map(p => p.user));
  avatorMode: any = 'fullName';
  showOverlay = false;
  currentSubMenu: MenuItem[] = [];

  @ViewChild('popButtonsTemp')
  popButtonsTemp!: TemplateRef<any>;
  overlayRef!: OverlayRef;

  // profileMenu$: Observable<MenuItem[]> = this.sessionService.profileMenu$;
  searchItems$?: Observable<any>;
  session$!: Observable<any>;
  clients$ = this.fundQuery.selectClients$();
  currentClientId$ = this.fundQuery.selectClientId$();
  cats = this.searchService.cats;
  constructor(private appQuery: AppQuery,
    private router: Router,
    // private oauthService: OAuthService,
    // private authService: AuthService,
    private fundQuery: FundQuery,
    private fundStore: FundStore,
    private accountQuery: AccountQuery,
    private companyStore: CompanyStore,
    private companyQuery: CompanyQuery,
    private companyService: CompanyService,
    // private userQuery: UserQuery,
    private searchService: SearchService,
    private sessionService: SessionService,

    private httpClient: HttpClient,
    private accountStore: AccountStore,

    private operationWatcher: OperationWatcher,
    private primengConfig: PrimeNGConfig,
    private authService: AuthService,
    private overlay: Overlay,
    public viewContainerRef: ViewContainerRef
  ) {

    this.version$ = this.appQuery.select(p => p.version);
    this.operationWatcher.operation$.subscribe(operation => {
      if (operation.name === 'new_funds' || operation.name === 'pickup') {

        this.currentSubMenu = (operation.state?.subMenus || []).map((p: any) => ({ ...p, label: p.displayName }));
        // this.showOverlay = true;
        this.overlayRef = this.overlay.create(new OverlayConfig({
          positionStrategy: this.overlay.position()
            .global().centerHorizontally().centerVertically(),
          hasBackdrop: true
        }))
        this.overlayRef.attach(new TemplatePortal(this.popButtonsTemp, this.viewContainerRef))
        this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
      }
    })

    // this.primengConfig.overlayOptions = {
    //   mode: 'modal',
    //   onAnimationStart: (event: any) => {
    //     if (event.toState === 'visible') {
    //       // Fix issue: when reopen the overlay, background will apply the leave style when modal is true
    //       DomHandler.removeClass(event.element.parentElement, 'p-component-overlay-leave')
    //     }
    //   },
    // };
  }
  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (document.body) {
          document.body.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    });
  }
  private initialStores(loadCompany = true) {
    const storeBatch = 'profile';
    this.httpClient.get<Fund[]>(`crm:/api/v1/fund`, withBatch(storeBatch, 4))
      .pipe(
        tap(funds => {
          this.fundStore.set(funds);
        }),
        delay(50),
        switchMap(() => this.httpClient.get<Account[]>(`crm:/api/v1/account`, withBatch(storeBatch))
          .pipe(tap(accounts => this.accountStore.set(accounts)))
        ),
        delay(50),
        switchMap(() =>
          this.httpClient.get<Company[]>(`crm:/api/v1/company`, withBatch(storeBatch))
            .pipe(tap(companies => {
              this.companyStore.set(companies);
            }))
        ),
        delay(50),
        // switchMap(() => this.httpClient.get<User[]>(`crm:/api/v1/user`, withBatch(storeBatch))
        //   .pipe(tap(users => this.userStore.set(users)))
        // )
      ).subscribe(() => {
      })


    this.currentClientId$.pipe(
      // delay(50),
      switchMap(clientId => {
        return combineQueries([this.companyQuery.selectComapnyWithUsers()
          .pipe(
            tap(companies => {
              if (clientId) {
                this.companyService.reloadCompany(clientId);
              }
            })
          ),
          // this.userQuery.selectUsers()
          //   .pipe(
          //     tap(users => {
          //       if (clientId && !users) {
          //         this.userService.reloadUsers(clientId);
          //       }
          //     })
          //   )
        ])
      })
    ).subscribe()
    this.searchItems$ = this.searchService.searchItems$;
  }

  changeClient(event: any) {
    this.fundStore.selectClient(event.value?.id)
  }


  updateSearchFilters(filters: { key: string, cats: any[] }) {
    this.searchService.updateFilters(filters);
  }

  // private configureCodeFlow() {
  //   this.oauthService.configure(authCodeFlowConfig);
  //   this.oauthService.loadDiscoveryDocumentAndTryLogin().then((_) => {
  //     if (false) {
  //       this.router.navigate(['/']);
  //     }
  //   });

  //   // Optional
  //   this.oauthService.setupAutomaticSilentRefresh();
  // }

  logout = (event: PointerEvent) => {
    /*this.authService.beginAuth()*/
    this.authService.logout();
  };
  private setOAuth() {
    // this.oauthService.configure(authConfig);
    // this.oauthService.setupAutomaticSilentRefresh();

    // // this.oauthService.events.subscribe((event: OAuthEvent) => {

    // //   if (event.type === 'token_received') {
    // //     if (!this.sessionStore.getValue().user) {
    // //       this.sessionService.session$.subscribe(() => {
    // //         console.debug('logged in');
    // //         this.router.navigate(['/dashboard']);
    // //       });
    // //     }
    // //   }
    // // });


    // const url =
    //   authConfig.issuer +
    //   '/.well-known/openid-configuration';

    // The convenience method mentioned in the docs (loadDiscoveryDocumentAndLogin) won't work
    // since we need a way to modify the token endpoint

  }
  private userHasEnteredPasswordResetFlow(): boolean {
    return window.location.search.indexOf('PASSWORD_RESET') > -1;
  }

  private userHasRequestedPasswordReset(err: OAuthErrorEvent): boolean {
    const errdes = err && (err.params as any)['error_description'];
    return errdes && errdes.startsWith(
      'AADB2C90118'
    );
  }


  onMenuClick(menu: TreeNode<MenuItem>) {
    // if (menu.data!.label === 'Log out') {
    //   this.authService.logout();
    // }
  }
  menuClick(event: MouseEvent, menu: MenuItem) {
    if (menu.command) {
      menu.command({ originalEvent: event, item: menu });
    } else if (menu.routerLink) {
      this.router.navigate([menu.routerLink]);
    }
    else if (menu.url) {
      location.href = menu.url;
    }
  }
  click(event: any, item: any) { };
}
