<div class="sticky top-0 z-50 bg-white">
  <a-nav styleClass="a-pack-nav" *ngIf="(packMenu$ | async) as packMenus" [items]="packMenus"></a-nav>
  <a-nav>
    <div class="flex justify-center" logo>
      <img src="https://alterdomus.com/wp-content/uploads/2023/08/Logo.svg" />
    </div>
    <div class="flex justify-center font-semibold" title>App Title</div>
    <div class="h-full flex items-center gap-1" *ngIf="loginUser$ | async as loginUser" end>
      <a-global-search></a-global-search>
      <a-profile-menu *ngIf="(profile$ | async) as profileMenus" [user]="loginUser" [avatorMode]="avatorMode"
        [menus]="profileMenus" [logoutHandler]="logout"></a-profile-menu>
    </div>
  </a-nav>
</div>
<div class="relative flex">
  <a-side-nav *ngIf="mainMenu$ | async as mainMenu" [items]="mainMenu"></a-side-nav>
  <div class="flex-1 overflow-hidden">
    <a-breadcrumb styleClass="text-sm"></a-breadcrumb>
    <div class="min-h-[32rem] lg:min-h-[28rem]">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<a-footer></a-footer>
<a-float-action-buttons *ngIf="(fabs$ | async) as fabs" [menus]="fabs" [dock]="true"></a-float-action-buttons>
<p-confirmDialog></p-confirmDialog>
<!-- <ng-template #popButtonsTemp>
  <ul class="pop-button-item-list">
    <li *ngFor="let item of currentSubMenu">
      <button pButton (click)="click($event,item)" class="flex flex-col  flex-layout"
        label="{{item.label || $any(item)['displayName']}}" icon="{{item.icon}}"></button>
    </li>
  </ul>
</ng-template> -->
<!--TODO: create a component to reuse it-->
<!-- <p-overlay [(visible)]="showOverlay" contentStyleClass="flex items-center justify-center w-0">
  <ng-template pTemplate=" content" let-option>
    <ul class="list-none bg-white flex gap-4 p-4 rounded shadow">
      <li *ngFor="let menu of currentSubMenu">
        <button pButton class="flex w-48 h-24 rounded p-button-outlined" (click)="menuClick($event,menu)"
          icon="{{menu.icon}}" label="{{menu.label}}"></button>
      </li>
    </ul>
  </ng-template>
</p-overlay> -->
