import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { MENU_CONFIG, breadCrumbConfig } from '../../bread-crumb-config';
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'a-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnInit {

  items$: Observable<MenuItem[]>;
  visible$: Observable<boolean>;
  home: MenuItem = {};

  @Input()
  styleClass = 'text-xs';

  constructor(
    @Inject(MENU_CONFIG) private config: breadCrumbConfig,
    private titleService: TitleService
  ) {
    this.items$ = this.titleService.menus$;
    this.visible$ = this.titleService.visible$;
  }

  ngOnInit() {
    this.home = {
      label: (this.config.showHomeTitle && this.config.homeTitle) ? this.config.homeTitle : '',
      icon: (this.config.showHomeIcon && this.config.homeIcon) ? this.config.homeIcon : '',
      routerLink: this.config.homeRouterLink
    };
  }

}
