<div class="relative w-[400px]">
  <div class="flex items-center">
    <input #input type="text" placeholder="Search profiles here..." (keyup)="keyChanged($event,input)"
      (focus)="checkPanelStatus($event)" class="flex-1 border outline-none p-2 text-sm h-8 lg:w-60 text-gray-800" />
    <button
      class="border border-primary-500 px-2 h-8 bg-primary-500 text-sm text-white hover:bg-gray-300 hover:border-gray-300 transition">Search</button>
  </div>
  <div class="fixed flex-col bg-white w-[400px] overflow-hidden shadow" [@slideDown]="resultPanelStatus">
    <p-virtualScroller [value]="searchResults" scrollHeight="360px" [itemSize]="24" styleClass="search-result-list">
      <ng-template pTemplate="header">
        <div class="text-sm flex items-center">
          <div class="text-sm min-w-[96px] h-9 bg-gray-100 text-right pr-1">Filter By</div>
          <div class="flex items-center flex-wrap space-x-1 space-x-reverse">
            <div class="flex items-center px-1" *ngFor="let cat of cats">
              <p-checkbox [binary]="true" styleClass="p-checkbox-sm select-none" checkboxIcon="fa fa-check"
                [(ngModel)]="cat.show" (onChange)="catChanged()" [label]="cat.label">
              </p-checkbox>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="item" let-i="index">
        <div class="flex w-full items-center text-sm h-6" [ngClass]="{'border-t': item.label}">
          <div class="text-sm min-w-[96px] bg-gray-100 h-full text-right pr-1">{{item.label}}</div>
          <div class="flex-1 flex items-center h-full overflow-hidden whitespace-nowrap pl-1 hover:bg-gray-100">
            <a class="text-ellipsis overflow-hidden" [routerLink]="[item.routerLink,item.id]"
              [title]="item.name">{{item.name}}</a>
          </div>
        </div>
      </ng-template>
    </p-virtualScroller>
    <!-- <div>

     <a-list [items]="searchResults" styleClass="single-col h-[300px]" [itemDimension]="{width:0,height:30}">
        <ng-template let-item>
          <div class="flex w-full items-center text-sm">
            <div class="text-sm min-w-[80px] bg-gray-100"></div>
            <div class="bg-white flex-1 flex items-center h-[30px] overflow-hidden text-ellipsis">
              <a [routerLink]="[item.routerLink,item.id]">{{item.name}}</a>
            </div>
          </div>
        </ng-template>
      </a-list> -->
    <!-- <ul virtualScroller #scroll [items]="searchResults" style="height: 380px;" class="thin-scroller">
        <li *ngFor="let item of scroll.viewPortItems; let i=index;">
          <ng-template
            *ngTemplateOutlet="itemTemplate || plainItem; context: { $implicit : item.data, cat:item.cat, index:item.index }">
          </ng-template>
        </li>
      </ul>
    </div> -->
  </div>
</div>
