import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationWatcher } from './operation.watcher';

@NgModule({
  imports: [CommonModule],
  // providers: [OperationWatcher]    // not work for create global operation watcher
})
export class OperationWatcherModule {
  // Call this in app module to register the operation watcher singleton in root
  static forRoot(): ModuleWithProviders<OperationWatcherModule> {
    return {
      ngModule: OperationWatcherModule,
      providers: [OperationWatcher]
    }
  }
}
