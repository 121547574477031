import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, ModuleWithProviders, NgModule, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MAT_SINGLE_DATE_SELECTION_MODEL_PROVIDER, MatDatepicker, MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CalendarHeader } from './calendar-header';
import { LuxonDateAdaptor } from './luxon-date-adaptor';

export const DATEPICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerComponent),
  multi: true
};

@Component({
  selector: 'a-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    MAT_SINGLE_DATE_SELECTION_MODEL_PROVIDER,
    DATEPICKER_VALUE_ACCESSOR]
})
export class DatePickerComponent implements ControlValueAccessor {

  customHeader = CalendarHeader;

  @Input()
  label = 'Date:';

  @Input()
  readonly = true;

  @Input()
  disabled?: boolean;

  @Input()
  minDate: Date | null = null;

  @Input()
  maxDate: Date | null = null;

  @Input()
  showApply = false;

  @Input()
  placeholder = 'dd-mmm-yyyy';

  value: Date | null = null;

  @Output()
  dateChange = new EventEmitter<Date | null>();

  // @ViewChild('input', { static: true })
  // input!: MatInput;


  @ViewChild(MatDatepicker, { static: true })
  picker: MatDatepicker<Date>;

  // @ViewChild('calendar', { read: MatCalendar, static: true })
  // calendar: MatCalendar<Date>;

  constructor(private cd: ChangeDetectorRef) {

  }

  clickInput(event: FocusEvent, picker: MatDatepicker<Date>) {
    if (this.readonly && !picker.opened) {
      picker.open();
    }
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    this.value = event.value;
    if (this.onModelChange)
      this.onModelChange(event.value);
    this.dateChange.next(event.value);
    this.cd.markForCheck();
  }

  clear(event: MouseEvent) {
    this.value = null;
    if (this.onModelChange)
      this.onModelChange(null);
    this.dateChange.next(null);
    this.cd.markForCheck();
  }


  // Reactive form interfaces

  public onModelChange: any;;

  public onModelTouched: any;;

  writeValue(value: any): void {
    this.value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.cd.markForCheck();
  }

}

@NgModule({
  imports: [CommonModule, MatIconModule, MatInputModule, MatButtonModule, MatDatepickerModule, MatNativeDateModule],
  declarations: [DatePickerComponent, CalendarHeader],
  exports: [DatePickerComponent, CalendarHeader],
})
export class DatePickerModule {
  static withCustomFormat(): ModuleWithProviders<DatePickerModule> {
    return {
      ngModule: DatePickerModule,
      providers: [
        {
          provide: MAT_DATE_FORMATS, useValue: {
            ...MAT_NATIVE_DATE_FORMATS, display: {
              ...MAT_NATIVE_DATE_FORMATS.display,
              dateInput: true   // true to indicate format on range display
            }
          }
        },
        { provide: DateAdapter, useClass: LuxonDateAdaptor }
      ]
    };
  }
}
