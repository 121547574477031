import { CommonModule } from '@angular/common';
import { AfterViewInit, Directive, NgModule } from '@angular/core';
import { SplitButtonModule as PSplitButtonModule, SplitButton } from 'primeng/splitbutton';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'p-splitbutton',
})
export class SplitbuttonDirective implements AfterViewInit {
  constructor(private splitButton: SplitButton) {
  }
  ngAfterViewInit(): void {
    this.overrideSplitButton();
  }
  overrideSplitButton() {
    if (this.splitButton) {
      const tieredMenu = this.splitButton.menu;
      if (tieredMenu) {
        tieredMenu.onOverlayAnimationEnd = (event) => {
          this.splitButton.isExpanded.set(tieredMenu.visible == true);
        }
        const originalHide = tieredMenu.hide;
        tieredMenu.hide = () => {
          this.splitButton.isExpanded.set(false);
          originalHide.call(tieredMenu);
        }
      }
    }
  }
}

@NgModule({
  imports: [CommonModule, PSplitButtonModule],
  declarations: [SplitbuttonDirective],
  exports: [SplitbuttonDirective, PSplitButtonModule],
})
export class SplitButtonModule { }
