import { DateTime } from "luxon";

let dateFormat: string = getDateFormatByPriority('M/dd/yyyy'); // CorPro currently has 2 date format: dd-MMM-yy (01-Apr-24) and M/dd/yyyy (4/01/2024)


function getDateFormatByPriority(format: string) {
  // shorterm solution
  const userFormat = localStorage.getItem('format');
  console.log(`############set format as ${format},from local storage is${userFormat}##############`)
  if (userFormat) {
    try {
      const { date } = JSON.parse(userFormat);
      return date ?? format;
    }
    catch (ex) {
      console.error(ex);
      return format;
    }
  }
  else {
    return format;
  }
}


export function setGlobalDateFormat(format: string) {
  dateFormat = getDateFormatByPriority(format);
  console.log(`the final date format is ${format}`);
}
export function getGlobalDateFormat() {
  return dateFormat;
}

export function getDateTimeFormat() {
  return `${dateFormat} HH:mm:ss`
}


export function formatJSDate(date?: Date | string) {
  return date == null || (typeof date === 'string' && !date.length) ? '' : transferToDateTime(date).toFormat(getGlobalDateFormat());
}

export function formatJSDateTime(datetime?: Date | string) {
  return datetime == null || (typeof datetime === 'string' && !datetime.length) ? '' : transferToDateTime(datetime).toFormat(getDateTimeFormat());
}

function transferToDateTime(date?: Date | string) {
  if (date == null) {
    return DateTime.now();
  } else if (typeof date === 'string') {
    return DateTime.fromISO(date);
  } else {
    return DateTime.fromJSDate(date);
  }
}

export function getDateFormatForDateRage() {
  return 'MM/dd/yyyy'
}
