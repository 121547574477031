import { SearchBoxComponent } from '@alterdomus/common-ui/search-box';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({
  selector: 'a-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnChanges {

  @Input()
  showHeader = false;

  @Input()
  searchable = false;

  @Input()
  disabled = false;

  @Input()
  styleClass = '';

  @Input()
  headerStyleClass = '';

  @Input()
  minBufferPx = 100;

  @Input()
  maxBufferPx = 200;

  @Input()
  itemDimension: any = { width: 200, height: 50 };

  @Input()
  itemStyleClass = '';

  @Input()
  showSelectedCount = true;

  private _visibleItemSize = 0;
  @Input()
  get visibleItemSize() {
    if (this.items && this.items.length > 0) {
      return Math.min(this.items.length, this._visibleItemSize);
    }
    else {
      return this._visibleItemSize;
    }
  }
  set visibleItemSize(val) {
    this._visibleItemSize = val;
  }

  @Input()
  searchFields: string[] = [];

  _selectionMode: 'single' | 'multiple' | null = 'single';
  @Input()
  get selectionMode() {
    return this._selectionMode;
  }
  set selectionMode(val) {
    this._selectionMode = val;
    this.createSelectionModel();
  }
  selectionModel: SelectionModel<any> = new SelectionModel<any>(this._selectionMode === 'multiple', [], true);

  @ContentChild(TemplateRef, { static: false })
  itemTemplate: TemplateRef<any> | undefined;

  // private _selectedItem: any[] = [];
  @Input()
  get selectedItem() {
    return this.selectionModel.selected;
  }
  set selectedItem(vals: any[]) {
    if (!vals || vals.length == 0) {
      this.selectionModel.clear();
    }
    else {
      if (this.selectionModel.isMultipleSelection()) {
        // reset selection, otherwise selected items might not be unselected
        // if (vals.length && _.isEqual(this.selectionModel.selected, vals)) {
        //   return;
        // }
        this.selectionModel.clear(false);
        this.selectionModel.select(...vals);
      }
      else {
        this.selectionModel.select(vals[0]);
      }
    }
  }


  @Output()
  selectionChanged = new EventEmitter<any[]>();

  private _items: any[] | null = Array.from({ length: 100 }).map((_, i) => `Item #${i}`);
  @Input()
  get items() {
    return this._items;
  }
  set items(val) {
    const flushEvent = this.compareWith ? false : true;
    if (!_.isEqual(this._items, val)) {
      // sometimes items will set multi-times
      this.clearSelection(flushEvent);
    }
    this._items = val;
    // TODO: option to flush event or not
    // TODO: clear selection or update selection with customized compareWith


  }

  @Input()
  showSelectAll = true;

  @Input()
  selectOnClick = true;

  @Input()
  compareWith?: (o1: any, o2: any) => boolean;

  @Input()
  itemSelectable?: (item: any) => boolean;

  filterItems?: any[] = undefined;

  allSelected = false;
  partialSelected = false;
  selectedCount?: number;

  @ViewChild('searchbox', { static: false })
  searchBox: SearchBoxComponent;

  @Input()
  filter: 'startsWith' | 'contains' = 'contains';

  constructor() {
    this.createSelectionModel();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectionMode'] || changes['compareWith']) {
      this.createSelectionModel();
    }
  }

  ngOnInit(): void {

  }

  clickItem(event: any, item: any) {
    if (!this.selectionMode) {
      return;
    }
    if (this.selectOnClick) {
      if (event) {
        event.stopPropagation();
      }
      if (this.selectionMode === 'multiple') {
        this.selectionModel.toggle(item);
      }
      else {
        this.selectionModel.select(item);
      }
      const selectedItems = this.selectionModel.selected;
    }
  }

  selectAll(select: boolean) {
    const availableItemsToSelect = (this.items || []).filter(p => !this.itemSelectable || this.itemSelectable(p));
    select ? this.selectionModel.select(...availableItemsToSelect) : this.selectionModel.clear();
  }

  isSelected(item: any) {
    return this.selectionModel.isSelected(item);
  }

  clearSelection(flushEvent = true) {
    this.selectionModel.clear(flushEvent);
    this.filterItems = undefined;
    if (this.searchBox) {
      this.searchBox.clearSearch(false);
    }
  }

  subs: Subscription | null = null;
  createSelectionModel() {
    if (this.selectionModel) {
      if (this.subs)
        this.subs.unsubscribe();
    }
    this.selectionModel = new SelectionModel<any>(this._selectionMode === 'multiple', this.selectedItem, true, this.compareWith);
    this.subs = this.selectionModel.changed.subscribe(p => {
      const selectableItems = (this.items || []).filter(p => !this.itemSelectable || this.itemSelectable(p));
      this.allSelected = selectableItems.every(p => this.selectionModel.isSelected(p));
      this.partialSelected = this.selectionModel.hasValue() && !selectableItems.every(p => this.selectionModel.isSelected(p));
      this.selectedCount = this.selectionModel?.selected?.length;
      this.selectionChanged.next(p.added);
    })
  }

}
