import { Client } from "../model/client";

export interface Fund {
  id: number,
  shortDealName: string,
  client?: Client;
  status: any
  profile?: any;
  isDataRoom: boolean;
  fundProfileType:number;
}
