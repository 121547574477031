import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Fund } from "./fund.model";

export interface FundState extends EntityState<Fund, number> {
  selectedClient?: number,
  viewBy: number
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'fund', resettable: true })
export class FundStore extends EntityStore<FundState>{
  constructor() {
    super({ selectedClient: undefined, viewBy: 1 });
  }

  selectClient(clientId?: number) {
    this.update({ selectedClient: clientId });
  }
}
