import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Company } from "./company.model";

export interface CompanyState extends EntityState<Company> {
  clientCompanies: { [clientId: number]: Company[] }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'company', resettable: true })
export class CompanyStore extends EntityStore<CompanyState>{
  constructor() {
    super({ clientCompanies: {} });

  }
  setClientCompanies(clientId: number, companies: Company[]) {
    this.update(state => ({
      ...state,
      clientCompanies: {
        ...state.clientCompanies,
        [clientId]: companies
      }
    }))
  }
}
