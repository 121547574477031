import { Operation, ProfileDto, TabDataDto } from '@alterdomus/crm-shared/core';
import { ProfileTab } from '@alterdomus/crm-shared/profile-form-tab';
import { noLoading } from '@alterdomus/rest/interceptor';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { ROUTER_NAVIGATED, RouterNavigatedAction, getRouterSelectors } from '@ngrx/router-store';
import { Store, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import * as _ from 'lodash-es';
import { filter, map, switchMap, withLatestFrom } from 'rxjs';

const expirationTime = 5 * 1000; // 10

const ProfileTypeApiMap: { [key: string]: string } = {
  contact: 'user',
  accountcontact: 'contact'
}

export interface Profile extends ProfileDto {
  key: string;
  expireAt: number;
}

//----------------------------------------
// Actions
//----------------------------------------

export const reloadProfileTab = createAction(
  '[Profile] Reload Profile Tab',
  props<{ profileType: string, id: number, tabName: string }>()
)

export const loadProfileSuccess = createAction(
  '[Profile] Load Profile Success',
  props<{ profile: Profile }>()
);



export const loadProfileTabSuccess = createAction(
  '[Profile] Load Profile Tab Success',
  props<{ tabData: TabDataDto, key: string }>()
);


// ---------------------------------------
// State
// ---------------------------------------
export interface ProfileState extends EntityState<Profile> {
  selectedProfileKey: string | null;
  loading: boolean
}

// ---------------------------------------
// Entity Adapter
// ---------------------------------------
export const selectEntityId = ({ type, id }: { type: string, id: number }) => `${type}-${id}`;
export const profileAdapter = createEntityAdapter<Profile>({
  selectId: p => p.key
});


export const initialProfileState: ProfileState = profileAdapter.getInitialState({
  selectedProfileKey: null,
  loading: false
});

export const profileReducer = createReducer(
  initialProfileState,
  on(loadProfileSuccess, (state, { profile }) => {
    return profileAdapter.upsertOne(
      {
        ...profile,
        tabs: profile?.tabs?.map(t => ({
          ...t,
          // if the entity loaded before, make sure the tab data is not lost
          ...state.entities[profile.key]?.tabs?.find(pt => t.name && pt.name?.toLowerCase() === t.name?.toLowerCase()),
          name: t.name?.toLowerCase()
        })),
        expireAt: new Date().getTime() + expirationTime
      },
      { ...state, selectedProfileKey: profile?.key, loading: false }
    );
  }),
  on(loadProfileTabSuccess, (state, { tabData, key }) => {
    const profile = state.entities[key];
    return profileAdapter.upsertOne(
      {
        ...profile,
        key: key,
        tabs: profile?.tabs?.map(t => {
          if (t.name && t.name.toLowerCase() === tabData?.name?.toLowerCase()) {
            return { ...t, ...tabData, name: t.name.toLowerCase(), loading: false };
          }
          return t;
        }),
        expireAt: new Date().getTime() + expirationTime,
      },
      { ...state, selectedProfileKey: key, loading: false }
    );
  }),
  on(reloadProfileTab, (state, { profileType, id, tabName }) => {
    const key = selectEntityId({ type: profileType, id });
    const profile = state.entities[key];
    if (profile) {
      return profileAdapter.upsertOne(
        {
          ...profile,
          tabs: profile.tabs?.map(t => {
            if (t.name && t.name.toLowerCase() === tabName.toLowerCase()) {
              return { ...t, name: t.name.toLowerCase(), data: null, loading: false };
            }
            return t;
          })
        },
        { ...state, selectedProfileKey: key, loading: true }
      );
    }
    else {
      return { ...state, loading: true }
    }
  })
);

// ---------------------------------------
// Selectors
// ---------------------------------------
export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectRouteDataParam, // factory function to select a route data param
  selectUrl, // select the current url
  selectTitle, // select the title if available
} = getRouterSelectors();
export const selectProfileState = createFeatureSelector<ProfileState>('profile');
export const selectProfileId = selectRouteParam('id');

export const {
  selectEntities: selectedProfileEntities,
} = profileAdapter.getSelectors(selectProfileState);

export const selectLoading = createSelector(
  selectProfileState,
  state => state.loading
);


const parseParams = (url: string) => {
  const params = url.split('/');
  return { type: params[1], id: +params[2], tabName: params[3] || '' };
}

export const isProfileLoaded = createSelector(
  selectedProfileEntities,
  selectUrl,
  (entities, url) => {
    if (!url) return false;
    const params = parseParams(url);
    const profileKey = selectEntityId(params);
    return !!entities[profileKey] && entities[profileKey]!.expireAt > new Date().getTime();
  }
);

export const selectProfile = createSelector(
  selectedProfileEntities,
  selectUrl,
  (entities, url) => {
    const params = parseParams(url);
    const profileKey = selectEntityId(params);
    return entities[profileKey] as ProfileDto;
  }
);

export const isProfileTabLoaded = createSelector(
  selectedProfileEntities,
  selectUrl,
  (entities, url) => {
    if (!url) return false;
    const params = parseParams(url);
    const profileKey = selectEntityId(params);
    const tabName = params.tabName;
    return tabName && !!entities[profileKey] && entities[profileKey]!.expireAt > new Date().getTime()
      && entities[profileKey]!.tabs?.find(t => t.name?.toLowerCase() === tabName.toLowerCase())?.data;
  }
);

export const selectProfileTab = createSelector(
  selectedProfileEntities,
  selectUrl,
  selectLoading,
  (entities, url, loading) => {
    const params = parseParams(url);
    const profileKey = selectEntityId(params);
    const tabName = params.tabName;
    const profileTab = entities[profileKey]?.tabs?.find(t => t.name?.toLowerCase() === tabName.toLowerCase()) as ProfileTab;
    return { ...profileTab, loading: loading };
  }
);

export const isProfilePageUrl = (action: RouterNavigatedAction) => {
  let firstChild = action.payload.routerState.root.firstChild;
  while (firstChild?.firstChild) {
    firstChild = firstChild.firstChild;
  }
  const profileRouteUrls = ['/fund/', '/account/', '/company/', '/contact/', '/dataroom', '/spv/']
  return profileRouteUrls.some(prefix => action.payload.routerState.url.startsWith(prefix))
    && parseInt(firstChild?.params?.['id']) > 0;
}

export const isProfileTabUrl = (action: RouterNavigatedAction) => {
  const excludedTabs = ['permissions', 'documents', 'notes', 'tasks', 'contacts', 'additionalrelationships', 'opportunities', 'accounts', 'investments', 'investors', 'spvs', 'companies', 'contacts'];
  let firstChild = action.payload.routerState.root.firstChild;
  while (firstChild?.firstChild) {
    firstChild = firstChild.firstChild;
  }
  const params = action.payload.routerState.url.split('/');
  return params.length == 4 && !excludedTabs.includes(params[3].toLowerCase());
}

@Injectable({ providedIn: 'root' })
export class ProfileEffects {
  loadDataOnNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
      filter(isProfilePageUrl),
      withLatestFrom(
        this.store.select(isProfileLoaded),
        this.store.select(selectCurrentRoute)
      ),
      filter(([action, loaded, route]) => !loaded),
      // tap(([action, loaded, route]) => {
      //   console.log(action, loaded, route);
      // }),
      switchMap(([action]) => {
        // ['/', 'fund', '1']
        const params = action.payload.routerState.url.split('/');
        const api = ProfileTypeApiMap[params[1]] || params[1];
        return this.loadProfile(api, params[2]).pipe(
          map(p => {
            const key = { type: params[1], id: +params[2] };
            return loadProfileSuccess({ profile: { ...p, key: selectEntityId(key) } })
          })
        )
      }
      )
    )
  );

  loadTabDataOnNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
      filter(isProfileTabUrl),
      withLatestFrom(
        this.store.select(isProfileTabLoaded),
        this.store.select(selectCurrentRoute)
      ),
      filter(([action, loaded, route]) => !loaded),
      switchMap(([action]) => {
        // ['/', 'fund', '1']
        const params = action.payload.routerState.url.split('/');
        const api = ProfileTypeApiMap[params[1]] || params[1];
        return this.loadProfileTab(api, params[2], params[3]).pipe(
          map(p => {
            const key = { type: params[1], id: +params[2] };
            return loadProfileTabSuccess({ tabData: p, key: selectEntityId(key) })
          })
        )
      })
    )
  );

  reloadTabData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reloadProfileTab),
      withLatestFrom(
        this.store.select(selectCurrentRoute)
      ),
      switchMap(([action]) => {
        // ['/', 'fund', '1']
        const params = action;
        return this.loadProfileTab(params.profileType, params.id + '', params.tabName).pipe(
          map(p => {
            const key = { type: params.profileType, id: +params.id };
            return loadProfileTabSuccess({ tabData: p, key: selectEntityId(key) })
          })
        )
      })
    )
  )


  private loadProfile(type: string, id: string) {
    return this.http.get<Profile>(`crm:api/v1/${type}/${id}`, noLoading).pipe(
      // startWith(null),
      map(p => {
        if (!p) { return p; }
        return {
          ...p,
          tabs: p.tabs?.map(t => ({
            ...t,
            name: t.name?.toLocaleLowerCase()
          }))
        }
      }));
  }
  private loadProfileTab(type: string, id: string, tabName: string) {
    console.log(`reload ${type} profile(${id}) tab[${tabName}] from server`)
    const api = ProfileTypeApiMap[type] || type;
    return this.http.get<Profile>(`crm:api/v1/${api}/${id}/${tabName}`, noLoading);
    // startWith(null),
  }


  constructor(
    private actions$: Actions,
    private store: Store,
    private http: HttpClient
  ) { }
}


@Injectable({ providedIn: 'root' })
export class ProfileStateService {
  profile$ = this.store.select(selectProfile);
  tab$ = this.store.select(selectProfileTab).pipe(
    filter(t => t != null),
    map(p => ({
      ...p,
      model: _.cloneDeep(p.data),
      operations: [
        // { name: 'design', label: 'Design', visiable: true, fixed: true, icon: 'fa fa-gear' },
        ...(p.operations || [])],
      editable: p.operations?.some((p: Operation) => p.name === 'edit') && !p.pendingApproval,
      // // designable: true,
      // fields: this.defualtFields[p.name] || ([enrichFieldConfig(_.cloneDeep((/*p.form || */this.defualtFields[p.name]) as any),
      //   {
      //     'currencyPipe': this.currencyPipe,
      //     'LookupCodes': this.lcs.asParameter(),
      //     'http': this.http
      //   })] || [])
    }))
  );


  constructor(
    private store: Store<ProfileState>,
    private profileEffects: ProfileEffects,
  ) {

  }

  reloadTab(profileType: string, id: number, tabName: string) {
    this.store.dispatch(reloadProfileTab({ profileType, id, tabName }));
  }

  updateTabCache(profileType: string, id: number, tabData: any) {
    const key = selectEntityId({ type: profileType, id: id });
    this.store.dispatch(loadProfileTabSuccess({
      tabData: tabData,
      key: key
    }))
  }

  mapTabToStoreObject(tabData: any, tabKey: string) {
    return {
      ...tabData,

      model: tabData.data,
      pendingData: tabData.pendingApproval?.pendingData,
      editable: tabData?.operations?.some((p: Operation) => p.name === 'edit') && !tabData?.pendingApproval,
      tabKey: tabKey,
      loading: false
    };
  }
}
