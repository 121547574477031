<p-menubar [styleClass]="styleClass" [model]="items" [autoDisplay]="false">
  <ng-template pTemplate="start">
    <ng-container *ngIf="!mobileView">
      <ng-container *ngTemplateOutlet="logo"></ng-container>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="end">
    <div class="flex flex-row items-center h-full">
      <div class="flex-1">
        <ng-container *ngIf="mobileView">
          <ng-container *ngTemplateOutlet="appTitle"></ng-container>
        </ng-container>
      </div>
      <ng-content select="[end]"></ng-content>
    </div>
  </ng-template>
</p-menubar>
<ng-template #logo><ng-content select="[logo]"></ng-content></ng-template>
<ng-template #appTitle><ng-content select="[title]"></ng-content></ng-template>
