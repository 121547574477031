import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { LookupCode } from './models/lookup-code';
import { LookupTypes } from './models/lookup-type';

@Injectable({ providedIn: 'root' })
export class LookupcodeService {

  private lookupCodes: {
    [key: number]: LookupCode[],
  } = {};

  setLookupCodes(codes?: { [key: number]: LookupCode[] }) {
    this.lookupCodes = codes || {};
  }

  getLookupCodes(lookupTypeId: number) {
    return this.lookupCodes[lookupTypeId] || [];
  }

  getLookupCodeNames(lookupTypeId: number, field: 'longName' | 'shortName' = 'longName') {
    return this.getLookupCodes(lookupTypeId).map(p => p[field]);
  }

  getAdditionalRelationshipRoles() {
    return _.sortBy(this.lookupCodes[LookupTypes.AccountRoleType].filter(p => ![1, 2, 7].some(ex => ex == p.id)), 'longName');
}

  getLookupCode(id: number) {
    for (const key in this.lookupCodes) {
      const code = this.lookupCodes[key].find(p => p.id == id);
      if (code) {
        return code;
      }
    }
    return null;
  }

  asParameter() {
    return this.lookupCodes
  }
}
