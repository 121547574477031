import { Session } from "./session";

export const isShadowUserMode = (session: Session) => {
  return session.user && session.user.impersonatorUserId &&
    session.user.id !== session.user.impersonatorUserId;
}

export const userFullName = (session: Session) => {
  if (session.user?.isAuthenticated) {
    return `${session.user.firstName} ${session.user.lastName}`;
  }
  return '';
}


export const isAdminUser = (session: Session) => {
  if (session.user?.permissions) {
    return session.user.permissions.indexOf('Pages.Admin') >= 0
  }
  return false;
}

export function isLogin(session: Session) {
  return session.user != null;
}
export function isAdmin(session: Session) {
  return session.user?.isAdmin == true || session.user?.permissions?.find(p => p === 'Pages.Admin');
}
export function isExternal(session: Session) {
  return session.user && session.user.isExternal !== false
}
export function shadowUser(session: Session) {
  return (session.user && session.user.shadowUser && session.user.shadowUser.id !== session.user.mcpId) ? session.user.shadowUser : undefined;
}
export function isInShadowMode(session: Session) {
  return session.user && session.user.isShadowUser == true
}
export function corproSession(session: Session): any {
  return session.user && session.user.sessionId;
}
export function showAccountTip(session: Session): any {
  return session.showAccountTip;
}
export function loginUserFullName(session: Session): any {
  return session.user && session.user.fullName;
}
export function enableBulkEmail(session: Session): any {
  return session.enableBulkEmail;
}
export function hasMenu(url: string, session: Session) {
  for (var key in session.menus) {
    if (session.menus[key].some(menu => menu.url &&
      (
        menu.url.toLocaleLowerCase() == url.toLocaleLowerCase()
        ||
        (
          menu.hasParam &&
          url.toLocaleLowerCase().indexOf(menu.url.toLocaleLowerCase()) == 0
        )
      ))) {
      return true;
    }
  }
  return false;
}
