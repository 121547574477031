import { Directive, forwardRef, Inject } from '@angular/core';
import { DomHandler } from 'primeng/dom';
import { Menu } from 'primeng/menu';

@Directive({
  selector: '[a-align-on-top]'
})
export class AlignOnTopDirective {

  constructor(@Inject(forwardRef(() => Menu)) private menu: Menu) {
    if (this.menu) {
      this.menu.alignOverlay = () => this.overrideMenuAlginOverlay(menu);
    }
  }

  private overrideMenuAlginOverlay(menu: Menu) {
    if (this.menu.relativeAlign) {
      DomHandler.relativePosition(this.menu.container, this.menu.target);
    }
    else {
      this.absolutePositionOnTop(this.menu.container, this.menu.target);
    }

  }
  private absolutePositionOnTop(element: any, target: any) {
    const elementDimensions = element.offsetParent ? { width: element.offsetWidth, height: element.offsetHeight } : DomHandler.getHiddenElementDimensions(element);
    const elementOuterHeight = elementDimensions.height;
    const elementOuterWidth = elementDimensions.width;
    const targetOuterHeight = target.offsetHeight;
    const targetOuterWidth = target.offsetWidth;
    const targetOffset = target.getBoundingClientRect();
    const windowScrollTop = DomHandler.getWindowScrollTop();
    const windowScrollLeft = DomHandler.getWindowScrollLeft();
    const viewport = DomHandler.getViewport();
    let top: number, left: number;

    // if (targetOffset.top + targetOuterHeight + elementOuterHeight > viewport.height) {
    //   top = targetOffset.top + windowScrollTop - elementOuterHeight;
    //   element.style.transformOrigin = 'bottom';

    //   if (top < 0) {
    //     top = windowScrollTop;
    //   }
    // } else {
    //   top = targetOuterHeight + targetOffset.top + windowScrollTop;
    //   element.style.transformOrigin = 'top';
    // }
    top = targetOffset.top + windowScrollTop - elementOuterHeight;
    element.style.transformOrigin = 'bottom';

    if (top < 0) {
      top = windowScrollTop;
    }

    if (targetOffset.left + elementOuterWidth > viewport.width) left = Math.max(0, targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth);
    else left = targetOffset.left + windowScrollLeft;

    element.style.top = top + 'px';
    element.style.left = left + 'px';
  }

}
