import { AuthEventType, AuthEvents } from '@alterdomus/auth/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable().pipe(distinctUntilChanged());

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
  get userName() {
    return this.oauthService.hasValidAccessToken() ? this.oauthService.getIdentityClaims()['preferred_username'] : null;
  }

  constructor(
    private oauthService: OAuthService,
    private authEvents: AuthEvents,
    private router: Router,
    private authConfig: AuthConfig
  ) {

    this.oauthService.events.subscribe(event => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      if (event.type === 'token_refresh_error') {
        // when refresh token is expired, need relogin
        this.oauthService.initCodeFlow();
      }
      else if (event.type === 'code_error') {
        this.oauthService.initCodeFlow();
      }
      else if (event.type === 'token_expires' && this.oauthService.tokenEndpoint) {
        this.oauthService.refreshToken()
      }
    });

    this.authEvents.events.pipe(debounceTime(50)).subscribe(event => {
      if (event.type === AuthEventType.RequireLogin) {
        this.login(event.data || '/');
      }
    })
  }

  // Call on app initializing
  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      console.table(location.hash.substring(1).split('&').map(kvp => kvp.split('=')));
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this.oauthService.loadDiscoveryDocument()

      // For demo purposes, we pretend the previous call was very slow
      .then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 50)))

      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      .then(() => {
        this.oauthService.tryLogin();
      })

      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        }

        // 2. SILENT LOGIN:
        // Try to log in via a refresh because then we can prevent
        // needing to redirect the user:
        return this.oauthService.silentRefresh()
          .then(() => Promise.resolve())
          .catch(result => {
            // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
            // Only the ones where it's reasonably sure that sending the
            // user to the IdServer will help.
            const errorResponsesRequiringUserInteraction = [
              'interaction_required',
              'login_required',
              'account_selection_required',
              'consent_required',
            ];

            if (result
              && result.reason
              && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

              // 3. ASK FOR LOGIN:
              // At this point we know for sure that we have to ask the
              // user to log in, so we redirect them to the IdServer to
              // enter credentials.
              //
              // Enable this to ALWAYS force a user to login.
              // this.login();
              //
              // Instead, we'll now do this:
              console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
              return Promise.resolve();
            }

            // We can't handle the truth, just pass on the problem to the
            // next handler.
            return Promise.reject(result);
          });
      })

      .then(() => {
        this.isDoneLoadingSubject$.next(true);

        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          this.router.navigateByUrl(stateUrl);
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.

    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout() {
    this.oauthService.logOut();
  }

  isAuthenticated() {
    return this.oauthService.hasValidAccessToken();
  }

  tryAuth() {
    this.oauthService.configure(this.authConfig);
    this.oauthService.setupAutomaticSilentRefresh();

    // The convenience method mentioned in the docs (loadDiscoveryDocumentAndLogin) won't work
    // since we need a way to modify the token endpoint
    return this.oauthService
      .loadDiscoveryDocument()
      .then((_) => {
        if (this.userHasEnteredPasswordResetFlow()) {
          // We need to change to token endpoint to match the reset-password flow
          this.oauthService.tokenEndpoint?.replace(
            'b2c_1_signupandsignin',
            'b2c_1_passwordreset'
          );
        }

        return this.oauthService.tryLoginCodeFlow();
      })
      .then((_) => {
        if (!this.oauthService.hasValidAccessToken()) {
          this.oauthService.initCodeFlow();
          return Promise.reject(false);
        }
        else {
          // already has token on app init
          return Promise.resolve(true);
        }
      })
      .catch((err) => {
        if (this.userHasRequestedPasswordReset(err)) {
          // In this case we need to enter a different flow on the Azure AD B2C side.
          // This is still a valid Code + PKCE flow, but uses a different form to support self service password reset
          this.oauthService.loginUrl = this.oauthService.loginUrl?.replace(
            'b2c_1_signupandsignin',
            'b2c_1_passwordreset'
          );
          // Add this to the state as we need it on our way back
          this.oauthService.initCodeFlow('PASSWORD_RESET');
        } else {
          // Another error has occurred, e.g. the user cancelled the reset-password flow.
          // In that case, simply retry the login.
          this.oauthService.initCodeFlow();
        }
      });
  }
  private userHasRequestedPasswordReset(err: OAuthErrorEvent): boolean {
    const errdes = err && (err.params as any)['error_description'];
    return errdes && errdes.startsWith(
      'AADB2C90118'
    );
  }
  private userHasEnteredPasswordResetFlow(): boolean {
    return window.location.search.indexOf('PASSWORD_RESET') > -1;
  }
}
