import { ProfileType } from '@alterdomus/crm-shared/core';
import { SettingService } from '@alterdomus/crm-shared/settings';
import { noLoading } from "@alterdomus/rest/interceptor";
import { CurrencyPipe } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, computed, signal } from "@angular/core";
import * as _ from 'lodash-es';
import { tap } from "rxjs";
import { Filter, FilterCat } from "./models/filter";
import { mapAccountToSearchResult, mapCompanyToSearchResult, mapFundToSearchResult, mapSPVToSearchResult, mapUserToSearchResult } from "./models/map-functions";
import { SEARCH_API_ENDPOINT } from "./models/search-api-endpoint";
import { SearchAPIResponse } from "./models/search-api-response";
import { SearchResult } from "./models/search-result";

interface CatItems {
  [key: number]: {
    data: any[],
    mapFunc: (params: { data: any, cat: FilterCat, index: number, cp: CurrencyPipe, currency: string }) => SearchResult
  }
}

const allCats: FilterCat[] = [
  {
    label: ProfileType[ProfileType.Fund],
    path: 'fund',
    iconClass: 'material-icons',
    icon: 'monetization_on',
    type: ProfileType.Fund, checked: true, fields: ['shortDealName']
  },
  {
    label: ProfileType[ProfileType.Account],
    path: 'account',
    iconClass: 'material-icons',
    icon: 'account_balance',
    type: ProfileType.Account, checked: true, fields: ['name']
  },
  {
    label: ProfileType[ProfileType.Company],
    path: 'company',
    iconClass: 'material-icons',
    icon: 'location_city',
    type: ProfileType.Company, checked: true, fields: ['companyShortName']
  },
  {
    label: ProfileType[ProfileType.Contact],
    path: 'contact',
    iconClass: 'material-icons',
    icon: 'person',
    type: ProfileType.Contact, checked: true, fields: ['name']
  },
  {
    label: ProfileType[ProfileType.SPV],
    path: 'spv',
    iconClass: 'material-icons',
    icon: 'monetization_on',
    type: ProfileType.SPV, checked: true, fields: ['shortDealName']
  }
]

@Injectable({ providedIn: 'root' })
export class GlobalSearchService {
  private availableCats: ProfileType[] = [ProfileType.Fund, ProfileType.Account, ProfileType.Company, ProfileType.Contact, ProfileType.SPV];

  cats = signal<Filter>({ key: '', cat: allCats })

  items = signal<SearchAPIResponse>({ funds: [], accounts: [], companies: [], users: [], spvs: [] });

  catItems = computed<CatItems>(() => {
    const response = this.items();
    return {
      [ProfileType.Fund]: {
        data: response.funds || [],
        mapFunc: mapFundToSearchResult
      },
      [ProfileType.Account]: {
        data: response.accounts || [],
        mapFunc: mapAccountToSearchResult
      },
      [ProfileType.Company]: {
        data: response.companies || [],
        mapFunc: mapCompanyToSearchResult
      },
      [ProfileType.Contact]: {
        data: response.users || [],
        mapFunc: mapUserToSearchResult
      },
      [ProfileType.SPV]: {
        data: response.spvs || [],
        mapFunc: mapSPVToSearchResult
      }
    }
  })

  filteredItems = computed(() => {
    const filters = this.cats();
    const response = this.catItems();
    return this.mapToSearchResult(response, filters.cat, filters.key);
  });

  setCat(cat: FilterCat, checked: any) {
    this.cats.update(cats => {
      const existCat = cats.cat.find(p => p.type == cat.type);
      if (existCat) {
        existCat.checked = checked;
      }
      return cats;
    });
  }

  setCats(cat: ProfileType[]) {
    this.availableCats = cat;
    const cats = allCats.filter(p => this.availableCats.indexOf(p.type) >= 0);
    this.cats.update(() => ({ cat: cats }));
  }

  getCatItems(cat: ProfileType) {
    return computed(() => {
      const catItems = this.catItems();
      return catItems[cat].data || [];
    })
  }

  addCatItems(items: any[], catLabel: string, key = 'id') {
    // if (!this.items) return;
    // this.items = this.items.map(cat => {
    //   if (cat.label == catLabel) {
    //     cat.items = _.unionBy(items, cat.items, 'id');
    //   }
    //   return cat;
    // })
  }


  // get key() {
  //   return this.filterSub.value.key;
  // }
  // set key(val: any) {
  //   this.filterSub.next({ ...this.filterSub.value, key: val });
  // }

  // protected cacheSub = new BehaviorSubject<SearchAPIResponse>({ funds: [], accounts: [], companies: [], users: [], spvs: [] });
  // private filterSub = new BehaviorSubject<Filter>({
  //   cat: []
  // });

  // searchCache$ = this.cacheSub.asObservable();

  // filteredItems$ = combineLatest([this.searchCache$, this.filterSub.asObservable()])
  //   .pipe(map(([cache, filters]) => {
  //     // this.FillStores(cache);
  //     return this.mapToSearchResult(cache, filters)
  //   }));

  constructor(
    private http: HttpClient,
    @Inject(SEARCH_API_ENDPOINT) private endPoint: string,
    private settings: SettingService,
    private currencyPipe: CurrencyPipe
  ) {

  }

  // get Cats() {
  //   return this.filterSub.value.cat;
  // }



  loadItems() {
    return this.http.get<SearchAPIResponse>(this.endPoint, noLoading).pipe(
      tap(response => {
        const funds = response.funds.filter(f => f.fundProfileType != 2);
        const spvs = response.funds.filter(f => f.fundProfileType == 2);
        const result = _.cloneDeep(response);
        // this.FillStores(result);
        result.funds = funds;
        result.spvs = spvs;
        // this.cacheSub.next(result);
        this.items.update(() => result);
      }))
  }

  // clearCache() {
  //   this.cacheSub.next({ funds: [], accounts: [], companies: [], users: [], spvs: [] });
  // }

  private mapToSearchResult(catItems: CatItems, cats: FilterCat[], key?: string): SearchResult[] {
    let results: SearchResult[] = [];
    const loweredKey = key?.toLowerCase();
    cats.forEach(cat => {
      if (cat.checked) {
        let items: any[] = [];
        if (loweredKey) {
          items = catItems[cat.type].data.filter(item => {
            return cat.fields.some(field => {
              const v = _.get(item, field);
              return v && v.toLowerCase().indexOf(loweredKey) >= 0
            });
          });
        }
        items = items.map((item, index) => catItems[cat.type].mapFunc({ data: item, cat, index, cp: this.currencyPipe, currency: item.currency || this.settings.get('defaultCurrency') }));
        results = results.concat(items);
      }
    })
    return results;
  }

  // refreshResult() {
  //   this.filterSub.next({ ...this.filterSub.value, key: this.filterSub.value.key });
  // }

  // private FillStores(cache:any) {
  //   this.fundStore.upsertMany(cache.funds);
  //   this.accountStore.upsertMany(cache.accounts);
  //   this.companyStore.upsertMany(cache.companies);
  //   this.userStore.setUsers(cache.users);
  // }
}
