import { ProfileType } from "@alterdomus/crm-shared/core";
import { FilterCat } from "./filter";

export interface ResultSummary {
  label: string,
  value: string
}

export interface ResultItem {
  id: number | undefined;
  title?: string | null;
  type: ProfileType;
  summaries: ResultSummary[];
}

export interface SearchResult {
  index: number;
  cat: FilterCat;
  data: ResultItem;
}
