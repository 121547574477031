<div class="a-global-search h-[34px] w-[450px] overflow-visible">
  <!--Search bar-->
  <div class="flex items-center w-full">
    <div class="p-inputgroup w-full flex">
      <input pInputText type="text" class="flex-1" #searchKeyInput [(ngModel)]="key" (focus)="key = key"
        id="globalSearchKey" [disabled]="!ready"
        placeholder="{{!ready?'Please wait for a moment':placeHolderAfterLoaded}}" />
      <!-- <button type="button" pButton icon="fa fa-search" styleClass="p-button-warn"></button> -->
      <button pButton id="globalSearchButton" icon="fa fa-search" [disabled]="!ready" styleClass="p-button-warn"
        (click)="buttonClick()"></button>
    </div>
    <!-- <div class="p-input-icon-right flex item-center">
      <input pInputText #searchKeyInput [(ngModel)]="searchService.key" (focus)="searchService.key = searchService.key"
        id="globalSearchKey" [disabled]="!ready" class="global-search-input"
        placeholder="{{!ready?'Please wait for a moment':placeHolderAfterLoaded}}">
      <i class="fa fa-times text-xl cursor-pointer hover:text-primary" *ngIf="searchService.key"
        (click)="searchService.key=''"></i>
    </div>
    <button pButton id="globalSearchButton" [disabled]="!ready" label="Search" class="global-search-button"
      (click)="buttonClick()"></button> -->
  </div>
  <!--Result panel-->
  <div class="a-global-search-result flex-wrapper flex-col shadow bg-white" [@slideIn]="resultState">
    <div class="a-global-search-filter flex items-center text-xs leading-5">
      <div class="label">Filter By</div>
      <div class="value gap-2">
        <p-checkbox *ngFor="let cat of searchService.cats().cat" [binary]="true" [(ngModel)]="cat.checked"
          (onChange)="checkCat($event,cat)" [label]="cat.label">
        </p-checkbox>
      </div>
    </div>
    <div class="a-global-search-result-list relative flex-main">
      <cdk-virtual-scroll-viewport [itemSize]="25"
        class="a-global-search-result-list-view overflow-x-hidden thin-scroller" [minBufferPx]="400"
        [maxBufferPx]="600">
        <li *cdkVirtualFor="let item of filteredItems(); let i=index;"
          class="list-none flex items-center whitespace-nowrap">
          <ng-template
            *ngTemplateOutlet="itemTemplate || plainItem; context: { $implicit : item.data, cat:item.cat, index:item.index }">
          </ng-template>
        </li>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
<ng-content></ng-content>
<ng-template #plainItem let-item let-cat="cat" let-index="index">
  <div class="label">{{index==0?cat.label:''}}</div>
  <div class="value flex items-center" [class.first]="index==0">
    <i class="material-icons" [ngClass]="cat.iconClass">{{cat.icon}}</i>
    <a class="link flex-main"
      [title]="item.title+(item.summaries?.length>0? '&#10;'+item.summaries[0].value:'')+(item.mcpid? '&#10;'+item.mcpid : '')"
      [routerLink]="[routePrefix,cat.path,item.id]" [state]="{title:item.title}" (click)="itemClicked()">
      <span>{{cat.label=="Account"?item.title+' ('+ item.summaries?.[0]?.value+')':item.title}}</span>
    </a>
  </div>
</ng-template>
