import { AuthEvents } from "@alterdomus/auth/core";
import { HttpErrorResponse, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, tap, throwError } from "rxjs";
import { AuthService } from "./auth.service";
import { TokenService } from "./token.service";

@Injectable({ providedIn: 'root' })
export class AuthenticateInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService,
    private authService: AuthService,
    private authEvent: AuthEvents
  ) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      headers: this.setAuthHeaders(req.headers)
    });
    return next.handle(authReq).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            const refreshToken = event.headers.get('refresh-token');
            if (refreshToken) {
              this.tokenService.setToken(refreshToken);
            }
          }
        }
      ),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.authService.beginAuth();
            // this.authEvent.emitEvent({ type: AuthEventType.RequireLogin })
          }
        }
        return throwError(() => error);
      })
    );
  }

  private setAuthHeaders(headers: HttpHeaders) {
    const accessToken = this.tokenService.getToken();
    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${this.tokenService.getToken()}`);
    }
    return headers;
  }
}
