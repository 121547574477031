import { AuthEventType, AuthEvents } from "@alterdomus/auth/core";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { debounceTime, tap } from "rxjs";
import { AUTH_CONFIG, AuthConfig } from "./auth.config";
import { TokenService } from "./token.service";

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private authEvents: AuthEvents,
    @Inject(AUTH_CONFIG) private authConfig: AuthConfig) {
    this.authEvents.events.pipe(debounceTime(50)).subscribe(event => {
      if (event.type === AuthEventType.RequireLogin) {
        this.redirectToLoginPage();
      }
    })
  }

  isAuthed() {
    const token = this.tokenService.getToken();
    if (token) {
      setTimeout(() => this.authEvents.emitEvent({ type: AuthEventType.Authenticated }), 50);
    }
    return token;
  }

  loadAuthConfig() {
    return this.http.get(`crm:api/abp/application-configuration`).pipe(
      tap((appConfig: any) => {
        this.applyAuthConfig(appConfig);
      })
    )
  }

  public applyAuthConfig(appConfig: any) {
    this.authConfig.authenticationUrl = appConfig.setting.values.authenticationUrl || "/login";
    this.authConfig.inited = true;
    if (!this.isAuthed() || appConfig.currentUser.isAuthenticated !== true) {
      this.beginAuth();
    }
    else {
      this.authEvents.emitEvent({ type: AuthEventType.SessionLoaded, data: appConfig });
    }
  }

  // auth service doesn't auth by it self, mostly it will jump to a preconfigured login page
  beginAuth() {
    this.tokenService.clearToken();
    this.authEvents.emitEvent({ type: AuthEventType.RequireLogin });
  }

  private redirectToLoginPage() {
    if (this.authConfig.authenticationUrl) {
      if (this.authConfig.authenticationUrl.toLowerCase().startsWith('http')) {
        location.href = this.authConfig.authenticationUrl;
      }
      else {
        this.router.navigate([this.authConfig.authenticationUrl]);
      }
    }
    else {
      console.warn('login url is empty!');
    }
  }
}
