<div *ngIf="(loading$|async) as loading" class="absolute top-0 left-0 right-0 pointer-events-all" [ngClass]="{
    'bottom-0 flex items-center justify-center': loading.spin,
    'pointer-events-none':loading.block===false,
    'bg-slate-300/50':loading.block!==false,
    'z-[10000]':!loadingKey,
    'z-[2]':loadingKey}">
  <div *ngIf="loading?.progress" class="bg-primary-500 h-1 shadow-md transition-[width]"
    [@slideDown]="loading.progress===100?'hidden':'visible'" [ngStyle]="{width: loading.progress + '%'}">
  </div>
  <div *ngIf="loading?.spin">
    <!-- <img src="assets/loading.png" /> -->
    <!-- <div role="progressbar" class="p-progress-spinner loading-spin w-32 h-32" aria-busy="true"
      data-pc-name="progressspinner" data-pc-section="root">
      <svg viewBox="25 25 50 50" class="p-progress-spinner-svg" data-pc-section="root" style="animation-duration: 3s;">
        <circle cx="50" cy="50" r="20" stroke-miterlimit="10" class="p-progress-spinner-circle" fill="none"
          stroke-width="2.5"></circle>
      </svg>
    </div> -->
    <Icon [name]="SpinIcon" [styleClass]="SpinStyleClass" [style]="SpinStyle"></Icon>
    <!-- <p-progressSpinner aria-label="loading" styleClass="loading-spin w-[128px] h-[128px]" strokeWidth="2.5"
      animationDuration="3s"></p-progressSpinner> -->
  </div>
</div>