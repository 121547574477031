export interface KYCCategory {
  category: string;
  entityType: string;
  entitySubType: string;
};
export const kycCategories: KYCCategory[] = [
  {
    "category": "Corporation",
    "entityType": "Alternative Investment Fund",
    "entitySubType": "Debt Fund"
  },
  {
    "category": "Corporation",
    "entityType": "Alternative Investment Fund",
    "entitySubType": "Hedge Fund"
  },
  {
    "category": "Corporation",
    "entityType": "Alternative Investment Fund",
    "entitySubType": "Private Equity Fund"
  },
  {
    "category": "Corporation",
    "entityType": "Alternative Investment Fund",
    "entitySubType": "Real Estate Fund"
  },
  {
    "category": "Corporation",
    "entityType": "Charity / Non-profit organisation",
    "entitySubType": "Non-Profit Organisation"
  },
  {
    "category": "Corporation",
    "entityType": "Charity / Non-profit organisation",
    "entitySubType": "Public Utility Foundation"
  },
  {
    "category": "Corporation",
    "entityType": "Club / Society",
    "entitySubType": "International Club / Society"
  },
  {
    "category": "Corporation",
    "entityType": "Club / Society",
    "entitySubType": "Local Club / Society"
  },
  {
    "category": "Corporation",
    "entityType": "Foundation",
    "entitySubType": "Private Foundation"
  },
  {
    "category": "Corporation",
    "entityType": "Foundation",
    "entitySubType": "Public Foundation"
  },
  {
    "category": "Corporation",
    "entityType": "Government Offices",
    "entitySubType": "Central Bank"
  },
  {
    "category": "Corporation",
    "entityType": "Government Offices",
    "entitySubType": "Government Owned Entity"
  },
  {
    "category": "Corporation",
    "entityType": "Government Offices",
    "entitySubType": "Market Infrastructure"
  },
  {
    "category": "Corporation",
    "entityType": "Government Offices",
    "entitySubType": "Public Sector Body"
  },
  {
    "category": "Corporation",
    "entityType": "Government Offices",
    "entitySubType": "State Pension Fund"
  },
  {
    "category": "Corporation",
    "entityType": "Government Offices",
    "entitySubType": "Statutory Body"
  },
  {
    "category": "Corporation",
    "entityType": "Government Offices",
    "entitySubType": "Supra-national and Development Organisations"
  },
  {
    "category": "Corporation",
    "entityType": "Investment Fund",
    "entitySubType": "Managed Fund"
  },
  {
    "category": "Corporation",
    "entityType": "Investment Fund",
    "entitySubType": "Mutual Fund"
  },
  {
    "category": "Corporation",
    "entityType": "Investment Fund",
    "entitySubType": "Pension Fund/Scheme"
  },
  {
    "category": "Corporation",
    "entityType": "Investment Fund",
    "entitySubType": "Securitization Vehicle"
  },
  {
    "category": "Corporation",
    "entityType": "Investment Fund",
    "entitySubType": "UCITS"
  },
  {
    "category": "Corporation",
    "entityType": "Investment Fund",
    "entitySubType": "Unit Trust"
  },
  {
    "category": "Corporation",
    "entityType": "Nominee",
    "entitySubType": "Nominee Account - Individual"
  },
  {
    "category": "Corporation",
    "entityType": "Nominee",
    "entitySubType": "Nominee Account - Non Individual"
  },
  {
    "category": "Corporation",
    "entityType": "Non-Financial Business",
    "entitySubType": "Non-Financial Business"
  },
  {
    "category": "Corporation",
    "entityType": "Pension Fund",
    "entitySubType": "Private Pension Fund"
  },
  {
    "category": "Corporation",
    "entityType": "Pension Fund",
    "entitySubType": "Public Pension Fund"
  },
  {
    "category": "Corporation",
    "entityType": "Pension Scheme",
    "entitySubType": "Private Pension Scheme"
  },
  {
    "category": "Corporation",
    "entityType": "Pension Scheme",
    "entitySubType": "Public Pension Scheme"
  },
  {
    "category": "Corporation",
    "entityType": "Personal Investment and Holding C",
    "entitySubType": "Personal Investment and Holding Companies"
  },
  {
    "category": "Corporation",
    "entityType": "Private Company/Privately held co",
    "entitySubType": "Private Company/Privately held companies"
  },
  {
    "category": "Corporation",
    "entityType": "Private Company/Privately held co",
    "entitySubType": "Subsidiary of Public Company"
  },
  {
    "category": "Corporation",
    "entityType": "Private school / Private Universi",
    "entitySubType": "Private school / Private University"
  },
  {
    "category": "Corporation",
    "entityType": "Public Company",
    "entitySubType": "Listed companies Non RSE"
  },
  {
    "category": "Corporation",
    "entityType": "Public Company",
    "entitySubType": "Listed companies RSE"
  },
  {
    "category": "Corporation",
    "entityType": "Regulated Financial Institution",
    "entitySubType": "Alternative Investment Fund Manager"
  },
  {
    "category": "Corporation",
    "entityType": "Regulated Financial Institution",
    "entitySubType": "Bank"
  },
  {
    "category": "Corporation",
    "entityType": "Regulated Financial Institution",
    "entitySubType": "Broker-Dealer"
  },
  {
    "category": "Corporation",
    "entityType": "Regulated Financial Institution",
    "entitySubType": "Insurance Company"
  },
  {
    "category": "Corporation",
    "entityType": "Regulated Financial Institution",
    "entitySubType": "Investment Manager"
  },
  {
    "category": "Corporation",
    "entityType": "Regulated Financial Institution",
    "entitySubType": "Trust Company"
  },
  {
    "category": "Corporation",
    "entityType": "Regulated Financial Institution",
    "entitySubType": "UCITS Management Company"
  },
  {
    "category": "Corporation",
    "entityType": "Religious Organisation/Places of ",
    "entitySubType": "Religious Organisation/Places of Worship"
  },
  {
    "category": "Corporation",
    "entityType": "Sovereign Wealth Fund",
    "entitySubType": "Sovereign Wealth Fund"
  },
  {
    "category": "Corporation",
    "entityType": "Special Purpose Vehicle or Entity",
    "entitySubType": "Special Purpose Vehicle or Entity"
  },
  {
    "category": "Corporation",
    "entityType": "State Owned Enterprise",
    "entitySubType": "State Owned Enterprise"
  },
  {
    "category": "Government Body",
    "entityType": "Charity / Non-profit organisation",
    "entitySubType": "Non-Profit Organisation"
  },
  {
    "category": "Government Body",
    "entityType": "Charity / Non-profit organisation",
    "entitySubType": "Public Utility Foundation"
  },
  {
    "category": "Government Body",
    "entityType": "Foundation",
    "entitySubType": "Private Foundation"
  },
  {
    "category": "Government Body",
    "entityType": "Foundation",
    "entitySubType": "Public Foundation"
  },
  {
    "category": "Government Body",
    "entityType": "Government Offices",
    "entitySubType": "Central Bank"
  },
  {
    "category": "Government Body",
    "entityType": "Government Offices",
    "entitySubType": "Government Owned Entity"
  },
  {
    "category": "Government Body",
    "entityType": "Government Offices",
    "entitySubType": "Market Infrastructure"
  },
  {
    "category": "Government Body",
    "entityType": "Government Offices",
    "entitySubType": "Public Sector Body"
  },
  {
    "category": "Government Body",
    "entityType": "Government Offices",
    "entitySubType": "State Pension Fund"
  },
  {
    "category": "Government Body",
    "entityType": "Government Offices",
    "entitySubType": "Statutory Body"
  },
  {
    "category": "Government Body",
    "entityType": "Government Offices",
    "entitySubType": "Supra-national and Development Organisations"
  },
  {
    "category": "Government Body",
    "entityType": "Religious Organisation/Places of ",
    "entitySubType": "Religious Organisation/Places of Worship"
  },
  {
    "category": "Government Body",
    "entityType": "Sovereign Wealth Fund",
    "entitySubType": "Sovereign Wealth Fund"
  },
  {
    "category": "Government Body",
    "entityType": "State Owned Enterprise",
    "entitySubType": "State Owned Enterprise"
  },
  {
    "category": "Individual",
    "entityType": "Individual",
    "entitySubType": "Individual - Joint Account"
  },
  {
    "category": "Individual",
    "entityType": "Individual",
    "entitySubType": "Individual - Legal Guardianship"
  },
  {
    "category": "Individual",
    "entityType": "Individual",
    "entitySubType": "Individual - Personal Account"
  },
  {
    "category": "Partnership",
    "entityType": "Alternative Investment Fund",
    "entitySubType": "Debt Fund"
  },
  {
    "category": "Partnership",
    "entityType": "Alternative Investment Fund",
    "entitySubType": "Hedge Fund"
  },
  {
    "category": "Partnership",
    "entityType": "Alternative Investment Fund",
    "entitySubType": "Private Equity Fund"
  },
  {
    "category": "Partnership",
    "entityType": "Alternative Investment Fund",
    "entitySubType": "Real Estate Fund"
  },
  {
    "category": "Partnership",
    "entityType": "Charity / Non-profit organisation",
    "entitySubType": "Non-Profit Organisation"
  },
  {
    "category": "Partnership",
    "entityType": "Charity / Non-profit organisation",
    "entitySubType": "Public Utility Foundation"
  },
  {
    "category": "Partnership",
    "entityType": "Club / Society",
    "entitySubType": "International Club / Society"
  },
  {
    "category": "Partnership",
    "entityType": "Club / Society",
    "entitySubType": "Local Club / Society"
  },
  {
    "category": "Partnership",
    "entityType": "Foundation",
    "entitySubType": "Private Foundation"
  },
  {
    "category": "Partnership",
    "entityType": "Foundation",
    "entitySubType": "Public Foundation"
  },
  {
    "category": "Partnership",
    "entityType": "Investment Fund",
    "entitySubType": "Managed Fund"
  },
  {
    "category": "Partnership",
    "entityType": "Investment Fund",
    "entitySubType": "Mutual Fund"
  },
  {
    "category": "Partnership",
    "entityType": "Investment Fund",
    "entitySubType": "Pension Fund/Scheme"
  },
  {
    "category": "Partnership",
    "entityType": "Investment Fund",
    "entitySubType": "Securitization Vehicle"
  },
  {
    "category": "Partnership",
    "entityType": "Investment Fund",
    "entitySubType": "UCITS"
  },
  {
    "category": "Partnership",
    "entityType": "Investment Fund",
    "entitySubType": "Unit Trust"
  },
  {
    "category": "Partnership",
    "entityType": "Partnership",
    "entitySubType": "Limited Liability Partnership"
  },
  {
    "category": "Partnership",
    "entityType": "Partnership",
    "entitySubType": "Limited Partnership"
  },
  {
    "category": "Partnership",
    "entityType": "Partnership",
    "entitySubType": "Ordinary Partnership"
  },
  {
    "category": "Partnership",
    "entityType": "Partnership",
    "entitySubType": "Scottish Partnership"
  },
  {
    "category": "Partnership",
    "entityType": "Pension Fund",
    "entitySubType": "Private Pension Fund"
  },
  {
    "category": "Partnership",
    "entityType": "Pension Fund",
    "entitySubType": "Public Pension Fund"
  },
  {
    "category": "Partnership",
    "entityType": "Pension Scheme",
    "entitySubType": "Private Pension Scheme"
  },
  {
    "category": "Partnership",
    "entityType": "Pension Scheme",
    "entitySubType": "Public Pension Scheme"
  },
  {
    "category": "Partnership",
    "entityType": "Religious Organisation/Places of ",
    "entitySubType": "Religious Organisation/Places of Worship"
  },
  {
    "category": "Partnership",
    "entityType": "Special Purpose Vehicle or Entity",
    "entitySubType": "Special Purpose Vehicle or Entity"
  },
  {
    "category": "Trust",
    "entityType": "Alternative Investment Fund",
    "entitySubType": "Debt Fund"
  },
  {
    "category": "Trust",
    "entityType": "Alternative Investment Fund",
    "entitySubType": "Hedge Fund"
  },
  {
    "category": "Trust",
    "entityType": "Alternative Investment Fund",
    "entitySubType": "Private Equity Fund"
  },
  {
    "category": "Trust",
    "entityType": "Alternative Investment Fund",
    "entitySubType": "Real Estate Fund"
  },
  {
    "category": "Trust",
    "entityType": "Charity / Non-profit organisation",
    "entitySubType": "Non-Profit Organisation"
  },
  {
    "category": "Trust",
    "entityType": "Charity / Non-profit organisation",
    "entitySubType": "Public Utility Foundation"
  },
  {
    "category": "Trust",
    "entityType": "Club / Society",
    "entitySubType": "International Club / Society"
  },
  {
    "category": "Trust",
    "entityType": "Club / Society",
    "entitySubType": "Local Club / Society"
  },
  {
    "category": "Trust",
    "entityType": "Foundation",
    "entitySubType": "Private Foundation"
  },
  {
    "category": "Trust",
    "entityType": "Foundation",
    "entitySubType": "Public Foundation"
  },
  {
    "category": "Trust",
    "entityType": "Investment Fund",
    "entitySubType": "Managed Fund"
  },
  {
    "category": "Trust",
    "entityType": "Investment Fund",
    "entitySubType": "Mutual Fund"
  },
  {
    "category": "Trust",
    "entityType": "Investment Fund",
    "entitySubType": "Pension Fund/Scheme"
  },
  {
    "category": "Trust",
    "entityType": "Investment Fund",
    "entitySubType": "Securitization Vehicle"
  },
  {
    "category": "Trust",
    "entityType": "Investment Fund",
    "entitySubType": "UCITS"
  },
  {
    "category": "Trust",
    "entityType": "Investment Fund",
    "entitySubType": "Unit Trust"
  },
  {
    "category": "Trust",
    "entityType": "Irrevocable Trust",
    "entitySubType": "Discretionary Trust"
  },
  {
    "category": "Trust",
    "entityType": "Irrevocable Trust",
    "entitySubType": "Non Discretionary Trust"
  },
  {
    "category": "Trust",
    "entityType": "Pension Fund",
    "entitySubType": "Private Pension Fund"
  },
  {
    "category": "Trust",
    "entityType": "Pension Fund",
    "entitySubType": "Public Pension Fund"
  },
  {
    "category": "Trust",
    "entityType": "Pension Scheme",
    "entitySubType": "Private Pension Scheme"
  },
  {
    "category": "Trust",
    "entityType": "Pension Scheme",
    "entitySubType": "Public Pension Scheme"
  },
  {
    "category": "Trust",
    "entityType": "Religious Organisation/Places of ",
    "entitySubType": "Religious Organisation/Places of Worship"
  },
  {
    "category": "Trust",
    "entityType": "Revocable Trust",
    "entitySubType": "Revocable Trust"
  },
  {
    "category": "Trust",
    "entityType": "Special Purpose Vehicle or Entity",
    "entitySubType": "Special Purpose Vehicle or Entity"
  }
];