import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { App } from "./app.model";

export function createInitialState(): App {
  return {
    version: '13'
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app', resettable: false })
export class AppStore extends Store<App>{
  constructor() {
    super(createInitialState())
  }
}
