import { AUTH_CONFIG } from '@alterdomus/auth/auth/auth.config';
import { OAuth2Module } from '@alterdomus/auth/oauth2';
import { BreadcrumbModule } from '@alterdomus/common-ui/breadcrumb';
import { DateRangePickerModule } from '@alterdomus/common-ui/date-range-picker';
import { FloatActionButtonsModule } from '@alterdomus/common-ui/float-action-buttons';
import { FooterModule } from '@alterdomus/common-ui/footer';
import { NavModule } from '@alterdomus/common-ui/nav';
import { OperationWatcherModule } from '@alterdomus/common-ui/operation-watcher';
import { DropdownModule, SplitButtonModule } from '@alterdomus/common-ui/overrides';
import { ProfileMenuModule } from '@alterdomus/common-ui/profile-menu';
import { SideNavModule } from '@alterdomus/common-ui/side-nav';
import { ConfigModule } from '@alterdomus/crm-shared/config';
import { GlobalSearchModule } from '@alterdomus/crm-shared/global-search';
import { ProfileEffects, profileReducer } from '@alterdomus/crm-shared/profile-state';
import { SearchBarModule } from '@alterdomus/crm-shared/search-bar';
import { InterceptorModule } from '@alterdomus/rest/interceptor';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { akitaConfig } from '@datorama/akita';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { of, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { AppRemoteErrorHandler } from './app-remote-error.handler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { authModuleConfig } from './oauth2/auth-module-config';
import { authConfig } from './oauth2/auth.config';
import { AppStore } from './store/app.store';


const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0
  }
};
// Set akita resetable
akitaConfig({ resettable: true });

function initialApp(http: HttpClient, appStore: AppStore) {
  // return http.get('appinfo url')
  return () => of({
    version: '15'
  }).pipe(
    tap(app => {
      appStore.update(app);
    })
  );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Core
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'RequestVerificationToken',
    }),

    // 3rd parties
    ButtonModule,
    DropdownModule,
    CheckboxModule,
    ConfirmDialogModule,
    // dynamic form used in dropdown as well
    // FormlyModule.forRoot({ types: [{ name: 'select', component: FormlyFieldDropdown }] }),
    AkitaNgRouterStoreModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),

    // Ng rx begin
    StoreModule.forRoot({
      router: routerReducer,
      profile: profileReducer
    }),
    EffectsModule.forRoot([ProfileEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: false }),
    // Ng rx end

    // Libs
    OAuth2Module.forRoot(authConfig, authModuleConfig),
    InterceptorModule.forRoot({ default: '/', crm: environment.apiBastPath }, AppRemoteErrorHandler),
    ConfigModule.forRoot('crm:api/abp/application-configuration'),
    GlobalSearchModule.withSearchAPI('crm:api/v1/search'),
    // EmailTemplateManageModule.withUrls(),

    ProfileMenuModule,
    NavModule,
    SideNavModule,
    SearchBarModule,
    FooterModule,
    FloatActionButtonsModule,
    BreadcrumbModule.forRoot({ homeRouterLink: '/dashboard', showInHome: false, homeIcon: 'invalid icon', appTitle: `CRM ${environment.production ? '' : ' (Dev)'}` }),
    OperationWatcherModule.forRoot(),
    DateRangePickerModule.withCustomFormat(),

    // Overrides
    SplitButtonModule,

    // App
    AppRoutingModule,
    ConfigModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initialApp, deps: [HttpClient, AppStore], multi: true },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: AUTH_CONFIG, useValue: { tokenKey: 'access_token' } },
    ConfirmationService,
    DialogService,      // required for ad-message
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
