import { ProfileType } from '@alterdomus/crm-shared/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, Input, OnInit, Renderer2, Signal, TemplateRef, effect } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GlobalSearchService } from './global-search.service';
import { FilterCat } from './models/filter';
import { SearchResult } from './models/search-result';

@Component({
  selector: 'a-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideIn', [
      state('hidden', style({ height: 0, 'box-shadow': 'none', overflow: 'hidden' })),
      state('visible', style({ height: '*' })),
      transition('hidden => visible', animate('150ms ease-in')),
      transition('visible => hidden', animate('150ms ease-out'))
    ])
    // trigger('slideIn', [
    //   state('hidden', style({ height: 0, opacity: 0 })),
    //   state('visible', style({ height: '*', opacity: 1 })),
    //   transition('hidden => visible', animate('150ms ease-in')),
    //   transition('visible => hidden', animate('150ms ease-out'))
    // ])
  ]
})
export class GlobalSearchComponent implements OnInit {
  ready = false;
  filteredItems: Signal<SearchResult[]>;
  get placeHolderAfterLoaded() {
    return `Search for ${this.searchService.cats().cat.filter(p => !this.isExternalUser || p.label != 'Company').map(p => p.label).join(", ")}`;
  }

  resultState: 'visible' | 'hidden' = 'hidden';
  // ready$;


  // fields to search, default to crm local search
  @Input()
  fields = ['name', 'summary1', 'mcpid'];
  // filter type
  @Input()
  filter: 'startWith' | 'contains' = 'contains';

  @Input()
  searchPage: string = '/search';

  @Input()
  closeOnSelect: boolean = true;

  @Input()
  set hideCompany(val: boolean) {
    if (val) {
      this.searchService.setCats(this.searchService.cats().cat.map(c => c.type).filter(c => c != ProfileType.Company))
    }
  }

  _items: SearchResult[] = [];

  @Input()
  routePrefix = '/';

  @ContentChild(TemplateRef)
  itemTemplate?: TemplateRef<any>;


  showPanel = true;
  get key() {
    const cats = this.searchService.cats();
    return cats.key;
  }
  set key(val) {
    this.searchService.cats.update((cat) => ({ ...cat, key: val }));
  }


  @Input()
  isExternalUser: boolean = true;

  private documentClickUnlistener?: () => void;

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    public searchService: GlobalSearchService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {

      const ne = event as NavigationEnd;
      this.showPanel = !this.searchPage ||
        !ne.url.toLowerCase().startsWith(this.searchPage);
      if (!this.showPanel) {
        // this.resultState = 'hidden';
        this.hidden();

        // this.globalSearchService.publishSearchResult();
        // this.cd.detectChanges();
      }
    })
    this.filteredItems = this.searchService.filteredItems;
    effect(() => {
      const filteredItems = this.filteredItems();
      const key = this.searchService.cats().key;
      if ((filteredItems && filteredItems.length > 0 || key) &&
        (!this.searchPage || !this.router.url.toLowerCase().startsWith(this.searchPage))) {
        this.resultState = 'visible';
        if (!this.documentClickUnlistener) {
          this.documentClickUnlistener = this.renderer.listen('document', 'click', (event) => {
            if (!this.el.nativeElement.contains(event.target)) {
              this.hidden();
            }
          });
        }
      }
      else {
        // this.resultState = 'hidden';
        this.hidden();
      }
    })

    // this.filteredItems$ = this.searchService.filteredItems$.pipe(tap(items => {
    //   if (((items && items.length > 0) || this.searchService.key()) && (!this.searchPage ||
    //     !this.router.url.toLowerCase().startsWith(this.searchPage))) {
    //     this.resultState = 'visible';
    //     if (!this.documentClickUnlistener) {
    //       this.documentClickUnlistener = this.renderer.listen('document', 'click', (event) => {
    //         if (!this.el.nativeElement.contains(event.target)) {
    //           this.hidden();
    //         }
    //       });
    //     }
    //   }
    //   else {
    //     // this.resultState = 'hidden';
    //     this.hidden();
    //   }
    // }));
  }

  ngOnInit() {
    setTimeout(() => this.searchService.loadItems().subscribe(result => {
      this.ready = true;
      this.cd.detectChanges();
    }), 5000);
  }

  checkCat(event: any, cat: FilterCat) {
    event.originalEvent.stopPropagation();
    this.searchService.setCat(cat, event.checked);
  }


  buttonClick() {
    if (this.showPanel)
      this.router.navigate([this.searchPage]);
    else {
      // this.globalSearchService.search(this.key, this.fields, this.fields);
    }
  }


  onkeydown(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.buttonClick();
    }
  }

  keyChanged(key: string) {
    // this.globalSearchService.search(key, this.filter, this.fields);
  }

  itemClicked() {
    if (this.closeOnSelect)
      // this.resultState = 'hidden';
      this.hidden();
  }
  // onBlur(event) {
  //   this.resultState = 'hidden';
  // }
  // @HostListener('document:click', ['$event.target'])
  // clickout(targetElement) {

  //   if (!this.el.nativeElement.contains(targetElement)) {
  //     this.hidden();
  //   }
  // }

  hidden() {
    if (this.documentClickUnlistener) {
      this.documentClickUnlistener();
      this.documentClickUnlistener = undefined;
    }
    this.resultState = 'hidden';
    this.cd.detectChanges();
  }
}
