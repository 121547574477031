import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class TokenStore {
  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }
  setItem(key: string, token: string) {
    localStorage.setItem(key, token);
  }
  removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
