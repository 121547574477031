import { SearchBoxModule } from '@alterdomus/common-ui/search-box';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ListComponent } from './components/list/list.component';
import { MultiColumnVirtualScroll } from './multi-column-virtual-scroll';



@NgModule({
  declarations: [
    ListComponent,
    MultiColumnVirtualScroll
  ],
  exports: [
    ListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    ScrollingModule,
    SearchBoxModule
  ]
})
export class ListModule { }
