import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { GlobalSearchComponent } from './global-search.component';
import { SEARCH_API_ENDPOINT } from './models/search-api-endpoint';
import { CheckboxModule } from 'primeng/checkbox';
import { RouterModule } from '@angular/router';
import { SettingsModule } from '@alterdomus/crm-shared/settings';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ScrollingModule,
    ButtonModule,
    InputTextModule,
    CheckboxModule,
    SettingsModule
  ],
  declarations: [GlobalSearchComponent],
  exports: [GlobalSearchComponent],
  providers: [CurrencyPipe]
})
export class GlobalSearchModule {
  static withSearchAPI(url: string): ModuleWithProviders<GlobalSearchModule> {
    return {
      ngModule: GlobalSearchModule,
      providers: [{ provide: SEARCH_API_ENDPOINT, useValue: url }]
    }
  }
}
