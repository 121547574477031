import { InjectionToken } from "@angular/core";

export interface breadCrumbConfig {
  menuCount: number,
  appTitle: string | null;
  updateTile: boolean | null;
  homeTitle: string | null;
  homeIcon: string | null;
  showHomeTitle: boolean | null;
  showHomeIcon: boolean | null;
  homeRouterLink?: string;
  showInHome?: boolean,
  hideBreadCrumbUrls?: string[]
}

export const defaultMenuConfig: breadCrumbConfig = {
  menuCount: 2,
  appTitle: null,
  updateTile: true,
  homeTitle: 'Dashboard',
  homeIcon: 'fa fa-home',
  showHomeTitle: true,
  showHomeIcon: true,
  homeRouterLink: '/',
  showInHome: true,
}

export const MENU_CONFIG: InjectionToken<breadCrumbConfig> = new InjectionToken('MENU_CONFIG');
