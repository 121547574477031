/* tslint:disable:max-line-length */
// inspried by https://github.com/kisenka/svg-sprite-loader/blob/master/runtime/browser-sprite.js
// Much simplified, do make sure run this after document ready
import { Injectable } from '@angular/core';

const svgSprite = (contents: any) => `
<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="https://www.w3.org/1999/xlink"
  id="__ALTER_DOMUS_SVG_SPRITE_NODE__"
  style="position:absolute;width:0;height:0"
>
  <defs>
    ${contents}
  </defs>
</svg>
`;

const iconNames = ['back', 'dropdown', 'onboarding', 'search', 'searchSM', 'uploadSM', 'x', 'xHover',
    'hourGlassHalf', 'circle', 'circleCheck', 'logo','logoIP', 'alignLeft', 'alignTop', 'alignRight', 'alignBottom', 'arrowStop',
    'dashboard', 'downloadSM', 'fx', 'investmentDetails', 'override', 'pdf', 'sum', 'vAlignBottom', 'vAlignMiddle', 'vAlignTop',
    'vitals', 'spin', 'filterClear'
] as const;

export type IconType = typeof iconNames[number];
export type IconDef = { [k in IconType]: string }

@Injectable()
export class IconHandler {
    constructor() { }
    contents = [];
    // both minified by https://github.com/svg/svgo
    icons: IconDef = {
        vitals: `
        <svg viewBox="0 0 48 48">
            <g stroke="transparent" fill="currentColor">
                <path d="M39.6,12.9H8.2c-1.3,0-2.3-1-2.3-2.3v0c0-1.3,1-2.3,2.3-2.3h31.4c1.3,0,2.3,1,2.3,2.3v0C41.9,11.8,40.9,12.9,39.6,12.9z"/>
                <path d="M27.9,21.2H8.2c-1.3,0-2.3-1-2.3-2.3v0c0-1.3,1-2.3,2.3-2.3h19.7c1.3,0,2.3,1,2.3,2.3v0C30.2,20.1,29.1,21.2,27.9,21.2z"/>
                <path d="M12.6,38.7H8.2c-1.3,0-2.3-1-2.3-2.3v-9.2c0-1.3,1-2.3,2.3-2.3h4.4c1.3,0,2.3,1,2.3,2.3v9.2C14.9,37.7,13.9,38.7,12.6,38.7z
                    "/>
                <path d="M39.6,38.7h-4.4c-1.3,0-2.3-1-2.3-2.3v-9.2c0-1.3,1-2.3,2.3-2.3h4.4c1.3,0,2.3,1,2.3,2.3v9.2C41.9,37.7,40.9,38.7,39.6,38.7
                    z"/>
                <path d="M26.1,38.7h-4.4c-1.3,0-2.3-1-2.3-2.3v-9.2c0-1.3,1-2.3,2.3-2.3h4.4c1.3,0,2.3,1,2.3,2.3v9.2C28.4,37.7,27.4,38.7,26.1,38.7
                    z"/>
            </g>
        </svg>
        `,
        vAlignTop: `
        <svg viewBox="0 0 48 48">
        <g stroke="transparent" fill="currentColor">
            <path d="M39.5,8.8c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v2.1c0,0.3-0.1,0.5-0.3,0.7S39.8,13,39.5,13H8.5c-0.3,0-0.5-0.1-0.7-0.3
                s-0.3-0.4-0.3-0.7V9.9c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3H39.5z M39.5,17.1c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v2.1
                c0,0.3-0.1,0.5-0.3,0.7s-0.4,0.3-0.7,0.3H8.5c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7v-2.1c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3
                H39.5z"/>
            <path d="M16.6,36.5l6.2-6.3c0.3-0.3,0.7-0.5,1.1-0.5s0.8,0.2,1.1,0.5l6.2,6.3c0.4,0.4,0.6,1.1,0.3,1.7c-0.2,0.6-0.8,1-1.4,1H17.8
                    c-0.6,0-1.2-0.4-1.4-1C16.1,37.6,16.2,36.9,16.6,36.5z"/>
        </g>
        </svg>
        `,
        vAlignMiddle: `
        <svg viewBox="0 0 48 48">
            <g stroke="transparent" fill="currentColor">
                <path d="M31.4,7.1l-6.2,6.2c-0.3,0.3-0.7,0.5-1.1,0.5s-0.8-0.2-1.1-0.5l-6.2-6.2c-0.5-0.4-0.6-1.1-0.4-1.7c0.2-0.6,0.8-1,1.4-1
                    h12.5c0.6,0,1.2,0.4,1.4,1S31.8,6.7,31.4,7.1z"/>
                <path d="M39.5,17.8c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v2.1c0,0.3-0.1,0.5-0.3,0.7s-0.4,0.3-0.7,0.3H8.5c-0.3,0-0.5-0.1-0.7-0.3
                    s-0.3-0.4-0.3-0.7v-2.1c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3H39.5z M39.5,26.1c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v2.1
                    c0,0.3-0.1,0.5-0.3,0.7s-0.4,0.3-0.7,0.3H8.5c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7v-2.1c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3
                    H39.5z"/>
                <path d="M16.6,40.9l6.2-6.2c0.3-0.3,0.7-0.5,1.1-0.5s0.8,0.2,1.1,0.5l6.2,6.2c0.4,0.4,0.6,1.1,0.3,1.7c-0.2,0.6-0.8,1-1.4,1H17.8
                    c-0.6,0-1.2-0.4-1.4-1C16.1,42,16.2,41.3,16.6,40.9z"/>
            </g>
        </svg>
        `,
        vAlignBottom: `
        <svg viewBox="0 0 48 48">
            <g stroke="transparent" fill="currentColor">
                <path d="M8.5,39.2c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7v-2.1c0-0.3,0.1-0.5,0.3-0.7S8.2,35,8.5,35h30.9c0.3,0,0.5,0.1,0.7,0.3
                    s0.3,0.4,0.3,0.7v2.1c0,0.3-0.1,0.5-0.3,0.7s-0.4,0.3-0.7,0.3H8.5z M8.5,30.9c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7v-2.1
                    c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3h30.9c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v2.1c0,0.3-0.1,0.5-0.3,0.7s-0.4,0.3-0.7,0.3
                    H8.5z"/>
                <path d="M16.3,9.8c0.2-0.6,0.8-1,1.4-1h12.5c0.7,0,1.2,0.4,1.4,1c0.2,0.6,0.1,1.3-0.3,1.7l-6.2,6.3c-0.3,0.3-0.7,0.5-1.1,0.5
                    s-0.8-0.2-1.1-0.5l-6.2-6.3C16.2,11.1,16.1,10.4,16.3,9.8z"/>
            </g>
        </svg>
        `,
        sum: `
            <svg viewBox="0 0 22.3 24.5">
                <path stroke="transparent" fill="currentColor" d="M19.3,16.8c-0.9,0-1.6,0.7-1.6,1.6v2h-11l7.1-7.1l0,0c0.3-0.3,0.5-0.7,0.5-1.2s-0.2-0.9-0.5-1.2L6.7,4h10.9v2
                    c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6V4c0-1.8-1.5-3.3-3.3-3.3H2.8l0,0c-0.9,0-1.6,0.7-1.6,1.6c0,0.4,0.2,0.8,0.5,1.1l0,0l0,0l0,0
                    l8.7,8.7L1.7,21l0,0l0,0l0,0c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.9,0.7,1.6,1.6,1.6l0,0h14.9l0,0c1.8,0,3.3-1.5,3.3-3.3v-2
                    C20.9,17.6,20.2,16.8,19.3,16.8z"/>
            </svg>
        `,
        pdf: `
        <svg viewBox="0 0 48 48">
            <g stroke="transparent" fill="currentColor">
                <path d="M28.4,16.8H39v25.6c0,0.5-0.2,1-0.5,1.3c-0.4,0.4-0.8,0.5-1.3,0.5H10.9c-0.5,0-1-0.2-1.3-0.5C9.2,43.3,9,42.9,9,42.4V6.1
                    c0-0.5,0.2-1,0.5-1.3c0.4-0.4,0.8-0.5,1.3-0.5h15.6v10.6c0,0.5,0.2,1,0.5,1.3C27.4,16.6,27.9,16.8,28.4,16.8z M27.8,30.2
                    c-1.5-0.9-2.6-2.3-3.4-4.2c0.6-2.4,0.8-4.1,0.5-5c-0.2-0.7-0.5-1.2-1.1-1.5c-0.5-0.3-1.1-0.3-1.7-0.1c-0.6,0.2-0.9,0.6-1,1.1
                    c-0.3,1.1-0.1,3.2,0.6,6c-1.2,2.8-2.3,5.1-3.2,6.7c-1.5,0.7-2.8,1.6-3.8,2.6c-1,1-1.2,1.9-0.5,2.7c0.4,0.5,1,0.8,1.7,0.8
                    c1.3,0,2.9-1.6,4.8-4.8c3.2-1,5.3-1.6,6.2-1.8c1.9,1,3.5,1.5,5,1.5c1,0,1.7-0.4,2-1.3c0.3-0.9,0.1-1.6-0.4-2
                    C32.7,30,30.8,29.8,27.8,30.2z M15.7,37.7c0.2-0.6,1.1-1.6,2.7-3.1c-0.5,0.8-1,1.4-1.4,2c-0.4,0.5-0.8,0.9-1,1
                    C15.8,37.7,15.7,37.7,15.7,37.7z M23.1,27.9c0.7,1.4,1.6,2.4,2.7,3.2c-0.6,0.1-2.3,0.7-4.9,1.7C21.6,31.6,22.3,30,23.1,27.9z
                    M23.2,24.3c-0.1-0.4-0.2-0.8-0.2-1.4c-0.1-0.6-0.1-1.1-0.1-1.6c0-0.4,0.1-0.7,0.2-0.7c0.3,0,0.5,0.5,0.5,1.6
                    C23.5,23.2,23.4,23.9,23.2,24.3z M38.5,12.5c0.4,0.4,0.5,0.8,0.5,1.3v0.5H29v-10h0.5c0.5,0,1,0.2,1.3,0.5L38.5,12.5z M32.7,32.4
                    c-0.3,0.4-1.4,0.1-3.4-0.7C31.6,31.6,32.7,31.9,32.7,32.4z"/>
            </g>
        </svg>
        `,
        override: `
        <svg  viewBox="0 0 22.3 23.4">
            <g stroke="transparent" fill="currentColor">
            <path d="M21.7,8.7l-3.4-3.4C18.1,5,17.9,5,17.6,5h-0.2v4.5h4.5V9.2C21.9,9,21.8,8.8,21.7,8.7z"/>
            <path d="M16.5,10.3c-0.2-0.2-0.2-0.4-0.2-0.6V5h-0.9v12.7c0,0.6-0.2,1.2-0.7,1.7s-1,0.7-1.7,0.7H8.4v2.2c0,0.2,0.1,0.4,0.2,0.6
                C8.8,22.9,9,23,9.3,23h11.8c0.2,0,0.4-0.1,0.6-0.2s0.2-0.4,0.2-0.6V10.6h-4.8C16.9,10.6,16.7,10.5,16.5,10.3z"/>
            <path d="M13.7,18.2c0.2-0.2,0.2-0.4,0.2-0.6V6.1H9.1C8.9,6.1,8.7,6,8.5,5.8C8.4,5.7,8.3,5.5,8.3,5.2V0.5h-7c-0.3,0-0.5,0-0.6,0.2
                C0.5,0.9,0.4,1.1,0.4,1.3v16.3c0,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.2,0.6,0.2H13C13.3,18.5,13.5,18.4,13.7,18.2z M10.4,13.3
                c0.3,0.3,0.3,0.7,0,1c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2L7.1,12l-2.3,2.3c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2
                c-0.3-0.3-0.3-0.7,0-1L6.1,11L3.9,8.8c-0.3-0.3-0.3-0.7,0-1s0.7-0.3,1,0l2.3,2.3l2.3-2.3c0.3-0.3,0.7-0.3,1,0s0.3,0.7,0,1l-2.3,2.3
                L10.4,13.3z"/>
            <path d="M13.9,4.7c0-0.2-0.1-0.4-0.2-0.6l-3.4-3.4c-0.2-0.2-0.4-0.2-0.6-0.2H9.4V5h4.5V4.7z"/>
            </g>        
        </svg>
        `,
        arrowStop: `
            <svg viewBox="0 0 48 48">
                <path stroke="transparent" fill="currentColor" d="M4.8,2h3.6c1.2,0,2.2,1,2.2,2.2v39.4c0,1.2-1,2.2-2.2,2.2H4.8c-1.2,0-2.2-1-2.2-2.2V4.1
                    C2.6,3,3.6,2,4.8,2z"/>
                <path stroke="transparent" fill="currentColor" d="M19.7,22.5L39.1,3.2c0.5-0.5,1.1-0.7,1.7-0.7s1.3,0.2,1.7,0.7l2.2,2.2c0.5,0.4,0.8,1,0.8,1.7s-0.2,1.3-0.7,1.7L29.3,24.2
                    l15.5,15.5c0.5,0.5,0.7,1.1,0.7,1.7c0,0.7-0.3,1.3-0.8,1.7l-2.1,2.3C42,45.8,41.4,46,40.8,46s-1.3-0.2-1.7-0.7L19.7,25.9
                    c-0.5-0.5-0.8-1.1-0.8-1.7C18.9,23.6,19.1,23,19.7,22.5z"/>
            </svg>
        `,
        dashboard: `
            <svg viewBox="0 0 48 48">
                <g>
                    <g transform="translate(3.068 4.482)">
                        <path stroke="transparent" fill="currentColor" d="M39.3,11.9C32.6,1.9,19-0.9,8.9,5.7s-13,20.2-6.3,30.4L3.2,37l5.5-3.7c0.5-0.3,0.6-1,0.3-1.6c-0.3-0.5-1-0.6-1.6-0.3
                            l-3.5,2.5C2,30.8,1.2,27.3,1.3,23.8h4.4c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9H1.4c0.3-2.7,1.1-5.3,2.5-7.7c0.6-1,1.3-2.1,2.1-3
                            l3,3c0.2,0.2,0.4,0.2,0.6,0.2l0,0c0.2,0,0.4-0.1,0.6-0.3c0.3-0.3,0.3-0.9,0-1.2L7.1,9.7c3.5-3.2,8.2-5.2,13-5.4v4.4
                            c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9V4.3c3,0.1,6.1,0.9,8.9,2.6c1.5,0.8,2.8,1.9,3.9,3l-3.1,3.1c-0.3,0.3-0.3,0.9,0,1.2
                            c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l3-3c2.6,3,4.2,6.7,4.6,10.7h-4.4c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h4.5
                            c0.1,3.4-0.7,7-2.6,10.2l-3.5-2.7c-0.5-0.3-1.1-0.2-1.6,0.3c-0.3,0.5-0.2,1.1,0.3,1.6l5.5,3.7l0.6-0.9C44,28.7,44,19.3,39.3,11.9z
                            "/>
                        <g transform="translate(10.949 8.528)">
                            <path stroke="transparent" fill="currentColor" d="M19.9,9.9L19.9,9.9l-1,0.2
                                c-2.2,0.6-4.4,1.2-6.5,1.9c-0.5,0.2-1.1,0.4-1.8,0.4c-0.8-0.1-1.7,0.4-1.9,1.1c-0.3,0.9,0.2,1.9,1.1,2.2c0.7,0.2,1.6-0.1,2-0.7
                                c0.1-0.2,0.3-0.3,0.4-0.4c1.6-0.9,3.1-1.9,4.7-2.8L19.9,9.9z"/>
                            <path stroke="transparent" fill="currentColor"  d="M19.9,9.9l-3,1.8c-1.6,0.9-3.1,1.9-4.7,2.8
                                c-0.2,0.1-0.3,0.3-0.4,0.4c-0.5,0.8-1.6,1-2.4,0.5c-0.6-0.4-0.9-1.2-0.7-2c0.2-0.8,1-1.3,1.9-1.1c0.6,0,1.2-0.1,1.8-0.4
                                c2.2-0.6,4.4-1.2,6.5-1.9L19.9,9.9L19.9,9.9z"/>
                        </g>
                    </g>
                </g>
            </svg>
        `,
        downloadSM: `
            <svg viewBox="0 0 48 48">
                <path stroke="transparent" fill="currentColor" d="M8.9,20.4c-1,1.1-0.9,2.8,0.2,3.8L22.6,36c0.4,0.5,1.1,0.7,1.7,0.7s1.2-0.3,1.7-0.8l13.5-11.8c1.1-0.9,1.2-2.7,0.2-3.8
                    c-0.9-1.1-2.7-1.2-3.8-0.2L27,28V4.1c0-1.5-1.2-2.7-2.7-2.7s-2.7,1.2-2.7,2.7v24l-8.9-7.9C11.5,19.2,9.8,19.3,8.9,20.4z"/>
                <path stroke="transparent" fill="currentColor" fill="currentColor" d="M42.3,30.4c-1.5,0-2.7,1.2-2.7,2.7v7.5H9v-7.5c0-1.5-1.2-2.7-2.7-2.7s-2.8,1.2-2.8,2.7v10.1c0,1.5,1.2,2.7,2.7,2.7h36
                    c1.5,0,2.7-1.2,2.7-2.7V33.1C44.9,31.7,43.7,30.4,42.3,30.4z"/>
            </svg>
        `,
        back: `<svg viewBox="0 0 48 48"> <path stroke="currentColor" fill="currentColor"  d="M18.9,24C22,20.5,29.6,12.1,34.5,6.6c1-1.1,0.9-2.8-0.2-3.8c-1.1-1-2.8-0.9-3.8,0.2c0,0-4.3,4.8-8.6,9.6c-2.5,2.8-4.4,5.1-5.9,6.6c-0.8,0.9-1.5,1.8-2,2.3c-0.2,0.3-0.4,0.5-0.6,0.7c-0.5,0.5-0.7,1.2-0.7,1.9c0,0.7,0.3,1.3,0.7,1.9c0.1,0.2,0.3,0.4,0.6,0.7c0.4,0.5,1.1,1.2,2,2.3c1.4,1.6,3.4,3.8,5.9,6.6c4.2,4.7,8.6,9.5,8.6,9.6c1,1.1,2.7,1.1,3.8,0.2c1.1-1,1.1-2.7,0.2-3.8C29.6,35.9,22,27.5,18.9,24z"/></svg>`,
        dropdown: `
            <svg viewBox="0 0 48 48">
                <path stroke="currentColor" fill="currentColor" d="M45.4,13.7c-0.9-1-2.2-1-3.1-0.2C36.5,18.8,27.4,26.9,24,29.9c-3.4-3-12.5-11.1-18.3-16.4c-1-0.9-2.3-0.7-3.1,0.2c-0.9,1-0.7,2.3,0.2,3.1c0,0,5,4.5,9.9,8.8c2.9,2.6,6.8,6.1,9.9,8.7c0.5,0.4,1.1,0.6,1.6,0.6c0.6,0,1.1-0.2,1.6-0.6c3.9-3.4,7-6.2,9.9-8.7c4.9-4.4,9.8-8.8,9.9-8.8C46.2,16.1,46.2,14.7,45.4,13.7z"/>
            </svg>
        `,
        fx: `
            <svg viewBox="0 0 22.3 24.5" >
                <g  stroke="transparent" fill="currentColor">
                    <path d="M3.2,12.1c-0.4,0-0.7,0-1,0c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1c0.3,0,0.6,0,1,0c0-0.6,0-1.2,0-1.8c0-0.4,0-0.8,0-1.3
                        c0-1.7,1.2-3,2.9-3.1c0.4,0,0.7,0,1.1,0c0.6,0,0.9,0.4,0.9,1c0,0.6-0.4,1-0.9,1C6.9,6,6.5,6,6.1,6C5.6,6,5.2,6.3,5.2,6.8
                        c-0.1,1.1,0,2.2,0,3.3c0.3,0,0.6,0,0.9,0c0.7,0,1,0.4,1.1,1c0,0.6-0.4,1-1,1c-0.3,0-0.6,0-1,0c0,0.2,0,0.4,0,0.6c0,1.1,0,2.2,0,3.4
                        c0,1.7-1,2.9-2.7,3.2c-0.6,0.1-1.1-0.3-1.2-0.8c-0.1-0.6,0.3-1.1,0.9-1.2c0.8-0.1,1.1-0.4,1.1-1.3C3.2,14.7,3.2,13.5,3.2,12.1z"/>
                    <path d="M21.5,14.2c0,1.9-0.5,3.7-1.5,5.3c-0.1,0.2-0.2,0.3-0.3,0.5c-0.3,0.4-0.9,0.4-1.2,0.2c-0.4-0.2-0.6-0.7-0.4-1.2
                        c0.1-0.2,0.1-0.3,0.2-0.4c1.7-2.9,1.7-5.8,0-8.7C18.1,9.6,18,9.4,18,9.2c0-0.5,0.1-0.8,0.6-1c0.4-0.2,0.9,0,1.2,0.4
                        c0.8,1.2,1.3,2.4,1.5,3.8C21.4,13,21.4,13.6,21.5,14.2z"/>
                    <path d="M8.2,14.2c0,1.9,0.5,3.7,1.5,5.3c0.1,0.2,0.2,0.3,0.3,0.5c0.3,0.4,0.9,0.4,1.2,0.2c0.4-0.2,0.6-0.7,0.4-1.2
                        c-0.1-0.2-0.1-0.3-0.2-0.4c-1.7-2.9-1.7-5.8,0-8.7c0.1-0.2,0.2-0.4,0.3-0.6c0-0.5-0.1-0.8-0.6-1c-0.4-0.2-0.9,0-1.2,0.4
                        C9.1,9.7,8.7,11,8.4,12.4C8.3,13,8.3,13.6,8.2,14.2z"/>
                    <path d="M13.5,14.2c-0.4-0.3-0.7-0.7-1-1c-0.4-0.5-0.5-1.1-0.1-1.5c0.4-0.4,1-0.4,1.5,0.1c0.3,0.3,0.6,0.6,1.1,1
                        c0.3-0.3,0.5-0.7,0.8-1c0.5-0.5,1.2-0.6,1.6-0.2c0.4,0.4,0.4,1.1-0.2,1.6c-0.3,0.3-0.6,0.6-0.9,0.9c0.3,0.3,0.7,0.7,1,1
                        c0.5,0.5,0.5,1.1,0.1,1.5c-0.4,0.4-1,0.4-1.5-0.1c-0.3-0.3-0.6-0.6-1-1c-0.4,0.4-0.7,0.7-1,1c-0.5,0.5-1.1,0.5-1.5,0.1
                        c-0.4-0.4-0.4-1,0.1-1.5C12.8,14.9,13.1,14.5,13.5,14.2z"/>
                </g>
            </svg>
        `,
        onboarding: `
            <svg viewBox="0 0 48 48">
                <path stroke="transparent" fill="currentColor" d="M4.7,44.4l0.5-1.8c0.7-3.3,1.9-6.4,3.7-9.2c0.9-1.2,2.1-2.2,3.5-2.6c0.4-0.1,0.8-0.2,1.2-0.2c1.3,0,2.6,0.6,3.4,1.6c0.9,1.2,1.1,2.6,0.7,3.9c-0.4,1.3-1.2,2.6-2.4,3.5c-2,1.4-4.2,2.6-6.5,3.4c-0.5,0.2-1,0.4-1.5,0.5l-0.8,0.3L4.7,44.4z M13.6,32.7c-0.3,0-0.5,0-0.8,0.1c-1,0.3-1.8,0.9-2.4,1.7c-1.4,2-2.4,4.3-3,6.8L8,41.1c2.1-0.7,4.1-1.7,5.9-3.1c0.9-0.6,1.4-1.4,1.7-2.4c0.3-0.8,0.1-1.5-0.3-2.2C15,33,14.4,32.7,13.6,32.7z"/>
                <g stroke="transparent" fill="currentColor">
                    <path d="M32,12.6c-1.7,0-3.1,1.3-3.1,3.1s1.3,3.1,3.1,3.1l0,0l0,0c0.8,0,1.5-0.4,2.1-1c0.6-0.6,0.9-1.3,0.9-2.1
                        C35.1,14.1,33.8,12.7,32,12.6z M32,17.5c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8c1.1,0,1.8,0.8,1.8,1.8C33.9,16.7,33,17.5,32,17.5z"
                        />
                    <path d="M45.2,2.2h-1h-0.1c-3.1,0.3-6.1,1.1-8.9,2.3L34,5.1l0,0c-0.2,0.1-0.4,0.2-0.6,0.3h-0.1c-5,2.7-9.3,6.6-12.4,11.3l-0.6,0.1
                        c-0.1,0-1,0.1-1.2,0.2c-2.6,0.7-5,1.6-7.4,2.9C10.4,20.7,9.1,21.7,8,23c-0.5,0.6-0.9,1.2-1.3,1.8l-0.6,0.8l-1.3,1.8l-2.1,2.8
                        l2.6-0.5h0.2c0.1,0,0.2,0,0.3-0.1c1.9-0.5,3.8-0.8,5.9-0.9h0.3c1.2,0,2.5,0.1,3.6,0.4l0.7,0.2c0.1,0.2,0.2,0.4,0.3,0.5
                        c0.8,0.7,1.4,1.4,2.2,2.1l0.5,0.5c0.1,0.1,0.2,0.2,0.3,0.2l0,0l0.4,0.3h0.1l0.1,0.3c0.4,1.2,0.6,2.4,0.6,3.6v0.3
                        c0,1.9-0.2,3.9-0.6,5.9c0,0.1,0,0.2,0,0.3v0.2L19.7,46l2.7-2.3l1.7-1.4l0.8-0.6c0.6-0.5,1.2-1,1.7-1.4c1.2-1.1,2.2-2.5,2.9-3.9
                        c1.1-2.4,1.9-4.9,2.5-7.5c0-0.2,0.1-1.1,0.1-1.2V27c1.2-0.9,2.3-1.9,3.4-2.9c3.1-3.1,5.6-6.7,7.4-10.6c0-0.1,0.1-0.2,0.1-0.2l0,0
                        l0.6-1.4c1.1-2.7,1.6-5.6,1.9-8.4L45.2,2.2z M15.1,26.9c-1.1-0.2-2.1-0.3-3.2-0.3h-0.3c-1.3,0-2.7,0.2-4,0.4l0.2-0.3l0.6-0.8
                        c0.5-0.6,1-1.2,1.3-1.8c0.9-1.1,1.9-1.9,3.2-2.5c1.7-1,3.5-1.6,5.5-2.2C17,21.8,15.8,24.3,15.1,26.9z M27.5,35.3
                        c-0.6,1.2-1.3,2.4-2.3,3.3c-0.6,0.5-1.2,1-1.7,1.4l-0.7,0.6l-0.3,0.3c0.2-1.3,0.3-2.7,0.2-4l0,0l0,0v-0.3c-0.1-1.1-0.2-2.1-0.4-3.2
                        c2.6-0.9,5-2.2,7.3-3.7C29,31.6,28.3,33.4,27.5,35.3z M34,22.4c-3.8,3.7-8.4,6.5-13.5,8.3h-0.2l-0.3-0.2c-0.7-0.6-1.3-1.2-2-1.9
                        c0.7-3,1.8-5.8,3.5-8.3c3.1-5.5,7.6-9.9,13-12.9h0.1l0.2-0.1l0.2,0.2c1.1,1,2.1,1.9,3.1,3l2.6,2.5C39,16.4,36.7,19.6,34,22.4z
                        M41.9,9.4L41.9,9.4c-0.8-0.7-1.5-1.2-2.2-1.9c-0.7-0.6-1.4-1.2-2.1-1.8c1.8-0.6,3.6-1,5.6-1.3C42.9,6,42.5,7.7,41.9,9.4z"/>
                </g>
            </svg>
        `,
        search: `
            <svg viewBox="0 0 48 48">
                <path stroke="transparent" fill="currentColor"  d="M104,100.7L71.8,67.6c6-7,9.7-16.1,9.7-26c0-22-17.8-39.8-39.8-39.8c-22,0-39.8,17.8-39.8,39.8c0,22,17.8,39.8,39.8,39.8
                c9.9,0,19-3.6,26-9.7l32.2,33L104,100.7z M7.5,41.6c0-18.8,15.3-34,34-34c18.8,0,34,15.3,34,34s-15.3,34-34,34
                C22.8,75.6,7.5,60.4,7.5,41.6z"/>
            </svg>
        `,
        searchSM: `
            <svg viewBox="0 0 48 48">
                <path stroke="transparent" fill="currentColor"  d="M45.8,44.3L32.1,30.2c2.6-3,4.1-6.9,4.1-11.1c0-9.4-7.6-17-17-17s-17,7.6-17,17c0,9.4,7.6,17,17,17c4.3,0,8.1-1.6,11.1-4.1
                    L44.1,46L45.8,44.3z M19.2,33.6c-8,0-14.5-6.5-14.5-14.5c0-8,6.5-14.5,14.5-14.5c8,0,14.5,6.5,14.5,14.5
                    C33.7,27,27.2,33.6,19.2,33.6z"/>
            </svg>
        `,
        uploadSM: `
            <svg viewBox="0 0 48 48">
                <path stroke="transparent" fill="currentColor"  d="M12.7,18.5l8.9-7.9v24c0,1.5,1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7V10.7l8.9,7.9c1.1,1,2.9,0.9,3.8-0.2c1-1.1,0.9-2.9-0.2-3.8
                    L26,2.8C25.5,2.3,24.9,2,24.3,2c-0.6,0-1.3,0.2-1.7,0.7L9.1,14.5c-1.1,1-1.2,2.7-0.2,3.8C9.8,19.4,11.5,19.5,12.7,18.5z"/>
                <path stroke="transparent" fill="currentColor"  d="M42.3,30.4c-1.5,0-2.7,1.2-2.7,2.7v7.5H9v-7.5c0-1.5-1.2-2.7-2.7-2.7s-2.8,1.2-2.8,2.7v10.1c0,1.5,1.2,2.7,2.7,2.7h36
                    c1.5,0,2.7-1.2,2.7-2.7V33.1C44.9,31.7,43.7,30.4,42.3,30.4z"/>
            </svg>
        `,
        x: `
            <svg viewBox="0 0 48 48">
                <path stroke="transparent" fill="currentColor"  d="M45.1,41.9L27.2,24L45.1,6.1c0.9-0.9,0.9-2.3,0-3.2s-2.3-0.9-3.2,0L24,20.8L6.1,2.9C5.2,2,3.8,2,2.9,2.9S2,5.2,2.9,6.1
                L20.8,24L2.9,41.9c-0.9,0.9-0.9,2.3,0,3.2c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7L24,27.2l17.9,17.9c0.4,0.4,1,0.7,1.6,0.7l0,0
                c0.6,0,1.2-0.2,1.6-0.7C46,44.2,46,42.8,45.1,41.9z"/>
            </svg>
        `,
        xHover: `
            <svg viewBox="0 0 48 48">
                <path stroke="transparent" fill="currentColor"  d="M45.1,40.6L28.5,24L45.1,7.4l0,0c1.2-1.2,1.2-3.2,0-4.5l0,0c-1.2-1.2-3.2-1.2-4.5,0L24,19.5L7.4,2.9l0,0
                c-1.2-1.2-3.2-1.2-4.5,0l0,0c-1.2,1.2-1.2,3.2,0,4.5L19.5,24L2.9,40.6l0,0c-1.2,1.2-1.2,3.2,0,4.5C3.5,45.7,4.3,46,5.2,46
                c0.8,0,1.6-0.3,2.2-0.9L24,28.5l16.6,16.6c0.6,0.6,1.4,0.9,2.2,0.9s1.6-0.3,2.2-0.9C46.3,43.9,46.3,41.9,45.1,40.6z"/>
            </svg>
        `,
        investmentDetails: `
        <svg viewBox="0 0 48 48">
            <g stroke="transparent" fill="currentColor">
                <path d="M41.5,15.9l0-4.7l-4.5,1.1l1.4,1.1l-6.2,7.8l-3.4-3.1c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.2-0.8,0.4l-8,9.8l-3.4-3.1
                    c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.2-0.8,0.4l-7.8,9.5c-0.4,0.5-0.3,1.2,0.2,1.6c0.2,0.2,0.5,0.3,0.7,0.3
                    c0.3,0,0.6-0.1,0.9-0.4l7-8.6l3.4,3.1c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.2,0.8-0.4l8-9.9l3.5,3.1c0.2,0.2,0.5,0.3,0.8,0.3
                    c0.3,0,0.6-0.2,0.8-0.4l6.9-8.8L41.5,15.9z"/>
                <path d="M41.4,26.8l0-4.7l-4.5,1.1l1.4,1.1l-4.9,6.3l-3.4-3.1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.6,0.2-0.8,0.4l-5.4,7.1
                    c-0.4,0.5-0.3,1.2,0.2,1.6c0.2,0.2,0.4,0.2,0.7,0.2c0.3,0,0.7-0.2,0.9-0.4l4.7-6.1l3.4,3.1c0.2,0.2,0.5,0.3,0.9,0.3
                    c0.3,0,0.6-0.2,0.8-0.4l5.7-7.3L41.4,26.8z"/>
                <path d="M45,38.8H4.6V10.5c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v29.1c0,0.4,0.3,0.8,0.8,0.8H45c0.4,0,0.8-0.3,0.8-0.8
                    S45.4,38.8,45,38.8z"/>
            </g>
        </svg>
        `,
        hourGlassHalf: `
            <svg viewBox="0 0 48 48">
                <g>
                <circle fill="currentcolor" r="22" cy="24" cx="24"/>
                <path stroke="white" d="m16.05173,10.93749c0,-0.69152 0.44295,-1.24785 0.99353,-1.24785l13.90949,0c0.55059,0 0.99353,0.55633 0.99353,1.24785s-0.44295,1.24785 -0.99353,1.24785l-0.33118,0l0,0.98788c0,2.09534 -0.66235,4.10749 -1.84218,5.58931l-3.37802,4.23747l3.37387,4.23747c1.18396,1.48182 1.84632,3.49397 1.84632,5.58931l0,0.98788l0.33118,0c0.55059,0 0.99353,0.55633 0.99353,1.24785s-0.44295,1.24785 -0.99353,1.24785l-13.90949,0c-0.55059,0 -0.99353,-0.55633 -0.99353,-1.24785s0.44295,-1.24785 0.99353,-1.24785l0.33118,0l0,-0.98788c0,-2.09534 0.66235,-4.10749 1.84218,-5.58931l3.37802,-4.23747l-3.37802,-4.23747c-1.17982,-1.48182 -1.84218,-3.49397 -1.84218,-5.58931l0,-0.98788l-0.33118,0c-0.55059,0 -0.99353,-0.55633 -0.99353,-1.24785l0.00001,0zm4.57441,18.06777c-0.16145,0.20277 -0.31048,0.42115 -0.44295,0.64992l7.63367,0c-0.13248,-0.22877 -0.28151,-0.44714 -0.44295,-0.64992l-3.37387,-4.24268l-3.37387,4.23747l0,0.0052l0,0.00001l-0.00001,0l-0.00001,0zm7.19071,-12.66043c0.52989,-0.91509 0.81967,-2.02775 0.81967,-3.17161l0,-0.98788l-9.273,0l0,0.98788c0,1.14906 0.28978,2.25652 0.81967,3.17161l7.63367,0l-0.00001,0z"/>
                </g>
            </svg>
          `,
        circle: `
            <svg viewBox="0 0 48 48">
                <circle stroke-width="4" stroke="currentcolor" fill="none" r="22" cy="24" cx="24" />
            </svg>
        `,
        circleCheck: `
            <svg viewBox="0 0 48 48">
                <circle cx="24" cy="24" r="22" fill="currentColor"/>
                <path stroke-width="3" stroke="white" d="m12.2,23.2l-2.2,2.1l10,9.9l17.2,-20.2l-2.2,-2l-15.2,17.8l-7.6,-7.6z"/>
            </svg>`,
        logo: `
            <svg viewBox="0 0 193.36 184.4">
                <path fill="currentColor" d="M131.035,180.278,98.673,135.736,66.312,180.277a10,10,0,0,1-13.968,2.212L31.31,167.206A10,10,0,0,1,29.1,153.239L61.52,108.614,9.51,91.715a10,10,0,0,1-6.42-12.6l8.034-24.728a10,10,0,0,1,12.6-6.421L75.8,64.886V10a10,10,0,0,1,10-10h26a10,10,0,0,1,10,10V64.615l53.032-17.232a10,10,0,0,1,12.6,6.421l8.034,24.728a10,10,0,0,1-6.421,12.6L135.714,108.46l32.534,44.78a10,10,0,0,1-2.212,13.968L145,182.49a10,10,0,0,1-13.968-2.212Z" transform="translate(-2.599)"/>
            </svg>
        `,
        logoIP:`
            <svg viewBox="0 0 50 48">
                <g transform="matrix(1 0 0 1 -665 -609)"> 
                    <path fill-rule="nonzero" fill="currentColor" d="m713.57143,631.14286l-14.28572,4.64285l8.92858,12.14286c0.71428,0.71429 0.35714,2.14286 -0.35715,2.5l-5.35714,3.92857c-0.71429,0.71429 -2.14286,0.35715 -2.85714,-0.35714l-9.28572,-11.78571l-8.57143,12.14285c-0.71428,0.71429 -1.78571,1.07143 -2.85714,0.35715l-5.35714,-3.92858c-0.71429,-0.71428 -1.07143,-1.78571 -0.35714,-2.5l8.92857,-12.14285l-14.28572,-4.64286c-1.07143,-0.35714 -1.42857,-1.42857 -1.42857,-2.5l2.14286,-6.42857c0.35714,-1.07143 1.42857,-1.42857 2.5,-1.07143l14.92857,4.64286l0,-15c-0.64286,-1.07143 0.42857,-2.14286 1.5,-2.14286l6.42857,0c1.07143,0 2.14286,1.07143 2.07143,2.14286l0,14.64285l14.35714,-4.64285c1.07143,-0.35715 2.14286,0.35714 2.5,1.07143l2.14286,6.42857c0,1.07143 -0.35714,2.14285 -1.42857,2.5z"/>
                </g>
            </svg>
        `,
        alignLeft: `
            <svg viewBox="0 0 32 32">
                <g transform="rotate(-90,16,16)">                
                    <path fill="currentColor" d="M0 0v1h32v-1h-32zm5.5 2c-.3 0-.5.2-.5.5v20c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-20c0-.3-.2-.5-.5-.5h-5zm8 0c-.3 0-.5.2-.5.5v12c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-12c0-.3-.2-.5-.5-.5h-5zm8 0c-.3 0-.5.2-.5.5v16c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-16c0-.3-.2-.5-.5-.5h-5z" transform="translate(0 4)"></path>
                </g>
            </svg>
        `,
        alignTop: `
            <svg viewBox="0 0 32 32">
                <g transform="rotate(0,16,16)">                
                    <path fill="currentColor" d="M0 0v1h32v-1h-32zm5.5 2c-.3 0-.5.2-.5.5v20c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-20c0-.3-.2-.5-.5-.5h-5zm8 0c-.3 0-.5.2-.5.5v12c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-12c0-.3-.2-.5-.5-.5h-5zm8 0c-.3 0-.5.2-.5.5v16c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-16c0-.3-.2-.5-.5-.5h-5z" transform="translate(0 4)"></path>
                </g>
            </svg>
        `,
        alignRight: `
            <svg viewBox="0 0 32 32">
                <g transform="rotate(90,16,16)">                
                    <path fill="currentColor" d="M0 0v1h32v-1h-32zm5.5 2c-.3 0-.5.2-.5.5v20c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-20c0-.3-.2-.5-.5-.5h-5zm8 0c-.3 0-.5.2-.5.5v12c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-12c0-.3-.2-.5-.5-.5h-5zm8 0c-.3 0-.5.2-.5.5v16c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-16c0-.3-.2-.5-.5-.5h-5z" transform="translate(0 4)"></path>
                </g>
            </svg>
        `,
        alignBottom: `
            <svg viewBox="0 0 32 32">
                <g transform="rotate(180,16,16)">                
                    <path fill="currentColor" d="M0 0v1h32v-1h-32zm5.5 2c-.3 0-.5.2-.5.5v20c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-20c0-.3-.2-.5-.5-.5h-5zm8 0c-.3 0-.5.2-.5.5v12c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-12c0-.3-.2-.5-.5-.5h-5zm8 0c-.3 0-.5.2-.5.5v16c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-16c0-.3-.2-.5-.5-.5h-5z" transform="translate(0 4)"></path>
                </g>
            </svg>
        `,
        spin: `
            <svg  viewBox="0 0 48 48" >
                <circle  cx="24" cy="24" r="20" fill="inherit" stroke-width="2" stroke="currentColor" 
                stroke-dasharray="89,200" stroke-dashoffset="0"
                stroke-miterlimit="10"
                >
                <animate attributeName="stroke-dasharray" values="1, 200;89,200;89,200" dur="1.5s" repeatCount="indefinite" />
                <animate attributeName="stroke-dashoffset" values="0;-35px;-124px" dur="1.5s" repeatCount="indefinite" /> 
                </circle>
            </svg>
        `,
        filterClear: `
            <svg  viewBox="0 0 14 14" >
                <g fill="currentColor">
                    <path d="m13,1.5l-0.4,-0.5c-0.1,-0.2 -0.3,-0.2 -0.4,-0.1l-1.7,1.3l-9.4,0c-0.2,0 -0.4,0.1 -0.5,0.3s-0.1,0.5 0.1,0.7l3.8,3.8l-3.8,2.9c-0.2,0.1 -0.2,0.3 -0.1,0.4l0.4,0.5c0.1,0.2 0.3,0.2 0.4,0.1l11.5,-8.9c0.2,-0.1 0.2,-0.3 0.1,-0.5z"/>
                    <path d="m4.7,11.6c0,0.2 0.1,0.3 0.2,0.4l1.7,1.2c0.2,0.1 0.3,0.1 0.5,0c0.2,-0.1 0.3,-0.2 0.3,-0.5l0,-5l-2.7,2.1l0,1.8z"/>
                </g>
            </svg>
        `
    };

    renderSvgSprite() {
        const symbols = (Object.keys(this.icons) as IconType[])
            .map(iconName => {
                const svgContent = this.icons[iconName].split('svg')[1];
                return `<symbol id=${iconName}${svgContent}symbol>`;
            })
            .join('');
        return svgSprite(symbols);
    }

    load() {
        if (!document) {
            return;
        }
        const existing = document.getElementById('__ALTER_DOMUS_SVG_SPRITE_NODE__');
        const mountNode = document.body;

        if (!existing) {
            mountNode.insertAdjacentHTML('afterbegin', this.renderSvgSprite());
        }
    }
}
