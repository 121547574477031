<div role="dialog" [attr.aria-modal]="true" [attr.aria-labelledby]="_dialogLabelId ?? undefined"
  class="mat-datepicker-content-container a-date-range-picker-content-container"
  [class.mat-datepicker-content-container-with-custom-header]="datepicker.calendarHeaderComponent"
  [class.mat-datepicker-content-container-with-actions]="_actionsPortal" [class.same-month]="sameMonthView$ | async">
  <div class="flex items-center gap-2">
    <ng-template [ngTemplateOutlet]="calendarTemp"
      [ngTemplateOutletContext]="{$implicit:{ id:'first',label:'Start:' }}"></ng-template>
    <div><i class="fa fa-arrow-right text-primary-500 text-lg"></i></div>
    <ng-template [ngTemplateOutlet]="calendarTemp"
      [ngTemplateOutletContext]="{$implicit:{ startAt: end, id:'last',label:'End:' }}"></ng-template>
  </div>

  <ng-template [cdkPortalOutlet]="_actionsPortal"></ng-template>

  <!-- Invisible close button for screen reader users. and always invisible, keep to block auto close on select end -->
  <!-- <button type="button" tabindex="-1" mat-raised-button [color]="color || 'primary'" class="mat-datepicker-close-button"
    [class.cdk-visually-hidden]="true" (click)="datepicker.close()">{{ _closeButtonText }}</button> -->
</div>


<ng-template #calendarTemp let-data>
  <div class="flex flex-col">
    <span class="px-6 pt-4 pb-0 font-medium text-charcoal-500">{{data.label}}</span>
    <mat-calendar [id]="datepicker.id + '-' + data.id" [class]="data.id" [ngClass]="datepicker.panelClass"
      [startAt]="data?.startAt || datepicker.startAt" [startView]="datepicker.startView"
      [minDate]="datepicker._getMinDate()" [maxDate]="datepicker._getMaxDate()"
      [dateFilter]="datepicker._getDateFilter()" [headerComponent]="datepicker.calendarHeaderComponent"
      [selected]="_getSelected()" [dateClass]="datepicker.dateClass" [comparisonStart]="comparisonStart"
      [comparisonEnd]="comparisonEnd" [@fadeInCalendar]="'enter'" [startDateAccessibleName]="startDateAccessibleName"
      [endDateAccessibleName]="endDateAccessibleName" (yearSelected)="datepicker._selectYear($event)"
      (monthSelected)="datepicker._selectMonth($event)" (viewChanged)="datepicker._viewChanged($event)"
      (_userSelection)="handleUserSelection($event,data)" (_userDragDrop)="_handleUserDragDrop($event)"></mat-calendar>
  </div>
</ng-template>