import { ElementRef, Inject, Injectable, Optional } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { DateRange, MatDateRangeSelectionStrategy } from "@angular/material/datepicker";
import { DATE_RANGE_PICKER_OPTION, DateRangePickerOption } from "./date-range-picker-option";

const onCalendar = (el: EventTarget, id: 'first' | 'last' = 'first') => {
  let ne: HTMLElement | null = el as HTMLElement;
  const dept = 20;
  let i = 0
  while (i < dept && ne) {
    if (ne.id?.match(`mat-datepicker-[0-9]*-${id}`)) {
      return true;
    }
    ne = ne.parentElement;
  }
  return false;
}

@Injectable()
export class DuoDirectionRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D>{
  constructor(
    private _dateAdapter: DateAdapter<D>,
    // @Inject(forwardRef(() => DateRangePickerContent)) private _content: DateRangePickerContent<D>,
    @Optional() @Inject(DATE_RANGE_PICKER_OPTION) private option?: DateRangePickerOption,
  ) {
  }
  selectionFinished(date: D, currentRange: DateRange<D>) {
    let { start, end } = currentRange;

    if (start == null) {
      start = date;
    } else if (end == null && date && this._dateAdapter.compareDate(date, start) >= 0) {
      end = date;
    } else {
      start = date;
      end = null;
    }

    return new DateRange<D>(start, end);
  }

  defaultCreatePreview(activeDate: D | null, currentRange: DateRange<D>) {
    let start: D | null = null;
    let end: D | null = null;

    if (currentRange.start && !currentRange.end && activeDate) {
      start = currentRange.start;
      end = activeDate;
    }

    return new DateRange<D>(start, end);
  }

  createDrag(dragOrigin: D, originalRange: DateRange<D>, newDate: D) {
    let start = originalRange.start;
    let end = originalRange.end;

    if (!start || !end) {
      // Can't drag from an incomplete range.
      return null;
    }

    const adapter = this._dateAdapter;

    const isRange = adapter.compareDate(start, end) !== 0;
    const diffYears = adapter.getYear(newDate) - adapter.getYear(dragOrigin);
    const diffMonths = adapter.getMonth(newDate) - adapter.getMonth(dragOrigin);
    const diffDays = adapter.getDate(newDate) - adapter.getDate(dragOrigin);

    if (isRange && adapter.sameDate(dragOrigin, originalRange.start)) {
      start = newDate;
      if (adapter.compareDate(newDate, end) > 0) {
        end = adapter.addCalendarYears(end, diffYears);
        end = adapter.addCalendarMonths(end, diffMonths);
        end = adapter.addCalendarDays(end, diffDays);
      }
    } else if (isRange && adapter.sameDate(dragOrigin, originalRange.end)) {
      end = newDate;
      if (adapter.compareDate(newDate, start) < 0) {
        start = adapter.addCalendarYears(start, diffYears);
        start = adapter.addCalendarMonths(start, diffMonths);
        start = adapter.addCalendarDays(start, diffDays);
      }
    } else {
      start = adapter.addCalendarYears(start, diffYears);
      start = adapter.addCalendarMonths(start, diffMonths);
      start = adapter.addCalendarDays(start, diffDays);
      end = adapter.addCalendarYears(end, diffYears);
      end = adapter.addCalendarMonths(end, diffMonths);
      end = adapter.addCalendarDays(end, diffDays);
    }

    return new DateRange<D>(start, end);
  }
  createPreview(activeDate: D | null, currentRange: DateRange<D>, event: MouseEvent): DateRange<D> {
    if (!this.option?.duoDirectionSelectionStrategy) {
      return this.defaultCreatePreview(activeDate, currentRange);
    }
    let start: D | null = null;
    let end: D | null = null;

    if (currentRange.start && !currentRange.end && activeDate) {
      // during the selection, only show range across month, same month won't show it
      if (activeDate && (currentRange.start as any).getMonth() != (activeDate as any).getMonth()) {
        start = currentRange.start;
        end = activeDate;
      }
    }
    else if (!currentRange.start && currentRange.end && activeDate) {
      if (currentRange.end > activeDate && (currentRange.end as any).getMonth() != (activeDate as any).getMonth()) {
        start = activeDate;
        end = currentRange.end;
      }
    }
    else if (currentRange.start && currentRange.end && activeDate) {
      // when on selected range
      if (activeDate < currentRange.start && event.target && onCalendar(event.target, 'first')) {
        start = activeDate;
        end = currentRange.start;
      }
      if (activeDate > currentRange.end && event.target && onCalendar(event.target, 'last')) {
        start = currentRange.end;
        end = activeDate;
      }
    }

    // if (currentRange.start && !currentRange.end && activeDate) {
    //   if (currentRange.start > activeDate) {
    //     start = activeDate;
    //     end = currentRange.start;
    //   }
    //   else {
    //     start = currentRange.start;
    //     end = activeDate;
    //   }
    // }
    // else if (currentRange.end && !currentRange.start && activeDate) {
    //   if (currentRange.end < activeDate) {
    //     start = currentRange.end;
    //     end = activeDate;
    //   }
    //   else {
    //     start = activeDate;
    //     end = currentRange.end;
    //   }
    // }
    // else if (currentRange.start && currentRange.end && activeDate) {
    //   if (activeDate < currentRange.start) {
    //     start = activeDate;
    //     end = currentRange.start;
    //   }
    //   if (activeDate > currentRange.end) {
    //     start = currentRange.end;
    //     end = activeDate;
    //   }
    // }

    return new DateRange<D>(start, end);
  }
}

// @Injectable()
// export class DuoDirectionRangeSelectionStrategy<D> extends DefaultMatCalendarRangeStrategy<D> implements MatDateRangeSelectionStrategy<D>{
//   constructor(
//     _dateAdapter: DateAdapter<D>,
//     @Optional() @Inject(DATE_RANGE_PICKER_OPTION) private option?: DateRangePickerOption,
//   ) {
//     super(_dateAdapter);
//   }
//   override createPreview(activeDate: D | null, currentRange: DateRange<D>): DateRange<D> {
//     if (!this.option?.duoDirectionSelectionStrategy) {
//       return super.createPreview(activeDate, currentRange);
//     }
//     let start: D | null = null;
//     let end: D | null = null;

//     if (currentRange.start && !currentRange.end && activeDate) {
//       if (currentRange.start > activeDate) {
//         start = activeDate;
//         end = currentRange.start;
//       }
//       else {
//         start = currentRange.start;
//         end = activeDate;
//       }
//     }
//     else if (currentRange.end && !currentRange.start && activeDate) {
//       if (currentRange.end < activeDate) {
//         start = currentRange.end;
//         end = activeDate;
//       }
//       else {
//         start = activeDate;
//         end = currentRange.end;
//       }
//     }
//     else if (currentRange.start && currentRange.end && activeDate) {
//       if (activeDate < currentRange.start) {
//         start = activeDate;
//         end = currentRange.start;
//       }
//       if (activeDate > currentRange.end) {
//         start = currentRange.end;
//         end = activeDate;
//       }
//     }

//     return new DateRange<D>(start, end);
//   }
// }