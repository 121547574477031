import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoadingComponent } from './loading/loading.component';
import { IconComponent } from '@alterdomus/common-ui/icon';


@NgModule({
  imports: [CommonModule, ProgressSpinnerModule, IconComponent],
  declarations: [LoadingComponent],
  exports: [LoadingComponent]
})
export class LoadingModule { }
