import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule as PBreadcrumbModule } from 'primeng/breadcrumb';
import { breadCrumbConfig, defaultMenuConfig, MENU_CONFIG } from './bread-crumb-config';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
// import { BreadcrumbDemoComponent } from './components/breadcrumb/breadcrumb-demo.component';


@NgModule({
  declarations: [
    BreadcrumbComponent
  ],
  exports: [
    BreadcrumbComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PBreadcrumbModule
  ],
  providers: [{ provide: MENU_CONFIG, useValue: defaultMenuConfig }]
})
export class BreadcrumbModule {
  static forRoot(config: Partial<breadCrumbConfig> | undefined = undefined): ModuleWithProviders<BreadcrumbModule> {
    return {
      ngModule: BreadcrumbModule,
      providers: [
        {
          provide: MENU_CONFIG, useValue: {
            ...defaultMenuConfig,
            ...config
          }
        }
      ]
    }
  }
}
