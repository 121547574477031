import { CompanyDto, ProfileDto } from '@alterdomus/crm-shared/core';

export type Company = CompanyDto & {
  data?: any,
  profile?: ProfileDto & {
    profiles?: any
  };
}


