
export interface User {
  id: any;
  userName: string,
  firstName: string,
  lastName: string,
  email: string,
  // internalUser: boolean,
  isAuthenticated?: boolean;
  impersonatorUserId?: number;
  permissions?: string[];
  fundPermissions?: string[];
  shadowUser: ShadowUser;
  isExternal: any;
  isAdmin: boolean;
  isShadowUser: boolean;
  sessionId: string;
  fullName: string;
  mcpId?: number;
}

export interface ShadowUser {
  id: number;
  name: string;
}

export interface Setting {
  name: string,
  value: string
}

export interface MenuItem {
  items: MenuItem[];
  routerLink?: string,
  url?: string,
  label: string,
  icon?: string,
  name?: string,
  hasParam?: boolean,
  click?: () => void;
  
  children?: MenuItem[]
  customData?: any;
  command?: () => void;
}

export interface Session {
  legalFormSettings?: any[];
  dataFormats?: any;
  user?: User | null,
  menus?: { [key: 'main' | 'profile' | 'fab' | string]: MenuItem[] }
  location?: string;
  showAccountTip?: boolean;
  enableBulkEmail?: boolean;
  settings?: Setting[];
  permissions?: string[];   // user level permisisons
}
