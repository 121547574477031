import { ListModule } from '@alterdomus/common-ui/list';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CheckboxModule } from 'primeng/checkbox';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { SearchBarComponent } from './search-bar/search-bar.component';

@NgModule({
  imports: [
    CommonModule,
    CheckboxModule,
    FormsModule,
    RouterModule,
    ListModule,
    VirtualScrollerModule
  ],
  declarations: [
    SearchBarComponent
  ],
  exports: [
    SearchBarComponent
  ]
})
export class SearchBarModule { }
