import { AccountDto, CompanyDto, FundDto, ProfileType, UserDto } from "@alterdomus/crm-shared/core"
import { CurrencyPipe } from "@angular/common"
import { FilterCat } from "./filter"
import { SearchResult } from "./search-result"

export const mapFundToSearchResult = (params: { data: FundDto, cat: FilterCat, index: number, cp: CurrencyPipe, currency: string }): SearchResult => {
  return {
    index: params.index,
    cat: params.cat,
    data: {
      id: params.data.id,
      title: params.data.shortDealName,
      type: ProfileType.Fund,
      summaries: [
        { label: '', value: `${params.data.investorCount} Investor Accounts` },
        { label: '', value: `${params.data.nav ? params.cp.transform(params.data.nav, params.data.currency || params.currency || 'USD') : ''}` },
      ]
    }
  }
}

export const mapSPVToSearchResult = (params: { data: any, cat: FilterCat, index: number, cp: CurrencyPipe, currency: string }): SearchResult => {
  return {
    index: params.index,
    cat: params.cat,
    data: {
      id: params.data.id,
      title: params.data.shortDealName,
      type: ProfileType.SPV,
      summaries: [
        { label: '', value: `${params.data.investorCount} Investor Accounts` },
        { label: '', value: `${params.data.nav ? params.cp.transform(params.data.nav, params.data.currency || params.currency || 'USD') : ''}` },
      ]
    }
  }
}

export const mapAccountToSearchResult = (params: { data: AccountDto, cat: FilterCat, index: number, cp: CurrencyPipe, currency: string }): SearchResult => {
  return {
    index: params.index,
    cat: params.cat,
    data: {
      id: params.data.id,
      title: params.data.name,
      type: ProfileType.Account,
      summaries: [
        { label: 'Fund Name:', value: `${params.data.fundName}` },
        { label: '', value: `${params.data.accountBalance ? params.cp.transform(params.data.accountBalance, params.data.currency || params.currency || 'USD') : ''}` },
      ]
    }
  }
}


export const mapCompanyToSearchResult = (params: { data: CompanyDto, cat: FilterCat, index: number }): SearchResult => {
  return {
    index: params.index,
    cat: params.cat,
    data: {
      id: params.data.id,
      title: params.data.companyShortName,
      type: ProfileType.Company,
      summaries: [
        { label: 'Company Tax Id:', value: `` },
        { label: '', value: `` },
      ]
    }
  }
}

export const mapUserToSearchResult = (params: { data: UserDto, cat: FilterCat, index: number }): SearchResult => {
  return {
    index: params.index,
    cat: params.cat,
    data: {
      id: params.data.id,
      title: params.data.name,
      type: ProfileType.Contact,
      summaries: [
        { label: 'UserName:', value: `${params.data.userName}` },
        { label: 'Primary Email:', value: `${params.data.emailAddress}` },
      ]
    }
  }
}
