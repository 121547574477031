import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { END_POINTS, EndPoints } from './end-points';
import { ErrorInterceptor } from './error.interceptor';
import { RemoteErrorHandler } from './remote-error.handler';
import { RestInterceptor } from './rest.interceptor';

@NgModule({
  imports: [CommonModule],
})
export class InterceptorModule {
  static forRoot(endpoints: EndPoints, errorHandler?: Type<RemoteErrorHandler>): ModuleWithProviders<InterceptorModule> {
    let providers: Provider = [
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: RestInterceptor, multi: true },
    ]
    providers.push({ provide: END_POINTS, useValue: endpoints });
    if (errorHandler) {
      providers.push({ provide: RemoteErrorHandler, useClass: errorHandler })
    }
    else {
      providers.push(RemoteErrorHandler);
    }
    return {
      ngModule: InterceptorModule,
      providers: providers
    };
  }
}
