import { Account, AccountQuery } from '@alterdomus/crm-shared/account-store';
import { Company, CompanyQuery } from '@alterdomus/crm-shared/company-store';
import { ProfileType } from '@alterdomus/crm-shared/core';
import { FundQuery } from '@alterdomus/crm-shared/fund-store';
import { Injectable } from '@angular/core';
import { applyTransaction, combineQueries } from '@datorama/akita';
import { FilterService } from 'primeng/api';
import { BehaviorSubject, map } from 'rxjs';
import { Category } from './category';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  updateFilters(filters: { key: string; cats: any[]; }) {
    applyTransaction(() => {
      this.filterBySub.next(filters.cats);
      this.searchSub.next(filters.key);
    })
  }

  private searchSub = new BehaviorSubject<string>('');
  private search$ = this.searchSub.asObservable();
  private filterBySub = new BehaviorSubject<Category[]>([]);
  private filterBy$ = this.filterBySub.asObservable();

  cats: Category[] = [
    { label: 'Fund', value: ProfileType.Fund, show: true },
    { label: 'Data Room', value: ProfileType.DataRoom, show: true },
    { label: 'Account', value: ProfileType.Account, show: true },
    { label: 'Opportunity', value: ProfileType.Account, show: true },
    { label: 'Company', value: ProfileType.Company, show: true },
    { label: 'Contact', value: ProfileType.Contact, show: true }
  ]

  constructor(
    private fundQuery: FundQuery,
    private accoutQuery: AccountQuery,
    private companyQuery: CompanyQuery,
    // private userQuery: UserQuery,
    private filterService: FilterService
  ) {

  }

  get searchItems$() {
    return combineQueries([
      this.search$,
      this.filterBy$,
      this.fundQuery.selectFunds(),
      this.fundQuery.selectDataRooms(),
      this.accoutQuery.selectAccounts(),
      this.accoutQuery.selectOpportunities(),
      this.companyQuery.selectCompanies(),
      // this.userQuery.selectUsers()
    ]).pipe(
      map(([key, filterBy, funds, dataRoms, accounts, opportunities, companies]) => {
        let result: any[] = [];
        filterBy.filter(p => p.show).forEach(cat => {
          if (cat.value == ProfileType.Fund) {
            result = [...result, ...this.searchFunds(funds, key)]
          }
          if (cat.value == ProfileType.DataRoom) {
            result = [...result, ...this.searchFunds(dataRoms, key, 'DataRoom')]
          }
          if (cat.value == ProfileType.Account) {
            result = [...result, ...this.searchAccounts(accounts, key)]
          }
          // if (cat.value == ProfileType.Account) {
          //   result = [...result, ...this.searchAccounts(opportunities, key, 'Opportunity')]
          // }
          if (cat.value == ProfileType.Company) {
            result = [...result, ...this.searchCompanies(companies, key)]
          }
          if (cat.value == ProfileType.Contact) {
            result = [...result, ...[]]
          }
        })
        return result;
      })
    )
  }
  private searchFunds(funds: any, key: string, label = 'Fund') {
    return this.filterService.filter(funds, ['shortDealName', 'mcpid',], key, 'contains').map((fund, i) => ({
      ...fund,
      label: i === 0 ? label : '',
      name: fund.shortDealName,
      routerLink: `/fund`
    }))
  }
  private searchAccounts(accounts: Account[], key: string, label = 'Account') {
    return this.filterService.filter(accounts, ['name', 'mcpid',], key, 'contains').map((account, i) => ({
      ...account,
      label: i === 0 ? label : '',
      name: account.name,
      routerLink: `/account`
    }))
  }
  private searchCompanies(companies: Company[], key: string) {
    return this.filterService.filter(companies, ['companyShortName', 'mcpid',], key, 'contains').map((company, i) => ({
      ...company,
      label: i === 0 ? 'Company' : '',
      name: company.companyShortName,
      routerLink: `/company`
    }))
  }

}
