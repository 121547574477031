import { AuthEventType, AuthEvents } from "@alterdomus/auth/core";
import { setGlobalDateFormat } from '@alterdomus/shared/pipes';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { BehaviorSubject, map, of, switchMap, tap } from "rxjs";
import { corproSession, enableBulkEmail, isAdmin, isAdminUser, isExternal, isLogin, isShadowUserMode, shadowUser, showAccountTip, userFullName } from "./models/selectors";
import { Session } from "./models/session";
import { SessionProvider } from "./session.provider";

@Injectable({ providedIn: 'root' })
export class SessionService {
  applySession(data: any) {
    this.sessionSub.next(data);
  }
  private sessionSub = new BehaviorSubject<Session>({});

  session$ = this.sessionSub.asObservable().pipe(map(session => this.mapToSession(session)));

  menus$ = (name: 'main' | 'float' | string) => this.session$.pipe(
    map(p => (p.menus && p.menus[name]))
  )

  get session() {
    return this.mapToSession(this.sessionSub.value);
  }
  get legalFormSettings(): any[] {
    return this.session.legalFormSettings || [];
  }

  constructor(
    private router: Router,
    private authEvents: AuthEvents,
    private sessionProvier: SessionProvider) {
    // Session module should request/clear session automatically with auth events in my opinion
    this.authEvents.events.subscribe(event => {
      if (event.type == AuthEventType.RequireLogin) {
        if (this.session.user?.isAuthenticated) {
          this.resetSession();
        }
        //  else {
        //   this.router.navigate(['/login']);
        // }
      }
      if (event.type == AuthEventType.SessionLoaded) {
        this.applySession(event.data);
      }
      if (event.type == AuthEventType.Authenticated) {
        if (!this.session.user || !this.session.user.isAuthenticated) {
          this.loadSession().subscribe((session) => {
            if (!this.router.routerState.snapshot.url || this.router.routerState.snapshot.url == '/login') {
              this.router.navigate(['']);
            }
          });
        }
        else {
          if (this.router.routerState.snapshot.url == '/login') {
            this.router.navigate(['']);
          }
          if (event.data !== undefined) {
            this.router.navigate([event.data]);
          }
        }
      }
    })
    console.log(`session service created`);
  }
  loadSession() {
    return this.sessionProvier.loadSession().pipe(
      // debounceTime(500),
      tap(session => {
        this.authEvents.emitEvent({ type: AuthEventType.SessionLoaded, data: session });
        // if (session && session.settings) {
        //   setGlobalDateFormat((session.settings as any)['dateFormat'])
        // }
        //setGlobalDateFormat(this.dataFormats.date);
        // this.sessionSub.next(session);
      }));
  }

  resetSession() {
    this.sessionSub.next({});
  }

  grantedOnUrl(url: string) {
    return this.session$.pipe(
      switchMap(session => {
        if (session.user) {
          return of(session);
        }
        else {
          return this.loadSession();
        }
      }),
      map(session => {
        if (session.user?.isAuthenticated !== true) {
          this.authEvents.emitEvent({ type: AuthEventType.RequireLogin });
          return false;
        }
        else if (!this.findUrl(url, session.menus)) {
          this.authEvents.emitEvent({ type: AuthEventType.NoPermissions });
          return false;
        }
        else {
          return true;
        }
      })
    )
  }

  mapToSession(response: any) {
    const user = response?.extraProperties?.currentUser;
    return (<Session>{
      user: user ? {
        ...user,
        firstName: user?.firstName || user?.['name'],
        lastName: user?.lastName || user?.['surName'],
      } : null,
      menus: this.mapToMenuItem(response?.extraProperties?.menus),
      legalFormSettings: response?.extraProperties?.legalFormSettings
    })
  }

  requestLogin() {
    this.authEvents.login();
  }

  private mapToMenuItem(menus: { [key: string]: MenuItem[] }) {
    const checkSubItems = (menuItem: MenuItem) => {
      const anyMenuItem = (menuItem as any);
      menuItem.label = anyMenuItem.displayName;
      menuItem.state = {
        ...anyMenuItem.customData,
        name: anyMenuItem.name,
      };
      menuItem.visible = anyMenuItem?.customData?.visible;
      menuItem.tabindex = anyMenuItem?.customData?.order;
      menuItem.styleClass = anyMenuItem?.cssClass;
      if (anyMenuItem.url?.startsWith('#') === true) {
        anyMenuItem.url = null;
      }
      if (anyMenuItem.url?.startsWith('http') != true) {
        menuItem.routerLink = anyMenuItem.url;
        menuItem.queryParams = anyMenuItem?.customData?.queryParams;
      }
      if (menuItem?.items) {
        if (menuItem.items.length > 0) {
          menuItem.items.forEach(item => {
            checkSubItems(item);
          });
        }
        else {
          delete menuItem.items;
        }
      }
    }
    for (var key in menus) {
      menus[key].forEach(element => {
        checkSubItems(element);
      });
    }
    return menus;
  }


  private findUrl(url: any, menus?: { [key: string]: MenuItem[] }) {
    if (!menus) return false;
    const hasMenu = (menu: { url?: string, state: { hasParam?: boolean }, items?: any[] }, url: string): boolean => {
      const found = (menu.url &&
        (
          menu.url.toLocaleLowerCase() == url.toLocaleLowerCase()
          ||
          (
            (menu.state.hasParam) &&
            url.toLocaleLowerCase().indexOf(menu.url.toLocaleLowerCase()) == 0
          )
        )) || (
          menu?.items?.some(item => hasMenu(item, url)) === true
        );
      return found;
    }

    const menusToCheck = menus;
    for (var key in menusToCheck) {
      if (menusToCheck[key].some((menu: any) => hasMenu(menu, url))) {
        return true;
      }
    }
    return false;
  }

  currentUser(): { id: number, userName: string, firstName: string, lastName: string, fullName: string } {
    const { mcpId: id = 0, userName = '', firstName = '', lastName = '', fullName = '', } = this.session?.user || {};
    return { id, userName, firstName, lastName, fullName };
  }
  isInShadow(): boolean {
    return this.isShadowUser;
  }


  /** @deprecated props and methods **/
  IsInShadowMode$ = this.session$.pipe(
    map(session => {
      return isShadowUserMode(session);
    }))
  /** @deprecated **/
  loginUserFullName$ = this.session$.pipe(
    map(session => userFullName(session))
  );

  /** @deprecated **/
  tryGetSession() {
    return this.session$;
  }

  // Used in DSF session service
  get isShadowUser() {
    return !!isShadowUserMode(this.session);
  }
  get userFullName() {
    return `${this.session.user?.firstName} ${this.session.user?.lastName}`
  }
  get isAdmin() {
    return isAdminUser(this.session);
  }
  get userEmail() {
    return this.session.user?.email;
  }
  get isPublicUser() {
    return this.session.user?.isExternal !== false;
  }

  get dataFormats() {
    // !temperoary solution before 2024.4
    // https://dev.azure.com/AlterDomusGlobal/CorPro/_workitems/edit/180584
    const userFormat = localStorage.getItem('format');
    if (userFormat) {
      try {
        return JSON.parse(userFormat);
      }
      catch {
        return {
          date: 'dd-MMM-yy',
          percentage: '00.0'
        }
      }
    }
    return {
      date: 'dd-MMM-yy',
      percentage: '00.0'
    }
  }

  get IsLogin$() {
    return this.session$.pipe(map(session => isLogin(session)));
  }
  get IsAdmin$() {
    return this.session$.pipe(map(session => isAdmin(session)));
  }
  get shadowUser$() {
    return this.session$.pipe(map(session => shadowUser(session)));
  }
  get Location$() {
    return this.session$.pipe(map(session => session?.location || 'US'));
  }
  get corproSession$() {
    return this.session$.pipe(map(session => corproSession(session)));
  }
  get IsExternalUser$() {
    return this.session$.pipe(map(session => isExternal(session)));
  }
  get showAccountTip$() {
    return this.session$.pipe(map(session => showAccountTip(session)));
  }
  get enableBulkEmail$() {
    return this.session$.pipe(map(session => enableBulkEmail(session)));
  }

  get IsEMEA$() {
    return this.Location$.pipe(map(location => location === 'EMEA'));
  };

  get IsEMEA() {
    return this.session?.location === 'EMEA';
  }

}
