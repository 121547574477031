<div class="fixed bottom-24 flex flex-col gap-3 z-[100]" [ngClass]="{
    'right-[-2rem] opacity-60 transition-[right] hover:opacity-100 hover:pr-2 hover:right-0':dock,
    'right-4':!dock,
  }">
  <button *ngFor="let item of menus" mat-fab color="primary" (click)="toggleMenu($event,menu,item)">
    <mat-icon fontIcon="{{item.icon}}"></mat-icon>
  </button>
</div>
<p-menu #menu a-align-on-top [popup]="true" [model]="subMenus" [baseZIndex]="999" styleClass="a-float-action-menu"></p-menu>
<ng-template #buttonsPanelTemplate let-menus>
  <ul class="a-float-action-menu-buttons">
    <li *ngFor="let item of menus">
      <button pButton (click)="panelButtonClick($event,item)"
        class="a-float-action-menu-button p-button-outlined"
        [icon]="item.icon" [label]="item.label || item.displayName"></button>
    </li>
  </ul>
</ng-template>

