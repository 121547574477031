import * as _ from 'lodash-es';
import { TreeNode } from 'primeng/api';
import { FormlyField } from './models/swagger-gen';

export const toOptions = (items: any[], options?: { labelField?: string, valueField?: string, uniqBy?: string }) => {
  items = items || [];
  if (options?.uniqBy) {
    items = _.uniqBy(items, options.uniqBy);
  }
  if (options?.labelField) {
    items = _.orderBy(items, options.labelField);
  }
  return _.map(items, p => ({ label: options?.labelField ? p[options.labelField] : p, value: options?.valueField ? p[options.valueField] : p }));
}

export const enumToOptions = (enumObj: object, options?: { labelFunc?: (o: any) => string, valueFunc?: (o: any) => string, excludes: any[] }) => {
  const items = Object.keys(enumObj)
    .filter(key => isNaN(Number(key)) && (!options?.excludes?.includes(key) && !options?.excludes?.includes(enumObj[key as keyof typeof enumObj])))
    .map(key => ({
      label: options?.labelFunc ? options.labelFunc(enumObj[key as keyof typeof enumObj]) : key,
      value: options?.valueFunc ? options.valueFunc(enumObj[key as keyof typeof enumObj]) : enumObj[key as keyof typeof enumObj]
    }));
  return _.orderBy(items, ['label']);
}


export const applyFolderIcon = (nodes: any[], matchFunc: (node: TreeNode) => boolean = (node) => !node.icon) => {
  nodes?.forEach(node => {
    if (matchFunc(node)) {
      node.icon = "fa fa-folder-o";
    }
    if (node.children && node.children.length > 0) {
      node.expandedIcon = 'fa fa-folder-open-o';
      applyFolderIcon(node.children, matchFunc);
    }
  });
  return nodes;
}

// export server side field configs
export const serverFieldProps = (fieldName: string, fields?: FormlyField[] | null) => {
  const field = fields?.find(p => p.key == fieldName) || {};
  return { readonly: field.serverProps?.['readonly'], tips: field.serverProps?.['readonlyReason'] }
}


export const normalizeFileSize = (fileLength: number) => {
  let size = fileLength;
  let level = 0;
  while (size > 500) {
    size = size / 1024;
    level++;
  }
  let unit = 'B';
  switch (level) {
    case 0: unit = 'B'; break;
    case 1: unit = 'KB'; break;
    case 2: unit = 'MB'; break;
    case 3: unit = 'GB'; break;
    case 4: unit = 'TB'; break;
    default: unit = '';
  }
  if (unit === '') {
    return '';
  }
  return `${size.toFixed(2)} ${unit}`;
}


export const removeFileGuid = (fileName: string) => {
  return fileName.replace(/-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g, '');
}
