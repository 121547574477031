import { RemoteErrorHandler as DefaultRemoteErrorHandler, RemoteError } from '@alterdomus/rest/interceptor';
import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class AppRemoteErrorHandler implements DefaultRemoteErrorHandler {
  constructor(private confirmationService: ConfirmationService) {

  }
  handleError(error: RemoteError): void {
    this.confirmationService.confirm({
      header: error.error.message,
      message: error.error.detail,
      icon: 'fas fa-exclamation-circle text-red-700 mr-2',
      acceptLabel: 'Ok',
      rejectVisible: false
    })
  }

}
