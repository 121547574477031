import { HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import * as _ from 'lodash-es';
import { DateTime } from 'luxon';

export function withBatch(batch: string = 'default', total: number = 0): {
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  }
} {
  return {
    "params": {
      'batch': batch,
      ...(total ? { 'total': total + '' } : {})
    }
  }
}
export function spinOnElement(elemntId: string, batch: string = 'default'): {
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  }
} {
  return {
    "params": {
      'batch': batch,
      'elementId': elemntId
    }
  }
}

interface LoadingParams {
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  }
};


export const showSpinLoading: {
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  }
} = { "params": { 'loading': 'spin' } };

export const downloadFile: {
  headers: HttpHeaders;
  responseType: any;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  }
} = { headers: new HttpHeaders({ 'Content-Type': 'application/json' },), observe: 'response', responseType: 'blob' as 'json', ...showSpinLoading };


export const noLoading: {
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  }
} = { "params": { 'loading': 'false' } };

export const noErrorHandler: {
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  }
} = { "params": { 'handleError': 'false' } };

export const setPrams = (...params: {
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  }
}[]) => {
  let param = {};
  for (const p of params) {
    param = { ...param, ...p };
  }
  return param;
}

export const queryParams = (query: any, encode = true) => {
  if (!query) {
    return '';
  }
  // Only encode string
  const q = Object.keys(query).filter(k => query[k] !== undefined && query[k] !== '').map(k => `${k}=${encode ? query[k].toString().replaceAll('&', escape('&')) : query[k]}`).join("&");;
  if (q) {
    return `?${q}`;
  }
  return q;
}

export const fromDays = (days: number) => {
  let start = DateTime.now().startOf('day').minus({ days: days - 1 }).toUTC();
  let end = DateTime.now().startOf('day').plus({ days: 1 }).minus({ milliseconds: 1 }).toUTC();
  return { start, end }
}

export function withOutErrorHandle(param:any={}){
  return _.merge(param,{ "params": { 'handleError': 'false' } }) as LoadingParams;
}

export function withSpin(param: any = {}) {
  return _.merge(param, { params: { loading: 'spin' } }) as LoadingParams;
}

export function withLoading(param: any = {}) {
  return _.merge(param, { params: { 'loading': true } }) as LoadingParams;
}

export function withOutLoading(param: any = {}) {
  return _.merge(param, { params: { 'loading': false } }) as LoadingParams;
}

export const getFileNameFromHeader = (resp: HttpResponse<Blob>) => {

  const contentDispositionHeader =
    // the return headers of DotNet Core Web API
    resp.headers.get('content-disposition') ||
    // the return headers of DotNet Web API
    resp.headers.get('Content-Disposition');
  const fileName = getFileNameFromContentDisposition(contentDispositionHeader);
  return fileName;
}
export const getFileNameFromContentDisposition = (contentDisposition: string | null): string => {
  if (contentDisposition) {
    const matches = contentDisposition.match(/filename\*=UTF-8''([^;]+)/i);
    if (matches && matches.length > 1) {
      return decodeURIComponent(matches[1]);
    } else {
      const attachmentMatches = contentDisposition.match(/attachment;\s*filename=["']?([^"';]+)/i);
      if (attachmentMatches && attachmentMatches.length > 1) {
        return decodeURIComponent(attachmentMatches[1]);
      }
    }
  }
  return 'file';
}
