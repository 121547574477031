<div class="a-search-box" [ngClass]="styleClass">
  <span class="a-search-box-label" *ngIf="label">{{label}}</span>
  <div class="a-search-box-input-wrapper flex items-center">
    <p-autoComplete [suggestions]="dropdownItems" styleClass="a-search-box-autocomplete" [ngModel]="searchKey"
      [showClear]="showClear" (completeMethod)="search($event)" (onClear)="clearSearch()" [field]="field"
      [placeholder]="placeholder" [disabled]="disabled">
    </p-autoComplete>
    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 m-0 a-search-box-icon {{disabled?'disabled':''}}"
      viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd"
        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        clip-rule="evenodd" />
    </svg>
  </div>
</div>
