export enum LookupTypes {
  // Preserved treated as LookupCode
  // Permission = -5,
  Disclaimer = -4,
  DealFamily = -3,
  AccountRoleType = -2,
  ContactType = -1,

  PhoneType = 1,
  EmailType = 2,
  UserType = 3,
  DealType = 4,
  Role = 5,
  BusinessLine = 6,
  BusinessFunction = 7,
  Country = 8,
  Application = 9,
  CompanyType = 10,
  State_Providence_County = 11,
  EmgencyContactDescription = 12,
  Task = 13,
  TableAction = 14,
  MailMergeSubject = 15,
  MailMergeFromAddress = 16,
  MailMergeCCEmail = 17,
  MailMergeBCCEmail = 18,
  New = 19,
  CompanyTags = 20,
  ClientBranding = 21,
  RegistrationProductLine = 22,
  EmailOption = 23,
  TicklerApproverEmails = 24,
  InvestorType = 25,
  ContactSource = 26,
  InvestorTypeforFormPF = 27,
  ChecksMailedTo = 28,
  AccreditedInvestorClassification = 29,
  QualifiedPurchaserClassification = 30,
  DistributionElection = 31,
  Non_US_Investors = 32,
  Tax_ExemptInvestors = 33,
  Salutation = 34,
  DistributionMethod = 35,
  MosaicAccredited = 36,
  MosaicQualified = 37,
  TaxDocuments = 38,
  BPSeries = 39,
  Checking_Savings = 40,
  CommunicationSuppression_ProvideonlytoPrivateBanker_WealthAdvisor = 41,
  AccreditedInvestorClassification2 = 42,
  AccreditedInvestorClassification3 = 43,
  AccountTermsDescription = 44,
  UKTaxResidentStatus = 45,
  InvestorisNOTafinancialinstitution = 46,
  StatementsMailedTo = 47,
  Qualified_Non_Qualified = 48,
  AIPReportingType = 49,
  FontType = 50,
  MDRLLocation = 51,
  MDRLTerminationReason = 52,
  MDRLReviewStatus = 53,
  ClientContactType = 54,
  AuthorizedSigner = 55,
  MDRLReviewDesc = 56,
  BusinessGroup = 57,
  CortlandSystem = 58,
  DealAttributeFundType = 59,
  DealAttributeStatus = 60,
  DealAttributeStrategy = 61,
  DealAttributeStructure = 62,
  FileTransmissionMethod = 63,
  FileFormat = 64,
  Bank_BrokerFeedReceived = 65,
  Bank_BrokerFees = 66,
  BankAccountType = 67,
  BankTokenType = 133,
  OverallTeamStatus = 68,
  DealAttributeDeadlineCalendar = 69,
  InvestorRiskScore = 70,
  AD_Country = 71,
  Client_main_location = 72,
  Business_Unit = 73,
  Accounting_System = 74,
  Open_Ended_or_Closed_Ended = 75,
  Cross_Jurisdictional = 76,
  Country_of_Master_Parallel = 77,
  Portfolio_Geography = 78,
  Segment = 79,
  AD_Mapping = 80,
  Segment_2 = 81,
  Strat_Level_1 = 82,
  AD_Strat_Level_1 = 83,
  AD_Strat_Level_2 = 84,
  Strat_Level_3 = 85,
  Fiscal_Year_end = 86,
  Accounting_Method = 87,
  Status = 88,
  AD_Status = 89,
  Audit_Responsibilities = 90,
  Entity_Type = 91,
  Currency = 92,
  System_FS_Cortland_Due_Date_Monthly = 93,
  Custom_FS_Cortland_Due_Date_Monthly = 94,
  System_FS_Regulatory_Due_Date_Monthly = 95,
  System_FS_Cortland_Due_Date_Quarterly = 96,
  Custom_FS_Cortland_Due_Date_Quarterly = 97,
  Custom_FS_Regulatory_Due_Date_Quarterly = 98,
  Annual_FS_Cortland_Due_Date = 99,
  Annual_FS_Regulatory_Due_Date = 100,
  PCAPS_Cortland_Due_Date_Monthly = 101,
  PCAPS_Regulatory_Due_Date_Monthly = 102,
  PCAPS_Cortland_Due_Date_Quarterly = 103,
  PCAPS_Regulatory_Due_Date_Quarterly = 104,
  Holdings_Report_Cortland_Due_Date = 105,
  Holdings_Report_Regulatory_Due_Date = 106,
  REIT_Test_Cortland_Due_Date = 107,
  REIT_Test_Regulatory_Due_Date = 108,
  Investor_Letter_Cortland_Due_Date = 109,
  Investor_Letter_Regulatory_Due_Date = 110,
  _1099_MISC_Cortland_Due_Date = 111,
  _1099_MISC_Regulatory_Due_Date = 112,
  Tax_Return_Cortland_Due_Date = 113,
  Tax_Return_Regulatory_Due_Date = 114,
  K_1_Cortland_Due_Date = 115,
  K_1_Regulatory_Due_Date = 116,
  Form_PF_Annual_Ammendment_Cortland_Due_Date = 117,
  Form_PF_Annual_Ammendment_Regulatory_Due_Date = 118,
  Form_ADV_Part_2a_2b_Cortland_Due_Date = 119,
  Form_ADV_Part_2a_2b_Regulatory_Due_Date = 120,
  FATCA_CRS_Filing_Cortland_Due_Date = 121,
  FATCA_CRS_Filing_Regulatory_Due_Date = 122,
  Ad_Hoc_Requests_explain_Cortland_Due_Date = 123,
  Ad_Hoc_Requests_explain_Regulatory_Due_Date = 124,
  AgencyClientContact = 125,
  OnboardingInvestoryType = 126,
  DataRoomStatus = 127,
  Strategy = 129,
  Country_ISO2 = 130,
  AccountGroup = 132,
  AccountStatus = 134,
  LegalStatus = 136,
  ClientStatus = 138
}
