import { LookupcodeService } from '@alterdomus/crm-shared/lookup-code';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { CONFIG_ENDPOINT } from './config-endpoint';
import { ApplicationConfigurationDto } from '@alterdomus/crm-shared/core';
import { AuthService } from '@alterdomus/auth/auth';
import { SettingService } from '@alterdomus/crm-shared/settings';
import { SessionService } from '@alterdomus/auth/session';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  constructor(
    @Inject(CONFIG_ENDPOINT) private configUrl: string,
    private http: HttpClient,
    private authService: AuthService,
    private sessionService: SessionService,
    private lookupCodeService: LookupcodeService,
    private settingService: SettingService) {
  }

  loadConfiguration() {
    return this.http.get<ApplicationConfigurationDto>(this.configUrl).pipe(
      tap((result) => {
        this.authService.applyAuthConfig(result);
        if (result.currentUser?.isAuthenticated) {
          this.sessionService.applySession(result);
        }
        this.lookupCodeService.setLookupCodes(result.extraProperties?.['lookupCodes']);
        this.settingService.extend(result.setting?.values || {});
      })
    )
  }

}
