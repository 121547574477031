import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgModule, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'a-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit {

  @Input()
  items: MenuItem[] = [];
  @Input()
  styleClass = 'a-nav-menu';

  mobileView = false;

  constructor(private bo: BreakpointObserver,
    private cd: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.bo.observe(Breakpoints.Handset)
      .subscribe(state => {
        this.mobileView = state.matches;
        this.cd.detectChanges();
      })
  }
}

@NgModule({
  imports: [CommonModule, MenubarModule],
  declarations: [NavComponent],
  exports: [NavComponent],
})
export class NavModule { }
