import { LookupcodeService } from '../lookup-code.service';
// !WARNING: This class is only used for storybook demos.
export class SBLookupcodeService extends LookupcodeService {
  constructor() {
    super();
    super.setLookupCodes({
      1: [
        {
          "id": 1,
          "shortName": "Business",
          "longName": "Business Phone"
        },
        {
          "id": 2,
          "shortName": "Cell",
          "longName": "Cell"
        },
        {
          "id": 3,
          "shortName": "Home",
          "longName": "Home"
        },
        {
          "id": 4,
          "shortName": "Other",
          "longName": "Other"
        },
        {
          "id": 364,
          "shortName": "Business Fax",
          "longName": "Business Fax"
        },
        {
          "id": 365,
          "shortName": "Personal Fax",
          "longName": "Personal Fax"
        }
      ],
      2: [
        {
          "id": 5,
          "shortName": "Business",
          "longName": "Business"
        },
        {
          "id": 6,
          "shortName": "Personal",
          "longName": "Personal"
        },
        {
          "id": 7,
          "shortName": "Other",
          "longName": "Other"
        }
      ],
      3: [
        {
          "id": 8,
          "shortName": "Internal",
          "longName": "Internal"
        },
        {
          "id": 9,
          "shortName": "Client",
          "longName": "Client"
        },
        {
          "id": 10,
          "shortName": "Other",
          "longName": "Other"
        },
        {
          "id": 11,
          "shortName": "Vendor",
          "longName": "Vendor"
        },
        {
          "id": 12,
          "shortName": "Contractor",
          "longName": "Contractor"
        },
        {
          "id": 1506,
          "shortName": "BankContact",
          "longName": "BankContact"
        },
        {
          "id": 1509,
          "shortName": "External",
          "longName": "External"
        },
        {
          "id": 3740,
          "shortName": "Investor",
          "longName": "Investor"
        },
        {
          "id": 3772,
          "shortName": "Auditor",
          "longName": "Auditor"
        }
      ],
      4: [
        {
          "id": 14,
          "shortName": "Asset Services",
          "longName": "Asset Services"
        },
        {
          "id": 915,
          "shortName": "ABS",
          "longName": "ABS"
        },
        {
          "id": 15,
          "shortName": "CDO",
          "longName": "Collateralized Debt Obligation"
        },
        {
          "id": 16,
          "shortName": "CLO",
          "longName": "Collateralized Loan Obligation"
        },
        {
          "id": 17,
          "shortName": "CMBS",
          "longName": "Commercial Mortgage Backed Security"
        },
        {
          "id": 909,
          "shortName": "Credit Fund",
          "longName": "Credit Fund"
        },
        {
          "id": 922,
          "shortName": "Commodity Trading Fund",
          "longName": "Commodity Trading Fund"
        },
        {
          "id": 18,
          "shortName": "RMBS",
          "longName": "Residential Mortgage Backed Security"
        },
        {
          "id": 19,
          "shortName": "C&I Loan Services - Lg Corp",
          "longName": "C&I Loan Services - Large Corporation"
        },
        {
          "id": 923,
          "shortName": "Managed Futures Fund",
          "longName": "Managed Futures Fund"
        },
        {
          "id": 925,
          "shortName": "C&I Loan Services - Sm/Med Corp",
          "longName": "C&I Loan Services - Sm/Med Corp"
        },
        {
          "id": 20,
          "shortName": "Business Loan Services",
          "longName": "Business Loan Services"
        },
        {
          "id": 916,
          "shortName": "None",
          "longName": "None"
        },
        {
          "id": 908,
          "shortName": "Hedge Fund",
          "longName": "Hedge Fund"
        },
        {
          "id": 911,
          "shortName": "Fund of Funds",
          "longName": "Fund of Funds"
        },
        {
          "id": 913,
          "shortName": "Direct Lending Program",
          "longName": "Direct Lending Program"
        },
        {
          "id": 917,
          "shortName": "Demo",
          "longName": "Demo"
        },
        {
          "id": 22,
          "shortName": "TRS",
          "longName": "Total Return Swap"
        },
        {
          "id": 441,
          "shortName": "Securitization Services",
          "longName": "Securitization Services"
        },
        {
          "id": 910,
          "shortName": "Real Estate Fund",
          "longName": "Real Estate Fund"
        },
        {
          "id": 914,
          "shortName": "Private Equity",
          "longName": "Private Equity"
        },
        {
          "id": 921,
          "shortName": "Trups",
          "longName": "Trups"
        },
        {
          "id": 642,
          "shortName": "Trade Settlement",
          "longName": "Trade Settlement"
        },
        {
          "id": 924,
          "shortName": "Speciality Acquisitions Fund",
          "longName": "Speciality Acquisitions Fund"
        },
        {
          "id": 3198,
          "shortName": "Investor Services",
          "longName": "Investor Services"
        },
        {
          "id": 3199,
          "shortName": "Real Estate - Equity",
          "longName": "Real Estate - Equity"
        },
        {
          "id": 3200,
          "shortName": "Real Estate - Debt",
          "longName": "Real Estate - Debt"
        },
        {
          "id": 3966,
          "shortName": "Private Credit Fund",
          "longName": "Private Credit Fund"
        }
      ],
      6: [
        {
          "id": 33,
          "shortName": "Asset Services",
          "longName": "Asset Services"
        },
        {
          "id": 907,
          "shortName": "Agency",
          "longName": "Agency"
        },
        {
          "id": 34,
          "shortName": "C&I Loan Services - Lg Corp",
          "longName": "C&I Loan Services - Large Corporation"
        },
        {
          "id": 35,
          "shortName": "Business Loan Services",
          "longName": "Business Loan Services"
        },
        {
          "id": 36,
          "shortName": "Fund Administration",
          "longName": "Fund Administration"
        },
        {
          "id": 37,
          "shortName": "Securitization Services",
          "longName": "Securitization Services"
        },
        {
          "id": 918,
          "shortName": "None",
          "longName": "None"
        },
        {
          "id": 919,
          "shortName": "Remic Tax Services",
          "longName": "Remic Tax Services"
        },
        {
          "id": 1626,
          "shortName": "CorPro Only",
          "longName": "CorPro Only"
        },
        {
          "id": 3142,
          "shortName": "Alterdomus",
          "longName": "Alterdomus"
        },
        {
          "id": 3183,
          "shortName": "Heitman",
          "longName": "Heitman"
        },
        {
          "id": 3184,
          "shortName": "Carta",
          "longName": "Carta"
        },
        {
          "id": 3785,
          "shortName": "Corporate Services",
          "longName": "Corporate Services"
        },
        {
          "id": 3389,
          "shortName": "Financial Tracking Administration",
          "longName": "Financial Tracking Administration"
        },
        {
          "id": 4384,
          "shortName": "Fund Services",
          "longName": "Fund Services"
        },
        {
          "id": 4385,
          "shortName": "Transfer Pricing",
          "longName": "Transfer Pricing"
        },
        {
          "id": 4386,
          "shortName": "Depositary",
          "longName": "Depositary"
        },
        {
          "id": 4387,
          "shortName": "BOS",
          "longName": "BOS"
        },
        {
          "id": 4388,
          "shortName": "Secondment",
          "longName": "Secondment"
        },
        {
          "id": 4389,
          "shortName": "ManCo",
          "longName": "ManCo"
        },
        {
          "id": 4390,
          "shortName": "Liquidation",
          "longName": "Liquidation"
        },
        {
          "id": 4391,
          "shortName": "DCM Services",
          "longName": "DCM Services"
        },
        {
          "id": 4392,
          "shortName": "Data and Analytics",
          "longName": "Data and Analytics"
        }
      ],
      7: [
        {
          "id": 38,
          "shortName": "Agency",
          "longName": "Agency"
        },
        {
          "id": 39,
          "shortName": "Calculation Agent",
          "longName": "Calculation Agent"
        },
        {
          "id": 40,
          "shortName": "Cash Mgmt",
          "longName": "Cash Mgmt"
        },
        {
          "id": 41,
          "shortName": "Collateral Agent",
          "longName": "Collateral Agent"
        },
        {
          "id": 912,
          "shortName": "CorPro Administrator",
          "longName": "CorPro Administrator"
        },
        {
          "id": 42,
          "shortName": "Custody",
          "longName": "Custody"
        },
        {
          "id": 43,
          "shortName": "Database Maint.",
          "longName": "Database Maint."
        },
        {
          "id": 3784,
          "shortName": "Director Services",
          "longName": "Director Services"
        },
        {
          "id": 44,
          "shortName": "Escrow",
          "longName": "Escrow"
        },
        {
          "id": 45,
          "shortName": "Fund Admin",
          "longName": "Fund Admin"
        },
        {
          "id": 46,
          "shortName": "Loan Admin",
          "longName": "Loan Admin"
        },
        {
          "id": 47,
          "shortName": "MNPI",
          "longName": "MNPI"
        },
        {
          "id": 48,
          "shortName": "One-Time Consulting",
          "longName": "One-Time Consulting"
        },
        {
          "id": 589,
          "shortName": "Mgr's Loan Data Agent",
          "longName": "Mgr's Loan Data Agent"
        },
        {
          "id": 587,
          "shortName": "Mgr's Compliance Agent",
          "longName": "Mgr's Compliance Agent"
        },
        {
          "id": 588,
          "shortName": "Mgr's Waterfall Agent",
          "longName": "Mgr's Waterfall Agent"
        },
        {
          "id": 49,
          "shortName": "Paying Agent",
          "longName": "Paying Agent"
        },
        {
          "id": 50,
          "shortName": "Reporting Agent",
          "longName": "Reporting Agent"
        },
        {
          "id": 51,
          "shortName": "Risk Management",
          "longName": "Risk Management"
        },
        {
          "id": 52,
          "shortName": "BLS Loan Services",
          "longName": "BLS Loan Services"
        },
        {
          "id": 53,
          "shortName": "Special Asset Mgmt",
          "longName": "Special Asset Mgmt"
        },
        {
          "id": 54,
          "shortName": "Tax Agent",
          "longName": "Tax Agent"
        },
        {
          "id": 55,
          "shortName": "Trade Closing",
          "longName": "Trade Closing"
        },
        {
          "id": 429,
          "shortName": "Other",
          "longName": "Other"
        },
        {
          "id": 430,
          "shortName": "Note Registrar",
          "longName": "Note Registrar"
        },
        {
          "id": 1457,
          "shortName": "Securitization Services",
          "longName": "Securitization Services"
        },
        {
          "id": 431,
          "shortName": "Document Custody",
          "longName": "Document Custody"
        },
        {
          "id": 1458,
          "shortName": "Loan TRS",
          "longName": "Loan TRS"
        },
        {
          "id": 1459,
          "shortName": "Business Loan Services",
          "longName": "Business Loan Services"
        },
        {
          "id": 1460,
          "shortName": "Doc Custody",
          "longName": "Doc Custody"
        },
        {
          "id": 1461,
          "shortName": "Investor Services",
          "longName": "Investor Services"
        },
        {
          "id": 1462,
          "shortName": "CorPro Administrator Marketing",
          "longName": "CorPro Administrator Marketing"
        },
        {
          "id": 1463,
          "shortName": "Property Accounting",
          "longName": "Property Accounting"
        },
        {
          "id": 1464,
          "shortName": "ABL",
          "longName": "ABL"
        },
        {
          "id": 1465,
          "shortName": "UK Agency",
          "longName": "UK Agency"
        },
        {
          "id": 1466,
          "shortName": "RE Loan Services",
          "longName": "RE Loan Services"
        },
        {
          "id": 1467,
          "shortName": "TRS",
          "longName": "TRS"
        },
        {
          "id": 1468,
          "shortName": "Shareholder Services",
          "longName": "Shareholder Services"
        },
        {
          "id": 1469,
          "shortName": "UK",
          "longName": "UK"
        },
        {
          "id": 1470,
          "shortName": "Strategic Analytics",
          "longName": "Strategic Analytics"
        },
        {
          "id": 1471,
          "shortName": "Receivables Servicing",
          "longName": "Receivables Servicing"
        },
        {
          "id": 3783,
          "shortName": "Registered Office",
          "longName": "Registered Office"
        },
        {
          "id": 1472,
          "shortName": "Credit Services",
          "longName": "Credit Services"
        },
        {
          "id": 1473,
          "shortName": "REMIC Tax Processing",
          "longName": "REMIC Tax Processing"
        },
        {
          "id": 1474,
          "shortName": "Documentation",
          "longName": "Documentation"
        },
        {
          "id": 1475,
          "shortName": "US Asset Services",
          "longName": "US Asset Services"
        },
        {
          "id": 1476,
          "shortName": "Investor Register",
          "longName": "Investor Register"
        },
        {
          "id": 1477,
          "shortName": "Loan Servicing",
          "longName": "Loan Servicing"
        },
        {
          "id": 3465,
          "shortName": "Loan Servicing (Loan)",
          "longName": "Loan Servicing (Loan)"
        },
        {
          "id": 1478,
          "shortName": "UK Cash Management",
          "longName": "UK Cash Management"
        },
        {
          "id": 1511,
          "shortName": "Agency - Sub Agent",
          "longName": "Agency - Sub Agent"
        },
        {
          "id": 1568,
          "shortName": "Agency (DELETE)",
          "longName": "Agency (DELETE)"
        },
        {
          "id": 1569,
          "shortName": "Asset Services",
          "longName": "Asset Services"
        },
        {
          "id": 1570,
          "shortName": "KYC Only",
          "longName": "KYC Only"
        },
        {
          "id": 1571,
          "shortName": "Loan Trade Closing",
          "longName": "Loan Trade Closing"
        },
        {
          "id": 1572,
          "shortName": "Loan Reconciliation",
          "longName": "Loan Reconciliation"
        },
        {
          "id": 1573,
          "shortName": "Loan Trade Settlement",
          "longName": "Loan Trade Settlement"
        },
        {
          "id": 1574,
          "shortName": "UK Agency - Security Trustee",
          "longName": "UK Agency - Security Trustee"
        },
        {
          "id": 3163,
          "shortName": "Cayman AML - AMLCO",
          "longName": "Cayman AML - AMLCO"
        },
        {
          "id": 3164,
          "shortName": "Cayman AML - MLRO",
          "longName": "Cayman AML - MLRO"
        },
        {
          "id": 3165,
          "shortName": "Cayman AML - DMLRO",
          "longName": "Cayman AML - DMLRO"
        },
        {
          "id": 4663,
          "shortName": "PropMgmt.Default",
          "longName": "PropMgmt.Default"
        },
        {
          "id": 4664,
          "shortName": "PropMgmt.Coala",
          "longName": "PropMgmt.Coala"
        },
        {
          "id": 4665,
          "shortName": "PropMgmt.A3",
          "longName": "PropMgmt.A3"
        }
      ],
      8: [
        {
          "id": 275,
          "shortName": "US",
          "longName": "United States"
        },
        {
          "id": 56,
          "shortName": "AF",
          "longName": "Afghanistan"
        },
        {
          "id": 57,
          "shortName": "AL",
          "longName": "Albania"
        },
        {
          "id": 58,
          "shortName": "DZ",
          "longName": "Algeria"
        },
        {
          "id": 811,
          "shortName": "AS",
          "longName": "American Samoa"
        },
        {
          "id": 59,
          "shortName": "AD",
          "longName": "Andorra"
        },
        {
          "id": 60,
          "shortName": "AO",
          "longName": "Angola"
        },
        {
          "id": 61,
          "shortName": "AI",
          "longName": "Anguilla"
        },
        {
          "id": 62,
          "shortName": "AQ",
          "longName": "Antarctica"
        },
        {
          "id": 63,
          "shortName": "AG",
          "longName": "Antigua and Barbuda"
        },
        {
          "id": 64,
          "shortName": "AR",
          "longName": "Argentina Republic"
        },
        {
          "id": 65,
          "shortName": "AM",
          "longName": "Armenia"
        },
        {
          "id": 66,
          "shortName": "AW",
          "longName": "Aruba"
        },
        {
          "id": 67,
          "shortName": "AU",
          "longName": "Australia"
        },
        {
          "id": 68,
          "shortName": "AT",
          "longName": "Austria"
        },
        {
          "id": 69,
          "shortName": "AZ",
          "longName": "Azerbaijan"
        },
        {
          "id": 70,
          "shortName": "BS",
          "longName": "Bahamas"
        },
        {
          "id": 71,
          "shortName": "BH",
          "longName": "Bahrain"
        },
        {
          "id": 72,
          "shortName": "BD",
          "longName": "Bangladesh"
        },
        {
          "id": 73,
          "shortName": "BB",
          "longName": "Barbados"
        },
        {
          "id": 74,
          "shortName": "BY",
          "longName": "Belarus"
        },
        {
          "id": 75,
          "shortName": "BE",
          "longName": "Belgium"
        },
        {
          "id": 76,
          "shortName": "BZ",
          "longName": "Belize"
        },
        {
          "id": 77,
          "shortName": "BJ",
          "longName": "Benin"
        },
        {
          "id": 78,
          "shortName": "BM",
          "longName": "Bermuda"
        },
        {
          "id": 79,
          "shortName": "BT",
          "longName": "Bhutan"
        },
        {
          "id": 82,
          "shortName": "BO",
          "longName": "Bolivia"
        },
        {
          "id": 83,
          "shortName": "BA",
          "longName": "Bosnia and Herzegovina"
        },
        {
          "id": 80,
          "shortName": "BW",
          "longName": "Botswana"
        },
        {
          "id": 81,
          "shortName": "BV",
          "longName": "Bouvet Island"
        },
        {
          "id": 84,
          "shortName": "BR",
          "longName": "Brazil"
        },
        {
          "id": 85,
          "shortName": "IO",
          "longName": "British Indian Ocean Territory"
        },
        {
          "id": 86,
          "shortName": "VG",
          "longName": "British Virgin Islands"
        },
        {
          "id": 87,
          "shortName": "BN",
          "longName": "Brunei Darussalam"
        },
        {
          "id": 88,
          "shortName": "BG",
          "longName": "Bulgaria"
        },
        {
          "id": 89,
          "shortName": "BF",
          "longName": "Burkina Faso"
        },
        {
          "id": 90,
          "shortName": "BI",
          "longName": "Burundi"
        },
        {
          "id": 92,
          "shortName": "KH",
          "longName": "Cambodia"
        },
        {
          "id": 94,
          "shortName": "CM",
          "longName": "Cameroon"
        },
        {
          "id": 95,
          "shortName": "CA",
          "longName": "Canada"
        },
        {
          "id": 96,
          "shortName": "CV",
          "longName": "Cape Verde"
        },
        {
          "id": 97,
          "shortName": "KY",
          "longName": "Cayman Islands"
        },
        {
          "id": 98,
          "shortName": "CF",
          "longName": "Central African Republic"
        },
        {
          "id": 815,
          "shortName": "TD",
          "longName": "Chad"
        },
        {
          "id": 99,
          "shortName": "CL",
          "longName": "Chile"
        },
        {
          "id": 100,
          "shortName": "CN",
          "longName": "China"
        },
        {
          "id": 101,
          "shortName": "CX",
          "longName": "Christmas Islands"
        },
        {
          "id": 102,
          "shortName": "CC",
          "longName": "Cocos Islands"
        },
        {
          "id": 103,
          "shortName": "CO",
          "longName": "Colombia"
        },
        {
          "id": 104,
          "shortName": "KM",
          "longName": "Comoros"
        },
        {
          "id": 105,
          "shortName": "CG",
          "longName": "Congo"
        },
        {
          "id": 817,
          "shortName": "CD",
          "longName": "Congo (Kinshasa)"
        },
        {
          "id": 106,
          "shortName": "CK",
          "longName": "Cook Islands"
        },
        {
          "id": 107,
          "shortName": "CR",
          "longName": "Costa Rica"
        },
        {
          "id": 108,
          "shortName": "CI",
          "longName": "Cote D’Ivoire"
        },
        {
          "id": 93,
          "shortName": "HR",
          "longName": "Croatia"
        },
        {
          "id": 110,
          "shortName": "CU",
          "longName": "Cuba"
        },
        {
          "id": 819,
          "shortName": "CW",
          "longName": "Curacao"
        },
        {
          "id": 109,
          "shortName": "CY",
          "longName": "Cyprus"
        },
        {
          "id": 111,
          "shortName": "CZ",
          "longName": "Czech Republic"
        },
        {
          "id": 112,
          "shortName": "DK",
          "longName": "Denmark"
        },
        {
          "id": 113,
          "shortName": "DJ",
          "longName": "Djibouti"
        },
        {
          "id": 114,
          "shortName": "DM",
          "longName": "Dominica"
        },
        {
          "id": 115,
          "shortName": "DO",
          "longName": "Dominican Republic"
        },
        {
          "id": 116,
          "shortName": "TL",
          "longName": "East Timor"
        },
        {
          "id": 117,
          "shortName": "EC",
          "longName": "Ecuador"
        },
        {
          "id": 118,
          "shortName": "EG",
          "longName": "Egypt"
        },
        {
          "id": 119,
          "shortName": "SV",
          "longName": "El Salvador"
        },
        {
          "id": 121,
          "shortName": "GQ",
          "longName": "Equatorial Guinea"
        },
        {
          "id": 821,
          "shortName": "ER",
          "longName": "Eritrea"
        },
        {
          "id": 120,
          "shortName": "EE",
          "longName": "Estonia"
        },
        {
          "id": 122,
          "shortName": "ET",
          "longName": "Ethiopia"
        },
        {
          "id": 123,
          "shortName": "FK",
          "longName": "Falkland Islands"
        },
        {
          "id": 124,
          "shortName": "FO",
          "longName": "Faroe Islands"
        },
        {
          "id": 125,
          "shortName": "FJ",
          "longName": "Fiji"
        },
        {
          "id": 126,
          "shortName": "FI",
          "longName": "Finland"
        },
        {
          "id": 127,
          "shortName": "FR",
          "longName": "France"
        },
        {
          "id": 128,
          "shortName": "GF",
          "longName": "French Guiana"
        },
        {
          "id": 129,
          "shortName": "PF",
          "longName": "French Polynesia"
        },
        {
          "id": 130,
          "shortName": "TF",
          "longName": "French Southern Territories"
        },
        {
          "id": 131,
          "shortName": "GA",
          "longName": "Gabon"
        },
        {
          "id": 132,
          "shortName": "GM",
          "longName": "Gambia"
        },
        {
          "id": 133,
          "shortName": "GE",
          "longName": "Georgia"
        },
        {
          "id": 134,
          "shortName": "DE",
          "longName": "Germany"
        },
        {
          "id": 135,
          "shortName": "GH",
          "longName": "Ghana"
        },
        {
          "id": 136,
          "shortName": "GI",
          "longName": "Gibraltar"
        },
        {
          "id": 137,
          "shortName": "GR",
          "longName": "Greece"
        },
        {
          "id": 138,
          "shortName": "GL",
          "longName": "Greenland"
        },
        {
          "id": 139,
          "shortName": "GD",
          "longName": "Grenada"
        },
        {
          "id": 140,
          "shortName": "GP",
          "longName": "Guadeloupe"
        },
        {
          "id": 141,
          "shortName": "GU",
          "longName": "Guam"
        },
        {
          "id": 142,
          "shortName": "GT",
          "longName": "Guatemala"
        },
        {
          "id": 823,
          "shortName": "GG",
          "longName": "Guernsey"
        },
        {
          "id": 143,
          "shortName": "GN",
          "longName": "Guinea"
        },
        {
          "id": 144,
          "shortName": "GW",
          "longName": "Guinea-Bissau"
        },
        {
          "id": 145,
          "shortName": "GY",
          "longName": "Guyana"
        },
        {
          "id": 146,
          "shortName": "HT",
          "longName": "Haiti"
        },
        {
          "id": 147,
          "shortName": "HM",
          "longName": "Heard and McDonald Islands"
        },
        {
          "id": 824,
          "shortName": "VA",
          "longName": "Holy See"
        },
        {
          "id": 148,
          "shortName": "HN",
          "longName": "Honduras"
        },
        {
          "id": 149,
          "shortName": "HK",
          "longName": "Hong Kong"
        },
        {
          "id": 150,
          "shortName": "HU",
          "longName": "Hungary"
        },
        {
          "id": 151,
          "shortName": "IS",
          "longName": "Iceland"
        },
        {
          "id": 152,
          "shortName": "IN",
          "longName": "India"
        },
        {
          "id": 154,
          "shortName": "ID",
          "longName": "Indonesia"
        },
        {
          "id": 155,
          "shortName": "IR",
          "longName": "Iran"
        },
        {
          "id": 153,
          "shortName": "IQ",
          "longName": "Iraq"
        },
        {
          "id": 156,
          "shortName": "IE",
          "longName": "Ireland"
        },
        {
          "id": 157,
          "shortName": "IL",
          "longName": "Israel"
        },
        {
          "id": 158,
          "shortName": "IT",
          "longName": "Italy"
        },
        {
          "id": 159,
          "shortName": "JM",
          "longName": "Jamaica"
        },
        {
          "id": 160,
          "shortName": "JP",
          "longName": "Japan"
        },
        {
          "id": 828,
          "shortName": "JE",
          "longName": "Jersey"
        },
        {
          "id": 161,
          "shortName": "JO",
          "longName": "Jordan"
        },
        {
          "id": 162,
          "shortName": "KZ",
          "longName": "Kazakhstan"
        },
        {
          "id": 163,
          "shortName": "KE",
          "longName": "Kenya"
        },
        {
          "id": 164,
          "shortName": "KI",
          "longName": "Kiribati"
        },
        {
          "id": 165,
          "shortName": "KP",
          "longName": "Korea (North)"
        },
        {
          "id": 166,
          "shortName": "KR",
          "longName": "Korea (South)"
        },
        {
          "id": 167,
          "shortName": "KW",
          "longName": "Kuwait"
        },
        {
          "id": 168,
          "shortName": "KG",
          "longName": "Kyrgyzstan"
        },
        {
          "id": 169,
          "shortName": "LA",
          "longName": "Lao"
        },
        {
          "id": 170,
          "shortName": "LV",
          "longName": "Latvia"
        },
        {
          "id": 171,
          "shortName": "LB",
          "longName": "Lebanon"
        },
        {
          "id": 172,
          "shortName": "LS",
          "longName": "Lesotho"
        },
        {
          "id": 173,
          "shortName": "LR",
          "longName": "Liberia"
        },
        {
          "id": 174,
          "shortName": "LY",
          "longName": "Libyan Arab Jamahiriya"
        },
        {
          "id": 175,
          "shortName": "LI",
          "longName": "Liechtenstein"
        },
        {
          "id": 176,
          "shortName": "LT",
          "longName": "Lithuania"
        },
        {
          "id": 177,
          "shortName": "LU",
          "longName": "Luxembourg"
        },
        {
          "id": 178,
          "shortName": "MO",
          "longName": "Macau"
        },
        {
          "id": 179,
          "shortName": "MK",
          "longName": "Macedonia"
        },
        {
          "id": 180,
          "shortName": "MG",
          "longName": "Madagascar"
        },
        {
          "id": 181,
          "shortName": "MW",
          "longName": "Malawi"
        },
        {
          "id": 182,
          "shortName": "MY",
          "longName": "Malaysia"
        },
        {
          "id": 183,
          "shortName": "MV",
          "longName": "Maldives"
        },
        {
          "id": 184,
          "shortName": "ML",
          "longName": "Mali"
        },
        {
          "id": 185,
          "shortName": "MT",
          "longName": "Malta"
        },
        {
          "id": 832,
          "shortName": "IM",
          "longName": "Man, Isle of"
        },
        {
          "id": 186,
          "shortName": "MH",
          "longName": "Marshall Islands"
        },
        {
          "id": 187,
          "shortName": "MQ",
          "longName": "Martinique"
        },
        {
          "id": 188,
          "shortName": "MR",
          "longName": "Mauritania"
        },
        {
          "id": 189,
          "shortName": "MU",
          "longName": "Mauritius"
        },
        {
          "id": 190,
          "shortName": "YT",
          "longName": "Mayotte"
        },
        {
          "id": 191,
          "shortName": "MX",
          "longName": "Mexico"
        },
        {
          "id": 198,
          "shortName": "FM",
          "longName": "Micronesia"
        },
        {
          "id": 834,
          "shortName": "MD",
          "longName": "Moldova"
        },
        {
          "id": 199,
          "shortName": "MC",
          "longName": "Monaco"
        },
        {
          "id": 192,
          "shortName": "MN",
          "longName": "Mongolia"
        },
        {
          "id": 193,
          "shortName": "ME",
          "longName": "Montenegro"
        },
        {
          "id": 194,
          "shortName": "MS",
          "longName": "Montserrat"
        },
        {
          "id": 195,
          "shortName": "MA",
          "longName": "Morocco"
        },
        {
          "id": 196,
          "shortName": "MZ",
          "longName": "Mozambique"
        },
        {
          "id": 197,
          "shortName": "MM",
          "longName": "Myanmar"
        },
        {
          "id": 200,
          "shortName": "NA",
          "longName": "Namibia"
        },
        {
          "id": 201,
          "shortName": "NR",
          "longName": "Nauru"
        },
        {
          "id": 202,
          "shortName": "NP",
          "longName": "Nepal"
        },
        {
          "id": 203,
          "shortName": "SX",
          "longName": "Netherlands Antilles"
        },
        {
          "id": 205,
          "shortName": "NC",
          "longName": "New Caledonia"
        },
        {
          "id": 206,
          "shortName": "NZ",
          "longName": "New Zealand"
        },
        {
          "id": 207,
          "shortName": "NI",
          "longName": "Nicaragua"
        },
        {
          "id": 208,
          "shortName": "NE",
          "longName": "Niger"
        },
        {
          "id": 209,
          "shortName": "NG",
          "longName": "Nigeria"
        },
        {
          "id": 210,
          "shortName": "NU",
          "longName": "Niue"
        },
        {
          "id": 211,
          "shortName": "NF",
          "longName": "Norfolk Island"
        },
        {
          "id": 212,
          "shortName": "MP",
          "longName": "Northern Mariana Islands"
        },
        {
          "id": 213,
          "shortName": "NO",
          "longName": "Norway"
        },
        {
          "id": 214,
          "shortName": "OM",
          "longName": "Oman"
        },
        {
          "id": 836,
          "shortName": "OC",
          "longName": "Other Country"
        },
        {
          "id": 216,
          "shortName": "PK",
          "longName": "Pakistan"
        },
        {
          "id": 217,
          "shortName": "PW",
          "longName": "Palau"
        },
        {
          "id": 225,
          "shortName": "PS",
          "longName": "Palestine"
        },
        {
          "id": 215,
          "shortName": "PA",
          "longName": "Panama"
        },
        {
          "id": 218,
          "shortName": "PG",
          "longName": "Papua New Guinea"
        },
        {
          "id": 219,
          "shortName": "PY",
          "longName": "Paraguay"
        },
        {
          "id": 220,
          "shortName": "PE",
          "longName": "Peru"
        },
        {
          "id": 221,
          "shortName": "PH",
          "longName": "Philippines"
        },
        {
          "id": 222,
          "shortName": "PN",
          "longName": "Pitcairn"
        },
        {
          "id": 223,
          "shortName": "PL",
          "longName": "Poland"
        },
        {
          "id": 224,
          "shortName": "PT",
          "longName": "Portugal"
        },
        {
          "id": 226,
          "shortName": "PR",
          "longName": "Puerto Rico"
        },
        {
          "id": 227,
          "shortName": "QA",
          "longName": "Qatar"
        },
        {
          "id": 228,
          "shortName": "RO",
          "longName": "Romania"
        },
        {
          "id": 229,
          "shortName": "RU",
          "longName": "Russian Federation"
        },
        {
          "id": 230,
          "shortName": "RW",
          "longName": "Rwanda"
        },
        {
          "id": 839,
          "shortName": "BL",
          "longName": "Saint Barthelemy"
        },
        {
          "id": 840,
          "shortName": "MF",
          "longName": "Saint Martin"
        },
        {
          "id": 231,
          "shortName": "WS",
          "longName": "Samoa"
        },
        {
          "id": 232,
          "shortName": "SM",
          "longName": "San Marino"
        },
        {
          "id": 233,
          "shortName": "ST",
          "longName": "Sao Tome and Principe"
        },
        {
          "id": 234,
          "shortName": "SA",
          "longName": "Saudi Arabia"
        },
        {
          "id": 235,
          "shortName": "SN",
          "longName": "Senegal"
        },
        {
          "id": 236,
          "shortName": "RS",
          "longName": "Serbia"
        },
        {
          "id": 237,
          "shortName": "SC",
          "longName": "Seychelles"
        },
        {
          "id": 238,
          "shortName": "SL",
          "longName": "Sierra Leone"
        },
        {
          "id": 239,
          "shortName": "SG",
          "longName": "Singapore"
        },
        {
          "id": 240,
          "shortName": "SK",
          "longName": "Slovakia"
        },
        {
          "id": 241,
          "shortName": "SI",
          "longName": "Slovenia"
        },
        {
          "id": 242,
          "shortName": "SB",
          "longName": "Solomon Islands"
        },
        {
          "id": 243,
          "shortName": "SO",
          "longName": "Somalia"
        },
        {
          "id": 244,
          "shortName": "ZA",
          "longName": "South Africa"
        },
        {
          "id": 842,
          "shortName": "GS",
          "longName": "South Georgia and the South Sandwich Islands"
        },
        {
          "id": 245,
          "shortName": "ES",
          "longName": "Spain"
        },
        {
          "id": 246,
          "shortName": "LK",
          "longName": "Sri Lanka"
        },
        {
          "id": 247,
          "shortName": "SH",
          "longName": "St. Helena"
        },
        {
          "id": 248,
          "shortName": "KN",
          "longName": "St. Kitts and Nevis"
        },
        {
          "id": 249,
          "shortName": "LC",
          "longName": "St. Lucia"
        },
        {
          "id": 250,
          "shortName": "PM",
          "longName": "St. Pierre and Miquelon"
        },
        {
          "id": 251,
          "shortName": "VC",
          "longName": "St. Vincent and the Grenadines"
        },
        {
          "id": 252,
          "shortName": "SD",
          "longName": "Sudan"
        },
        {
          "id": 253,
          "shortName": "SR",
          "longName": "Suriname"
        },
        {
          "id": 254,
          "shortName": "SJ",
          "longName": "Svalbard and Jan Mayen"
        },
        {
          "id": 255,
          "shortName": "SZ",
          "longName": "Swaziland"
        },
        {
          "id": 256,
          "shortName": "SE",
          "longName": "Sweden"
        },
        {
          "id": 257,
          "shortName": "CH",
          "longName": "Switzerland"
        },
        {
          "id": 258,
          "shortName": "SY",
          "longName": "Syrian Arab Republic"
        },
        {
          "id": 259,
          "shortName": "TW",
          "longName": "Taiwan"
        },
        {
          "id": 260,
          "shortName": "TJ",
          "longName": "Tajikistan"
        },
        {
          "id": 261,
          "shortName": "TZ",
          "longName": "Tanzania"
        },
        {
          "id": 262,
          "shortName": "TH",
          "longName": "Thailand"
        },
        {
          "id": 204,
          "shortName": "NL",
          "longName": "The Netherlands"
        },
        {
          "id": 263,
          "shortName": "TG",
          "longName": "Togo"
        },
        {
          "id": 264,
          "shortName": "TK",
          "longName": "Tokelau"
        },
        {
          "id": 265,
          "shortName": "TO",
          "longName": "Tonga"
        },
        {
          "id": 266,
          "shortName": "TT",
          "longName": "Trinidad and Tobago"
        },
        {
          "id": 267,
          "shortName": "TN",
          "longName": "Tunisia"
        },
        {
          "id": 268,
          "shortName": "TR",
          "longName": "Turkey"
        },
        {
          "id": 269,
          "shortName": "TM",
          "longName": "Turkmenistan"
        },
        {
          "id": 844,
          "shortName": "TC",
          "longName": "Turks and Caicos Islands"
        },
        {
          "id": 270,
          "shortName": "TV",
          "longName": "Tuvalu"
        },
        {
          "id": 276,
          "shortName": "UM",
          "longName": "U.S. Minor Outlying Islands"
        },
        {
          "id": 271,
          "shortName": "UG",
          "longName": "Uganda"
        },
        {
          "id": 272,
          "shortName": "UA",
          "longName": "Ukraine"
        },
        {
          "id": 273,
          "shortName": "AE",
          "longName": "United Arab Emirates"
        },
        {
          "id": 274,
          "shortName": "GB",
          "longName": "United Kingdom"
        },
        {
          "id": 277,
          "shortName": "UY",
          "longName": "Uruguay"
        },
        {
          "id": 278,
          "shortName": "UZ",
          "longName": "Uzbekistan"
        },
        {
          "id": 279,
          "shortName": "VU",
          "longName": "Vanuatu"
        },
        {
          "id": 280,
          "shortName": "VE",
          "longName": "Venezuela"
        },
        {
          "id": 281,
          "shortName": "VN",
          "longName": "Vietnam"
        },
        {
          "id": 282,
          "shortName": "VI",
          "longName": "Virgin Islands (USA)"
        },
        {
          "id": 284,
          "shortName": "WF",
          "longName": "Wallis and Futuna Islands"
        },
        {
          "id": 285,
          "shortName": "EH",
          "longName": "Western Sahara"
        },
        {
          "id": 286,
          "shortName": "YE",
          "longName": "Yemen"
        },
        {
          "id": 288,
          "shortName": "ZM",
          "longName": "Zambia"
        },
        {
          "id": 289,
          "shortName": "ZW",
          "longName": "Zimbabwe"
        },
        {
          "id": 4380,
          "shortName": "AX",
          "longName": "Åland Islands"
        },
        {
          "id": 4381,
          "shortName": "BQ",
          "longName": "Bonaire, Sint Eustatius and Saba"
        },
        {
          "id": 4382,
          "shortName": "RE",
          "longName": "Réunion"
        },
        {
          "id": 4383,
          "shortName": "SS",
          "longName": "South Sudan"
        }
      ],
      9: [
        {
          "id": 290,
          "shortName": "PCFS",
          "longName": "PCFS"
        },
        {
          "id": 291,
          "shortName": "CorPro",
          "longName": "CorPro"
        },
        {
          "id": 292,
          "shortName": "Document Library",
          "longName": "Document Library"
        },
        {
          "id": 293,
          "shortName": "CorTax",
          "longName": "CorTax"
        },
        {
          "id": 294,
          "shortName": "CorReport",
          "longName": "CorReport"
        },
        {
          "id": 295,
          "shortName": "CorLoader",
          "longName": "CorLoader"
        },
        {
          "id": 296,
          "shortName": "CorAnalytics&Admin",
          "longName": "CorAnalytics&Admin"
        },
        {
          "id": 643,
          "shortName": "Trade Blotter",
          "longName": "Trade Blotter"
        },
        {
          "id": 644,
          "shortName": "Servicing Tickler",
          "longName": "Servicing Tickler"
        },
        {
          "id": 1450,
          "shortName": "WebNotice",
          "longName": "WebNotice"
        }
      ],
      11: [
        {
          "id": 304,
          "shortName": "AL",
          "longName": "ALABAMA"
        },
        {
          "id": 305,
          "shortName": "AK",
          "longName": "ALASKA"
        },
        {
          "id": 306,
          "shortName": "AZ",
          "longName": "ARIZONA"
        },
        {
          "id": 307,
          "shortName": "AR",
          "longName": "ARKANSAS"
        },
        {
          "id": 308,
          "shortName": "CA",
          "longName": "CALIFORNIA"
        },
        {
          "id": 309,
          "shortName": "CO",
          "longName": "COLORADO"
        },
        {
          "id": 310,
          "shortName": "CT",
          "longName": "CONNECTICUT"
        },
        {
          "id": 311,
          "shortName": "DE",
          "longName": "DELAWARE"
        },
        {
          "id": 312,
          "shortName": "DC",
          "longName": "DISTRICT OF COLUMBIA"
        },
        {
          "id": 313,
          "shortName": "FL",
          "longName": "FLORIDA"
        },
        {
          "id": 314,
          "shortName": "GA",
          "longName": "GEORGIA"
        },
        {
          "id": 315,
          "shortName": "HI",
          "longName": "HAWAII"
        },
        {
          "id": 316,
          "shortName": "ID",
          "longName": "IDAHO"
        },
        {
          "id": 317,
          "shortName": "IL",
          "longName": "ILLINOIS"
        },
        {
          "id": 318,
          "shortName": "IN",
          "longName": "INDIANA"
        },
        {
          "id": 319,
          "shortName": "IA",
          "longName": "IOWA"
        },
        {
          "id": 320,
          "shortName": "KS",
          "longName": "KANSAS"
        },
        {
          "id": 321,
          "shortName": "KY",
          "longName": "KENTUCKY"
        },
        {
          "id": 322,
          "shortName": "LA",
          "longName": "LOUISIANA"
        },
        {
          "id": 323,
          "shortName": "ME",
          "longName": "MAINE"
        },
        {
          "id": 324,
          "shortName": "MD",
          "longName": "MARYLAND"
        },
        {
          "id": 325,
          "shortName": "MA",
          "longName": "MASSACHUSETTS"
        },
        {
          "id": 326,
          "shortName": "MI",
          "longName": "MICHIGAN"
        },
        {
          "id": 327,
          "shortName": "MN",
          "longName": "MINNESOTA"
        },
        {
          "id": 328,
          "shortName": "MS",
          "longName": "MISSISSIPPI"
        },
        {
          "id": 329,
          "shortName": "MO",
          "longName": "MISSOURI"
        },
        {
          "id": 330,
          "shortName": "MT",
          "longName": "MONTANA"
        },
        {
          "id": 331,
          "shortName": "NE",
          "longName": "NEBRASKA"
        },
        {
          "id": 332,
          "shortName": "NV",
          "longName": "NEVADA"
        },
        {
          "id": 333,
          "shortName": "NH",
          "longName": "NEW HAMPSHIRE"
        },
        {
          "id": 334,
          "shortName": "NJ",
          "longName": "NEW JERSEY"
        },
        {
          "id": 335,
          "shortName": "NM",
          "longName": "NEW MEXICO"
        },
        {
          "id": 336,
          "shortName": "NY",
          "longName": "NEW YORK"
        },
        {
          "id": 337,
          "shortName": "NC",
          "longName": "NORTH CAROLINA"
        },
        {
          "id": 338,
          "shortName": "ND",
          "longName": "NORTH DAKOTA"
        },
        {
          "id": 339,
          "shortName": "OH",
          "longName": "OHIO"
        },
        {
          "id": 340,
          "shortName": "OK",
          "longName": "OKLAHOMA"
        },
        {
          "id": 341,
          "shortName": "OR",
          "longName": "OREGON"
        },
        {
          "id": 342,
          "shortName": "PA",
          "longName": "PENNSYLVANIA"
        },
        {
          "id": 343,
          "shortName": "RI",
          "longName": "RHODE ISLAND"
        },
        {
          "id": 344,
          "shortName": "SC",
          "longName": "SOUTH CAROLINA"
        },
        {
          "id": 345,
          "shortName": "SD",
          "longName": "SOUTH DAKOTA"
        },
        {
          "id": 346,
          "shortName": "TN",
          "longName": "TENNESSEE"
        },
        {
          "id": 347,
          "shortName": "TX",
          "longName": "TEXAS"
        },
        {
          "id": 348,
          "shortName": "UT",
          "longName": "UTAH"
        },
        {
          "id": 349,
          "shortName": "VT",
          "longName": "VERMONT"
        },
        {
          "id": 350,
          "shortName": "VA",
          "longName": "VIRGINIA"
        },
        {
          "id": 351,
          "shortName": "WA",
          "longName": "WASHINGTON"
        },
        {
          "id": 352,
          "shortName": "WV",
          "longName": "WEST VIRGINIA"
        },
        {
          "id": 353,
          "shortName": "WI",
          "longName": "WISCONSIN"
        },
        {
          "id": 354,
          "shortName": "WY",
          "longName": "WYOMING"
        },
        {
          "id": 3386,
          "shortName": "N/A",
          "longName": "N/A"
        }
      ],
      16: [
        {
          "id": 647,
          "shortName": "\"Agriculture Capital Management\" AgCapManagement@alterdomus.com",
          "longName": "\"Agriculture Capital Management\" AgCapManagement@alterdomus.com"
        },
        {
          "id": 457,
          "shortName": "\"Alter Domus Investor Services\"  InvestorServices@alterdomus.com",
          "longName": "\"Alter Domus Investor Services\"  InvestorServices@alterdomus.com"
        },
        {
          "id": 560,
          "shortName": "\"Brickman Investor Relations\" Brickman@alterdomus.com",
          "longName": "\"Brickman Investor Relations\" Brickman@alterdomus.com"
        },
        {
          "id": 847,
          "shortName": "\"GreenOak Investor Relations\" GreenOakIR@alterdomus.com",
          "longName": "\"GreenOak Investor Relations\" GreenOakIR@alterdomus.com"
        },
        {
          "id": 906,
          "shortName": "\"GreenOak Investor Relations\" GreenOakIR@greenoakrealestate.com",
          "longName": "\"GreenOak Investor Relations\" GreenOakIR@greenoakrealestate.com"
        },
        {
          "id": 3158,
          "shortName": "\"On Behalf of Evolution Fund I\" morningside@alterdomus.com",
          "longName": "\"On Behalf of Evolution Fund I\" morningside@alterdomus.com"
        },
        {
          "id": 656,
          "shortName": "\"Raith Capital Partners\" Raith@alterdomus.com",
          "longName": "\"Raith Capital Partners\" Raith@alterdomus.com"
        },
        {
          "id": 716,
          "shortName": "\"Raith Real Estate Fund I\" RaithREFundI@alterdomus.com",
          "longName": "\"Raith Real Estate Fund I\" RaithREFundI@alterdomus.com"
        },
        {
          "id": 607,
          "shortName": "\"Singerman Investor Services\" Singerman_RE@alterdomus.com",
          "longName": "\"Singerman Investor Services\" Singerman_RE@alterdomus.com"
        },
        {
          "id": 584,
          "shortName": "\"T2 Investor Services\" T2investorservices@alterdomus.com",
          "longName": "\"T2 Investor Services\" T2investorservices@alterdomus.com"
        },
        {
          "id": 866,
          "shortName": "\"Wastewater Opportunity Fund\" WOFinvestor@alterdomus.com",
          "longName": "\"Wastewater Opportunity Fund\" WOFinvestor@alterdomus.com"
        },
        {
          "id": 3778,
          "shortName": "01VC@alterdomus.com",
          "longName": "01VC@alterdomus.com"
        },
        {
          "id": 1581,
          "shortName": "25Capital@alterdomus.com",
          "longName": "25Capital@alterdomus.com"
        },
        {
          "id": 3468,
          "shortName": "4Cinvestors@fourcornerstaverns.com",
          "longName": "4Cinvestors@fourcornerstaverns.com"
        },
        {
          "id": 3147,
          "shortName": "548Capital@alterdomus.com",
          "longName": "548Capital@alterdomus.com"
        },
        {
          "id": 3403,
          "shortName": "5YCAP@alterdomus.com",
          "longName": "5YCAP@alterdomus.com"
        },
        {
          "id": 3395,
          "shortName": "abacus@alterdomus.com",
          "longName": "abacus@alterdomus.com"
        },
        {
          "id": 3900,
          "shortName": "adimplementationteam@gmail.com",
          "longName": "adimplementationteam@gmail.com"
        },
        {
          "id": 1633,
          "shortName": "AgricultureCapital@alterdomus.com",
          "longName": "AgricultureCapital@alterdomus.com"
        },
        {
          "id": 1612,
          "shortName": "Alcova@alterdomus.com",
          "longName": "Alcova@alterdomus.com"
        },
        {
          "id": 1596,
          "shortName": "alidade@alterdomus.com",
          "longName": "alidade@alterdomus.com"
        },
        {
          "id": 3730,
          "shortName": "alignment@alterdomus.com",
          "longName": "alignment@alterdomus.com"
        },
        {
          "id": 3190,
          "shortName": "allegiantoefund@alterdomus.com",
          "longName": "allegiantoefund@alterdomus.com"
        },
        {
          "id": 3202,
          "shortName": "Alphavc@alterdomus.com",
          "longName": "Alphavc@alterdomus.com"
        },
        {
          "id": 3872,
          "shortName": "alphawise@alterdomus.com",
          "longName": "alphawise@alterdomus.com"
        },
        {
          "id": 1609,
          "shortName": "alpinegroveinvestorservices@alterdomus.com",
          "longName": "alpinegroveinvestorservices@alterdomus.com"
        },
        {
          "id": 3738,
          "shortName": "Alwin@alterdomus.com",
          "longName": "Alwin@alterdomus.com"
        },
        {
          "id": 3746,
          "shortName": "ameba@alterdomus.com",
          "longName": "ameba@alterdomus.com"
        },
        {
          "id": 3176,
          "shortName": "anvil@alterdomus.com",
          "longName": "anvil@alterdomus.com"
        },
        {
          "id": 4022,
          "shortName": "Antares.IS@alterdomus.com",
          "longName": "Antares.IS@alterdomus.com"
        },
        {
          "id": 1608,
          "shortName": "argonne@alterdomus.com",
          "longName": "argonne@alterdomus.com"
        },
        {
          "id": 1455,
          "shortName": "ASL@alterdomus.com",
          "longName": "ASL@alterdomus.com"
        },
        {
          "id": 3771,
          "shortName": "asref@alterdomus.com",
          "longName": "asref@alterdomus.com"
        },
        {
          "id": 1451,
          "shortName": "atlascapital@alterdomus.com",
          "longName": "atlascapital@alterdomus.com"
        },
        {
          "id": 3867,
          "shortName": "Backcast@alterdomus.com",
          "longName": "Backcast@alterdomus.com"
        },
        {
          "id": 632,
          "shortName": "BainbridgeZKS@alterdomus.com",
          "longName": "BainbridgeZKS@alterdomus.com"
        },
        {
          "id": 891,
          "shortName": "BalboaRetail@alterdomus.com",
          "longName": "BalboaRetail@alterdomus.com"
        },
        {
          "id": 1576,
          "shortName": "BasisInvestmentGroup@alterdomus.com",
          "longName": "BasisInvestmentGroup@alterdomus.com"
        },
        {
          "id": 1577,
          "shortName": "BasisInvestmentGroup@alterdomus.com",
          "longName": "BasisInvestmentGroup@alterdomus.com"
        },
        {
          "id": 3173,
          "shortName": "BayGrove@alterdomus.com",
          "longName": "BayGrove@alterdomus.com"
        },
        {
          "id": 933,
          "shortName": "blackbird@alterdomus.com",
          "longName": "blackbird@alterdomus.com"
        },
        {
          "id": 3399,
          "shortName": "boydwatterson@alterdomus.com",
          "longName": "boydwatterson@alterdomus.com"
        },
        {
          "id": 3779,
          "shortName": "bridge@alterdomus.com",
          "longName": "bridge@alterdomus.com"
        },
        {
          "id": 1583,
          "shortName": "Brightstone@alterdomus.com",
          "longName": "Brightstone@alterdomus.com"
        },
        {
          "id": 3203,
          "shortName": "BSREF@alterdomus.com",
          "longName": "BSREF@alterdomus.com"
        },
        {
          "id": 3207,
          "shortName": "CarlyleAviation@alterdomus.com",
          "longName": "CarlyleAviation@alterdomus.com"
        },
        {
          "id": 458,
          "shortName": "cec@alterdomus.com",
          "longName": "cec@alterdomus.com"
        },
        {
          "id": 1127,
          "shortName": "CEFFInvestor@alterdomus.com",
          "longName": "CEFFInvestor@alterdomus.com"
        },
        {
          "id": 684,
          "shortName": "CFIP@alterdomus.com",
          "longName": "CFIP@alterdomus.com"
        },
        {
          "id": 3790,
          "shortName": "charismapartner@alterdomus.com",
          "longName": "charismapartner@alterdomus.com"
        },
        {
          "id": 3759,
          "shortName": "CICCHCF@alterdomus.com",
          "longName": "CICCHCF@alterdomus.com"
        },
        {
          "id": 3752,
          "shortName": "ciccjinpuviii@alterdomus.com",
          "longName": "ciccjinpuviii@alterdomus.com"
        },
        {
          "id": 3763,
          "shortName": "ciccsp@alterdomus.com",
          "longName": "ciccsp@alterdomus.com"
        },
        {
          "id": 3796,
          "shortName": "CIMInfraIII@alterdomus.com",
          "longName": "CIMInfraIII@alterdomus.com"
        },
        {
          "id": 3765,
          "shortName": "CM-FIN@cortlandglobal.com",
          "longName": "CM-FIN@cortlandglobal.com"
        },
        {
          "id": 3764,
          "shortName": "cmbwlam@alterdomus.com",
          "longName": "cmbwlam@alterdomus.com"
        },
        {
          "id": 928,
          "shortName": "Colbeckfundadmin@alterdomus.com",
          "longName": "Colbeckfundadmin@alterdomus.com"
        },
        {
          "id": 3795,
          "shortName": "CommercialRE@alterdomus.com",
          "longName": "CommercialRE@alterdomus.com"
        },
        {
          "id": 3393,
          "shortName": "concentric@cortlandglobal.com",
          "longName": "concentric@cortlandglobal.com"
        },
        {
          "id": 3739,
          "shortName": "CorProGlobalSupport@alterdomus.com",
          "longName": "CorProGlobalSupport@alterdomus.com"
        },
        {
          "id": 3737,
          "shortName": "CorProGlobalSupport@alterdomus.com",
          "longName": "CorProGlobalSupport@alterdomus.com"
        },
        {
          "id": 1579,
          "shortName": "CrestlineFundAdmin@alterdomus.com",
          "longName": "CrestlineFundAdmin@alterdomus.com"
        },
        {
          "id": 3404,
          "shortName": "dayonecapital@alterdomus.com",
          "longName": "dayonecapital@alterdomus.com"
        },
        {
          "id": 3886,
          "shortName": "DMT@alterdomus.com",
          "longName": "DMT@alterdomus.com"
        },
        {
          "id": 3729,
          "shortName": "do-not-reply@alterdomus.com",
          "longName": "do-not-reply@alterdomus.com"
        },
        {
          "id": 950,
          "shortName": "drake@alterdomus.com",
          "longName": "drake@alterdomus.com"
        },
        {
          "id": 3776,
          "shortName": "dwightinvestors@alterdomus.com",
          "longName": "dwightinvestors@alterdomus.com"
        },
        {
          "id": 1578,
          "shortName": "EatonVance@alterdomus.com",
          "longName": "EatonVance@alterdomus.com"
        },
        {
          "id": 3743,
          "shortName": "egp@alterdomus.com",
          "longName": "egp@alterdomus.com"
        },
        {
          "id": 3968,
          "shortName": "Eightfold.IS@alterdomus.com",
          "longName": "Eightfold.IS@alterdomus.com"
        },
        {
          "id": 1605,
          "shortName": "EQWWinvestor@alterdomus.com",
          "longName": "EQWWinvestor@alterdomus.com"
        },
        {
          "id": 1575,
          "shortName": "Feenix@alterdomus.com",
          "longName": "Feenix@alterdomus.com"
        },
        {
          "id": 3742,
          "shortName": "fides@alterdomus.com",
          "longName": "fides@alterdomus.com"
        },
        {
          "id": 927,
          "shortName": "formationcapital@alterdomus.com",
          "longName": "formationcapital@alterdomus.com"
        },
        {
          "id": 3402,
          "shortName": "futurecapital@alterdomus.com",
          "longName": "futurecapital@alterdomus.com"
        },
        {
          "id": 3800,
          "shortName": "geely@alterdomus.com",
          "longName": "geely@alterdomus.com"
        },
        {
          "id": 3793,
          "shortName": "GLAM@alterdomus.com",
          "longName": "GLAM@alterdomus.com"
        },
        {
          "id": 3186,
          "shortName": "graycocapitaladvisors@alterdomus.com",
          "longName": "graycocapitaladvisors@alterdomus.com"
        },
        {
          "id": 3460,
          "shortName": "greensledge@alterdomus.com",
          "longName": "greensledge@alterdomus.com"
        },
        {
          "id": 1140,
          "shortName": "greystonemezzanine@alterdomus.com",
          "longName": "greystonemezzanine@alterdomus.com"
        },
        {
          "id": 3191,
          "shortName": "grouprmc@alterdomus.com",
          "longName": "grouprmc@alterdomus.com"
        },
        {
          "id": 935,
          "shortName": "GroveInvestorServices@alterdomus.com",
          "longName": "GroveInvestorServices@alterdomus.com"
        },
        {
          "id": 949,
          "shortName": "GroveInvestorServices@alterdomus.com",
          "longName": "GroveInvestorServices@alterdomus.com"
        },
        {
          "id": 3180,
          "shortName": "hankang@alterdomus.com",
          "longName": "hankang@alterdomus.com"
        },
        {
          "id": 3388,
          "shortName": "harbourhouse@alterdomus.com",
          "longName": "harbourhouse@alterdomus.com"
        },
        {
          "id": 3760,
          "shortName": "HHF-Capital@alterdomus.com",
          "longName": "HHF-Capital@alterdomus.com"
        },
        {
          "id": 953,
          "shortName": "Hillcrest@alterdomus.com",
          "longName": "Hillcrest@alterdomus.com"
        },
        {
          "id": 3732,
          "shortName": "HMcapital@alterdomus.com",
          "longName": "HMcapital@alterdomus.com"
        },
        {
          "id": 3194,
          "shortName": "hngreenhollow@alterdomus.com",
          "longName": "hngreenhollow@alterdomus.com"
        },
        {
          "id": 3211,
          "shortName": "hosencapital@alterdomus.com",
          "longName": "hosencapital@alterdomus.com"
        },
        {
          "id": 3895,
          "shortName": "HuiCapital@alterdomus.com",
          "longName": "HuiCapital@alterdomus.com"
        },
        {
          "id": 3394,
          "shortName": "IceCap@alterdomus.com",
          "longName": "IceCap@alterdomus.com"
        },
        {
          "id": 1131,
          "shortName": "imperialrealestate@alterdomus.com",
          "longName": "imperialrealestate@alterdomus.com"
        },
        {
          "id": 3750,
          "shortName": "Inceptiv@alterdomus.com",
          "longName": "Inceptiv@alterdomus.com"
        },
        {
          "id": 1141,
          "shortName": "InspiredHealthcare@alterdomus.com",
          "longName": "InspiredHealthcare@alterdomus.com"
        },
        {
          "id": 940,
          "shortName": "investorrelations@asanapartners.com",
          "longName": "investorrelations@asanapartners.com"
        },
        {
          "id": 3167,
          "shortName": "InvestorServicesAsia@alterdomus.com",
          "longName": "InvestorServicesAsia@alterdomus.com"
        },
        {
          "id": 1508,
          "shortName": "IR@basisinvgroup.com",
          "longName": "IR@basisinvgroup.com"
        },
        {
          "id": 3212,
          "shortName": "IR@bentallgreenoak.com",
          "longName": "IR@bentallgreenoak.com"
        },
        {
          "id": 3762,
          "shortName": "irradiantpartners@alterdomus.com",
          "longName": "irradiantpartners@alterdomus.com"
        },
        {
          "id": 3405,
          "shortName": "Jefferies@alterdomus.com",
          "longName": "Jefferies@alterdomus.com"
        },
        {
          "id": 3201,
          "shortName": "jiangmen@alterdomus.com",
          "longName": "jiangmen@alterdomus.com"
        },
        {
          "id": 3466,
          "shortName": "jwcm@alterdomus.com",
          "longName": "jwcm@alterdomus.com"
        },
        {
          "id": 1586,
          "shortName": "Kandle@alterdomus.com",
          "longName": "Kandle@alterdomus.com"
        },
        {
          "id": 3205,
          "shortName": "Kinzon@alterdomus.com",
          "longName": "Kinzon@alterdomus.com"
        },
        {
          "id": 3156,
          "shortName": "kodiak@alterdomus.com",
          "longName": "kodiak@alterdomus.com"
        },
        {
          "id": 3788,
          "shortName": "Kovitz Investment Group Private Placements <PrivatePlacements@kovitz.com>",
          "longName": "Kovitz Investment Group Private Placements <PrivatePlacements@kovitz.com>"
        },
        {
          "id": 3136,
          "shortName": "Kovitz@alterdomus.com",
          "longName": "Kovitz@alterdomus.com"
        },
        {
          "id": 3789,
          "shortName": "kunyuan@alterdomus.com",
          "longName": "kunyuan@alterdomus.com"
        },
        {
          "id": 945,
          "shortName": "L3@alterdomus.com",
          "longName": "L3@alterdomus.com"
        },
        {
          "id": 3152,
          "shortName": "Ladera@alterdomus.com",
          "longName": "Ladera@alterdomus.com"
        },
        {
          "id": 3141,
          "shortName": "laura.mollard@alpinegrovepartners.com",
          "longName": "laura.mollard@alpinegrovepartners.com"
        },
        {
          "id": 3172,
          "shortName": "Lavenderhillcapital@alterdomus.com",
          "longName": "Lavenderhillcapital@alterdomus.com"
        },
        {
          "id": 3398,
          "shortName": "LCFUND@alterdomus.com",
          "longName": "LCFUND@alterdomus.com"
        },
        {
          "id": 1592,
          "shortName": "Linchris@alterdomus.com",
          "longName": "Linchris@alterdomus.com"
        },
        {
          "id": 3197,
          "shortName": "Lowkey@alterdomus.com",
          "longName": "Lowkey@alterdomus.com"
        },
        {
          "id": 3861,
          "shortName": "LR_investments@alterdomus.com",
          "longName": "LR_investments@alterdomus.com"
        },
        {
          "id": 3747,
          "shortName": "LSC@alterdomus.com",
          "longName": "LSC@alterdomus.com"
        },
        {
          "id": 1627,
          "shortName": "maranoncsp@alterdomus.com",
          "longName": "maranoncsp@alterdomus.com"
        },
        {
          "id": 3868,
          "shortName": "marathonvp@alterdomus.com",
          "longName": "marathonvp@alterdomus.com"
        },
        {
          "id": 1584,
          "shortName": "mbp@alterdomus.com",
          "longName": "mbp@alterdomus.com"
        },
        {
          "id": 3137,
          "shortName": "NBridgePartners@alterdomus.com",
          "longName": "NBridgePartners@alterdomus.com"
        },
        {
          "id": 3768,
          "shortName": "noahark@alterdomus.com",
          "longName": "noahark@alterdomus.com"
        },
        {
          "id": 1623,
          "shortName": "NorthBridgePartners@alterdomus.com",
          "longName": "NorthBridgePartners@alterdomus.com"
        },
        {
          "id": 3769,
          "shortName": "novahorizon@alterdomus.com",
          "longName": "novahorizon@alterdomus.com"
        },
        {
          "id": 3473,
          "shortName": "panagram@alterdomus.com",
          "longName": "panagram@alterdomus.com"
        },
        {
          "id": 1600,
          "shortName": "paxion@alterdomus.com",
          "longName": "paxion@alterdomus.com"
        },
        {
          "id": 943,
          "shortName": "Pennybacker@alterdomus.com",
          "longName": "Pennybacker@alterdomus.com"
        },
        {
          "id": 3748,
          "shortName": "pgss@alterdomus.com",
          "longName": "pgss@alterdomus.com"
        },
        {
          "id": 3781,
          "shortName": "pih@alterdomus.com",
          "longName": "pih@alterdomus.com"
        },
        {
          "id": 1601,
          "shortName": "PiperJaffrayFinance@alterdomus.com",
          "longName": "PiperJaffrayFinance@alterdomus.com"
        },
        {
          "id": 3891,
          "shortName": "premierpartners@alterdomus.com",
          "longName": "premierpartners@alterdomus.com"
        },
        {
          "id": 3753,
          "shortName": "protossspc@alterdomus.com",
          "longName": "protossspc@alterdomus.com"
        },
        {
          "id": 3179,
          "shortName": "quancapital@alterdomus.com",
          "longName": "quancapital@alterdomus.com"
        },
        {
          "id": 1603,
          "shortName": "redburn@alterdomus.com",
          "longName": "redburn@alterdomus.com"
        },
        {
          "id": 1593,
          "shortName": "RegentProperties@alterdomus.com",
          "longName": "RegentProperties@alterdomus.com"
        },
        {
          "id": 602,
          "shortName": "renovo@alterdomus.com",
          "longName": "renovo@alterdomus.com"
        },
        {
          "id": 3196,
          "shortName": "RevOZ@alterdomus.com",
          "longName": "RevOZ@alterdomus.com"
        },
        {
          "id": 630,
          "shortName": "Roc1@alterdomus.com",
          "longName": "Roc1@alterdomus.com"
        },
        {
          "id": 631,
          "shortName": "Roc2@alterdomus.com",
          "longName": "Roc2@alterdomus.com"
        },
        {
          "id": 1126,
          "shortName": "RRACapital@alterdomus.com",
          "longName": "RRACapital@alterdomus.com"
        },
        {
          "id": 3157,
          "shortName": "Runway@alterdomus.com",
          "longName": "Runway@alterdomus.com"
        },
        {
          "id": 806,
          "shortName": "ryanspecialtygroup@alterdomus.com",
          "longName": "ryanspecialtygroup@alterdomus.com"
        },
        {
          "id": 3209,
          "shortName": "Samas@alterdomus.com",
          "longName": "Samas@alterdomus.com"
        },
        {
          "id": 3458,
          "shortName": "SDS-SHF@alterdomus.com",
          "longName": "SDS-SHF@alterdomus.com"
        },
        {
          "id": 3149,
          "shortName": "sequoia@alterdomus.com",
          "longName": "sequoia@alterdomus.com"
        },
        {
          "id": 3770,
          "shortName": "shenneng@alterdomus.com",
          "longName": "shenneng@alterdomus.com"
        },
        {
          "id": 3798,
          "shortName": "Silverpeak.is@alterdomus.com",
          "longName": "Silverpeak.is@alterdomus.com"
        },
        {
          "id": 1629,
          "shortName": "smi2018@alterdomus.com",
          "longName": "smi2018@alterdomus.com"
        },
        {
          "id": 3802,
          "shortName": "SRF@alterdomus.com",
          "longName": "SRF@alterdomus.com"
        },
        {
          "id": 952,
          "shortName": "stellus@alterdomus.com",
          "longName": "stellus@alterdomus.com"
        },
        {
          "id": 1622,
          "shortName": "SummitRealEstateGroup@alterdomus.com",
          "longName": "SummitRealEstateGroup@alterdomus.com"
        },
        {
          "id": 3731,
          "shortName": "tangwealth@alterdomus.com",
          "longName": "tangwealth@alterdomus.com"
        },
        {
          "id": 876,
          "shortName": "ThorofareIR@alterdomus.com",
          "longName": "ThorofareIR@alterdomus.com"
        },
        {
          "id": 3896,
          "shortName": "Trenda@alterdomus.com",
          "longName": "Trenda@alterdomus.com"
        },
        {
          "id": 934,
          "shortName": "TriPost@alterdomus.com",
          "longName": "TriPost@alterdomus.com"
        },
        {
          "id": 938,
          "shortName": "VaragonLPServices@alterdomus.com",
          "longName": "VaragonLPServices@alterdomus.com"
        },
        {
          "id": 3213,
          "shortName": "ventas@alterdomus.com",
          "longName": "ventas@alterdomus.com"
        },
        {
          "id": 3204,
          "shortName": "Visionplus@alterdomus.com",
          "longName": "Visionplus@alterdomus.com"
        },
        {
          "id": 1530,
          "shortName": "Waterton@alterdomus.com",
          "longName": "Waterton@alterdomus.com"
        },
        {
          "id": 3972,
          "shortName": "WayPoint.IS@alterdomus.com",
          "longName": "WayPoint.IS@alterdomus.com"
        },
        {
          "id": 1624,
          "shortName": "WCreekCredit@alterdomus.com",
          "longName": "WCreekCredit@alterdomus.com"
        },
        {
          "id": 3775,
          "shortName": "West@alterdomus.com",
          "longName": "West@alterdomus.com"
        },
        {
          "id": 1628,
          "shortName": "WillowTreeLP@alterdomus.com",
          "longName": "WillowTreeLP@alterdomus.com"
        },
        {
          "id": 1582,
          "shortName": "wolff@alterdomus.com",
          "longName": "wolff@alterdomus.com"
        },
        {
          "id": 3860,
          "shortName": "Woodbridge@alterdomus.com",
          "longName": "Woodbridge@alterdomus.com"
        },
        {
          "id": 3178,
          "shortName": "XVC@alterdomus.com",
          "longName": "XVC@alterdomus.com"
        },
        {
          "id": 3869,
          "shortName": "S3Capital@alterdomus.com",
          "longName": "S3Capital@alterdomus.com"
        },
        {
          "id": 3870,
          "shortName": "Silverview.is@alterdomus.com",
          "longName": "Silverview.is@alterdomus.com"
        },
        {
          "id": 3879,
          "shortName": "irradiant.is@alterdomus.com",
          "longName": "irradiant.is@alterdomus.com"
        },
        {
          "id": 3888,
          "shortName": "BridgeInvest.IS@alterdomus.com",
          "longName": "BridgeInvest.IS@alterdomus.com"
        },
        {
          "id": 3901,
          "shortName": "406ventures.IS@alterdomus.com",
          "longName": "406ventures.IS@alterdomus.com"
        },
        {
          "id": 3902,
          "shortName": "3x5partners.IS@alterdomus.com",
          "longName": "3x5partners.IS@alterdomus.com"
        },
        {
          "id": 3903,
          "shortName": "Acorn.IS@alterdomus.com",
          "longName": "Acorn.IS@alterdomus.com"
        },
        {
          "id": 3904,
          "shortName": "AcornCA.IS@alterdomus.com",
          "longName": "AcornCA.IS@alterdomus.com"
        },
        {
          "id": 3905,
          "shortName": "Ceres.IS@alterdomus.com",
          "longName": "Ceres.IS@alterdomus.com"
        },
        {
          "id": 3906,
          "shortName": "CEVF.IS@alterdomus.com",
          "longName": "CEVF.IS@alterdomus.com"
        },
        {
          "id": 3907,
          "shortName": "Commonwealth.IS@alterdomus.com",
          "longName": "Commonwealth.IS@alterdomus.com"
        },
        {
          "id": 3908,
          "shortName": "CueBall.IS@alterdomus.com",
          "longName": "CueBall.IS@alterdomus.com"
        },
        {
          "id": 3909,
          "shortName": "Denham.IS@alterdomus.com",
          "longName": "Denham.IS@alterdomus.com"
        },
        {
          "id": 3910,
          "shortName": "Exaltare.IS@alterdomus.com",
          "longName": "Exaltare.IS@alterdomus.com"
        },
        {
          "id": 3911,
          "shortName": "FortPoint.IS@alterdomus.com",
          "longName": "FortPoint.IS@alterdomus.com"
        },
        {
          "id": 3912,
          "shortName": "Harmon.IS@alterdomus.com",
          "longName": "Harmon.IS@alterdomus.com"
        },
        {
          "id": 3913,
          "shortName": "Hometap.IS@alterdomus.com",
          "longName": "Hometap.IS@alterdomus.com"
        },
        {
          "id": 3914,
          "shortName": "hyperplane.IS@alterdomus.com",
          "longName": "hyperplane.IS@alterdomus.com"
        },
        {
          "id": 3915,
          "shortName": "JMC.IS@alterdomus.com",
          "longName": "JMC.IS@alterdomus.com"
        },
        {
          "id": 3916,
          "shortName": "kendall.IS@alterdomus.com",
          "longName": "kendall.IS@alterdomus.com"
        },
        {
          "id": 3917,
          "shortName": "Kepha.IS@alterdomus.com",
          "longName": "Kepha.IS@alterdomus.com"
        },
        {
          "id": 3918,
          "shortName": "LFM.IS@alterdomus.com",
          "longName": "LFM.IS@alterdomus.com"
        },
        {
          "id": 3919,
          "shortName": "lincolnpeak.IS@alterdomus.com",
          "longName": "lincolnpeak.IS@alterdomus.com"
        },
        {
          "id": 3920,
          "shortName": "Longwood.IS@alterdomus.com",
          "longName": "Longwood.IS@alterdomus.com"
        },
        {
          "id": 3921,
          "shortName": "Method.IS@alterdomus.com",
          "longName": "Method.IS@alterdomus.com"
        },
        {
          "id": 3922,
          "shortName": "novalis.IS@alterdomus.com",
          "longName": "novalis.IS@alterdomus.com"
        },
        {
          "id": 3923,
          "shortName": "omegacapital.IS@alterdomus.com",
          "longName": "omegacapital.IS@alterdomus.com"
        },
        {
          "id": 3924,
          "shortName": "perspecta.IS@alterdomus.com",
          "longName": "perspecta.IS@alterdomus.com"
        },
        {
          "id": 3925,
          "shortName": "Rivervest.IS@alterdomus.com",
          "longName": "Rivervest.IS@alterdomus.com"
        },
        {
          "id": 3926,
          "shortName": "sightline.IS@alterdomus.com",
          "longName": "sightline.IS@alterdomus.com"
        },
        {
          "id": 3927,
          "shortName": "Staley.IS@alterdomus.com",
          "longName": "Staley.IS@alterdomus.com"
        },
        {
          "id": 3928,
          "shortName": "Stone-Goff.IS@alterdomus.com",
          "longName": "Stone-Goff.IS@alterdomus.com"
        },
        {
          "id": 3929,
          "shortName": "TEM.IS@alterdomus.com",
          "longName": "TEM.IS@alterdomus.com"
        },
        {
          "id": 3930,
          "shortName": "TNC.IS@alterdomus.com",
          "longName": "TNC.IS@alterdomus.com"
        },
        {
          "id": 3931,
          "shortName": "TVP.IS@alterdomus.com",
          "longName": "TVP.IS@alterdomus.com"
        },
        {
          "id": 3932,
          "shortName": "aBayPoint.IS@alterDomus.com",
          "longName": "aBayPoint.IS@alterDomus.com"
        },
        {
          "id": 3933,
          "shortName": "Endeavor8.IS@alterDomus.com",
          "longName": "Endeavor8.IS@alterDomus.com"
        },
        {
          "id": 3934,
          "shortName": "Truvvo.IS@alterDomus.com",
          "longName": "Truvvo.IS@alterDomus.com"
        },
        {
          "id": 3938,
          "shortName": "novirian@alterdomus.com",
          "longName": "novirian@alterdomus.com"
        },
        {
          "id": 3939,
          "shortName": "GCI@alterdomus.com",
          "longName": "GCI@alterdomus.com"
        },
        {
          "id": 3940,
          "shortName": "IDA@alterdomus.com",
          "longName": "IDA@alterdomus.com"
        },
        {
          "id": 3941,
          "shortName": "merricks@alterdomus.com",
          "longName": "merricks@alterdomus.com"
        },
        {
          "id": 3942,
          "shortName": "qualitas@alterdomus.com",
          "longName": "qualitas@alterdomus.com"
        },
        {
          "id": 3943,
          "shortName": "1.8meters@alterdomus.com",
          "longName": "1.8meters@alterdomus.com"
        },
        {
          "id": 3944,
          "shortName": "L5.IS@alterdomus.com",
          "longName": "L5.IS@alterdomus.com"
        },
        {
          "id": 3945,
          "shortName": "CreateHealth.IS@alterdomus.com",
          "longName": "CreateHealth.IS@alterdomus.com"
        },
        {
          "id": 3946,
          "shortName": "OceanAvenue.IS@alterdomus.com",
          "longName": "OceanAvenue.IS@alterdomus.com"
        },
        {
          "id": 3953,
          "shortName": "sirona@alterdomus.com",
          "longName": "sirona@alterdomus.com"
        },
        {
          "id": 3954,
          "shortName": "OceanAvenueInvestments@alterdomus.com",
          "longName": "OceanAvenueInvestments@alterdomus.com"
        },
        {
          "id": 3955,
          "shortName": "CIM.IS@alterdomus.com",
          "longName": "CIM.IS@alterdomus.com"
        },
        {
          "id": 3957,
          "shortName": "Validus.IS@alterdomus.com",
          "longName": "Validus.IS@alterdomus.com"
        },
        {
          "id": 3958,
          "shortName": "Finistere.IS@alterdomus.com",
          "longName": "Finistere.IS@alterdomus.com"
        },
        {
          "id": 3960,
          "shortName": "JordanPark.is@alterdomus.com",
          "longName": "JordanPark.is@alterdomus.com"
        },
        {
          "id": 3963,
          "shortName": "CloudCapital.IS@AlterDomus.com",
          "longName": "CloudCapital.IS@AlterDomus.com"
        },
        {
          "id": 3964,
          "shortName": "OakStreet.IS@alterdomus.com",
          "longName": "OakStreet.IS@alterdomus.com"
        },
        {
          "id": 3967,
          "shortName": "Grubb.IS@alterdomus.com",
          "longName": "Grubb.IS@alterdomus.com"
        },
        {
          "id": 3971,
          "shortName": "amicaa@alterdomus.com",
          "longName": "amicaa@alterdomus.com"
        },
        {
          "id": 3985,
          "shortName": "LoneTree.IS@alterdomus.com",
          "longName": "LoneTree.IS@alterdomus.com"
        },
        {
          "id": 3999,
          "shortName": "statements@alterdomus.com",
          "longName": "statements@alterdomus.com"
        },
        {
          "id": 4003,
          "shortName": "Tworoads.IS@alterdomus.com",
          "longName": "Tworoads.IS@alterdomus.com"
        },
        {
          "id": 4013,
          "shortName": "Argonne.IS@alterdomus.com",
          "longName": "Argonne.IS@alterdomus.com"
        },
        {
          "id": 4015,
          "shortName": "WaltonStreet.IS@alterdomus.com",
          "longName": "WaltonStreet.IS@alterdomus.com"
        },
        {
          "id": 4016,
          "shortName": "selbylaneinvestments@alterdomus.com",
          "longName": "selbylaneinvestments@alterdomus.com"
        },
        {
          "id": 4017,
          "shortName": "CVTeam@alterdomus.com",
          "longName": "CVTeam@alterdomus.com"
        },
        {
          "id": 4021,
          "shortName": "Drake.IS@alterdomus.com",
          "longName": "Drake.IS@alterdomus.com"
        },
        {
          "id": 4023,
          "shortName": "AvantBio.IS@alterdomus.com",
          "longName": "AvantBio.IS@alterdomus.com"
        },
        {
          "id": 4024,
          "shortName": "SymbioticCapital.IS@alterdomus.com",
          "longName": "SymbioticCapital.IS@alterdomus.com"
        },
        {
          "id": 4029,
          "shortName": "Dynamk.IS@alterdomus.com",
          "longName": "Dynamk.IS@alterdomus.com"
        },
        {
          "id": 4030,
          "shortName": "merithill.is@alterdomus.com",
          "longName": "merithill.is@alterdomus.com"
        },
        {
          "id": 4031,
          "shortName": "hylandhill.is@alterdomus.com",
          "longName": "hylandhill.is@alterdomus.com"
        },
        {
          "id": 4032,
          "shortName": "Leargas.IS@alterdomus.com",
          "longName": "Leargas.IS@alterdomus.com"
        },
        {
          "id": 4048,
          "shortName": "Comvest.IS@alterdomus.com",
          "longName": "Comvest.IS@alterdomus.com"
        },
        {
          "id": 4049,
          "shortName": "AtlanticCreek.IS@alterdomus.com",
          "longName": "AtlanticCreek.IS@alterdomus.com"
        }
      ],
      20: [
        {
          "id": 562,
          "shortName": "Agency Borrower",
          "longName": "Agency Borrower"
        },
        {
          "id": 563,
          "shortName": "Agency Lender",
          "longName": "Agency Lender"
        },
        {
          "id": 564,
          "shortName": "Fund Investor",
          "longName": "Fund Investor"
        },
        {
          "id": 571,
          "shortName": "Agency Client",
          "longName": "Agency Client"
        },
        {
          "id": 572,
          "shortName": "Agency Admin",
          "longName": "Agency Admin"
        },
        {
          "id": 582,
          "shortName": "Agency Custodian",
          "longName": "Agency Custodian"
        },
        {
          "id": 612,
          "shortName": "Agency Guarantor",
          "longName": "Agency Guarantor"
        },
        {
          "id": 610,
          "shortName": "Agency Arranger",
          "longName": "Agency Arranger"
        },
        {
          "id": 613,
          "shortName": "Agency Syndication Agent",
          "longName": "Agency Syndication Agent"
        },
        {
          "id": 771,
          "shortName": "Agency Collateral Agent",
          "longName": "Agency Collateral Agent"
        },
        {
          "id": 611,
          "shortName": "Agency Documentation Agent",
          "longName": "Agency Documentation Agent"
        },
        {
          "id": 645,
          "shortName": "Trade Settlement Client",
          "longName": "Trade Settlement Client"
        },
        {
          "id": 646,
          "shortName": "Trade Settlement Trustee",
          "longName": "Trade Settlement Trustee"
        },
        {
          "id": 846,
          "shortName": "Agency Tax Party",
          "longName": "Agency Tax Party"
        },
        {
          "id": 888,
          "shortName": "LC Beneficiary",
          "longName": "LC Beneficiary"
        },
        {
          "id": 889,
          "shortName": "LC Issuing Bank",
          "longName": "LC Issuing Bank"
        },
        {
          "id": 3470,
          "shortName": "KYC on File (NA) – Level TBD",
          "longName": "KYC on File (NA) – Level TBD"
        },
        {
          "id": 3471,
          "shortName": "Internal Only",
          "longName": "Internal Only"
        },
        {
          "id": 3472,
          "shortName": "Loan Servicing Loan",
          "longName": "Loan Servicing Loan"
        },
        {
          "id": 3474,
          "shortName": "KYC on File (NA) - Level 1",
          "longName": "KYC on File (NA) - Level 1"
        },
        {
          "id": 3475,
          "shortName": "KYC on File (NA) - Level 2",
          "longName": "KYC on File (NA) - Level 2"
        },
        {
          "id": 3476,
          "shortName": "KYC on File (NA) - Level 3",
          "longName": "KYC on File (NA) - Level 3"
        },
        {
          "id": 3477,
          "shortName": "KYC on File (EMEA/APAC)",
          "longName": "KYC on File (EMEA/APAC)"
        }
      ],
      21: [
        {
          "id": 565,
          "shortName": "N/A",
          "longName": "N/A"
        },
        {
          "id": 3150,
          "shortName": "548Capital",
          "longName": "548Capital"
        },
        {
          "id": 686,
          "shortName": "AbacusCapitalGroup",
          "longName": "AbacusCapitalGroup"
        },
        {
          "id": 3736,
          "shortName": "AdventPartners",
          "longName": "AdventPartners"
        },
        {
          "id": 635,
          "shortName": "Agriculturecapital",
          "longName": "Agriculturecapital"
        },
        {
          "id": 3799,
          "shortName": "Aiga",
          "longName": "Aiga"
        },
        {
          "id": 1454,
          "shortName": "AladinSecuredLending",
          "longName": "AladinSecuredLending"
        },
        {
          "id": 1611,
          "shortName": "AlcovaCapital",
          "longName": "AlcovaCapital"
        },
        {
          "id": 1595,
          "shortName": "AlidadeCapital",
          "longName": "AlidadeCapital"
        },
        {
          "id": 3733,
          "shortName": "AlignmentCredit",
          "longName": "AlignmentCredit"
        },
        {
          "id": 3189,
          "shortName": "AllegiantRealEstateCapital",
          "longName": "AllegiantRealEstateCapital"
        },
        {
          "id": 862,
          "shortName": "Ameritus",
          "longName": "Ameritus"
        },
        {
          "id": 3169,
          "shortName": "AnvilEnergyPartners",
          "longName": "AnvilEnergyPartners"
        },
        {
          "id": 3973,
          "shortName": "AMICAA",
          "longName": "AMICAA"
        },
        {
          "id": 4018,
          "shortName": "Antares",
          "longName": "Antares"
        },
        {
          "id": 1607,
          "shortName": "ArgonneCapitalGroup",
          "longName": "ArgonneCapitalGroup"
        },
        {
          "id": 936,
          "shortName": "Asana",
          "longName": "Asana"
        },
        {
          "id": 721,
          "shortName": "ASICapital",
          "longName": "ASICapital"
        },
        {
          "id": 1453,
          "shortName": "AtlasCapitalGroup",
          "longName": "AtlasCapitalGroup"
        },
        {
          "id": 4045,
          "shortName": "AvantBio",
          "longName": "AvantBio"
        },
        {
          "id": 749,
          "shortName": "BalboaRetail",
          "longName": "BalboaRetail"
        },
        {
          "id": 3865,
          "shortName": "BackcastPartners",
          "longName": "BackcastPartners"
        },
        {
          "id": 3151,
          "shortName": "BanyanStreet",
          "longName": "BanyanStreet"
        },
        {
          "id": 929,
          "shortName": "BasePoint",
          "longName": "BasePoint"
        },
        {
          "id": 1542,
          "shortName": "BasisInvestmentGroup",
          "longName": "BasisInvestmentGroup"
        },
        {
          "id": 3171,
          "shortName": "BayGrove",
          "longName": "BayGrove"
        },
        {
          "id": 893,
          "shortName": "BelridgeCapital",
          "longName": "BelridgeCapital"
        },
        {
          "id": 850,
          "shortName": "BKM",
          "longName": "BKM"
        },
        {
          "id": 944,
          "shortName": "Blackbird",
          "longName": "Blackbird"
        },
        {
          "id": 926,
          "shortName": "BloomfieldCapital",
          "longName": "BloomfieldCapital"
        },
        {
          "id": 3780,
          "shortName": "Bridge",
          "longName": "Bridge"
        },
        {
          "id": 3859,
          "shortName": "BridgeInvest",
          "longName": "BridgeInvest"
        },
        {
          "id": 3758,
          "shortName": "BridgerHoldings",
          "longName": "BridgerHoldings"
        },
        {
          "id": 857,
          "shortName": "BroadMeadow",
          "longName": "BroadMeadow"
        },
        {
          "id": 570,
          "shortName": "Cadence",
          "longName": "Cadence"
        },
        {
          "id": 867,
          "shortName": "CapRidge",
          "longName": "CapRidge"
        },
        {
          "id": 3208,
          "shortName": "CarlyleAviation",
          "longName": "CarlyleAviation"
        },
        {
          "id": 1119,
          "shortName": "Castellan",
          "longName": "Castellan"
        },
        {
          "id": 863,
          "shortName": "CCG",
          "longName": "CCG"
        },
        {
          "id": 865,
          "shortName": "ChambersEnergyCapital-II",
          "longName": "ChambersEnergyCapital-II"
        },
        {
          "id": 864,
          "shortName": "ChambersEnergyCapital-III",
          "longName": "ChambersEnergyCapital-III"
        },
        {
          "id": 3153,
          "shortName": "ChambersEnergyCapital-IV",
          "longName": "ChambersEnergyCapital-IV"
        },
        {
          "id": 869,
          "shortName": "Cielo",
          "longName": "Cielo"
        },
        {
          "id": 3936,
          "shortName": "ChambersMarketingDataroom",
          "longName": "ChambersMarketingDataroom"
        },
        {
          "id": 3794,
          "shortName": "CIM",
          "longName": "CIM"
        },
        {
          "id": 3956,
          "shortName": "CreateHealthVentures",
          "longName": "CreateHealthVentures"
        },
        {
          "id": 3397,
          "shortName": "ConcentricPartners",
          "longName": "ConcentricPartners"
        },
        {
          "id": 3970,
          "shortName": "CloudCapital",
          "longName": "CloudCapital"
        },
        {
          "id": 1121,
          "shortName": "ControlledEnvironmentFoodFund",
          "longName": "ControlledEnvironmentFoodFund"
        },
        {
          "id": 736,
          "shortName": "ConvectorCapital",
          "longName": "ConvectorCapital"
        },
        {
          "id": 4019,
          "shortName": "ConnectVentures",
          "longName": "ConnectVentures"
        },
        {
          "id": 1615,
          "shortName": "CrossHarborCapitalPartners",
          "longName": "CrossHarborCapitalPartners"
        },
        {
          "id": 946,
          "shortName": "DelawareTrust",
          "longName": "DelawareTrust"
        },
        {
          "id": 930,
          "shortName": "Demo",
          "longName": "Demo"
        },
        {
          "id": 4020,
          "shortName": "DynamkCapital",
          "longName": "DynamkCapital"
        },
        {
          "id": 621,
          "shortName": "DiversifiedResidential",
          "longName": "DiversifiedResidential"
        },
        {
          "id": 951,
          "shortName": "DrakeRealEstatePartners",
          "longName": "DrakeRealEstatePartners"
        },
        {
          "id": 3773,
          "shortName": "DwightMortgageTrust",
          "longName": "DwightMortgageTrust"
        },
        {
          "id": 1580,
          "shortName": "EatonVance",
          "longName": "EatonVance"
        },
        {
          "id": 858,
          "shortName": "Eightfold",
          "longName": "Eightfold"
        },
        {
          "id": 931,
          "shortName": "EIIRealty",
          "longName": "EIIRealty"
        },
        {
          "id": 887,
          "shortName": "EmeraldSpringsAsset",
          "longName": "EmeraldSpringsAsset"
        },
        {
          "id": 851,
          "shortName": "EMV",
          "longName": "EMV"
        },
        {
          "id": 3959,
          "shortName": "Endeavor8",
          "longName": "Endeavor8"
        },
        {
          "id": 3735,
          "shortName": "Equilibrium",
          "longName": "Equilibrium"
        },
        {
          "id": 3866,
          "shortName": "FinistereVentures",
          "longName": "FinistereVentures"
        },
        {
          "id": 947,
          "shortName": "FocusHealthcarePartners",
          "longName": "FocusHealthcarePartners"
        },
        {
          "id": 1118,
          "shortName": "FoundationView",
          "longName": "FoundationView"
        },
        {
          "id": 664,
          "shortName": "FourCorners",
          "longName": "FourCorners"
        },
        {
          "id": 3792,
          "shortName": "GCI",
          "longName": "GCI"
        },
        {
          "id": 3801,
          "shortName": "Generic",
          "longName": "Generic"
        },
        {
          "id": 3744,
          "shortName": "GlenfieldCapital",
          "longName": "GlenfieldCapital"
        },
        {
          "id": 1134,
          "shortName": "GlobalWealthGroup",
          "longName": "GlobalWealthGroup"
        },
        {
          "id": 3182,
          "shortName": "GrayCoCapitalAdvisors",
          "longName": "GrayCoCapitalAdvisors"
        },
        {
          "id": 750,
          "shortName": "GreenOak",
          "longName": "GreenOak"
        },
        {
          "id": 3464,
          "shortName": "GreensLedge",
          "longName": "GreensLedge"
        },
        {
          "id": 1139,
          "shortName": "GreyStone",
          "longName": "GreyStone"
        },
        {
          "id": 3187,
          "shortName": "GroupRMC",
          "longName": "GroupRMC"
        },
        {
          "id": 859,
          "shortName": "Grubb",
          "longName": "Grubb"
        },
        {
          "id": 3192,
          "shortName": "HNGreenHollowCapitalPartners",
          "longName": "HNGreenHollowCapitalPartners"
        },
        {
          "id": 3396,
          "shortName": "Icecap",
          "longName": "Icecap"
        },
        {
          "id": 3885,
          "shortName": "IDA",
          "longName": "IDA"
        },
        {
          "id": 1117,
          "shortName": "ImperialCompanies",
          "longName": "ImperialCompanies"
        },
        {
          "id": 3749,
          "shortName": "Inceptiv",
          "longName": "Inceptiv"
        },
        {
          "id": 1449,
          "shortName": "InspiredHealthCareCapital",
          "longName": "InspiredHealthCareCapital"
        },
        {
          "id": 3761,
          "shortName": "IrradiantPartners",
          "longName": "IrradiantPartners"
        },
        {
          "id": 690,
          "shortName": "JCRCapital",
          "longName": "JCRCapital"
        },
        {
          "id": 3803,
          "shortName": "Jefferies",
          "longName": "Jefferies"
        },
        {
          "id": 4046,
          "shortName": "JordanPark",
          "longName": "JordanPark"
        },
        {
          "id": 790,
          "shortName": "JVM",
          "longName": "JVM"
        },
        {
          "id": 3467,
          "shortName": "JWCMCreditOpportunities",
          "longName": "JWCMCreditOpportunities"
        },
        {
          "id": 1585,
          "shortName": "Kandle",
          "longName": "Kandle"
        },
        {
          "id": 3862,
          "shortName": "KendallInvestments",
          "longName": "KendallInvestments"
        },
        {
          "id": 3786,
          "shortName": "Kovitz",
          "longName": "Kovitz"
        },
        {
          "id": 3138,
          "shortName": "KovitzDiversifiedRealEstateFund",
          "longName": "KovitzDiversifiedRealEstateFund"
        },
        {
          "id": 3787,
          "shortName": "KovitzVentureOne",
          "longName": "KovitzVentureOne"
        },
        {
          "id": 3148,
          "shortName": "LaderaCapitalPartners",
          "longName": "LaderaCapitalPartners"
        },
        {
          "id": 856,
          "shortName": "LC",
          "longName": "LC"
        },
        {
          "id": 3937,
          "shortName": "Level5Capital",
          "longName": "Level5Capital"
        },
        {
          "id": 852,
          "shortName": "LFG",
          "longName": "LFG"
        },
        {
          "id": 3986,
          "shortName": "LoneTree",
          "longName": "LoneTree"
        },
        {
          "id": 1120,
          "shortName": "LongPoint",
          "longName": "LongPoint"
        },
        {
          "id": 759,
          "shortName": "M3Ventures",
          "longName": "M3Ventures"
        },
        {
          "id": 1625,
          "shortName": "MaranonCapital",
          "longName": "MaranonCapital"
        },
        {
          "id": 1588,
          "shortName": "MBPCapital",
          "longName": "MBPCapital"
        },
        {
          "id": 3898,
          "shortName": "MerricksCapital",
          "longName": "MerricksCapital"
        },
        {
          "id": 702,
          "shortName": "Momentumrep",
          "longName": "Momentumrep"
        },
        {
          "id": 948,
          "shortName": "Monticello",
          "longName": "Monticello"
        },
        {
          "id": 875,
          "shortName": "Mosaic",
          "longName": "Mosaic"
        },
        {
          "id": 849,
          "shortName": "Nascent",
          "longName": "Nascent"
        },
        {
          "id": 1621,
          "shortName": "NorthBridgePartners",
          "longName": "NorthBridgePartners"
        },
        {
          "id": 3889,
          "shortName": "Novirian",
          "longName": "Novirian"
        },
        {
          "id": 890,
          "shortName": "OakStreet",
          "longName": "OakStreet"
        },
        {
          "id": 3952,
          "shortName": "OceanAvenueCapitalPartners",
          "longName": "OceanAvenueCapitalPartners"
        },
        {
          "id": 954,
          "shortName": "OFSCapital",
          "longName": "OFSCapital"
        },
        {
          "id": 3863,
          "shortName": "OmegaCapital",
          "longName": "OmegaCapital"
        },
        {
          "id": 3727,
          "shortName": "Panagram",
          "longName": "Panagram"
        },
        {
          "id": 1594,
          "shortName": "PaxionCapital",
          "longName": "PaxionCapital"
        },
        {
          "id": 892,
          "shortName": "PenderCapital",
          "longName": "PenderCapital"
        },
        {
          "id": 941,
          "shortName": "PennybackerCapital",
          "longName": "PennybackerCapital"
        },
        {
          "id": 1597,
          "shortName": "PiperJaffray",
          "longName": "PiperJaffray"
        },
        {
          "id": 3864,
          "shortName": "Point406Ventures",
          "longName": "Point406Ventures"
        },
        {
          "id": 653,
          "shortName": "Procida",
          "longName": "Procida"
        },
        {
          "id": 3469,
          "shortName": "Qualitas",
          "longName": "Qualitas"
        },
        {
          "id": 669,
          "shortName": "QuiksilverHotels",
          "longName": "QuiksilverHotels"
        },
        {
          "id": 648,
          "shortName": "Raith",
          "longName": "Raith"
        },
        {
          "id": 868,
          "shortName": "Rambleside",
          "longName": "Rambleside"
        },
        {
          "id": 1606,
          "shortName": "RDGFunds",
          "longName": "RDGFunds"
        },
        {
          "id": 1602,
          "shortName": "RedburnRealEstatePartners",
          "longName": "RedburnRealEstatePartners"
        },
        {
          "id": 1591,
          "shortName": "RegentProperties",
          "longName": "RegentProperties"
        },
        {
          "id": 3193,
          "shortName": "RevOZCapital",
          "longName": "RevOZCapital"
        },
        {
          "id": 566,
          "shortName": "ROCBridge",
          "longName": "ROCBridge"
        },
        {
          "id": 1124,
          "shortName": "RRACapital",
          "longName": "RRACapital"
        },
        {
          "id": 3890,
          "shortName": "RuckerPark",
          "longName": "RuckerPark"
        },
        {
          "id": 895,
          "shortName": "RTMFund2",
          "longName": "RTMFund2"
        },
        {
          "id": 3155,
          "shortName": "RunwayGrowthCreditFund",
          "longName": "RunwayGrowthCreditFund"
        },
        {
          "id": 3387,
          "shortName": "RunwayGrowthFinance",
          "longName": "RunwayGrowthFinance"
        },
        {
          "id": 3757,
          "shortName": "RunwayGrowthFinanceCorp",
          "longName": "RunwayGrowthFinanceCorp"
        },
        {
          "id": 942,
          "shortName": "Salmanson",
          "longName": "Salmanson"
        },
        {
          "id": 3878,
          "shortName": "S3Capital",
          "longName": "S3Capital"
        },
        {
          "id": 3206,
          "shortName": "SamasFundingPACE",
          "longName": "SamasFundingPACE"
        },
        {
          "id": 3459,
          "shortName": "SDSCapitalGroup",
          "longName": "SDSCapitalGroup"
        },
        {
          "id": 3154,
          "shortName": "SewardCapital",
          "longName": "SewardCapital"
        },
        {
          "id": 861,
          "shortName": "SeymourNexusPartners",
          "longName": "SeymourNexusPartners"
        },
        {
          "id": 3897,
          "shortName": "SFEquityPartners",
          "longName": "SFEquityPartners"
        },
        {
          "id": 3453,
          "shortName": "SFLParkway",
          "longName": "SFLParkway"
        },
        {
          "id": 3797,
          "shortName": "Silverview",
          "longName": "Silverview"
        },
        {
          "id": 567,
          "shortName": "Singerman",
          "longName": "Singerman"
        },
        {
          "id": 955,
          "shortName": "StellusCapital",
          "longName": "StellusCapital"
        },
        {
          "id": 655,
          "shortName": "SterlingBay",
          "longName": "SterlingBay"
        },
        {
          "id": 853,
          "shortName": "StoneHill",
          "longName": "StoneHill"
        },
        {
          "id": 1620,
          "shortName": "SummitRealEstateGroup",
          "longName": "SummitRealEstateGroup"
        },
        {
          "id": 4050,
          "shortName": "SymbioticCapital",
          "longName": "SymbioticCapital"
        },
        {
          "id": 668,
          "shortName": "ThorofareCapital",
          "longName": "ThorofareCapital"
        },
        {
          "id": 894,
          "shortName": "TitanPartners",
          "longName": "TitanPartners"
        },
        {
          "id": 629,
          "shortName": "Transwestern",
          "longName": "Transwestern"
        },
        {
          "id": 678,
          "shortName": "TrueNorth",
          "longName": "TrueNorth"
        },
        {
          "id": 4014,
          "shortName": "TwoRoadsPartners",
          "longName": "TwoRoadsPartners"
        },
        {
          "id": 939,
          "shortName": "Varagon",
          "longName": "Varagon"
        },
        {
          "id": 3894,
          "shortName": "ValidusCapital",
          "longName": "ValidusCapital"
        },
        {
          "id": 3214,
          "shortName": "Ventas",
          "longName": "Ventas"
        },
        {
          "id": 709,
          "shortName": "VikingPartners",
          "longName": "VikingPartners"
        },
        {
          "id": 569,
          "shortName": "Virtus",
          "longName": "Virtus"
        },
        {
          "id": 677,
          "shortName": "WastewaterCapital",
          "longName": "WastewaterCapital"
        },
        {
          "id": 4047,
          "shortName": "WaltonStreetCapital",
          "longName": "WaltonStreetCapital"
        },
        {
          "id": 1507,
          "shortName": "Waterton",
          "longName": "Waterton"
        },
        {
          "id": 3974,
          "shortName": "WayPointCapitalPartners",
          "longName": "WayPointCapitalPartners"
        },
        {
          "id": 3777,
          "shortName": "West",
          "longName": "West"
        },
        {
          "id": 848,
          "shortName": "WestHill",
          "longName": "WestHill"
        },
        {
          "id": 1614,
          "shortName": "WillowCreekCredit",
          "longName": "WillowCreekCredit"
        },
        {
          "id": 1630,
          "shortName": "WillowTreeCreditPartners",
          "longName": "WillowTreeCreditPartners"
        },
        {
          "id": 860,
          "shortName": "Wolff",
          "longName": "Wolff"
        },
        {
          "id": 3845,
          "shortName": "WoodbridgeCapital",
          "longName": "WoodbridgeCapital"
        },
        {
          "id": 4076,
          "shortName": "shawn",
          "longName": "shawn"
        }
      ],
      23: [
        {
          "id": 671,
          "shortName": "NA",
          "longName": "NA"
        },
        {
          "id": 672,
          "shortName": "To",
          "longName": "To"
        },
        {
          "id": 673,
          "shortName": "CC",
          "longName": "CC"
        },
        {
          "id": 674,
          "shortName": "BCC",
          "longName": "BCC"
        }
      ],
      25: [
        {
          "id": 982,
          "shortName": "Corporation",
          "longName": "Corporation"
        },
        {
          "id": 983,
          "shortName": "DBPP",
          "longName": "DBPP"
        },
        {
          "id": 984,
          "shortName": "Employee Benefit Plan",
          "longName": "Employee Benefit Plan"
        },
        {
          "id": 985,
          "shortName": "Endowment / Foundation",
          "longName": "Endowment / Foundation"
        },
        {
          "id": 986,
          "shortName": "Estate",
          "longName": "Estate"
        },
        {
          "id": 987,
          "shortName": "Exempt Organization",
          "longName": "Exempt Organization"
        },
        {
          "id": 988,
          "shortName": "Foundation",
          "longName": "Foundation"
        },
        {
          "id": 989,
          "shortName": "Fund",
          "longName": "Fund"
        },
        {
          "id": 990,
          "shortName": "Government Pension Plan",
          "longName": "Government Pension Plan"
        },
        {
          "id": 991,
          "shortName": "IRA",
          "longName": "IRA"
        },
        {
          "id": 992,
          "shortName": "Incorporated",
          "longName": "Incorporated"
        },
        {
          "id": 993,
          "shortName": "Individual",
          "longName": "Individual"
        },
        {
          "id": 994,
          "shortName": "JTWROS",
          "longName": "JTWROS"
        },
        {
          "id": 995,
          "shortName": "Joint Tenants",
          "longName": "Joint Tenants"
        },
        {
          "id": 996,
          "shortName": "LLC",
          "longName": "LLC"
        },
        {
          "id": 997,
          "shortName": "Limited Partnership",
          "longName": "Limited Partnership"
        },
        {
          "id": 998,
          "shortName": "Ltd.",
          "longName": "Ltd."
        },
        {
          "id": 999,
          "shortName": "Non-Profit",
          "longName": "Non-Profit"
        },
        {
          "id": 1000,
          "shortName": "PSP",
          "longName": "PSP"
        },
        {
          "id": 1001,
          "shortName": "Partnership",
          "longName": "Partnership"
        },
        {
          "id": 1002,
          "shortName": "Pension Plan",
          "longName": "Pension Plan"
        },
        {
          "id": 1003,
          "shortName": "SEP IRA",
          "longName": "SEP IRA"
        },
        {
          "id": 1004,
          "shortName": "Self-Directed Pension Plan",
          "longName": "Self-Directed Pension Plan"
        },
        {
          "id": 1005,
          "shortName": "Tax Exempt Trust",
          "longName": "Tax Exempt Trust"
        },
        {
          "id": 1006,
          "shortName": "Tenants in Common",
          "longName": "Tenants in Common"
        },
        {
          "id": 1007,
          "shortName": "Trust",
          "longName": "Trust"
        },
        {
          "id": 1590,
          "shortName": "Individual IRA",
          "longName": "Individual IRA"
        },
        {
          "id": 1008,
          "shortName": "Other",
          "longName": "Other"
        },
        {
          "id": 1073,
          "shortName": "Spouses, as community property",
          "longName": "Spouses, as community property"
        },
        {
          "id": 1074,
          "shortName": "401k",
          "longName": "401k"
        }
      ],
      27: [
        {
          "id": 1081,
          "shortName": "(a) Individuals that are United States persons (including their trusts)",
          "longName": "(a) Individuals that are United States persons (including their trusts)"
        },
        {
          "id": 1076,
          "shortName": "(b) Individuals that are not United States persons (including their trusts)",
          "longName": "(b) Individuals that are not United States persons (including their trusts)"
        },
        {
          "id": 3980,
          "shortName": "(c) Broker-dealers",
          "longName": "(c) Broker-dealers"
        },
        {
          "id": 1085,
          "shortName": "(d) Insurance companies",
          "longName": "(d) Insurance companies"
        },
        {
          "id": 1078,
          "shortName": "(e) Investment companies registered with the SEC",
          "longName": "(e) Investment companies registered with the SEC"
        },
        {
          "id": 1075,
          "shortName": "(f) Private funds",
          "longName": "(f) Private funds"
        },
        {
          "id": 1083,
          "shortName": "(g) Non-profits",
          "longName": "(g) Non-profits"
        },
        {
          "id": 1082,
          "shortName": "(h) Pension plans (excluding governmental pension plans)",
          "longName": "(h) Pension plans (excluding governmental pension plans)"
        },
        {
          "id": 3981,
          "shortName": "(i) Banking or thrift institutions (proprietary)",
          "longName": "(i) Banking or thrift institutions (proprietary)"
        },
        {
          "id": 1079,
          "shortName": "(j) State or municipal government entities (excluding governmental pension plans)",
          "longName": "(j) State or municipal government entities (excluding governmental pension plans)"
        },
        {
          "id": 1080,
          "shortName": "(k) State or municipal governmental pension plans",
          "longName": "(k) State or municipal governmental pension plans"
        },
        {
          "id": 3982,
          "shortName": "(l) Sovereign wealth funds and foreign official institutions",
          "longName": "(l) Sovereign wealth funds and foreign official institutions"
        },
        {
          "id": 1084,
          "shortName": "(n) Other",
          "longName": "(n) Other"
        },
        {
          "id": 1077,
          "shortName": "Unknown",
          "longName": "Unknown"
        },
        {
          "id": 3979,
          "shortName": "N/A",
          "longName": "N/A"
        },
        {
          "id": 3984,
          "shortName": "(m) Investors that are not United States persons and about which the foregoing beneficial ownership information is not known and cannot reasonably be obtained because the beneficial interest is held through a chain involving one or more third-party intermediaries",
          "longName": "(m) Investors that are not United States persons and about which the foregoing beneficial ownership information is not known and cannot reasonably be obtained because the beneficial interest is held through a chain involving one or more third-party intermediaries"
        }
      ],
      28: [
        {
          "id": 1064,
          "shortName": "Business",
          "longName": "Business"
        },
        {
          "id": 1065,
          "shortName": "Home",
          "longName": "Home"
        },
        {
          "id": 1066,
          "shortName": "Other",
          "longName": "Other"
        },
        {
          "id": 1067,
          "shortName": "Special Instructions",
          "longName": "Special Instructions"
        },
        {
          "id": 1116,
          "shortName": "(blank)",
          "longName": "(blank)"
        }
      ],
      31: [
        {
          "id": 959,
          "shortName": "Quarterly",
          "longName": "Quarterly"
        },
        {
          "id": 960,
          "shortName": "Reinvest",
          "longName": "Reinvest"
        },
        {
          "id": 1610,
          "shortName": "Non ROC",
          "longName": "Non ROC"
        },
        {
          "id": 961,
          "shortName": "(Blank)",
          "longName": "(Blank)"
        },
        {
          "id": 3935,
          "shortName": "0",
          "longName": "0"
        }
      ],
      35: [
        {
          "id": 1009,
          "shortName": "(blank)",
          "longName": "(blank)"
        },
        {
          "id": 1010,
          "shortName": "ACH",
          "longName": "ACH"
        },
        {
          "id": 1011,
          "shortName": "Check",
          "longName": "Check"
        },
        {
          "id": 1012,
          "shortName": "Direct Deposit",
          "longName": "Direct Deposit"
        },
        {
          "id": 1013,
          "shortName": "Reinvest",
          "longName": "Reinvest"
        },
        {
          "id": 1014,
          "shortName": "Reinvest/Wire",
          "longName": "Reinvest/Wire"
        },
        {
          "id": 1015,
          "shortName": "Transfer",
          "longName": "Transfer"
        },
        {
          "id": 1016,
          "shortName": "Wire",
          "longName": "Wire"
        },
        {
          "id": 1017,
          "shortName": "Wire - Fidelity",
          "longName": "Wire - Fidelity"
        },
        {
          "id": 1018,
          "shortName": "Wire - Millennium",
          "longName": "Wire - Millennium"
        },
        {
          "id": 3170,
          "shortName": "Wire - Pershing",
          "longName": "Wire - Pershing"
        },
        {
          "id": 1019,
          "shortName": "Wire - Schwab - Custody",
          "longName": "Wire - Schwab - Custody"
        },
        {
          "id": 1020,
          "shortName": "Wire - Schwab - Non-custody",
          "longName": "Wire - Schwab - Non-custody"
        },
        {
          "id": 1125,
          "shortName": "Wire - TD",
          "longName": "Wire - TD"
        }
      ],
      38: [
        {
          "id": 973,
          "shortName": "(Blank]",
          "longName": "(Blank]"
        },
        {
          "id": 974,
          "shortName": "W-9",
          "longName": "W-9"
        },
        {
          "id": 975,
          "shortName": "W-8",
          "longName": "W-8"
        }
      ],
      47: [
        {
          "id": 1068,
          "shortName": "All",
          "longName": "All"
        },
        {
          "id": 1069,
          "shortName": "Business",
          "longName": "Business"
        },
        {
          "id": 1070,
          "shortName": "Home",
          "longName": "Home"
        },
        {
          "id": 1071,
          "shortName": "None - No Paper Statements",
          "longName": "None - No Paper Statements"
        },
        {
          "id": 1072,
          "shortName": "Other",
          "longName": "Other"
        },
        {
          "id": 1589,
          "shortName": "Secondary",
          "longName": "Secondary"
        }
      ],
      49: [
        {
          "id": 1136,
          "shortName": "Unitized",
          "longName": "Unitized"
        },
        {
          "id": 1138,
          "shortName": "Capital Balance",
          "longName": "Capital Balance"
        }
      ],
      58: [
        {
          "id": 1504,
          "shortName": "Intellimatch",
          "longName": "Intellimatch"
        },
        {
          "id": 1505,
          "shortName": "Swift",
          "longName": "Swift"
        }
      ],
      63: [
        {
          "id": 1532,
          "shortName": "sFTP-Cortland",
          "longName": "sFTP-Cortland"
        },
        {
          "id": 1533,
          "shortName": "FTP-Bank",
          "longName": "FTP-Bank"
        },
        {
          "id": 1534,
          "shortName": "G-Treasury",
          "longName": "G-Treasury"
        },
        {
          "id": 1535,
          "shortName": "SWIFT",
          "longName": "SWIFT"
        },
        {
          "id": 1536,
          "shortName": "Web Site",
          "longName": "Web Site"
        },
        {
          "id": 1537,
          "shortName": "Manual",
          "longName": "Manual"
        },
        {
          "id": 1538,
          "shortName": "Other",
          "longName": "Other"
        }
      ],
      64: [
        {
          "id": 1539,
          "shortName": "BAI",
          "longName": "BAI"
        },
        {
          "id": 1540,
          "shortName": "BAI2",
          "longName": "BAI2"
        },
        {
          "id": 1541,
          "shortName": "CSV",
          "longName": "CSV"
        },
        {
          "id": 1543,
          "shortName": "MT 940",
          "longName": "MT 940"
        },
        {
          "id": 1544,
          "shortName": "Excel",
          "longName": "Excel"
        },
        {
          "id": 1545,
          "shortName": "Other",
          "longName": "Other"
        }
      ],
      65: [
        {
          "id": 1546,
          "shortName": "Yes",
          "longName": "Yes"
        },
        {
          "id": 1547,
          "shortName": "No",
          "longName": "No"
        }
      ],
      66: [
        {
          "id": 1548,
          "shortName": "Yes",
          "longName": "Yes"
        },
        {
          "id": 1549,
          "shortName": "No",
          "longName": "No"
        }
      ],
      67: [
        {
          "id": 1550,
          "shortName": "Checking",
          "longName": "Checking"
        },
        {
          "id": 1551,
          "shortName": "Savings",
          "longName": "Savings"
        },
        {
          "id": 1552,
          "shortName": "Broker",
          "longName": "Broker"
        },
        {
          "id": 1553,
          "shortName": "Operating Account",
          "longName": "Operating Account"
        },
        {
          "id": 1554,
          "shortName": "Subscription Account",
          "longName": "Subscription Account"
        }
      ],
      72: [
        {
          "id": 1651,
          "shortName": "Aruba",
          "longName": "Aruba"
        },
        {
          "id": 1652,
          "shortName": "Afghanistan",
          "longName": "Afghanistan"
        },
        {
          "id": 1653,
          "shortName": "Angola",
          "longName": "Angola"
        },
        {
          "id": 1654,
          "shortName": "Anguilla",
          "longName": "Anguilla"
        },
        {
          "id": 1655,
          "shortName": "Aland Islands",
          "longName": "Aland Islands"
        },
        {
          "id": 1656,
          "shortName": "Albania",
          "longName": "Albania"
        },
        {
          "id": 1657,
          "shortName": "Andorra",
          "longName": "Andorra"
        },
        {
          "id": 1658,
          "shortName": "United Arab Emirates",
          "longName": "United Arab Emirates"
        },
        {
          "id": 1659,
          "shortName": "Argentina",
          "longName": "Argentina"
        },
        {
          "id": 1660,
          "shortName": "Armenia",
          "longName": "Armenia"
        },
        {
          "id": 1661,
          "shortName": "American Samoa",
          "longName": "American Samoa"
        },
        {
          "id": 1662,
          "shortName": "Antarctica",
          "longName": "Antarctica"
        },
        {
          "id": 1663,
          "shortName": "French Southern Territories",
          "longName": "French Southern Territories"
        },
        {
          "id": 1664,
          "shortName": "Antigua and Barbuda",
          "longName": "Antigua and Barbuda"
        },
        {
          "id": 1665,
          "shortName": "Australia",
          "longName": "Australia"
        },
        {
          "id": 1666,
          "shortName": "Austria",
          "longName": "Austria"
        },
        {
          "id": 1667,
          "shortName": "Azerbaijan",
          "longName": "Azerbaijan"
        },
        {
          "id": 1668,
          "shortName": "Burundi",
          "longName": "Burundi"
        },
        {
          "id": 1669,
          "shortName": "Belgium",
          "longName": "Belgium"
        },
        {
          "id": 1670,
          "shortName": "Benin",
          "longName": "Benin"
        },
        {
          "id": 1672,
          "shortName": "Burkina Faso",
          "longName": "Burkina Faso"
        },
        {
          "id": 1673,
          "shortName": "Bangladesh",
          "longName": "Bangladesh"
        },
        {
          "id": 1674,
          "shortName": "Bulgaria",
          "longName": "Bulgaria"
        },
        {
          "id": 1675,
          "shortName": "Bahrain",
          "longName": "Bahrain"
        },
        {
          "id": 1676,
          "shortName": "Bahamas",
          "longName": "Bahamas"
        },
        {
          "id": 1677,
          "shortName": "Bosnia and Herzegovina",
          "longName": "Bosnia and Herzegovina"
        },
        {
          "id": 1678,
          "shortName": "Saint-Barthelemy",
          "longName": "Saint-Barthelemy"
        },
        {
          "id": 1679,
          "shortName": "Belarus",
          "longName": "Belarus"
        },
        {
          "id": 1680,
          "shortName": "Belize",
          "longName": "Belize"
        },
        {
          "id": 1681,
          "shortName": "Bermuda",
          "longName": "Bermuda"
        },
        {
          "id": 1682,
          "shortName": "Bolivia",
          "longName": "Bolivia"
        },
        {
          "id": 1683,
          "shortName": "Brazil",
          "longName": "Brazil"
        },
        {
          "id": 1684,
          "shortName": "Barbados",
          "longName": "Barbados"
        },
        {
          "id": 1685,
          "shortName": "Brunei Darussalam",
          "longName": "Brunei Darussalam"
        },
        {
          "id": 1686,
          "shortName": "Bhutan",
          "longName": "Bhutan"
        },
        {
          "id": 1687,
          "shortName": "Bouvet Island",
          "longName": "Bouvet Island"
        },
        {
          "id": 1688,
          "shortName": "Botswana",
          "longName": "Botswana"
        },
        {
          "id": 1689,
          "shortName": "Central African Republic",
          "longName": "Central African Republic"
        },
        {
          "id": 1690,
          "shortName": "Canada",
          "longName": "Canada"
        },
        {
          "id": 1691,
          "shortName": "Cocos (Keeling) Islands",
          "longName": "Cocos (Keeling) Islands"
        },
        {
          "id": 1692,
          "shortName": "Switzerland",
          "longName": "Switzerland"
        },
        {
          "id": 1693,
          "shortName": "Chile",
          "longName": "Chile"
        },
        {
          "id": 1694,
          "shortName": "China",
          "longName": "China"
        },
        {
          "id": 1695,
          "shortName": "Ivory Coast",
          "longName": "Ivory Coast"
        },
        {
          "id": 1696,
          "shortName": "Cameroon",
          "longName": "Cameroon"
        },
        {
          "id": 1697,
          "shortName": "Congo, Democratic Republic of the",
          "longName": "Congo, Democratic Republic of the"
        },
        {
          "id": 1698,
          "shortName": "Congo (Brazzaville)",
          "longName": "Congo (Brazzaville)"
        },
        {
          "id": 1699,
          "shortName": "Cook Islands",
          "longName": "Cook Islands"
        },
        {
          "id": 1700,
          "shortName": "Colombia",
          "longName": "Colombia"
        },
        {
          "id": 1701,
          "shortName": "Comoros",
          "longName": "Comoros"
        },
        {
          "id": 1702,
          "shortName": "Cape Verde",
          "longName": "Cape Verde"
        },
        {
          "id": 1703,
          "shortName": "Costa Rica",
          "longName": "Costa Rica"
        },
        {
          "id": 1704,
          "shortName": "Cuba",
          "longName": "Cuba"
        },
        {
          "id": 1705,
          "shortName": "Curacao",
          "longName": "Curacao"
        },
        {
          "id": 1706,
          "shortName": "Christmas Island",
          "longName": "Christmas Island"
        },
        {
          "id": 1707,
          "shortName": "Cayman Islands",
          "longName": "Cayman Islands"
        },
        {
          "id": 1708,
          "shortName": "Cyprus",
          "longName": "Cyprus"
        },
        {
          "id": 1709,
          "shortName": "Czech Republic",
          "longName": "Czechia"
        },
        {
          "id": 1710,
          "shortName": "Germany",
          "longName": "Germany"
        },
        {
          "id": 1711,
          "shortName": "Djibouti",
          "longName": "Djibouti"
        },
        {
          "id": 1712,
          "shortName": "Dominica",
          "longName": "Dominica"
        },
        {
          "id": 1713,
          "shortName": "Denmark",
          "longName": "Denmark"
        },
        {
          "id": 1714,
          "shortName": "Dominican Republic",
          "longName": "Dominican Republic"
        },
        {
          "id": 1715,
          "shortName": "Algeria",
          "longName": "Algeria"
        },
        {
          "id": 1716,
          "shortName": "Ecuador",
          "longName": "Ecuador"
        },
        {
          "id": 1717,
          "shortName": "Egypt",
          "longName": "Egypt"
        },
        {
          "id": 1718,
          "shortName": "Eritrea",
          "longName": "Eritrea"
        },
        {
          "id": 1719,
          "shortName": "Western Sahara",
          "longName": "Western Sahara"
        },
        {
          "id": 1720,
          "shortName": "Spain",
          "longName": "Spain"
        },
        {
          "id": 1721,
          "shortName": "Estonia",
          "longName": "Estonia"
        },
        {
          "id": 1722,
          "shortName": "Ethiopia",
          "longName": "Ethiopia"
        },
        {
          "id": 1723,
          "shortName": "Finland",
          "longName": "Finland"
        },
        {
          "id": 1724,
          "shortName": "Fiji",
          "longName": "Fiji"
        },
        {
          "id": 1725,
          "shortName": "Falkland Islands (Malvinas)",
          "longName": "Falkland Islands (Malvinas)"
        },
        {
          "id": 1726,
          "shortName": "France",
          "longName": "France"
        },
        {
          "id": 1727,
          "shortName": "Faroe Islands",
          "longName": "Faroe Islands"
        },
        {
          "id": 1728,
          "shortName": "Micronesia, Federated States of",
          "longName": "Micronesia, Federated States of"
        },
        {
          "id": 1729,
          "shortName": "Gabon",
          "longName": "Gabon"
        },
        {
          "id": 1730,
          "shortName": "United Kingdom",
          "longName": "United Kingdom"
        },
        {
          "id": 1731,
          "shortName": "Georgia",
          "longName": "Georgia"
        },
        {
          "id": 1732,
          "shortName": "Guernsey",
          "longName": "Guernsey"
        },
        {
          "id": 1733,
          "shortName": "Ghana",
          "longName": "Ghana"
        },
        {
          "id": 1734,
          "shortName": "Gibraltar",
          "longName": "Gibraltar"
        },
        {
          "id": 1735,
          "shortName": "Guinea",
          "longName": "Guinea"
        },
        {
          "id": 1736,
          "shortName": "Guadeloupe",
          "longName": "Guadeloupe"
        },
        {
          "id": 1737,
          "shortName": "Gambia",
          "longName": "Gambia"
        },
        {
          "id": 1738,
          "shortName": "Guinea-Bissau",
          "longName": "Guinea-Bissau"
        },
        {
          "id": 1739,
          "shortName": "Equatorial Guinea",
          "longName": "Equatorial Guinea"
        },
        {
          "id": 1740,
          "shortName": "Greece",
          "longName": "Greece"
        },
        {
          "id": 1741,
          "shortName": "Grenada",
          "longName": "Grenada"
        },
        {
          "id": 1742,
          "shortName": "Greenland",
          "longName": "Greenland"
        },
        {
          "id": 1743,
          "shortName": "Guatemala",
          "longName": "Guatemala"
        },
        {
          "id": 1744,
          "shortName": "French Guiana",
          "longName": "French Guiana"
        },
        {
          "id": 1745,
          "shortName": "Guam",
          "longName": "Guam"
        },
        {
          "id": 1746,
          "shortName": "Guyana",
          "longName": "Guyana"
        },
        {
          "id": 1747,
          "shortName": "Hong Kong, Special Administrative Region of China",
          "longName": "Hong Kong, Special Administrative Region of China"
        },
        {
          "id": 1748,
          "shortName": "Heard Island and Mcdonald Islands",
          "longName": "Heard Island and Mcdonald Islands"
        },
        {
          "id": 1749,
          "shortName": "Honduras",
          "longName": "Honduras"
        },
        {
          "id": 1750,
          "shortName": "Croatia",
          "longName": "Croatia"
        },
        {
          "id": 1751,
          "shortName": "Haiti",
          "longName": "Haiti"
        },
        {
          "id": 1752,
          "shortName": "Hungary",
          "longName": "Hungary"
        },
        {
          "id": 1753,
          "shortName": "Indonesia",
          "longName": "Indonesia"
        },
        {
          "id": 1754,
          "shortName": "Isle of Man",
          "longName": "Isle of Man"
        },
        {
          "id": 1755,
          "shortName": "India",
          "longName": "India"
        },
        {
          "id": 1756,
          "shortName": "British Indian Ocean Territory",
          "longName": "British Indian Ocean Territory"
        },
        {
          "id": 1757,
          "shortName": "Ireland",
          "longName": "Ireland"
        },
        {
          "id": 1758,
          "shortName": "Iran, Islamic Republic of",
          "longName": "Iran, Islamic Republic of"
        },
        {
          "id": 1759,
          "shortName": "Iraq",
          "longName": "Iraq"
        },
        {
          "id": 1760,
          "shortName": "Iceland",
          "longName": "Iceland"
        },
        {
          "id": 1761,
          "shortName": "Israel",
          "longName": "Israel"
        },
        {
          "id": 1762,
          "shortName": "Italy",
          "longName": "Italy"
        },
        {
          "id": 1763,
          "shortName": "Jamaica",
          "longName": "Jamaica"
        },
        {
          "id": 1764,
          "shortName": "Jersey",
          "longName": "Jersey"
        },
        {
          "id": 1765,
          "shortName": "Jordan",
          "longName": "Jordan"
        },
        {
          "id": 1766,
          "shortName": "Japan",
          "longName": "Japan"
        },
        {
          "id": 1767,
          "shortName": "Kazakhstan",
          "longName": "Kazakhstan"
        },
        {
          "id": 1768,
          "shortName": "Kenya",
          "longName": "Kenya"
        },
        {
          "id": 1769,
          "shortName": "Kyrgyzstan",
          "longName": "Kyrgyzstan"
        },
        {
          "id": 1770,
          "shortName": "Cambodia",
          "longName": "Cambodia"
        },
        {
          "id": 1771,
          "shortName": "Kiribati",
          "longName": "Kiribati"
        },
        {
          "id": 1772,
          "shortName": "Saint Kitts and Nevis",
          "longName": "Saint Kitts and Nevis"
        },
        {
          "id": 1773,
          "shortName": "Korea, Republic of",
          "longName": "Korea, Republic of"
        },
        {
          "id": 1774,
          "shortName": "Kuwait",
          "longName": "Kuwait"
        },
        {
          "id": 1775,
          "shortName": "Lao People's Democratic Republic",
          "longName": "Lao People's Democratic Republic"
        },
        {
          "id": 1776,
          "shortName": "Lebanon",
          "longName": "Lebanon"
        },
        {
          "id": 1777,
          "shortName": "Liberia",
          "longName": "Liberia"
        },
        {
          "id": 1778,
          "shortName": "Libya",
          "longName": "Libya"
        },
        {
          "id": 1779,
          "shortName": "Saint Lucia",
          "longName": "Saint Lucia"
        },
        {
          "id": 1780,
          "shortName": "Liechtenstein",
          "longName": "Liechtenstein"
        },
        {
          "id": 1781,
          "shortName": "Sri Lanka",
          "longName": "Sri Lanka"
        },
        {
          "id": 1782,
          "shortName": "Lesotho",
          "longName": "Lesotho"
        },
        {
          "id": 1783,
          "shortName": "Lithuania",
          "longName": "Lithuania"
        },
        {
          "id": 1784,
          "shortName": "Luxembourg",
          "longName": "Luxembourg"
        },
        {
          "id": 1785,
          "shortName": "Latvia",
          "longName": "Latvia"
        },
        {
          "id": 1786,
          "shortName": "Macao, Special Administrative Region of China",
          "longName": "Macao, Special Administrative Region of China"
        },
        {
          "id": 1787,
          "shortName": "Saint-Martin (French part)",
          "longName": "Saint-Martin (French part)"
        },
        {
          "id": 1788,
          "shortName": "Morocco",
          "longName": "Morocco"
        },
        {
          "id": 1789,
          "shortName": "Monaco",
          "longName": "Monaco"
        },
        {
          "id": 1790,
          "shortName": "Moldova",
          "longName": "Moldova"
        },
        {
          "id": 1791,
          "shortName": "Madagascar",
          "longName": "Madagascar"
        },
        {
          "id": 1792,
          "shortName": "Maldives",
          "longName": "Maldives"
        },
        {
          "id": 1793,
          "shortName": "Mexico",
          "longName": "Mexico"
        },
        {
          "id": 1794,
          "shortName": "Marshall Islands",
          "longName": "Marshall Islands"
        },
        {
          "id": 1795,
          "shortName": "Macedonia, Republic of",
          "longName": "Macedonia, Republic of"
        },
        {
          "id": 1796,
          "shortName": "Mali",
          "longName": "Mali"
        },
        {
          "id": 1797,
          "shortName": "Malta",
          "longName": "Malta"
        },
        {
          "id": 1798,
          "shortName": "Myanmar",
          "longName": "Myanmar"
        },
        {
          "id": 1799,
          "shortName": "Montenegro",
          "longName": "Montenegro"
        },
        {
          "id": 1800,
          "shortName": "Mongolia",
          "longName": "Mongolia"
        },
        {
          "id": 1801,
          "shortName": "Northern Mariana Islands",
          "longName": "Northern Mariana Islands"
        },
        {
          "id": 1802,
          "shortName": "Mozambique",
          "longName": "Mozambique"
        },
        {
          "id": 1803,
          "shortName": "Mauritania",
          "longName": "Mauritania"
        },
        {
          "id": 1804,
          "shortName": "Montserrat",
          "longName": "Montserrat"
        },
        {
          "id": 1805,
          "shortName": "Martinique",
          "longName": "Martinique"
        },
        {
          "id": 1806,
          "shortName": "Mauritius",
          "longName": "Mauritius"
        },
        {
          "id": 1807,
          "shortName": "Malawi",
          "longName": "Malawi"
        },
        {
          "id": 1808,
          "shortName": "Malaysia",
          "longName": "Malaysia"
        },
        {
          "id": 1809,
          "shortName": "Mayotte",
          "longName": "Mayotte"
        },
        {
          "id": 1810,
          "shortName": "Namibia",
          "longName": "Namibia"
        },
        {
          "id": 1811,
          "shortName": "New Caledonia",
          "longName": "New Caledonia"
        },
        {
          "id": 1812,
          "shortName": "Niger",
          "longName": "Niger"
        },
        {
          "id": 1813,
          "shortName": "Norfolk Island",
          "longName": "Norfolk Island"
        },
        {
          "id": 1814,
          "shortName": "Nigeria",
          "longName": "Nigeria"
        },
        {
          "id": 1815,
          "shortName": "Nicaragua",
          "longName": "Nicaragua"
        },
        {
          "id": 1816,
          "shortName": "Niue",
          "longName": "Niue"
        },
        {
          "id": 1817,
          "shortName": "Netherlands",
          "longName": "Netherlands"
        },
        {
          "id": 1818,
          "shortName": "Norway",
          "longName": "Norway"
        },
        {
          "id": 1819,
          "shortName": "Nepal",
          "longName": "Nepal"
        },
        {
          "id": 1820,
          "shortName": "Nauru",
          "longName": "Nauru"
        },
        {
          "id": 1821,
          "shortName": "New Zealand",
          "longName": "New Zealand"
        },
        {
          "id": 1822,
          "shortName": "Oman",
          "longName": "Oman"
        },
        {
          "id": 1823,
          "shortName": "Pakistan",
          "longName": "Pakistan"
        },
        {
          "id": 1824,
          "shortName": "Panama",
          "longName": "Panama"
        },
        {
          "id": 1825,
          "shortName": "Pitcairn",
          "longName": "Pitcairn"
        },
        {
          "id": 1826,
          "shortName": "Peru",
          "longName": "Peru"
        },
        {
          "id": 1827,
          "shortName": "Philippines",
          "longName": "Philippines"
        },
        {
          "id": 1828,
          "shortName": "Palau, Republic of",
          "longName": "Palau, Republic of"
        },
        {
          "id": 1829,
          "shortName": "Papua New Guinea",
          "longName": "Papua New Guinea"
        },
        {
          "id": 1830,
          "shortName": "Poland",
          "longName": "Poland"
        },
        {
          "id": 1831,
          "shortName": "Puerto Rico",
          "longName": "Puerto Rico"
        },
        {
          "id": 1832,
          "shortName": "Korea, Democratic People's Republic of",
          "longName": "Korea, Democratic People's Republic of"
        },
        {
          "id": 1833,
          "shortName": "Portugal",
          "longName": "Portugal"
        },
        {
          "id": 1834,
          "shortName": "Paraguay",
          "longName": "Paraguay"
        },
        {
          "id": 1835,
          "shortName": "Palestine, State of",
          "longName": "Palestine, State of"
        },
        {
          "id": 1836,
          "shortName": "French Polynesia",
          "longName": "French Polynesia"
        },
        {
          "id": 1837,
          "shortName": "Qatar",
          "longName": "Qatar"
        },
        {
          "id": 1838,
          "shortName": "Reunion",
          "longName": "Reunion"
        },
        {
          "id": 1839,
          "shortName": "Romania",
          "longName": "Romania"
        },
        {
          "id": 1840,
          "shortName": "Russian Federation",
          "longName": "Russia"
        },
        {
          "id": 1841,
          "shortName": "Rwanda",
          "longName": "Rwanda"
        },
        {
          "id": 1842,
          "shortName": "Saudi Arabia",
          "longName": "Saudi Arabia"
        },
        {
          "id": 1843,
          "shortName": "Sudan",
          "longName": "Sudan"
        },
        {
          "id": 1844,
          "shortName": "Senegal",
          "longName": "Senegal"
        },
        {
          "id": 1845,
          "shortName": "Singapore",
          "longName": "Singapore"
        },
        {
          "id": 1846,
          "shortName": "South Georgia and the South Sandwich Islands",
          "longName": "South Georgia and the South Sandwich Islands"
        },
        {
          "id": 1847,
          "shortName": "Saint Helena",
          "longName": "Saint Helena"
        },
        {
          "id": 1848,
          "shortName": "Svalbard and Jan Mayen Islands",
          "longName": "Svalbard and Jan Mayen Islands"
        },
        {
          "id": 1849,
          "shortName": "Solomon Islands",
          "longName": "Solomon Islands"
        },
        {
          "id": 1850,
          "shortName": "Sierra Leone",
          "longName": "Sierra Leone"
        },
        {
          "id": 1851,
          "shortName": "El Salvador, Republic of",
          "longName": "El Salvador, Republic of"
        },
        {
          "id": 1852,
          "shortName": "San Marino",
          "longName": "San Marino"
        },
        {
          "id": 1853,
          "shortName": "Somalia",
          "longName": "Somalia"
        },
        {
          "id": 1854,
          "shortName": "Saint Pierre and Miquelon",
          "longName": "Saint Pierre and Miquelon"
        },
        {
          "id": 1855,
          "shortName": "Serbia",
          "longName": "Serbia"
        },
        {
          "id": 1857,
          "shortName": "Sao Tome and Principe",
          "longName": "Sao Tome and Principe"
        },
        {
          "id": 1858,
          "shortName": "Suriname",
          "longName": "Suriname"
        },
        {
          "id": 1859,
          "shortName": "Slovakia",
          "longName": "Slovakia"
        },
        {
          "id": 1860,
          "shortName": "Slovenia",
          "longName": "Slovenia"
        },
        {
          "id": 1861,
          "shortName": "Sweden",
          "longName": "Sweden"
        },
        {
          "id": 1862,
          "shortName": "Swaziland",
          "longName": "Swaziland"
        },
        {
          "id": 1863,
          "shortName": "Sint Maarten (Dutch part)",
          "longName": "Sint Maarten (Dutch part)"
        },
        {
          "id": 1864,
          "shortName": "Seychelles",
          "longName": "Seychelles"
        },
        {
          "id": 1865,
          "shortName": "Syrian Arab Republic (Syria)",
          "longName": "Syrian Arab Republic (Syria)"
        },
        {
          "id": 1866,
          "shortName": "Turks and Caicos Islands",
          "longName": "Turks and Caicos Islands"
        },
        {
          "id": 1867,
          "shortName": "Chad",
          "longName": "Chad"
        },
        {
          "id": 1868,
          "shortName": "Togo",
          "longName": "Togo"
        },
        {
          "id": 1869,
          "shortName": "Thailand",
          "longName": "Thailand"
        },
        {
          "id": 1870,
          "shortName": "Tajikistan",
          "longName": "Tajikistan"
        },
        {
          "id": 1871,
          "shortName": "Tokelau",
          "longName": "Tokelau"
        },
        {
          "id": 1872,
          "shortName": "Turkmenistan",
          "longName": "Turkmenistan"
        },
        {
          "id": 1873,
          "shortName": "Timor-Leste",
          "longName": "Timor-Leste"
        },
        {
          "id": 1874,
          "shortName": "Tonga",
          "longName": "Tonga"
        },
        {
          "id": 1875,
          "shortName": "Trinidad and Tobago",
          "longName": "Trinidad and Tobago"
        },
        {
          "id": 1876,
          "shortName": "Tunisia",
          "longName": "Tunisia"
        },
        {
          "id": 1877,
          "shortName": "Turkey",
          "longName": "Turkey"
        },
        {
          "id": 1878,
          "shortName": "Tuvalu",
          "longName": "Tuvalu"
        },
        {
          "id": 1879,
          "shortName": "Taiwan, Republic of China",
          "longName": "Taiwan, Republic of China"
        },
        {
          "id": 1880,
          "shortName": "Tanzania, United Republic of",
          "longName": "Tanzania, United Republic of"
        },
        {
          "id": 1881,
          "shortName": "Uganda",
          "longName": "Uganda"
        },
        {
          "id": 1882,
          "shortName": "Ukraine",
          "longName": "Ukraine"
        },
        {
          "id": 1883,
          "shortName": "United States Minor Outlying Islands",
          "longName": "United States Minor Outlying Islands"
        },
        {
          "id": 1884,
          "shortName": "Uruguay",
          "longName": "Uruguay"
        },
        {
          "id": 1885,
          "shortName": "United States of America",
          "longName": "United States of America"
        },
        {
          "id": 1886,
          "shortName": "Uzbekistan",
          "longName": "Uzbekistan"
        },
        {
          "id": 1887,
          "shortName": "Holy See (Vatican City State)",
          "longName": "Holy See (Vatican City State)"
        },
        {
          "id": 1888,
          "shortName": "Saint Vincent and Grenadines",
          "longName": "Saint Vincent and Grenadines"
        },
        {
          "id": 1889,
          "shortName": "Venezuela (Bolivarian Republic of)",
          "longName": "Venezuela (Bolivarian Republic of)"
        },
        {
          "id": 1890,
          "shortName": "British Virgin Islands",
          "longName": "British Virgin Islands"
        },
        {
          "id": 1891,
          "shortName": "Virgin Islands of the United States",
          "longName": "Virgin Islands of the United States"
        },
        {
          "id": 1892,
          "shortName": "Vietnam, Socialist Republic of",
          "longName": "Vietnam, Socialist Republic of"
        },
        {
          "id": 1893,
          "shortName": "Vanuatu",
          "longName": "Vanuatu"
        },
        {
          "id": 1894,
          "shortName": "Wallis and Futuna Islands",
          "longName": "Wallis and Futuna Islands"
        },
        {
          "id": 1895,
          "shortName": "Samoa",
          "longName": "Samoa"
        },
        {
          "id": 1896,
          "shortName": "Yemen",
          "longName": "Yemen"
        },
        {
          "id": 1897,
          "shortName": "South Africa",
          "longName": "South Africa"
        },
        {
          "id": 1898,
          "shortName": "Zambia",
          "longName": "Zambia"
        },
        {
          "id": 1899,
          "shortName": "Zimbabwe",
          "longName": "Zimbabwe"
        },
        {
          "id": 3454,
          "shortName": "Asia",
          "longName": "Asia"
        },
        {
          "id": 3455,
          "shortName": "Delaware",
          "longName": "Delaware"
        },
        {
          "id": 3456,
          "shortName": "Ontario",
          "longName": "Ontario"
        }
      ],
      73: [
        {
          "id": 1900,
          "shortName": "CR1: Structured Funds",
          "longName": "CR1: Structured Funds"
        },
        {
          "id": 1904,
          "shortName": "CR2: Credit",
          "longName": "CR2: Credit"
        },
        {
          "id": 3161,
          "shortName": "PE1: Private Equity",
          "longName": "PE1: Private Equity"
        },
        {
          "id": 3160,
          "shortName": "RE1: Heitman",
          "longName": "RE1: Heitman"
        },
        {
          "id": 3159,
          "shortName": "RE2: Cortland",
          "longName": "RE2: Cortland"
        },
        {
          "id": 3391,
          "shortName": "NJ1: Nanjing Fund Services",
          "longName": "NJ1: Nanjing Fund Services"
        },
        {
          "id": 3392,
          "shortName": "FUND MGT",
          "longName": "FUND MGT"
        },
        {
          "id": 3457,
          "shortName": "MB1: Melbourne Fund Services",
          "longName": "MB1: Melbourne Fund Services"
        },
        {
          "id": 3461,
          "shortName": "RE3: Hybrid",
          "longName": "RE3: Hybrid"
        },
        {
          "id": 3734,
          "shortName": "PE2: Singapore",
          "longName": "PE2: Singapore"
        },
        {
          "id": 3754,
          "shortName": "PE4: IPS",
          "longName": "PE4: IPS"
        },
        {
          "id": 3755,
          "shortName": "PE5: IEA",
          "longName": "PE5: IEA"
        },
        {
          "id": 3756,
          "shortName": "PE6: Strata",
          "longName": "PE6: Strata"
        }
      ],
      74: [
        {
          "id": 1905,
          "shortName": "Yardi",
          "longName": "Yardi"
        },
        {
          "id": 1906,
          "shortName": "VBO",
          "longName": "VBO"
        },
        {
          "id": 1907,
          "shortName": "FundCount",
          "longName": "FundCount"
        },
        {
          "id": 1908,
          "shortName": "N/A",
          "longName": "N/A"
        },
        {
          "id": 3390,
          "shortName": "Allvue",
          "longName": "Allvue"
        },
        {
          "id": 3741,
          "shortName": "eFront",
          "longName": "eFront"
        },
        {
          "id": 3782,
          "shortName": "Investran",
          "longName": "Investran"
        }
      ],
      75: [
        {
          "id": 1909,
          "shortName": "Open Ended",
          "longName": "Open Ended"
        },
        {
          "id": 1910,
          "shortName": "Closed Ended",
          "longName": "Closed Ended"
        },
        {
          "id": 3445,
          "shortName": "Separate/Single Client",
          "longName": "Separate/Single Client"
        },
        {
          "id": 1911,
          "shortName": "N/A",
          "longName": "N/A"
        }
      ],
      79: [
        {
          "id": 3447,
          "shortName": "Private Equity",
          "longName": "Private Equity"
        },
        {
          "id": 3448,
          "shortName": "Infrastructure",
          "longName": "Infrastructure"
        },
        {
          "id": 3449,
          "shortName": "Fund of Funds",
          "longName": "Fund of Funds"
        },
        {
          "id": 3450,
          "shortName": "Real Estate",
          "longName": "Real Estate"
        },
        {
          "id": 3451,
          "shortName": "Debt",
          "longName": "Debt"
        },
        {
          "id": 3452,
          "shortName": "Hedge",
          "longName": "Hedge"
        }
      ],
      88: [
        {
          "id": 2071,
          "shortName": "CorPro Marketing",
          "longName": "CorPro Marketing"
        },
        {
          "id": 2072,
          "shortName": "Implementation",
          "longName": "Implementation"
        },
        {
          "id": 2073,
          "shortName": "Production",
          "longName": "Production"
        },
        {
          "id": 2074,
          "shortName": "Terminated",
          "longName": "Terminated"
        },
        {
          "id": 2075,
          "shortName": "Inactive",
          "longName": "Inactive"
        },
        {
          "id": 3887,
          "shortName": "Pending",
          "longName": "Pending"
        }
      ],
      91: [
        {
          "id": 2087,
          "shortName": "Main Fund",
          "longName": "Main Fund"
        },
        {
          "id": 2088,
          "shortName": "Parallel Fund",
          "longName": "Parallel Fund"
        },
        {
          "id": 2089,
          "shortName": "REIT",
          "longName": "REIT"
        },
        {
          "id": 2090,
          "shortName": "Joint Venture",
          "longName": "Joint Venture"
        },
        {
          "id": 2091,
          "shortName": "Co-Invest",
          "longName": "Co-Invest"
        },
        {
          "id": 2092,
          "shortName": "Onshore Feeder",
          "longName": "Onshore Feeder"
        },
        {
          "id": 2093,
          "shortName": "Property Accounting",
          "longName": "Property Accounting"
        },
        {
          "id": 2094,
          "shortName": "Offshore Feeder",
          "longName": "Offshore Feeder"
        },
        {
          "id": 2095,
          "shortName": "Aggregator/Holdco",
          "longName": "Aggregator/Holdco"
        },
        {
          "id": 2096,
          "shortName": "Blocker Inc.",
          "longName": "Blocker Inc."
        },
        {
          "id": 2097,
          "shortName": "CLO",
          "longName": "CLO"
        },
        {
          "id": 2098,
          "shortName": "AIV",
          "longName": "AIV"
        },
        {
          "id": 2099,
          "shortName": "SMA",
          "longName": "SMA"
        },
        {
          "id": 2100,
          "shortName": "SPV/SPE",
          "longName": "SPV/SPE"
        },
        {
          "id": 2101,
          "shortName": "Promote Entity / GP Entity",
          "longName": "Promote Entity / GP Entity"
        },
        {
          "id": 2102,
          "shortName": "Investor Services",
          "longName": "Investor Services"
        },
        {
          "id": 2103,
          "shortName": "REIT TRS",
          "longName": "REIT TRS"
        },
        {
          "id": 3871,
          "shortName": "N/A",
          "longName": "N/A"
        },
        {
          "id": 3975,
          "shortName": "LLC - Corporation",
          "longName": "LLC - Corporation"
        },
        {
          "id": 3976,
          "shortName": "LLC – S Corporation",
          "longName": "LLC – S Corporation"
        },
        {
          "id": 3977,
          "shortName": "LLC - Partnership",
          "longName": "LLC - Partnership"
        },
        {
          "id": 3978,
          "shortName": "LLC – Single Member",
          "longName": "LLC – Single Member"
        },
        {
          "id": 3997,
          "shortName": "Sub Fund",
          "longName": "Sub Fund"
        }
      ],
      92: [
        {
          "id": 2104,
          "shortName": "USD",
          "longName": "USD"
        },
        {
          "id": 2105,
          "shortName": "EUR",
          "longName": "EUR"
        },
        {
          "id": 2106,
          "shortName": "GBP",
          "longName": "GBP"
        },
        {
          "id": 2107,
          "shortName": "KRW",
          "longName": "KRW"
        },
        {
          "id": 2108,
          "shortName": "CNY",
          "longName": "CNY"
        },
        {
          "id": 2109,
          "shortName": "JPY",
          "longName": "JPY"
        },
        {
          "id": 2110,
          "shortName": "CAD",
          "longName": "CAD"
        },
        {
          "id": 3225,
          "shortName": "AFN",
          "longName": "Afghani"
        },
        {
          "id": 3226,
          "shortName": "ALL",
          "longName": "Lek"
        },
        {
          "id": 3227,
          "shortName": "DZD",
          "longName": "Algerian Dinar"
        },
        {
          "id": 3228,
          "shortName": "AOA",
          "longName": "Kwanza"
        },
        {
          "id": 3229,
          "shortName": "XCD",
          "longName": "East Caribbean Dollar"
        },
        {
          "id": 3230,
          "shortName": "ARS",
          "longName": "Argentine Peso"
        },
        {
          "id": 3231,
          "shortName": "AMD",
          "longName": "Armenian Dram"
        },
        {
          "id": 3232,
          "shortName": "AWG",
          "longName": "Aruban Florin"
        },
        {
          "id": 3233,
          "shortName": "AUD",
          "longName": "Australian Dollar"
        },
        {
          "id": 3234,
          "shortName": "AZN",
          "longName": "Azerbaijan Manat"
        },
        {
          "id": 3235,
          "shortName": "BSD",
          "longName": "Bahamian Dollar"
        },
        {
          "id": 3236,
          "shortName": "BHD",
          "longName": "Bahraini Dinar"
        },
        {
          "id": 3237,
          "shortName": "BDT",
          "longName": "Taka"
        },
        {
          "id": 3238,
          "shortName": "BBD",
          "longName": "Barbados Dollar"
        },
        {
          "id": 3239,
          "shortName": "BYN",
          "longName": "Belarusian Ruble"
        },
        {
          "id": 3240,
          "shortName": "BZD",
          "longName": "Belize Dollar"
        },
        {
          "id": 3241,
          "shortName": "XOF",
          "longName": "CFA Franc BCEAO"
        },
        {
          "id": 3242,
          "shortName": "BMD",
          "longName": "Bermudian Dollar"
        },
        {
          "id": 3243,
          "shortName": "INR",
          "longName": "Indian Rupee"
        },
        {
          "id": 3244,
          "shortName": "BTN",
          "longName": "Ngultrum"
        },
        {
          "id": 3245,
          "shortName": "BOB",
          "longName": "Boliviano"
        },
        {
          "id": 3246,
          "shortName": "BOV",
          "longName": "Mvdol"
        },
        {
          "id": 3247,
          "shortName": "BAM",
          "longName": "Convertible Mark"
        },
        {
          "id": 3248,
          "shortName": "BWP",
          "longName": "Pula"
        },
        {
          "id": 3249,
          "shortName": "NOK",
          "longName": "Norwegian Krone"
        },
        {
          "id": 3250,
          "shortName": "BRL",
          "longName": "Brazilian Real"
        },
        {
          "id": 3251,
          "shortName": "BND",
          "longName": "Brunei Dollar"
        },
        {
          "id": 3252,
          "shortName": "BGN",
          "longName": "Bulgarian Lev"
        },
        {
          "id": 3253,
          "shortName": "BIF",
          "longName": "Burundi Franc"
        },
        {
          "id": 3254,
          "shortName": "CVE",
          "longName": "Cabo Verde Escudo"
        },
        {
          "id": 3255,
          "shortName": "KHR",
          "longName": "Riel"
        },
        {
          "id": 3256,
          "shortName": "XAF",
          "longName": "CFA Franc BEAC"
        },
        {
          "id": 3257,
          "shortName": "KYD",
          "longName": "Cayman Islands Dollar"
        },
        {
          "id": 3258,
          "shortName": "CLP",
          "longName": "Chilean Peso"
        },
        {
          "id": 3259,
          "shortName": "CLF",
          "longName": "Unidad de Fomento"
        },
        {
          "id": 3260,
          "shortName": "COP",
          "longName": "Colombian Peso"
        },
        {
          "id": 3261,
          "shortName": "COU",
          "longName": "Unidad de Valor Real"
        },
        {
          "id": 3262,
          "shortName": "KMF",
          "longName": "Comorian Franc"
        },
        {
          "id": 3263,
          "shortName": "CDF",
          "longName": "Congolese Franc"
        },
        {
          "id": 3264,
          "shortName": "NZD",
          "longName": "New Zealand Dollar"
        },
        {
          "id": 3265,
          "shortName": "CRC",
          "longName": "Costa Rican Colon"
        },
        {
          "id": 3266,
          "shortName": "HRK",
          "longName": "Kuna"
        },
        {
          "id": 3267,
          "shortName": "CUP",
          "longName": "Cuban Peso"
        },
        {
          "id": 3268,
          "shortName": "CUC",
          "longName": "Peso Convertible"
        },
        {
          "id": 3269,
          "shortName": "ANG",
          "longName": "Netherlands Antillean Guilder"
        },
        {
          "id": 3270,
          "shortName": "CZK",
          "longName": "Czech Koruna"
        },
        {
          "id": 3271,
          "shortName": "DKK",
          "longName": "Danish Krone"
        },
        {
          "id": 3272,
          "shortName": "DJF",
          "longName": "Djibouti Franc"
        },
        {
          "id": 3273,
          "shortName": "DOP",
          "longName": "Dominican Peso"
        },
        {
          "id": 3274,
          "shortName": "EGP",
          "longName": "Egyptian Pound"
        },
        {
          "id": 3275,
          "shortName": "SVC",
          "longName": "El Salvador Colon"
        },
        {
          "id": 3276,
          "shortName": "ERN",
          "longName": "Nakfa"
        },
        {
          "id": 3277,
          "shortName": "ETB",
          "longName": "Ethiopian Birr"
        },
        {
          "id": 3278,
          "shortName": "FKP",
          "longName": "Falkland Islands Pound"
        },
        {
          "id": 3279,
          "shortName": "FJD",
          "longName": "Fiji Dollar"
        },
        {
          "id": 3280,
          "shortName": "XPF",
          "longName": "CFP Franc"
        },
        {
          "id": 3281,
          "shortName": "GMD",
          "longName": "Dalasi"
        },
        {
          "id": 3282,
          "shortName": "GEL",
          "longName": "Lari"
        },
        {
          "id": 3283,
          "shortName": "GHS",
          "longName": "Ghana Cedi"
        },
        {
          "id": 3284,
          "shortName": "GIP",
          "longName": "Gibraltar Pound"
        },
        {
          "id": 3285,
          "shortName": "GTQ",
          "longName": "Quetzal"
        },
        {
          "id": 3286,
          "shortName": "GNF",
          "longName": "Guinean Franc"
        },
        {
          "id": 3287,
          "shortName": "GYD",
          "longName": "Guyana Dollar"
        },
        {
          "id": 3288,
          "shortName": "HTG",
          "longName": "Gourde"
        },
        {
          "id": 3289,
          "shortName": "HNL",
          "longName": "Lempira"
        },
        {
          "id": 3290,
          "shortName": "HKD",
          "longName": "Hong Kong Dollar"
        },
        {
          "id": 3291,
          "shortName": "HUF",
          "longName": "Forint"
        },
        {
          "id": 3292,
          "shortName": "ISK",
          "longName": "Iceland Krona"
        },
        {
          "id": 3293,
          "shortName": "IDR",
          "longName": "Rupiah"
        },
        {
          "id": 3294,
          "shortName": "IRR",
          "longName": "Iranian Rial"
        },
        {
          "id": 3295,
          "shortName": "IQD",
          "longName": "Iraqi Dinar"
        },
        {
          "id": 3296,
          "shortName": "ILS",
          "longName": "New Israeli Sheqel"
        },
        {
          "id": 3297,
          "shortName": "JMD",
          "longName": "Jamaican Dollar"
        },
        {
          "id": 3298,
          "shortName": "JOD",
          "longName": "Jordanian Dinar"
        },
        {
          "id": 3299,
          "shortName": "KZT",
          "longName": "Tenge"
        },
        {
          "id": 3300,
          "shortName": "KES",
          "longName": "Kenyan Shilling"
        },
        {
          "id": 3301,
          "shortName": "KPW",
          "longName": "North Korean Won"
        },
        {
          "id": 3302,
          "shortName": "KWD",
          "longName": "Kuwaiti Dinar"
        },
        {
          "id": 3303,
          "shortName": "KGS",
          "longName": "Som"
        },
        {
          "id": 3304,
          "shortName": "LAK",
          "longName": "Lao Kip"
        },
        {
          "id": 3305,
          "shortName": "LBP",
          "longName": "Lebanese Pound"
        },
        {
          "id": 3306,
          "shortName": "LSL",
          "longName": "Loti"
        },
        {
          "id": 3307,
          "shortName": "ZAR",
          "longName": "Rand"
        },
        {
          "id": 3308,
          "shortName": "LRD",
          "longName": "Liberian Dollar"
        },
        {
          "id": 3309,
          "shortName": "LYD",
          "longName": "Libyan Dinar"
        },
        {
          "id": 3310,
          "shortName": "CHF",
          "longName": "Swiss Franc"
        },
        {
          "id": 3311,
          "shortName": "MOP",
          "longName": "Pataca"
        },
        {
          "id": 3312,
          "shortName": "MKD",
          "longName": "Denar"
        },
        {
          "id": 3313,
          "shortName": "MGA",
          "longName": "Malagasy Ariary"
        },
        {
          "id": 3314,
          "shortName": "MWK",
          "longName": "Malawi Kwacha"
        },
        {
          "id": 3315,
          "shortName": "MYR",
          "longName": "Malaysian Ringgit"
        },
        {
          "id": 3316,
          "shortName": "MVR",
          "longName": "Rufiyaa"
        },
        {
          "id": 3317,
          "shortName": "MRU",
          "longName": "Ouguiya"
        },
        {
          "id": 3318,
          "shortName": "MUR",
          "longName": "Mauritius Rupee"
        },
        {
          "id": 3319,
          "shortName": "XUA",
          "longName": "ADB Unit of Account"
        },
        {
          "id": 3320,
          "shortName": "MXN",
          "longName": "Mexican Peso"
        },
        {
          "id": 3321,
          "shortName": "MXV",
          "longName": "Mexican Unidad de Inversion (UDI)"
        },
        {
          "id": 3322,
          "shortName": "MDL",
          "longName": "Moldovan Leu"
        },
        {
          "id": 3323,
          "shortName": "MNT",
          "longName": "Tugrik"
        },
        {
          "id": 3324,
          "shortName": "MAD",
          "longName": "Moroccan Dirham"
        },
        {
          "id": 3325,
          "shortName": "MZN",
          "longName": "Mozambique Metical"
        },
        {
          "id": 3326,
          "shortName": "MMK",
          "longName": "Kyat"
        },
        {
          "id": 3327,
          "shortName": "NAD",
          "longName": "Namibia Dollar"
        },
        {
          "id": 3328,
          "shortName": "NPR",
          "longName": "Nepalese Rupee"
        },
        {
          "id": 3329,
          "shortName": "NIO",
          "longName": "Cordoba Oro"
        },
        {
          "id": 3330,
          "shortName": "NGN",
          "longName": "Naira"
        },
        {
          "id": 3331,
          "shortName": "OMR",
          "longName": "Rial Omani"
        },
        {
          "id": 3332,
          "shortName": "PKR",
          "longName": "Pakistan Rupee"
        },
        {
          "id": 3333,
          "shortName": "PAB",
          "longName": "Balboa"
        },
        {
          "id": 3334,
          "shortName": "PGK",
          "longName": "Kina"
        },
        {
          "id": 3335,
          "shortName": "PYG",
          "longName": "Guarani"
        },
        {
          "id": 3336,
          "shortName": "PEN",
          "longName": "Sol"
        },
        {
          "id": 3337,
          "shortName": "PHP",
          "longName": "Philippine Peso"
        },
        {
          "id": 3338,
          "shortName": "PLN",
          "longName": "Zloty"
        },
        {
          "id": 3339,
          "shortName": "QAR",
          "longName": "Qatari Rial"
        },
        {
          "id": 3340,
          "shortName": "RON",
          "longName": "Romanian Leu"
        },
        {
          "id": 3341,
          "shortName": "RUB",
          "longName": "Russian Ruble"
        },
        {
          "id": 3342,
          "shortName": "RWF",
          "longName": "Rwanda Franc"
        },
        {
          "id": 3343,
          "shortName": "SHP",
          "longName": "Saint Helena Pound"
        },
        {
          "id": 3344,
          "shortName": "WST",
          "longName": "Tala"
        },
        {
          "id": 3345,
          "shortName": "STN",
          "longName": "Dobra"
        },
        {
          "id": 3346,
          "shortName": "SAR",
          "longName": "Saudi Riyal"
        },
        {
          "id": 3347,
          "shortName": "RSD",
          "longName": "Serbian Dinar"
        },
        {
          "id": 3348,
          "shortName": "SCR",
          "longName": "Seychelles Rupee"
        },
        {
          "id": 3349,
          "shortName": "SLL",
          "longName": "Leone"
        },
        {
          "id": 3350,
          "shortName": "SGD",
          "longName": "Singapore Dollar"
        },
        {
          "id": 3351,
          "shortName": "XSU",
          "longName": "Sucre"
        },
        {
          "id": 3352,
          "shortName": "SBD",
          "longName": "Solomon Islands Dollar"
        },
        {
          "id": 3353,
          "shortName": "SOS",
          "longName": "Somali Shilling"
        },
        {
          "id": 3354,
          "shortName": "SSP",
          "longName": "South Sudanese Pound"
        },
        {
          "id": 3355,
          "shortName": "LKR",
          "longName": "Sri Lanka Rupee"
        },
        {
          "id": 3356,
          "shortName": "SDG",
          "longName": "Sudanese Pound"
        },
        {
          "id": 3357,
          "shortName": "SRD",
          "longName": "Surinam Dollar"
        },
        {
          "id": 3358,
          "shortName": "SZL",
          "longName": "Lilangeni"
        },
        {
          "id": 3359,
          "shortName": "SEK",
          "longName": "Swedish Krona"
        },
        {
          "id": 3360,
          "shortName": "CHE",
          "longName": "WIR Euro"
        },
        {
          "id": 3361,
          "shortName": "CHW",
          "longName": "WIR Franc"
        },
        {
          "id": 3362,
          "shortName": "SYP",
          "longName": "Syrian Pound"
        },
        {
          "id": 3363,
          "shortName": "TWD",
          "longName": "New Taiwan Dollar"
        },
        {
          "id": 3364,
          "shortName": "TJS",
          "longName": "Somoni"
        },
        {
          "id": 3365,
          "shortName": "TZS",
          "longName": "Tanzanian Shilling"
        },
        {
          "id": 3366,
          "shortName": "THB",
          "longName": "Baht"
        },
        {
          "id": 3367,
          "shortName": "TOP",
          "longName": "Pa’anga"
        },
        {
          "id": 3368,
          "shortName": "TTD",
          "longName": "Trinidad and Tobago Dollar"
        },
        {
          "id": 3369,
          "shortName": "TND",
          "longName": "Tunisian Dinar"
        },
        {
          "id": 3370,
          "shortName": "TRY",
          "longName": "Turkish Lira"
        },
        {
          "id": 3371,
          "shortName": "TMT",
          "longName": "Turkmenistan New Manat"
        },
        {
          "id": 3372,
          "shortName": "UGX",
          "longName": "Uganda Shilling"
        },
        {
          "id": 3373,
          "shortName": "UAH",
          "longName": "Hryvnia"
        },
        {
          "id": 3374,
          "shortName": "AED",
          "longName": "UAE Dirham"
        },
        {
          "id": 3375,
          "shortName": "UYU",
          "longName": "Peso Uruguayo"
        },
        {
          "id": 3376,
          "shortName": "UYI",
          "longName": "Uruguay Peso en Unidades Indexadas (UI)"
        },
        {
          "id": 3377,
          "shortName": "UYW",
          "longName": "Unidad Previsional"
        },
        {
          "id": 3378,
          "shortName": "UZS",
          "longName": "Uzbekistan Sum"
        },
        {
          "id": 3379,
          "shortName": "VUV",
          "longName": "Vatu"
        },
        {
          "id": 3380,
          "shortName": "VES",
          "longName": "Bolívar Soberano"
        },
        {
          "id": 3381,
          "shortName": "VND",
          "longName": "Dong"
        },
        {
          "id": 3382,
          "shortName": "YER",
          "longName": "Yemeni Rial"
        },
        {
          "id": 3383,
          "shortName": "ZMW",
          "longName": "Zambian Kwacha"
        },
        {
          "id": 3384,
          "shortName": "ZWL",
          "longName": "Zimbabwe Dollar"
        },
        {
          "id": 2111,
          "shortName": "Other",
          "longName": "Other"
        }
      ],
      127: [
        {
          "id": 3400,
          "shortName": "Open to Investors",
          "longName": "Open to Investors"
        },
        {
          "id": 3401,
          "shortName": "Closed to Investors",
          "longName": "Closed to Investors"
        },
        {
          "id": 3947,
          "shortName": "Pending",
          "longName": "Pending"
        },
        {
          "id": 3948,
          "shortName": "Terminated",
          "longName": "Terminated"
        }
      ],
      129: [
        {
          "id": 3412,
          "shortName": "Private Equity",
          "longName": "Buyout"
        },
        {
          "id": 3423,
          "shortName": "Infrastructure",
          "longName": "Core"
        },
        {
          "id": 3427,
          "shortName": "Fund of Funds",
          "longName": "Private Equity (Primary/Multi Strategy)"
        },
        {
          "id": 3431,
          "shortName": "Real Estate",
          "longName": "Core/Core+"
        },
        {
          "id": 3436,
          "shortName": "Debt",
          "longName": "Infra Debt"
        },
        {
          "id": 3444,
          "shortName": "Hedge",
          "longName": "Hedge"
        },
        {
          "id": 3413,
          "shortName": "Private Equity",
          "longName": "Venture"
        },
        {
          "id": 3424,
          "shortName": "Infrastructure",
          "longName": "Renewables"
        },
        {
          "id": 3428,
          "shortName": "Fund of Funds",
          "longName": "Secondaries"
        },
        {
          "id": 3432,
          "shortName": "Real Estate",
          "longName": "Value-Add"
        },
        {
          "id": 3437,
          "shortName": "Debt",
          "longName": "DL (SMEs, Mid Cap…);"
        },
        {
          "id": 3414,
          "shortName": "Private Equity",
          "longName": "Secondaries"
        },
        {
          "id": 3425,
          "shortName": "Infrastructure",
          "longName": "Natural Resources"
        },
        {
          "id": 3429,
          "shortName": "Fund of Funds",
          "longName": "Real Estate"
        },
        {
          "id": 3433,
          "shortName": "Real Estate",
          "longName": "Opportunistic"
        },
        {
          "id": 3438,
          "shortName": "Debt",
          "longName": "Venture"
        },
        {
          "id": 3415,
          "shortName": "Private Equity",
          "longName": "Growth"
        },
        {
          "id": 3426,
          "shortName": "Infrastructure",
          "longName": "Other"
        },
        {
          "id": 3430,
          "shortName": "Fund of Funds",
          "longName": "Mandates/SMAs"
        },
        {
          "id": 3434,
          "shortName": "Real Estate",
          "longName": "Real Estate Debt"
        },
        {
          "id": 3439,
          "shortName": "Debt",
          "longName": "Private Debt Funds"
        },
        {
          "id": 3416,
          "shortName": "Private Equity",
          "longName": "Co-Investment"
        },
        {
          "id": 3435,
          "shortName": "Real Estate",
          "longName": "Multi-Strategy"
        },
        {
          "id": 3440,
          "shortName": "Debt",
          "longName": "Distressed Credit Funds"
        },
        {
          "id": 3417,
          "shortName": "Private Equity",
          "longName": "Distressed"
        },
        {
          "id": 3441,
          "shortName": "Debt",
          "longName": "Broadly Syndicated Debt Funds"
        },
        {
          "id": 3418,
          "shortName": "Private Equity",
          "longName": "Multi-Strategy"
        },
        {
          "id": 3442,
          "shortName": "Debt",
          "longName": "Structured Debt Funds"
        },
        {
          "id": 3419,
          "shortName": "Private Equity",
          "longName": "Emerging Markets"
        },
        {
          "id": 3443,
          "shortName": "Debt",
          "longName": "BDC (Public and Private)"
        },
        {
          "id": 3420,
          "shortName": "Private Equity",
          "longName": "ESG"
        },
        {
          "id": 3421,
          "shortName": "Private Equity",
          "longName": "Special Situations"
        },
        {
          "id": 3422,
          "shortName": "Private Equity",
          "longName": "SPVs (Feeder, Blockers, Operating Companies)"
        }
      ],
      133: [
        {
          "id": 3949,
          "shortName": "Digital",
          "longName": "Digital"
        },
        {
          "id": 3950,
          "shortName": "Phone",
          "longName": "Phone"
        },
        {
          "id": 3951,
          "shortName": "Physical",
          "longName": "Physical"
        }
      ],
      134: [
        {
          "id": 4051,
          "shortName": "Active",
          "longName": "Active"
        },
        {
          "id": 4052,
          "shortName": "Shell",
          "longName": "Shell"
        },
        {
          "id": 4053,
          "shortName": "Needs Additional Info",
          "longName": "Needs Additional Info"
        },
        {
          "id": 4054,
          "shortName": "InActive",
          "longName": "InActive"
        },
        {
          "id": 4055,
          "shortName": "Transferred",
          "longName": "Transferred"
        },
        {
          "id": 4056,
          "shortName": "Redeemed",
          "longName": "Redeemed"
        }
      ],
      136: [
        {
          "id": 4816,
          "shortName": "Active",
          "longName": "Active"
        },
        {
          "id": 4817,
          "shortName": "Archived",
          "longName": "Archived"
        },
        {
          "id": 4818,
          "shortName": "N/A",
          "longName": "N/A"
        },
        {
          "id": 4819,
          "shortName": "In course of archiving",
          "longName": "In course of archiving"
        },
        {
          "id": 4820,
          "shortName": "Incorporated",
          "longName": "Incorporated"
        },
        {
          "id": 4821,
          "shortName": "In liquidation",
          "longName": "In liquidation"
        },
        {
          "id": 4822,
          "shortName": "In bankruptcy",
          "longName": "In bankruptcy"
        },
        {
          "id": 4823,
          "shortName": "Transferred",
          "longName": "Transferred"
        },
        {
          "id": 4824,
          "shortName": "Incorporation in process",
          "longName": "Incorporation in process"
        },
        {
          "id": 4825,
          "shortName": "Dissolved",
          "longName": "Dissolved"
        },
        {
          "id": 4826,
          "shortName": "End of Assignment",
          "longName": "End of Assignment"
        },
        {
          "id": 4827,
          "shortName": "Strike off from RCS",
          "longName": "Strike off from RCS"
        },
        {
          "id": 4828,
          "shortName": "Denounced Seat",
          "longName": "Denounced Seat"
        },
        {
          "id": 4829,
          "shortName": "Aborted",
          "longName": "Aborted"
        },
        {
          "id": 4830,
          "shortName": "Merged",
          "longName": "Merged"
        },
        {
          "id": 4831,
          "shortName": "Liquidated",
          "longName": "Liquidated"
        },
        {
          "id": 4832,
          "shortName": "Bankrupt",
          "longName": "Bankrupt"
        },
        {
          "id": 4833,
          "shortName": "In Transfer",
          "longName": "In Transfer"
        },
        {
          "id": 4834,
          "shortName": "Dormant",
          "longName": "Dormant"
        },
        {
          "id": 4835,
          "shortName": "Active Non-Trading",
          "longName": "Active Non-Trading"
        },
        {
          "id": 4836,
          "shortName": "In Administrative Receivership",
          "longName": "In Administrative Receivership"
        },
        {
          "id": 4837,
          "shortName": "In Administration",
          "longName": "In Administration"
        },
        {
          "id": 4838,
          "shortName": "Strike Off Requested",
          "longName": "Strike Off Requested"
        },
        {
          "id": 4839,
          "shortName": "De-registered",
          "longName": "De-registered"
        },
        {
          "id": 4840,
          "shortName": "Struck Off",
          "longName": "Struck Off"
        }
      ],
      138: [
        {
          "id": 4509,
          "shortName": "Created",
          "longName": "Created"
        },
        {
          "id": 4510,
          "shortName": "Pending creation",
          "longName": "Pending creation"
        },
        {
          "id": 4511,
          "shortName": "Archived",
          "longName": "Archived"
        },
        {
          "id": 4512,
          "shortName": "Cancelled",
          "longName": "Cancelled"
        }
      ],
      "-4": [
        {
          "id": 1,
          "shortName": "CEM",
          "longName": "CEM"
        },
        {
          "id": 2,
          "shortName": "CEM-TIAA",
          "longName": "CEM-TIAA"
        },
        {
          "id": 3,
          "shortName": "GEN",
          "longName": "GEN"
        },
        {
          "id": 4,
          "shortName": "LASCAUX",
          "longName": "LASCAUX"
        },
        {
          "id": 5,
          "shortName": "Singerman",
          "longName": "Singerman"
        },
        {
          "id": 6,
          "shortName": "ROC",
          "longName": "ROC"
        },
        {
          "id": 7,
          "shortName": "T2",
          "longName": "T2"
        },
        {
          "id": 8,
          "shortName": "Backcountry2",
          "longName": "Backcountry2"
        },
        {
          "id": 9,
          "shortName": "LRCOFF",
          "longName": "LRCOFF"
        },
        {
          "id": 10,
          "shortName": "NNN123",
          "longName": "NNN123"
        },
        {
          "id": 11,
          "shortName": "CEMIII",
          "longName": "CEMIII"
        },
        {
          "id": 12,
          "shortName": "CEM-GEN",
          "longName": "CEM-GEN"
        },
        {
          "id": 13,
          "shortName": "ACM",
          "longName": "ACM"
        },
        {
          "id": 14,
          "shortName": "RaithIII",
          "longName": "RaithIII"
        },
        {
          "id": 15,
          "shortName": "GreenOak",
          "longName": "GreenOak"
        },
        {
          "id": 17,
          "shortName": "Varagon",
          "longName": "Varagon"
        },
        {
          "id": 19,
          "shortName": "Virtus",
          "longName": "Virtus"
        },
        {
          "id": 20,
          "shortName": "CapRidge",
          "longName": "CapRidge"
        },
        {
          "id": 21,
          "shortName": "ControlledEnvironmentFoodFund",
          "longName": "ControlledEnvironmentFoodFund"
        },
        {
          "id": 24,
          "shortName": "Abacus",
          "longName": "Abacus"
        },
        {
          "id": 25,
          "shortName": "OFSCapital",
          "longName": "OFSCapital"
        },
        {
          "id": 26,
          "shortName": "Water",
          "longName": "Water"
        },
        {
          "id": 27,
          "shortName": "Chambers",
          "longName": "Chambers"
        },
        {
          "id": 28,
          "shortName": "NBPartners",
          "longName": "NBPartners"
        },
        {
          "id": 29,
          "shortName": "ControlledEnvironmentFoodFundII",
          "longName": "ControlledEnvironmentFoodFundII"
        },
        {
          "id": 30,
          "shortName": "Drake",
          "longName": "Drake"
        },
        {
          "id": 31,
          "shortName": "PGP",
          "longName": "PGP"
        },
        {
          "id": 32,
          "shortName": "IrradiantSolutionsII",
          "longName": "IrradiantSolutionsII"
        },
        {
          "id": 33,
          "shortName": "ChambersMarketingDataroom",
          "longName": "ChambersMarketingDataroom"
        },
        {
          "id": 34,
          "shortName": "Inceptiv",
          "longName": "Inceptiv"
        },
        {
          "id": 35,
          "shortName": "Inceptiv",
          "longName": "Inceptiv"
        }
      ],
      "-3": [
        {
          "id": 1,
          "shortName": "GreenOak",
          "longName": "GreenOak"
        },
        {
          "id": 2,
          "shortName": "Grove",
          "longName": "Grove"
        },
        {
          "id": 3,
          "shortName": "Arcadia",
          "longName": "Arcadia"
        },
        {
          "id": 4,
          "shortName": "Mosaic Funds",
          "longName": "Mosaic Funds"
        },
        {
          "id": 5,
          "shortName": "Basepoint Group",
          "longName": "Basepoint Group"
        },
        {
          "id": 6,
          "shortName": "Grubb Funds",
          "longName": "Grubb Funds"
        },
        {
          "id": 7,
          "shortName": "Stonehill",
          "longName": "Stonehill"
        },
        {
          "id": 8,
          "shortName": "Singerman",
          "longName": "Singerman"
        },
        {
          "id": 9,
          "shortName": "Bloomfield",
          "longName": "Bloomfield"
        },
        {
          "id": 10,
          "shortName": "Wolff Funds",
          "longName": "Wolff Funds"
        },
        {
          "id": 11,
          "shortName": "Eightfold",
          "longName": "Eightfold"
        },
        {
          "id": 12,
          "shortName": "Transwestern Group",
          "longName": "Transwestern Group"
        },
        {
          "id": 13,
          "shortName": "Thorofare",
          "longName": "Thorofare"
        },
        {
          "id": 14,
          "shortName": "Breach",
          "longName": "Breach"
        },
        {
          "id": 15,
          "shortName": "Crestline II Group",
          "longName": "Crestline II Group"
        },
        {
          "id": 16,
          "shortName": "Monticello",
          "longName": "Monticello"
        },
        {
          "id": 17,
          "shortName": "Portfolio Advisors",
          "longName": "Portfolio Advisors"
        },
        {
          "id": 18,
          "shortName": "CommonFund",
          "longName": "CommonFund"
        },
        {
          "id": 19,
          "shortName": "Lcatterton",
          "longName": "Lcatterton"
        },
        {
          "id": 20,
          "shortName": "PGIM",
          "longName": "PGIM"
        },
        {
          "id": 21,
          "shortName": "Group RMC",
          "longName": "Group RMC"
        },
        {
          "id": 22,
          "shortName": "GrayCo",
          "longName": "GrayCo"
        },
        {
          "id": 23,
          "shortName": "Qualitas",
          "longName": "Qualitas"
        },
        {
          "id": 24,
          "shortName": "3x5 Partners",
          "longName": "3x5 Partners"
        },
        {
          "id": 25,
          "shortName": "Acorn Capital Advisors",
          "longName": "Acorn Capital Advisors"
        },
        {
          "id": 26,
          "shortName": "Acorn Growth Companies",
          "longName": "Acorn Growth Companies"
        },
        {
          "id": 27,
          "shortName": "Ceres Partners",
          "longName": "Ceres Partners"
        },
        {
          "id": 28,
          "shortName": "CEVF",
          "longName": "CEVF"
        },
        {
          "id": 29,
          "shortName": "Commonwealth Ventures",
          "longName": "Commonwealth Ventures"
        },
        {
          "id": 30,
          "shortName": "Cue Ball Capital",
          "longName": "Cue Ball Capital"
        },
        {
          "id": 31,
          "shortName": "Denham Capital",
          "longName": "Denham Capital"
        },
        {
          "id": 32,
          "shortName": "Exaltare Capital",
          "longName": "Exaltare Capital"
        },
        {
          "id": 33,
          "shortName": "Exaltare Capital Partners Fund I, LP",
          "longName": "Exaltare Capital Partners Fund I, LP"
        },
        {
          "id": 34,
          "shortName": "Fort Point Capital",
          "longName": "Fort Point Capital"
        },
        {
          "id": 35,
          "shortName": "Harmon Street Investors, LLC",
          "longName": "Harmon Street Investors, LLC"
        },
        {
          "id": 36,
          "shortName": "Hyperplane",
          "longName": "Hyperplane"
        },
        {
          "id": 37,
          "shortName": "JMC Capital",
          "longName": "JMC Capital"
        },
        {
          "id": 38,
          "shortName": "Kendall Investments",
          "longName": "Kendall Investments"
        },
        {
          "id": 39,
          "shortName": "Kepha Partners",
          "longName": "Kepha Partners"
        },
        {
          "id": 40,
          "shortName": "LFM Capital",
          "longName": "LFM Capital"
        },
        {
          "id": 41,
          "shortName": "Lincoln Peak Capital",
          "longName": "Lincoln Peak Capital"
        },
        {
          "id": 42,
          "shortName": "Longwood",
          "longName": "Longwood"
        },
        {
          "id": 43,
          "shortName": "Method Advisors",
          "longName": "Method Advisors"
        },
        {
          "id": 44,
          "shortName": "Novalis LifeSciences LLC",
          "longName": "Novalis LifeSciences LLC"
        },
        {
          "id": 45,
          "shortName": "Omega Capital",
          "longName": "Omega Capital"
        },
        {
          "id": 46,
          "shortName": "Perspecta Trust LLC",
          "longName": "Perspecta Trust LLC"
        },
        {
          "id": 47,
          "shortName": "Point 406 Ventures",
          "longName": "Point 406 Ventures"
        },
        {
          "id": 48,
          "shortName": "RiverVest Venture Partners",
          "longName": "RiverVest Venture Partners"
        },
        {
          "id": 49,
          "shortName": "Sightline Opportunity Management II, LLC",
          "longName": "Sightline Opportunity Management II, LLC"
        },
        {
          "id": 50,
          "shortName": "Staley Capital",
          "longName": "Staley Capital"
        },
        {
          "id": 51,
          "shortName": "Stone-Goff Management, LLC",
          "longName": "Stone-Goff Management, LLC"
        },
        {
          "id": 52,
          "shortName": "Stone-Goff Partners II GP, LP",
          "longName": "Stone-Goff Partners II GP, LP"
        },
        {
          "id": 53,
          "shortName": "Technology Venture",
          "longName": "Technology Venture"
        },
        {
          "id": 54,
          "shortName": "TEM Capital",
          "longName": "TEM Capital"
        },
        {
          "id": 55,
          "shortName": "The Nashton Company, LLC",
          "longName": "The Nashton Company, LLC"
        },
        {
          "id": 56,
          "shortName": "Delaware Trust",
          "longName": "Delaware Trust"
        }
      ],
      "-2": [
        {
          "id": 1,
          "shortName": "Investor",
          "longName": "Investor"
        },
        {
          "id": 2,
          "shortName": "Issuer",
          "longName": "Issuer"
        },
        {
          "id": 3,
          "shortName": "Account Administrator",
          "longName": "Account Administrator"
        },
        {
          "id": 4,
          "shortName": "Fund Manager",
          "longName": "Fund Manager"
        },
        {
          "id": 5,
          "shortName": "Agency Borrower",
          "longName": "Agency Borrower"
        },
        {
          "id": 6,
          "shortName": "Agency Lender",
          "longName": "Agency Lender"
        },
        {
          "id": 7,
          "shortName": "Client",
          "longName": "Client"
        },
        {
          "id": 8,
          "shortName": "Trustee",
          "longName": "Trustee"
        },
        {
          "id": 9,
          "shortName": "Self Issuer",
          "longName": "Self Issuer"
        },
        {
          "id": 10,
          "shortName": "Admin",
          "longName": "Admin"
        },
        {
          "id": 11,
          "shortName": "WebNotice",
          "longName": "WebNotice"
        },
        {
          "id": 12,
          "shortName": "Default Role",
          "longName": "Default Role"
        },
        {
          "id": 25,
          "shortName": "Agency Client",
          "longName": "Agency Client"
        },
        {
          "id": 32,
          "shortName": "PropMgmt.TransactionUploader",
          "longName": "PropMgmt.TransactionUploader"
        },
        {
          "id": 33,
          "shortName": "PropMgmt.Internal",
          "longName": "PropMgmt.Internal"
        },
        {
          "id": 123,
          "shortName": "PropMgmt.Accounting",
          "longName": "PropMgmt.Accounting"
        },
        {
          "id": 124,
          "shortName": "PropMgmt.PermissionsManager",
          "longName": "PropMgmt.PermissionsManager"
        },
        {
          "id": 125,
          "shortName": "PropMgmt.YardiAdmin",
          "longName": "PropMgmt.YardiAdmin"
        },
        {
          "id": 127,
          "shortName": "PropMgmt.FileConfigManager",
          "longName": "PropMgmt.FileConfigManager"
        },
        {
          "id": 139,
          "shortName": "Loan Servicing: Loan",
          "longName": "Loan Servicing: Loan"
        },
        {
          "id": 141,
          "shortName": "PropMgmt.Admin",
          "longName": "PropMgmt.Admin"
        },
        {
          "id": 142,
          "shortName": "Loan Servicing: Borrower",
          "longName": "Loan Servicing: Borrower"
        },
        {
          "id": 937,
          "shortName": "PropMgmt.ViewOnly",
          "longName": "PropMgmt.ViewOnly"
        },
        {
          "id": 998,
          "shortName": "PropMgmt.FileProccessing",
          "longName": "PropMgmt.FileProccessing"
        }
      ],
      "-1": [
        {
          "id": 1,
          "shortName": "Investor",
          "longName": "Investor"
        },
        {
          "id": 2,
          "shortName": "Secondary",
          "longName": "Secondary"
        },
        {
          "id": 3,
          "shortName": "Accountant",
          "longName": "Accountant"
        },
        {
          "id": 4,
          "shortName": "Primary",
          "longName": "Primary"
        },
        {
          "id": 5,
          "shortName": "Legal",
          "longName": "Legal"
        },
        {
          "id": 6,
          "shortName": "Investment Advisor",
          "longName": "Investment Advisor"
        },
        {
          "id": 7,
          "shortName": "Tax",
          "longName": "Tax"
        },
        {
          "id": 8,
          "shortName": "Custodian",
          "longName": "Custodian"
        },
        {
          "id": 9,
          "shortName": "Account Contact",
          "longName": "Account Contact"
        },
        {
          "id": 10,
          "shortName": "Trustee",
          "longName": "Trustee"
        },
        {
          "id": 11,
          "shortName": "Co-Trustee",
          "longName": "Co-Trustee"
        }
      ]
    })
  }
}
