import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from "@angular/core";
import { lastValueFrom, of, switchMap, tap } from "rxjs";
import { SessionProvider } from "./session.provider";
import { AuthCoreModule, AuthEventType, AuthEvents } from "@alterdomus/auth/core";
import { SessionService } from "./session.service";
import { RouterModule } from "@angular/router";

function loadSessionFactory(authEvents: AuthEvents, sessionService: SessionService) {
  return () => {
    // return authEvents.events.pipe(
    //   tap(event => {
    //     if (event.type == AuthEventType.SessionLoaded) {
    //       sessionService.applySession(event.data);
    //     }
    //   })
    // )
    // const session$ = sessionService.loadSession();
    
    // return session$;
  }
}

@NgModule({
  imports: [
    CommonModule,
    AuthCoreModule
  ],
  declarations: [],
  exports: [],
})
export class SessionModule {
  static forRoot(sessionProvider?: SessionProvider): ModuleWithProviders<SessionModule> {
    const providers = [];
    if (sessionProvider) {
      providers.push({ provide: SessionProvider, useExisting: sessionProvider });
    }
    // providers.push({ provide: APP_INITIALIZER, useFactory: loadSessionFactory, deps: [AuthEvents, SessionService], multi: true });
    return {
      ngModule: SessionModule,
      providers: providers
    }
  }
}
