import { Component, ViewEncapsulation, Input, SimpleChanges } from '@angular/core';
import { IconHandler, IconType } from './icon';
import { CommonModule } from '@angular/common';

export type IconSize = 'base' | ''

@Component({
    selector: 'Icon',
    template: `
    <svg *ngIf="name" class="inline-block w-full h-full text-inherit fill-none" [ngClass]="styleClass" [ngStyle]="style">
        <use xmlns:xlink="https://www.w3.org/1999/xlink" attr.xlink:href="#{{ name }}"></use>
    </svg>
    <ng-content></ng-content>
`,
    styles: [
        `
        @keyframes icon-rotate-animation {
            100% {
                transform: rotate(360deg);
            }
}
        .spin-rotate{
            animation: icon-rotate-animation 3s linear infinite;
            transform-origin:center center;
        }
        .logo-rotate{
            animation: icon-rotate-animation 1.5s linear infinite;
            transform-origin:center center;
        }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    providers: [IconHandler],
    standalone: true,
    imports: [CommonModule]
})
export class IconComponent {
    private _type: IconType;

    @Input()
    color: string = '';
    @Input()
    get name(): IconType {
        return this._type;
    }
    set name(value) {
        this._type = value;
    }
    @Input()
    styleClass: string = '';

    @Input()
    style: any;

    constructor(private _iconHandler: IconHandler) {
        this._iconHandler.load();
    }

    // get iconStyleClass() {
    //     return `a-icon-${this._type} ${this.styleClass}`;
    // }
}
