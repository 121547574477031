
import { AccountType, ApprovalDto, MixApprovalDto, ObsEntityDto, ObsProfileTab, Operation, ProfileType, ResponseMessage, TabBase } from './swagger-gen';

export const AccountTypeStr = {
  [AccountType.Investor]: 'Account',
  [AccountType.Opportunities]: 'Opportunity',
  [AccountType.AdditionalRelationships]: 'Additional Relationship',
}

export interface ApprovalDetailDto extends ApprovalDto {
  pendingData?: any;
  originData?: any;
  groupId?: any;
  isParent?: boolean | null;
  incompatible?: boolean;
}

export interface GenericApprovalDto<T> extends ApprovalDto {
  incompatible?: boolean;
  pendingData?: T;
}


export interface GenericProfileTab<T> extends ObsProfileTab {
  data: T;
  pendingApproval: GenericApprovalDto<T>;
}

export interface BasicProfileTab extends GenericProfileTab<any> {
}

export interface GenericTab<T> extends TabBase {
  id?: number;
  title?: string | null;
  attributes?: { [key: string]: any; } | null;
  params?: any | null;
  data?: T | null;
  pendingApproval?: MixApprovalDto | null;
  responseMessage?: ResponseMessage | null;
}

export interface GenericTabDataDto<T> {
  id: number,
  name: string,
  title: string,
  operations: Operation[],
  parameters: any,
  data: T
}

export enum ComRequestEmailIssue {
  NoIssue = 0,
  SubjectEmpty = 1,
  SubjectToLong = 2,
  ToTooLong = 4,
  CCTooLong = 8,
  BCCTooLong = 16,
  AttachmentOverSize = 32,
  DocumentPathTooLong = 64,
}

export const EmailIssues = {
  [ComRequestEmailIssue.AttachmentOverSize]: 'Attachment size too big to send',
  [ComRequestEmailIssue.BCCTooLong]: 'BCC address is too long',
  [ComRequestEmailIssue.CCTooLong]: 'CC address is too long',
  [ComRequestEmailIssue.DocumentPathTooLong]: 'Some documents path are too long',
  [ComRequestEmailIssue.SubjectEmpty]: 'No mail subject',
  [ComRequestEmailIssue.SubjectToLong]: 'Mail subject is too long',
  [ComRequestEmailIssue.ToTooLong]: 'To address is too long',
}


export const accountReadonlyStatus: string[] = ['Needs Additional Info', 'Transferred', 'Redeemed', 'InActive'];


export interface ProfileBaseDto extends ObsEntityDto {
  profileType?: ProfileType;
  name?: string | null;
  summary1?: string | null;
  summary2?: string | null;
  mcpid?: string | null;
  balance?: number;
}


