import { CalendarHeader, DatePickerModule, LuxonDateAdaptor } from '@alterdomus/common-ui/date-picker';
import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, ModuleWithProviders, NgModule, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { DateRange, MAT_DATE_RANGE_SELECTION_STRATEGY, MAT_RANGE_DATE_SELECTION_MODEL_PROVIDER, MatDateRangeInput, MatDateRangePicker, MatDateSelectionModel, MatDatepickerModule, MatEndDate, MatStartDate } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import * as _ from 'lodash-es';
import { distinctUntilChanged } from 'rxjs';
import { DateRangePickerContent as DaterangePickerContent } from './date-range-picker-content';
import { DATE_RANGE_PICKER_OPTION } from './date-range-picker-option';
import { DateRangeValidatorDirective } from './date-range-validator';
import { DuoCalendarDirective } from './duo-calendar.directive';
import { DuoDirectionRangeSelectionStrategy } from './duo-direction-range-strategy';

export const DATERANGEPICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateRangePickerComponent),
  multi: true
};

@Component({
  selector: 'a-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DATERANGEPICKER_VALUE_ACCESSOR,
    MAT_RANGE_DATE_SELECTION_MODEL_PROVIDER,
    { provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DuoDirectionRangeSelectionStrategy },
    { provide: DATE_RANGE_PICKER_OPTION, useValue: { duoDirectionSelectionStrategy: true } }
  ]
})
export class DateRangePickerComponent implements OnInit, ControlValueAccessor {
  // range = new FormGroup({
  //   start: new FormControl<Date | null>(null, { updateOn: 'blur' }),
  //   end: new FormControl<Date | null>(null, { updateOn: 'blur' }),
  // });


  customHeader = CalendarHeader;

  @Input()
  disabled = false;

  @Input()
  minDate: Date | null = null;

  @Input()
  maxDate: Date | null = null;

  @Input()
  readonly = false;

  @Input()
  showClear = true;

  @Input()
  startPlaceholder = 'dd-mmm-yyyy';

  @Input()
  endPlaceholder = 'dd-mmm-yyyy';

  @ViewChild('input', { static: true })
  input!: MatDateRangeInput<any>;

  @ViewChild(MatDateRangePicker, { read: MatDateSelectionModel, static: true })
  model: MatDateSelectionModel<DateRange<Date>, Date>;

  @ViewChild(MatDateRangePicker, { static: true })
  picker: MatDateRangePicker<Date>;

  @Output()
  onRangeChanged = new EventEmitter<DateRange<Date>>();

  @Input()
  value = new DateRange<Date>(null, null);

  @ViewChild('start', { read: MatStartDate, static: true })
  startDateInput: MatStartDate<Date>;


  constructor(private cd: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.model.selectionChanged
      .pipe(
        distinctUntilChanged((x, y) =>
          _.isEqual(x.selection.start, y.selection.start) &&
          _.isEqual(x.selection.end, y.selection.end) &&
          y.source !== this.input),
      )
      .subscribe(val => {
        if (!(val.source instanceof MatEndDate || val.source instanceof MatStartDate)) {
          if (!(this.value?.start == val.selection?.start && this.value?.end == val.selection?.end)) {
            this.value = val.selection;
            this.onModelChange(val.selection);
            this.onRangeChanged.emit(val.selection);
          }
        }
      })
  }

  clickInput(event: FocusEvent, picker: MatDateRangePicker<Date>) {
    if (this.readonly && !picker.opened) {
      picker.open();
    }
  }

  clear(event: MouseEvent) {
    this.model.updateSelection(new DateRange<Date>(null, null), event);
    this.cd.markForCheck();
  }

  onBlur(input: HTMLInputElement, type: 'start' | 'end') {

    this.model.updateSelection(this.model.selection, this.input);
    if (type === 'start') {
      if (!this.model.selection.start) {
        input.value = '';
      }
    }
    if (type === 'end') {
      if (!this.model.selection.end) {
        input.value = '';
      }
    }

  }


  // Reactive form interfaces
  public onModelChange: Function = () => { };

  public onModelTouched: Function = () => { };

  writeValue(value: any): void {
    this.value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }
  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.cd.markForCheck();
  }
}

@NgModule({
  imports: [CommonModule, A11yModule, ReactiveFormsModule,
    PortalModule, MatButtonModule, MatNativeDateModule,
    MatDatepickerModule, MatFormFieldModule, DatePickerModule],
  declarations: [DateRangePickerComponent, DuoCalendarDirective, DaterangePickerContent, DateRangeValidatorDirective],
  exports: [DateRangePickerComponent, DateRangeValidatorDirective]
})
export class DateRangePickerModule {
  static withCustomFormat(): ModuleWithProviders<DateRangePickerModule> {
    return {
      ngModule: DateRangePickerModule,
      providers: [
        {
          provide: MAT_DATE_FORMATS, useValue: {
            ...MAT_NATIVE_DATE_FORMATS, display: {
              ...MAT_NATIVE_DATE_FORMATS.display,
              dateInput: true   // true to indicate format on range display
            }
          }
        },
        { provide: DateAdapter, useClass: LuxonDateAdaptor }
      ]
    };
  }
  // static withCustomStrategy(strategy: Type<MatDateRangeSelectionStrategy<Date>> = DuoDirectionRangeSelectionStrategy<Date>): ModuleWithProviders<DateRangePickerModule> {
  //   return {
  //     ngModule: DateRangePickerModule,
  //     providers: [
  //       {
  //         provide: MAT_DATE_FORMATS, useValue: {
  //           ...MAT_NATIVE_DATE_FORMATS, display: {
  //             ...MAT_NATIVE_DATE_FORMATS.display,
  //             dateInput: true   // true to indicate format on range display
  //           }
  //         }
  //       },
  //       { provide: DateAdapter, useClass: LuxonDateAdaptor },
  //       { provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: strategy }
  //     ]
  //   };
  // }
}
