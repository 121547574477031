import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FilterService } from 'primeng/api';

@Component({
  selector: 'a-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SearchBoxComponent {

  @Input()
  label = '';

  @Input()
  items: any[] = [];

  @Input()
  searchKey = '';

  @Input()
  searchFields: string[] = [];

  @Input()
  disabled = false;

  @Input()
  filter: 'startsWith' | 'contains' = 'contains';

  @Input()
  field: any = null;

  @Input()
  showDropdown = false;

  @Input()
  styleClass = "";

  @Input()
  placeholder = 'Search here...';

  @Input()
  showClear: boolean = false;

  @Output()
  onSearch = new EventEmitter<string>();

  @Output()
  onItemFiltered = new EventEmitter<any[]>();


  results: any[] = [];
  dropdownItems: any[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private filterService: FilterService
  ) { }

  search(event: { query: string; }) {
    this.searchKey = event.query;
    this.results = this.filterItems(this.searchKey);

    this.updateUI();
  }

  clearSearch(emitEvent = true) {
    this.results = this.items;
    this.searchKey = '';

    this.updateUI(emitEvent);
  }
  private updateUI(emitEvent = true) {
    if (this.showDropdown) {
      this.dropdownItems = this.results;
    }
    else {
      this.dropdownItems = [];
    }
    if (emitEvent) {
      this.onSearch.next(this.searchKey);
      this.onItemFiltered.next(this.results);
    }
    this.cd.detectChanges();
  }

  private filterItems(key: string) {
    let results: any[] = [];
    if (this.items && this.items.length > 0 && key) {
      if (this.searchFields && this.searchFields.length > 0) {
        results = this.filterService.filter(
          this.items,
          this.searchFields,
          key,
          this.filter
        );
      }
      else {
        results = this.items.filter(item => this.filterService.filters[this.filter](item, key));
      }
    }
    return results;
  }
}
