import { OperationWatcher, OperationWatcherModule } from '@alterdomus/common-ui/operation-watcher';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { Menu, MenuModule } from 'primeng/menu';
import { AlignOnTopDirective } from './align-on-top.directive';

@Component({
  selector: 'a-float-action-buttons',
  templateUrl: './float-action-buttons.component.html',
  styleUrls: ['./float-action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatActionButtonsComponent implements OnInit {
  @Input()
  menus: MenuItem[] = [];

  @Input()
  watchOperation = true

  @Input()
  dock = false;

  subMenus: MenuItem[] = [];

  @ViewChild('menu', { static: true })
  menu!: Menu;

  private buttonsPanelOverlayRef?: OverlayRef;

  @ViewChild('buttonsPanelTemplate', { static: true })
  buttonsPanelTemplate!: TemplateRef<any>;

  constructor(
    private operationWatcher: OperationWatcher,
    private overlay: Overlay,
    private router: Router,
    public viewContainerRef: ViewContainerRef) {

  }
  ngOnInit(): void {
    if (this.menu) {
      // float action button don't have second level menu for now
      this.menu.hasSubMenu = () => false;
    }
  }

  toggleMenu(event: MouseEvent, menu: Menu, fab: MenuItem) {
    this.subMenus = fab.items || [];
    if (this.watchOperation) {
      const watchMenu = (menuItem: MenuItem | any, level = 0) => {
        const subMenus = menuItem.items?.length > 0 ? menuItem.items : (menuItem.state?.subMenus || [])
        if (!menuItem.url && !menuItem.routerLink && !menuItem.command) {
          if (subMenus.length > 0) {
            if (subMenus.length == 1) {
              // click as the only sub menu
              const operation = { name: subMenus[0].state!['name'], label: subMenus[0].label, state: subMenus[0].state, originData: subMenus[0] };
              menuItem.command = () => this.operationWatcher.emitOperation(operation);
            }
            else {
              menuItem.command = () => this.popupButtonsPanel(subMenus!);
            }
          }
          else {
            menuItem.command = () => this.operationWatcher.emitOperation({ name: menuItem.state!['name'], label: menuItem.label, state: menuItem.state, data: menuItem })
          }
        }
        else if (menuItem.url) {
          if (menuItem.url.startsWith('http')) {
            menuItem.command = () => window.open(menuItem.url);
          }
          else {
            menuItem.command = () => this.router.navigate([menuItem.url]);
          }
        }
        if (subMenus.length > 1) {
          subMenus.forEach((p: any) => watchMenu(p));
        }
      };
      this.subMenus.forEach(menu => watchMenu(menu, 1));
    }
    if (this.subMenus.length > 0) {
      menu.toggle(event);
    }
  }
  popupButtonsPanel(menus: MenuItem[]) {
    this.buttonsPanelOverlayRef = this.overlay.create(new OverlayConfig({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true,
    }))
    this.buttonsPanelOverlayRef.attach(new TemplatePortal(this.buttonsPanelTemplate, this.viewContainerRef, { $implicit: menus }))
    this.buttonsPanelOverlayRef.backdropClick().subscribe(() => this.buttonsPanelOverlayRef!.detach());
  }

  panelButtonClick(event: MouseEvent, item: MenuItem) {
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }
    if (this.buttonsPanelOverlayRef) {
      this.buttonsPanelOverlayRef.detach();
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule, MatButtonModule, MatIconModule,
    MenuModule, ButtonModule, OperationWatcherModule],
  declarations: [FloatActionButtonsComponent, AlignOnTopDirective],
  exports: [FloatActionButtonsComponent],
})
export class FloatActionButtonsModule { }
