import { getGlobalDateFormat } from '@alterdomus/shared/pipes';
import { Inject, Injectable, Optional } from "@angular/core";
import { MAT_DATE_LOCALE, NativeDateAdapter } from "@angular/material/core";
import { DateTime } from "luxon";

@Injectable()
export class LuxonDateAdaptor extends NativeDateAdapter {
  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string
  ) {
    super(matDateLocale, undefined)
  }

  override format(date: Date, displayFormat: object): string {
    if (!this.isValid(date)) {
      throw Error('NativeDateAdapter: Cannot format invalid date.');
    }

    const customFormat = typeof displayFormat === 'boolean' && displayFormat && getGlobalDateFormat();
    if (customFormat) {
      const luxonDate = DateTime.fromJSDate(date);
      return luxonDate.toFormat(customFormat);
    }
    else {
      return super.format(date, displayFormat);
    }
  }

  override isValid(date: Date): boolean {
    if (typeof date === 'string') {
      return super.isValid(DateTime.fromFormat(date, getGlobalDateFormat()).toJSDate());
    }
    return super.isValid(date);
  }

  override parse(value: any, parseFormat?: any): Date | null {
    let date = super.parse(value, parseFormat);
    if ((!date || isNaN(date.getTime())) && value) {
      date = this.arbitaryPaseDate(value);
    }
    return date;
  }

  private arbitaryPaseDate(value: any) {
    const formats = ['ddMMMyy', 'ddMMyyyy', 'yyyyMMdd'];
    for (const format of formats) {
      const luxonDate = DateTime.fromFormat(value, format);
      if (luxonDate.isValid) {
        return luxonDate.toJSDate();
      }
    }
    return null;
  }
}
