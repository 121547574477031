import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'a-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  footerConfig = {
    'US': {
      copyRight: `© 2008 - ${new Date().getFullYear()} Alter Domus (US) LLC ("Alter Domus")`,
      emailAddress: `CorProSupport@alterDomus.com`,
      emailLink: `mailto:CorProSupport@alterDomus.com`,
      phoneNumber: `T: +1 312-262-3200`,
      phoneNumberLink: `tel:13122623200`,
      supportTime: `Hours of Operation: 8am – 5pm CST`,
    },
    'EMEA': {
      copyRight: `© ${new Date().getFullYear()} Alter Domus`,
      emailAddress: `CorProGlobalSupport@alterdomus.com`,
      emailLink: `mailto:CorProGlobalSupport@alterdomus.com`,
      phoneNumber: `T: +3524818286060`,
      phoneNumberLink: `tel:+3524818286060`,
      supportTime: `Hours of Operation: 6am – 11pm CET`,
    }
  }
  copyRight = 'copyRight';
  emailAddress = 'emailAddress';
  emailLink = 'emailLink';
  phoneNumber = 'phoneNumber';
  phoneNumberLink = 'phoneNumberLink';
  supportTime = 'supportTime';


  _location: 'EMEA' | 'US' = 'US';
  @Input()
  get location(): 'EMEA' | 'US' {
    return this._location;
  }
  set location(val: 'EMEA' | 'US') {
    if (val != this._location) {
      this._location = val;
      this.settingFooter();
    }
  }


  ngOnInit(): void {
    this.settingFooter();
  }

  settingFooter() {
    Object.assign(this, this.footerConfig[this.location]);
  }


}
