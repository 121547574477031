import { CommonModule } from '@angular/common';
import { Directive, Input, NgModule, Optional } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Listbox, ListboxModule as PListboxModule } from 'primeng/listbox';

/*
 * This directive is used to override the default behavior of the listbox component
 * which has performance issues when the number of options is greater than 50.
 * this is test in version 16.9.1 and used in formly-ext list-box component
 */

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'p-listbox[maxSelectAll]' })
export class ListBoxMaxSelectAllDirective {
  @Input()
  maxSelectAll?: number;

  constructor(@Optional() private listbox: Listbox) {
    if (this.listbox) {
      toObservable(this.listbox.visibleOptions).pipe(takeUntilDestroyed()).subscribe(options => {
        (this.listbox as any)['toggleAllDisabled'] = this.maxSelectAll && options?.length > this.maxSelectAll;
      })
    }
  }
}

@NgModule({
  imports: [CommonModule, PListboxModule],
  declarations: [ListBoxMaxSelectAllDirective],
  exports: [ListBoxMaxSelectAllDirective, PListboxModule],
})
export class ListboxModule { }
