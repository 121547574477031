import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AuthEvent, AuthEventType } from "./auth.event";

@Injectable({ providedIn: 'root' })
export class AuthEvents {
  private eventSub = new Subject<AuthEvent>();
  events = this.eventSub.asObservable();
  emitEvent(event: AuthEvent) {
    this.eventSub.next(event);
  }

  login() {
    this.eventSub.next({ type: AuthEventType.RequireLogin });
  }
}
