import { FundQuery } from "@alterdomus/crm-shared/fund-store";
import { Injectable } from "@angular/core";
import { combineQueries, QueryEntity } from "@datorama/akita";
import { map } from "rxjs";
import { AccountState, AccountStore } from "./account.store";

@Injectable({ providedIn: 'root' })
export class AccountQuery extends QueryEntity<AccountState> {

  constructor(protected override store: AccountStore,
    private fundQuery: FundQuery
  ) {
    super(store);
  }

  selectAccounts() {
    return this.selectAccountProfiles(false);
  }

  selectOpportunities() {
    return this.selectAccountProfiles(true);
  }

  private selectAccountProfiles(opportunity?: boolean) {
    return combineQueries([
      this.selectAll(),
      this.fundQuery.select(p => p.selectedClient)
    ]).pipe(
      map(([acconts, clientId]) => {
        return acconts.filter(account => (!clientId || account.fund.client?.id === clientId) && (opportunity === undefined || account.fund?.isDataRoom == opportunity));
      })
    )
  }
}
