<!-- <div *ngIf="user" [id]="elementId" (pointerdown)="triggerMenu($event,menu)" [title]="user.userName"
  class="h-full hover:bg-slate-50 items-center justify-center flex cursor-pointer"
  [ngClass]="{'rounded-3xl w-10 h-10':avatorMode!='fullName','px-2':avatorMode=='fullName'}" [innerHTML]="avator"></div>
<p-menu #menu [popup]="true" [model]="items"></p-menu> -->
<p-menubar #menubar [styleClass]="styleClass" [model]="profileMenu" [autoDisplay]="false">

  <ng-template pTemplate="menuicon">
    <ng-container *ngIf="avatorMode!=='icon';else image">{{avator}}</ng-container>
    <ng-template #image>
      <img class="rounded-3xl w-8 h-8 hover:bg-slate-50 items-center justify-center flex" [src]="avatorUrl" />
    </ng-template>

  </ng-template>
</p-menubar>
