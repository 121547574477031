import { LookupTypes } from "@alterdomus/crm-shared/lookup-code";

export const bankingFieldsMapping = [
  {
    id: 0,
    key: 'BankName',
    label: 'Primary Bank - Primary Bank Name',
    expanded: true,
    data: 'BankName',
  },
  {
    id: 0,
    key: 'IBANCode',
    label: 'Primary Bank - IBAN Code',
    expanded: true,
    data: 'IBANCode',
  },
  {
    id: 0,
    key: 'BeneficiaryAccountName',
    label: 'Primary Bank - Account Name',
    expanded: true,
    data: 'BeneficiaryAccountName',
  },
  {
    id: 0,
    key: 'BeneficiaryAccountNumber',
    label: 'Primary Bank - Account Number',
    expanded: true,
    data: 'BeneficiaryAccountNumber',
  },
  {
    id: 0,
    key: 'BankSwiftCode',
    label: 'Primary Bank - Bank Swift / BIC Code',
    expanded: true,
    data: 'BankSwiftCode',
  },
  {
    id: 0,
    key: 'BeneficiaryCountry',
    label: 'Primary Bank - Bank Country',
    expanded: true,
    data: 'BeneficiaryCountry',
    type: 'select',
    lookupType: LookupTypes.Country
  },
  {
    id: 0,
    key: 'BankABACode',
    label: 'Primary Bank - Bank ABA Code',
    expanded: true,
    data: 'BankABACode',
  },
  {
    id: 0,
    key: 'SortCode',
    label: 'Primary Bank - Sort Code',
    expanded: true,
    data: 'SortCode',
  },
  {
    id: 0,
    key: 'ForFurtherCredit',
    label: 'Primary Bank - For Further Credit',
    expanded: true,
    data: 'ForFurtherCredit',
  },
  {
    id: 0,
    key: 'BankOther',
    label: 'Primary Bank - Bank Other',
    expanded: true,
    data: 'BankOther',
  },
  {
    id: 0,
    key: 'DistributionMethod',
    label: 'Primary Bank - Distribution Method',
    expanded: true,
    data: 'DistributionMethod',
  },
  {
    id: 0,
    key: 'OtherBankCode',
    label: 'Primary Bank - Other Bank Code (BSB, CLABE, IFSC, CNAPS)',
    expanded: true,
    data: 'OtherBankCode',
  },
  {
    id: 0,
    key: 'PrimaryCurrency',
    label: 'Primary Bank -Currency',
    expanded: true,
    data: 'PrimaryCurrency',
    type: 'select',
    lookupType: LookupTypes.Currency
  },
  {
    id: 0,
    key: 'IntermediaryBankName',
    label: 'Intermediary Bank - Intermediary Bank Name',
    expanded: true,
    data: 'IntermediaryBankName',
  },
  {
    id: 0,
    key: 'IntermediaryAccountName',
    label: 'Intermediary Bank - Intermediary Account Name',
    expanded: true,
    data: 'IntermediaryAccountName',
  },
  {
    id: 0,
    key: 'IntermediaryAccountNumber',
    label: 'Intermediary Bank - Intermediary Bank Account Number',
    expanded: true,
    data: 'IntermediaryAccountNumber',
  },
  {
    id: 0,
    key: 'IntermediaryBankSwiftCode',
    label: 'Intermediary Bank - Intermediary Bank SWIFT / BIC',
    expanded: true,
    data: 'IntermediaryBankSwiftCode',
  },
  {
    id: 0,
    key: 'IntermediaryBankCountry',
    label: 'Intermediary Bank - Bank Country',
    expanded: true,
    data: 'IntermediaryBankCountry',
    type: 'select',
    lookupType: LookupTypes.Country
  },
  {
    id: 0,
    key: 'IntermediaryBankABACode',
    label: 'Intermediary Bank - Intermediary Bank ABA',
    expanded: true,
    data: 'IntermediaryBankABACode',
  },
  {
    id: 0,
    key: 'IntermediaryBankSortCode',
    label: 'Intermediary Bank - Sort Code',
    expanded: true,
    data: 'IntermediaryBankSortCode',
  },
  {
    id: 0,
    key: 'IntermediaryOtherBankCode',
    label: 'Intermediary Bank - Other Bank Code (BSB, CLABE, IFSC, CNAPS)',
    expanded: true,
    data: 'IntermediaryOtherBankCode',
  },
  {
    id: 0,
    key: 'IntermediaryIBAN',
    label: 'Intermediary Bank - IBAN',
    expanded: true,
    data: 'IntermediaryIBAN',
  },
  {
    id: 0,
    key: 'IntermediaryCurrency',
    label: 'Intermediary Bank - Currency',
    expanded: true,
    data: 'IntermediaryCurrency',
    type: 'select',
    lookupType: LookupTypes.Currency
  },
  {
    id: 0,
    key: 'BeneficiaryStreet',
    label: 'Additional Information - Bank Address',
    expanded: true,
    data: 'BeneficiaryStreet',
  },
  {
    id: 0,
    key: 'BranchNumber',
    label: 'Additional Information - Branch Number',
    expanded: true,
    data: 'BranchNumber',
  },
  {
    id: 0,
    key: 'AdvisorNumber',
    label: 'Additional Information - Advisor Number',
    expanded: true,
    data: 'AdvisorNumber',
  },
  {
    id: 0,
    key: 'RepresentativeName',
    label: 'Additional Information - Representative Name',
    expanded: true,
    data: 'RepresentativeName',
  },
  {
    id: 0,
    key: 'BrokerDealer',
    label: 'Additional Information - Broker Dealer',
    expanded: true,
    data: 'BrokerDealer',
  },
  {
    id: 0,
    key: 'MonthlyInterestPayment',
    label: 'Additional Information - Monthly Interest Payment',
    expanded: true,
    data: 'MonthlyInterestPayment',
  },
  {
    id: 0,
    key: 'InvestedCapitalPayment',
    label: 'Additional Information - Invested Capital Payment',
    expanded: true,
    data: 'InvestedCapitalPayment',
  },
  {
    id: 0,
    key: 'QualifiedPurchaser',
    label: 'Additional Information - Qualified Purchaser',
    expanded: true,
    data: 'QualifiedPurchaser',
    type: 'switch'
  },
  {
    id: 0,
    key: 'QualifiedInstitutionalBuyer',
    label: 'Additional Information - Qualified Institutional Buyer',
    expanded: true,
    data: 'QualifiedInstitutionalBuyer',
    type: 'switch'
  },
];
