import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Inject, Injectable, Injector, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LOADING_KEY, loadingData } from './loading-data';
import { LoadingComponent } from './loading/loading.component';
const defaultKey = '$default$';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private progressSub: { [key: string]: BehaviorSubject<loadingData> } = { [defaultKey]: new BehaviorSubject(<loadingData>{}) };
  progress$ = (key: string | undefined) => this.progressSub[key || defaultKey]?.asObservable();

  // private loadingPortal: ComponentPortal<LoadingComponent> = new ComponentPortal(LoadingComponent);
  private loadingCmpRef?: ComponentRef<LoadingComponent>;
  private portalHost?: DomPortalOutlet;

  spinIcon: 'spin' | 'logoIP' = 'spin';

  setSpinIcon(icon: 'spin' | 'logoIP') {
    this.spinIcon = icon;
  }


  private outlests: { [key: string]: DomPortalOutlet } = {};

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(DOCUMENT) private document: Document,
    private appRef: ApplicationRef,
    private injector: Injector) { }

  showProgress(progress: number, element: string | HTMLElement, block = false, progressTemp?: TemplateRef<any>) {
    const { outlet, outletKey } = this.getOrCreateOutlets(element);
    if (!outlet.hasAttached()) {
      this.loadingCmpRef = outlet.attach(new ComponentPortal(LoadingComponent, null,
        Injector.create({ providers: [{ provide: LOADING_KEY, useValue: outletKey }] })));
    }
    if (progress > 0) {
      this.progressSub[outletKey || defaultKey].next({ progress: progress, spin: false, block: block });
    }
    else if (progress == -1) {
      this.progressSub[outletKey || defaultKey].next({ progress: 0, spin: true, block: true });
    }
  }
  reset(element: string | HTMLElement) {
    const { outlet, outletKey } = this.getOrCreateOutlets(element);
    if (outlet) {
      this.progressSub[outletKey || defaultKey].next({ progress: 0, spin: false, block: false });
      outlet.detach();
    }
  }

  grow(element: string | HTMLElement) {
    const { outlet, outletKey } = this.getOrCreateOutlets(element);
    if (!outlet.hasAttached()) {
      this.loadingCmpRef = outlet.attach(new ComponentPortal(LoadingComponent, null,
        Injector.create({ providers: [{ provide: LOADING_KEY, useValue: outletKey }] })));
    }
    const currentValue = this.progressSub[outletKey || defaultKey].getValue();
    if (currentValue && currentValue.progress && currentValue.progress < 100 && currentValue.progress >= 0) {
      this.progressSub[outletKey || defaultKey].next({ progress: Math.min(currentValue.progress + 10, 100), spin: false, block: true });
    }
  }

  private getOrCreateOutlets(element: string | HTMLElement) {
    let outletElement: HTMLElement;
    let outletKey: string | undefined = undefined;
    if (element instanceof HTMLElement) {
      outletElement = element;
    }
    else {
      outletElement = this.document.getElementById(element) || this.document.body;
      outletKey = element;
    }
    let outlet = !outletKey ? this.portalHost : this.outlests[outletKey];
    if (outlet && outlet.outletElement != outletElement) {
      outlet.detach();
      outlet = undefined;
    }
    if (!outlet) {
      outlet = new DomPortalOutlet(outletElement,
        this.componentFactoryResolver,
        this.appRef,
        this.injector);
      if (!outletKey) {
        this.portalHost = outlet;
      }
      else {
        if (!this.progressSub[outletKey]) {
          this.progressSub[outletKey] = new BehaviorSubject({});
        }
        this.outlests[outletKey] = outlet;
      }
    }
    return { outlet, outletKey };
  }
}
