<div class="a-calendar-header mat-calendar-controls flex items-center justify-between gap-1 text-blue-500 ">
  <button mat-icon-button type="button" *ngIf="_calendar.currentView !== 'year'" [disabled]="!previousEnabled('year')"
    (click)="previousClicked('year')" [attr.aria-label]="periodButtonLabel">
    <mat-icon>keyboard_double_arrow_left</mat-icon>
  </button>
  <button mat-icon-button type="button" *ngIf="_calendar.currentView !== 'multi-year'"
    [disabled]="!previousEnabled('month')" (click)="previousClicked('month')" [attr.aria-label]="nextButtonLabel">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-button type="button" class="mat-calendar-period-button  flex-1" (click)="currentPeriodClicked()"
    [attr.aria-label]="periodButtonLabel" [attr.aria-describedby]="_periodButtonLabelId" aria-live="polite">
    <span aria-hidden="true">{{periodButtonText}}</span>
    <i class="fa-solid fa-chevron-down ml-1" [class.mat-calendar-invert]="_calendar.currentView !== 'month'"
      focusable="false" aria-hidden="true"></i>
    <!-- <svg class="mat-calendar-arrow" [class.mat-calendar-invert]="_calendar.currentView !== 'month'" viewBox="0 0 10 5"
      focusable="false" aria-hidden="true">
      <polygon points="0,0 5,5 10,0" />
    </svg> -->
    <!-- <svg class="mat-calendar-arrow" [class.mat-calendar-invert]="_calendar.currentView !== 'month'" focusable="false" aria-hidden="true" width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.25 15.688l-7.656 7.656-3.594-3.688 11.063-11.094 11.344 11.344-3.5 3.5z"></path>
    </svg> -->
  </button>
  <button mat-icon-button type="button" *ngIf="_calendar.currentView !== 'multi-year'"
    [disabled]="!nextEnabled('month')" (click)="nextClicked('month')" [attr.aria-label]="nextButtonLabel">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <button mat-icon-button type="button" *ngIf="_calendar.currentView !== 'year'" [disabled]="!nextEnabled('year')"
    (click)="nextClicked('year')" [attr.aria-label]="nextButtonLabel">
    <mat-icon>keyboard_double_arrow_right</mat-icon>
  </button>
</div>
