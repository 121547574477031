import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from './session.service';

export const AuthGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(SessionService).grantedOnUrl(state.url);
};

export const IsAdminGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(SessionService).IsAdmin$;
}