<div class="date-picker-common date-range-picker-container inline-flex items-center">
  <mat-date-range-input #input [min]="minDate" [max]="maxDate" [disabled]="disabled" [rangePicker]="picker"
    separator="">
    <input matStartDate #start [value]="value?.start" [min]="minDate" [max]="maxDate" [readonly]="readonly"
      (click)="clickInput($event,picker)" [placeholder]="startPlaceholder" (blur)="onBlur(start,'start')">
    <input matEndDate #end [value]="value?.end" [min]="minDate" [max]="maxDate" [readonly]="readonly"
      (click)="clickInput($event,picker)" [placeholder]="endPlaceholder" (blur)="onBlur(start,'end')">
  </mat-date-range-input>
  <i *ngIf="showClear" class="fa-solid fa-xmark mx-1 text-base cursor-pointer"
    [class.text-transparent]="disabled || !input.value || (!input.value.start && !input.value.end)"
    [class.text-charcoal-500]="!disabled && input.value && (input.value.start || input.value.end)"
    [ngClass]="{'hover:text-blue-500':!disabled && input.value && (input.value.start || input.value.end)}"
    (click)="clear($event)"></i>
  <mat-datepicker-toggle [disabled]="disabled" matIconSuffix [for]="picker">
    <i matDatepickerToggleIcon
      class="fa-solid fa-calendar text-base leading-4 absolute top-0 left-0 p-1 data-picker-common-toggle-icon"></i>
  </mat-datepicker-toggle>
  <mat-date-range-picker [calendarHeaderComponent]="customHeader" duo-calendar #picker
    panelClass="date-picker-common-panel">
    <mat-datepicker-actions>
      <button mat-raised-button color="primary" class="w-48" matDatepickerApply>Apply</button>
    </mat-datepicker-actions>
  </mat-date-range-picker>
</div>