import { CommonModule } from "@angular/common";
import { AfterViewInit, Directive, Input, NgModule, Optional } from "@angular/core";
import { Dropdown, DropdownModule as PDropdownModule } from "primeng/dropdown";
import { MultiSelect, MultiSelectModule as PMultiSelectModule } from "primeng/multiselect";
import { ObjectUtils } from 'primeng/utils';

const defaultOverlayOptions = {
  style: { 'max-width': 0 },
  listener(event: any, opt: { type: string, mode: string, valid: boolean }) {
    return (opt.mode != 'overlay' || opt.type != 'scroll') && opt.valid;
  },
  showTransitionOptions: "0ms",
  hideTransitionOptions: "0ms"
};

function isEmpty(value: any) {
  return value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0) || (!(Object.prototype.toString.call(value) === '[object Date]') && typeof value === 'object' && Object.keys(value).length === 0);
}
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'p-dropdown,p-multiSelect',
  standalone: true,
})
export class PDropdownMultiSelectHotfixDirective implements AfterViewInit {
  @Input()
  overlayOptionsOverride: any;
  rollbacks: (() => void)[] = [];
  constructor(
    @Optional() private pdropdown: Dropdown,
    @Optional() private pmultiselect: MultiSelect
  ) {

  }
  ngAfterViewInit() {
    this.applyHotfixes();
  }

  ngOnDestory() {
    this.rollback();
  }

  applyHotfixes() {
    this.hotfixForSpace();
    this.hotfixForOpenClass();
    this.hotfixForFilter();
    this.hotfixForScroll();
    this.hotfixForMultiSelectUncheck();
    this.hotfixForKeyDown();
  }

  //https://github.com/primefaces/primeng/issues/14377
  //issue in primeng@16.9.1
  hotfixForSpace() {
    if (this.pdropdown) {
      const original = this.pdropdown.onSpaceKey;
      this.pdropdown.onSpaceKey = (function (this: Dropdown, event: KeyboardEvent, pressedInInputText = false) {
        !this.editable && !pressedInInputText && this.onEnterKey(event);
      }).bind(this.pdropdown);
      this.rollbacks.push(() => {
        this.pdropdown.onSpaceKey = original;
      })
    }
  }

  hotfixForOpenClass() {
    if (this.pdropdown) {
      const { prop: origin, obj: container } = this.findPropertyDescriptor('containerClass', this.pdropdown) || {}
      if (origin) {
        Object.defineProperty(container, 'containerClass', {
          get() {
            return {
              'p-dropdown p-component p-inputwrapper': true,
              'p-disabled': this.disabled,
              'p-dropdown-clearable': this.showClear && !this.disabled,
              'p-focus': this.focused,
              'p-inputwrapper-filled': this.modelValue(),
              'p-inputwrapper-focus': this.focused || this.overlayVisible,
              'p-dropdown-open': this.overlayVisible,
            };
          },
          set: undefined,
          configurable: true,
          enumerable: false,
        });
        this.rollbacks.push(() => {
          Object.defineProperty(container, 'container', origin);
        });
      }
    }
    if (this.pmultiselect) {
      const { prop: origin, obj: container } = this.findPropertyDescriptor('containerClass', this.pmultiselect) || {}
      if (origin) {
        Object.defineProperty(container, 'containerClass', {
          get() {
            return {
              'p-multiselect p-component p-inputwrapper': true,
              'p-disabled': this.disabled,
              'p-multiselect-clearable': this.showClear && !this.disabled,
              'p-multiselect-chip': this.display === 'chip',
              'p-focus': this.focused,
              'p-inputwrapper-filled': isEmpty(this.modelValue()),
              'p-inputwrapper-focus': this.focused || this.overlayVisible,
              'p-multiselect-open': this.overlayVisible,
            };
          },
          set: undefined,
          configurable: true,
          enumerable: false,
        });
        this.rollbacks.push(() => {
          Object.defineProperty(container, 'container', origin);
        });
      }
    }

  }

  hotfixForMultiSelectUncheck() {
    if (this.pmultiselect) {
      const original = this.pmultiselect.onOptionSelect;
      this.pmultiselect.onOptionSelect = (event: any, ...remainging: any[]) => {
        const { originalEvent } = event;
        if (originalEvent) {
          originalEvent.stopPropagation();
        }
        original.call(this.pmultiselect, event, ...remainging);
      }
      this.rollbacks.push(() => {
        this.pmultiselect.onOptionSelect = original;
      })
    }
  }

  hotfixForEditable() {
    // if (this.pdropdown && this.pdropdown.editable && this.pdropdown.filter) {
    //     this.pdropdown.filter = false;
    // }
  }

  hotfixForScroll() {
    if (this.pdropdown) {
      this.pdropdown.overlayOptions = { ...defaultOverlayOptions as any, ...this.pdropdown.overlayOptions || {}, ...this.overlayOptionsOverride || {} };
    }
    if (this.pmultiselect) {
      this.pmultiselect.overlayOptions = { ...defaultOverlayOptions as any, ...this.pmultiselect.overlayOptions || {}, ...this.overlayOptionsOverride || {} };
    }
  }

  hotfixForFilter() {
    if (this.pdropdown) {
      if (
        this.pdropdown.filter &&
        !this.pdropdown.filterFields?.length
      ) {
        this.pdropdown.filterFields = !this.pdropdown.optionLabel?.length ? ['label'] : [this.pdropdown.optionLabel];
      }
    }
    if (this.pmultiselect) {
      if (
        this.pmultiselect.filter &&
        !this.pmultiselect.filterFields?.length
      ) {
        this.pmultiselect.filterFields = !this.pmultiselect.optionLabel?.length ? ['label'] : [this.pmultiselect.optionLabel];
      }
    }

  }

  hotfixForKeyDown() {
    if (this.pdropdown) {
      const original = this.pdropdown.onKeyDown;
      this.pdropdown.onKeyDown = (event: any, search: boolean) => {
        if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Delete', 'Home', 'End', 'PageDown', 'PageUp', 'Space', 'Enter', 'NumpadEnter', 'Escape', 'ShiftLeft', 'ShiftRight'].includes(event.code)) {
          original.call(this.pdropdown, event, search);
        }
        else if (event.code === 'Backspace') {
          if (!this.pdropdown.editable) {
            original.call(this.pdropdown, event, search);
          }
        }
        else if (event.code === 'Tab') {
          this.pdropdown.onTabKey(event, this.pdropdown.editable);
        }
        else {
          if (!event.metaKey && ObjectUtils.isPrintableCharacter(event.key)) {
            !this.pdropdown.overlayVisible && !this.pdropdown.editable && this.pdropdown.show();
            !this.pdropdown.editable && this.pdropdown.searchOptions(event, event.key);
          }
        }
      }
      this.rollbacks.push(() => {
        this.pdropdown.onKeyDown = original;
      })
    }
  }

  findPropertyDescriptor(propName: string, obj: any) {
    while (obj) {
      const prop = Object.getOwnPropertyDescriptor(obj, propName);
      if (prop) {
        return { prop, obj };
      }
      obj = Object.getPrototypeOf(obj);
    }
    return null;
  }

  rollback() {
    for (const rollback of this.rollbacks) {
      rollback();
    }
  }
}


/** Overrides to fix issue on primeng 16.9.1, might be removed in 17.0+ **/

@NgModule({
  imports: [CommonModule, PDropdownMultiSelectHotfixDirective, PDropdownModule],
  exports: [PDropdownMultiSelectHotfixDirective, PDropdownModule],
})
export class DropdownModule { }

@NgModule({
  imports: [CommonModule, PDropdownMultiSelectHotfixDirective, PMultiSelectModule],
  exports: [PDropdownMultiSelectHotfixDirective, PMultiSelectModule],
})
export class MultiSelectModule { }
