import { AuthGuard, IsAdminGuard } from '@alterdomus/auth/session';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('@alterdomus/crm-shared/dashboard').then(m => m.DashboardModule),
    title: 'DASHBOARD',
    canActivate: [AuthGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('src/app/account').then(m => m.AccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'fund',
    loadChildren: () => import('src/app/fund').then(m => m.FundModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'spv',
    loadChildren: () => import('@alterdomus/crm-shared/spv').then(m => m.SpvModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contact',
    loadChildren: () => import('src/app/user').then(m => m.UserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'communication',
    loadChildren: () => import('@alterdomus/crm-shared/com-request').then(m => m.ComRequestModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [IsAdminGuard]
  },
  // {
  //   path: 'report',
  //   loadChildren: () => import('@alterdomus/crm-shared/report').then(m => m.ReportModule),
  //   title: 'Report',
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: ':profile',
    loadChildren: () => import('@alterdomus/crm-shared/profile').then(m => m.ProfileModule),
  },

  // Debug pages

  // {
  //   path: 'emailtemplate',
  //   component: EmailTemplateManageComponent
  // },
  //


  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload', bindToComponentInputs: true, paramsInheritanceStrategy: 'always' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
