import { InjectionToken } from "@angular/core";

export interface AuthConfig {
  tokenKey: string,
  authenticationUrl: string,
  inited: boolean
}

export const defaultAuthConfig = <AuthConfig>{
  tokenKey: 'access-token',     // auth2 key will be access_token, but we will use access-token before oauth2
  authenticationUrl: ''
}

export const AUTH_CONFIG = new InjectionToken<AuthConfig>("AUTH_CONFIG");
