import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { AuthConfig, DefaultOAuthInterceptor, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { authAppInitializerFactory } from './auth-app-initializer.factory';
import { AuthService } from './auth.service';

@NgModule({
  imports: [
    CommonModule,
    OAuthModule.forRoot()
  ],
})
export class OAuth2Module {
  static forRoot(authConfig: AuthConfig, authModuleConfig: OAuthModuleConfig): ModuleWithProviders<OAuth2Module> {
    return {
      ngModule: OAuth2Module,
      providers: [
        { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: () => localStorage },
        // TODO: OAuthResourceServerErrorHandler implemented by OAuthNoopResourceServerErrorHandler not handled 401 yet.
        { provide: HTTP_INTERCEPTORS, useClass: DefaultOAuthInterceptor, multi: true }
      ]
    };
  }
}
