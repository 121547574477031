import { Directive, Optional } from "@angular/core";
import { Listbox } from 'primeng/listbox';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'p-listbox[skipshortkey]',
  standalone: true,
})
export class pListBoxSkipHotkeyDirective {
  constructor(
    @Optional() private listbox: Listbox
  ) {
    this.skiphotkey();
  }

  skiphotkey() {
    this.listbox.onListKeyDown = () => {
      return;
    }
  }
}
