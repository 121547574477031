import { slideDown, slideDownType } from '@alterdomus/common-ui/animation';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'a-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  animations: [slideDown()]
})
export class SearchBarComponent implements OnInit {
  private _key: string = '';
  @Input()
  get key() {
    return this._key;
  }
  set key(val) {
    this._key = val;
    if (this.input && this.input.nativeElement.value != val) {
      this.input.nativeElement.value = val;
    }
    console.log(`search with key ${val}`);
  }

  @Input()
  cats: any[] = [];

  @Input()
  searchResults: any[] = Array.from({ length: 100 }).map((_, i) => ({
    label: i % 100 == 0 ? `Label ${i}` : '',
    name: `Name ${i}`,
    id: i,
    routerLink: `/api/fund`
  }));

  @ViewChild('input')
  input?: ElementRef<HTMLInputElement>;

  resultPanelStatus: slideDownType = 'hidden';

  @Output()
  filterChanged = new EventEmitter<{ key: string, cats: any[] }>();

  private documentClickUnlistener?: () => void;

  private keyEventSubject = new Subject<any>();

  constructor(
    private el: ElementRef<any>,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2) {
    this.keyEventSubject.asObservable().pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(result => {
      this.key = result;
      this.resultPanelStatus = 'visible';
      if (!this.documentClickUnlistener) {
        this.documentClickUnlistener = this.renderer.listen('document', 'click', (event) => {
          if (!this.el.nativeElement.contains(event.target)) {
            this.hidden();
          }
        });
      }
      this.emitFilters();
    })
  }
  private emitFilters() {
    const filters = {
      key: this.key,
      cats: this.cats
    };
    this.filterChanged.next(filters);
  }

  keyChanged(event: Event, input: HTMLInputElement) {
    this.keyEventSubject.next(input.value);
  }
  checkPanelStatus(event: Event) {
    this.resultPanelStatus = (this.input?.nativeElement.value &&
      this.el.nativeElement.contains(document.activeElement)) ? 'visible' : 'hidden';
  }
  ngOnInit(): void {
  }
  catChanged() {
    this.emitFilters();
  }

  hidden() {
    if (this.documentClickUnlistener) {
      this.documentClickUnlistener();
      this.documentClickUnlistener = undefined;
    }
    this.resultPanelStatus = 'hidden';
    this.cd.detectChanges();
  }


}
