import { FundQuery } from "@alterdomus/crm-shared/fund-store";
import { Injectable } from "@angular/core";
import { combineQueries, QueryEntity } from "@datorama/akita";
import { map, switchMap } from "rxjs";
import { Company } from "./company.model";
import { CompanyState, CompanyStore } from "./company.store";

@Injectable({ providedIn: 'root' })
export class CompanyQuery extends QueryEntity<CompanyState> {
  constructor(protected override store: CompanyStore,
    private fundQuery: FundQuery) {
    super(store);

  }

  selectComapnyWithUsers() {
    return combineQueries([
      this.selectCompanies(),
      // this.userQuery.selectAll()
    ]).pipe(
      map(([companies]) => this.combineUsers(companies))
    )
  }

  selectCompanies() {
    return this.fundQuery.select(p => p.selectedClient).pipe(
      switchMap(clientId => {
        if (clientId) {
          return this.select(p => p.clientCompanies[clientId]);
        }
        else {
          return this.selectAll();
        }
      })
    )
  }

  private combineUsers(companies: Company[]) {
    return companies;
    // if (!companies) {
    //   return companies;
    // }
    // const companyUsers = users.reduce((acc: { [key: number]: User[] }, cur: User) => {
    //   if (!acc[cur.companyId]) acc[cur.companyId] = [];
    //   acc[cur.companyId].push(cur)
    //   return acc;
    // }, {})
    // return companies.map(company => ({
    //   ...company,
    //   users: _.sortBy(companyUsers[company.id], ['name']) || []
    // }))
  }

  // selectCompanyWithUsers() {
  //   return this.fundQuery.select(p => p.selectedClient).pipe(
  //     switchMap(clientId => {
  //       if (clientId) {

  //       }
  //       else {
  //         return this.selectAllCompaniesWithUsers();
  //       }
  //     })
  //   )
  // }
  // private selectAllCompaniesWithUsers() {
  //   return combineQueries([
  //     this.selectAll(),
  //     this.userQuery.selectAll(),
  //   ]).pipe(
  //     map(([companies, users]) => this.combineUsers(companies, users))
  //   )
  // }
  // private selectClientCompaniesWithUsers(clientId: number) {
  //   return combineQueries([
  //     this.select(p => p.clientCompanies[clientId] || []),
  //     this.userQuery.select,
  //   ]).pipe(
  //     map(([companies, users]) => {
  //       const companyUsers = users.reduce((acc: { [key: number]: User[] }, cur: User) => {
  //         if (!acc[cur.companyId]) acc[cur.companyId] = [];
  //         acc[cur.companyId].push(cur)
  //         return acc;
  //       }, {})
  //       return companies.map(company => ({
  //         ...company,
  //         users: _.sortBy(companyUsers[company.id], ['name']) || []
  //       }))
  //     })
  //   )
  // }
}
