import { Injectable } from "@angular/core";
import { combineQueries, QueryEntity } from "@datorama/akita";
import * as _ from "lodash-es";
import { map } from "rxjs";
import { FundState, FundStore } from "./fund.store";

@Injectable({ providedIn: 'root' })
export class FundQuery extends QueryEntity<FundState> {
  constructor(protected override store: FundStore) {
    super(store);
  }

  selectFunds() {
    // include fund and data room
    return this.selectFundProfiles();
  }

  selectDataRooms() {
    return this.selectFundProfiles(true);
  }

  selectClients$() {
    return this.selectAll({
      filterBy: (fund) => fund.client != null
    }).pipe(
      map(funds => {
        // _.chain(funds.map(f => f.client)).uniqBy(p => p?.id).orderBy(p => p?.companyShortName).value()
        return _.orderBy(_.uniqBy(funds.map(f => f.client), p => p?.id), p => p?.companyShortName);
      })
    )
  }

  selectClientId$() {
    return this.select(p => p.selectedClient);
  }
  selectViewBy$() {
    return this.select(p => p.viewBy);
  }

  private selectFundProfiles(dataRoom?: boolean) {
    return combineQueries([
      this.select(p => p.selectedClient),
      this.select(p => p.viewBy),
      this.selectAll()])
      .pipe(
        map(([clientId, viewBy, funds]) => {
          let fields = ['shortDealName'];
          let orders: any = ['desc'];
          if (viewBy == 1) {
            orders = ['asc'];
          }
          else if (viewBy == 0) {
            fields = ['activityTime'] // not returned yet.
          }
          return _.orderBy(funds.filter(p => (!clientId || p.client?.id == clientId) && (dataRoom == undefined || p.isDataRoom == dataRoom)),
            fields,
            orders
          );
        })
      )
  }
}
