export interface RequestInfo {
  method: string;
  url: string;
  batch: string | null;
  element: string | null;
  completed?: boolean;
  // we can preset total
  total?: number;
  showLoading?: 'progress' | 'spin' | 'false' | string;
  error?: Error
}
