import { AuthModule } from '@alterdomus/auth/auth';
import { LookupCodeModule } from '@alterdomus/crm-shared/lookup-code';
import { SettingService, SettingsModule } from '@alterdomus/crm-shared/settings';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';
import { CONFIG_ENDPOINT } from './config-endpoint';
import { ConfigService } from './config.service';


function initCRMConfig(configService: ConfigService) {
  return () => {
    console.log(`loading auth config`);
    const init$ = configService.loadConfiguration();
    console.log(`initAuthModuleFactory called`);
    return init$;
  }
}

function initAuthConfig(settingService: SettingService) {
  return () => firstValueFrom(settingService.settings$('idPURL', 'enableSPLogin').pipe(map(auth => {
    if (auth.idPURL === 'True') {
      return { authenticationUrl: auth.idPURL }
    }
    else {
      return { authenticationUrl: '/login' }
    }
  })));
}

/**
 * This module used to intialize all related services which CRM app depend on
 * so no need to call conifguration api by each module, one single call to accomplish
 *  - auth configuration
 *  - session configuration
 *  - settings configuration
 *  - lookupcodes
 */

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AuthModule.forRoot(localStorage),
    SettingsModule,
    LookupCodeModule,
  ],
})
export class ConfigModule {
  static forRoot(configUrl: string): ModuleWithProviders<ConfigModule> {
    return {
      ngModule: ConfigModule,
      providers: [
        { provide: CONFIG_ENDPOINT, useValue: configUrl },
        // { provide: AUTH_CONFIG, useFactory: initAuthConfig, deps: [SettingService], multi: true },
        { provide: APP_INITIALIZER, useFactory: initCRMConfig, deps: [ConfigService], multi: true }
      ]
    }
  }
}
