import { animate, state, style, transition, trigger } from "@angular/animations";

export type slideDownType = 'hidden' | 'visible';

export function slideDown(showSpeed = 150, hideSpeed = 150) {
  return trigger('slideDown', [
    state('hidden', style({ 'height': 0, 'box-shadow': 'none' })),
    state('visible', style({ 'height': '*' })),
    transition('hidden => visible', animate(`${showSpeed}ms ease-in`)),
    transition('visible => hidden', animate(`${hideSpeed}ms ease-out`))
  ])
}
