import { ObsCompanyProfileDto } from '@alterdomus/crm-shared/core';
import { showSpinLoading, withBatch } from '@alterdomus/rest/interceptor';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { CompanyStore } from './public_api';
import { Company } from './store/company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private http: HttpClient,
    private companyStore: CompanyStore
  ) { }

  reloadCompany(clientId: number | undefined = undefined) {
    return this.http.get<Company[]>(
      `crm:/api/v1/company${(clientId ? `?clientid=${clientId}` : '')}`,
      withBatch('profile', 2)
    )
      .pipe(
        tap(companies => {
          if (clientId) {
            this.companyStore.setClientCompanies(clientId, companies);
          }
          else {
            this.companyStore.set(companies);
          }
        })
      )
      .subscribe();
  }

  getCompany(id: number): Observable<Company> {
    //return of();
    return this.http.get<Company>(`crm:api/v1/company/${id}`, showSpinLoading);
  }
  getProfile(id: number): Observable<any> {
    // return of({
    //   id: 1,
    //   overview: {
    //     companyShortName: 'testCompany1',
    //     companyLegalName: 'testCompany1'
    //   },
    //   contact: [{
    //     streetAddress1: 'test1'
    //   }]
    // });
    return this.http.get<ObsCompanyProfileDto>(`crm:api/v1/company/${id}/profile`, showSpinLoading);
  }

  getCompanies(): Observable<Company[]> {
    // return of([{companyShortName:'testCompany1',id:1},{companyShortName:'testCompany2',id:2}])
    return this.http.get<Company[]>(`crm:api/v1/company`, showSpinLoading).pipe(
      tap(companies => {
        this.companyStore.set(companies);
      })
    );
  }

}
