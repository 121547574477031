import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { breadCrumbConfig, MENU_CONFIG } from '../bread-crumb-config';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private items: MenuItem[] = [];

  private menusSubject = new BehaviorSubject<MenuItem[]>([]);

  private visibleSubject = new BehaviorSubject<boolean>(false);

  menus$ = this.menusSubject.asObservable();

  visible$ = this.visibleSubject.asObservable();


  constructor(
    private title: Title,
    private router: Router,
    @Inject(MENU_CONFIG) private config: breadCrumbConfig) {
    this.setBrowserTitle('');
    // handle default navigate behaviour
    // if this component is the fist component on loading, there will be no title in extras
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const params = this.router.parseUrl(event.url).queryParams;
        const state = this.router.getCurrentNavigation()?.extras?.state
        let title = '';
        if (state) {
          title = state['title'];
        }
        if (title || event.url == this.config.homeRouterLink) {
          // push to end of menu
          const newMenu: MenuItem = {
            label: title,
            routerLink: event.url.split('?')[0],
            queryParams: params,
            state: state
          }
          this.checkVisibility();
          this.setBrowserTitle(title);
          this.addMenu(newMenu);
        }
      }
    });
  }

  // use for set title on component loading
  setTitle(title: string, addToMenu: boolean = true, url?: string) {
    if (!title) {
      return;
    }
    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    this.setBrowserTitle(title);
    if (addToMenu) {
      this.addMenu({
        label: title,
        routerLink: url || this.router.url.split('?')[0],
        queryParams: queryParams,
        state: { title: title }
      });
    }
  }

  private addMenu(newMenu: MenuItem) {
    const isHomeLink = this.isNavigateToHome(newMenu.routerLink);
    if (isHomeLink) {
      this.items = [];
    }
    else {
      if (this.items.length > 0) {
        const index = this.menuIndex(newMenu);
        // if click in menus, remove all followed menu
        if (index >= 0) {
          this.items.splice(index, this.items.length - index);
        }
        if (this.items.length > this.config.menuCount - 1) {
          // slice to end
          this.items.splice(0, this.items.length + 1 - this.config.menuCount);
        }
      }
      this.items = [...this.items, newMenu];
    }
    this.checkVisibility();
    this.menusSubject.next(this.items);
  }
  private checkVisibility() {
    const visible = !this.isNavigateToHome(this.router.url) || (this.config.showInHome || false);
    this.visibleSubject.next(visible);
  }

  private setBrowserTitle(title: string) {
    if (this.config.updateTile) {
      this.title.setTitle(`${title}${this.config.appTitle ? `${title ? ' - ' : ''}${this.config.appTitle}` : ''}`)
    }
  }
  private isSameMenuItem(a: MenuItem, b: MenuItem) {
    return a.label === b.label && a.url === b.url &&
      (
        a.queryParams == b.queryParams ||
        (a.queryParams && b.queryParams && (JSON.stringify(a.queryParams) === JSON.stringify(b.queryParams)))
      )
  }
  private menuIndex(clickedMenu: MenuItem) {
    return this.items.findIndex(menu => this.isSameMenuItem(clickedMenu, menu));
  }
  private isNavigateToHome(routerLink: string) {
    return routerLink === this.config.homeRouterLink || (this.config.hideBreadCrumbUrls && this.config.hideBreadCrumbUrls.some(link => link === routerLink));
  }
}
