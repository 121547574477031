import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Operation } from "./operation";

@Injectable({ providedIn: 'root' })
export class OperationWatcher {
  constructor() {
    console.log(`OperationWatcher created`);
  }
  private operationSub = new Subject<Operation>();
  operation$ = this.operationSub.asObservable();
  emitOperation(operation: Operation) {
    this.operationSub.next(operation);
  }
}
