export const contactinfoFieldsMapping = [
  {
    key: 'PrimaryLocationCountry',
    label: 'Primary Location - Country',
  },
  {
    key: 'PrimaryLocationState',
    label: 'Primary Location - State',
  },
  {
    key: 'PrimaryLocationCity',
    label: 'Primary Location - City',
  },
  {
    key: 'PrimaryLocationPostalCode',
    label: 'Primary Location - Zip Code',
  },
  {
    key: 'PrimaryLocationStreetAddress',
    label: 'Primary Location - Street Address',
  },
  {
    key: 'PrimaryLocationPhone',
    label: 'Primary Location - Phone',
  },
  {
    key: 'PrimaryLocationFax',
    label: 'Primary Location - Fax',
  },
  {
    key: 'SecondaryLocationCountry',
    label: 'Secondary Location - Country',
  },
  {
    key: 'SecondaryLocationState',
    label: 'Secondary Location - State',
  },
  {
    key: 'SecondaryLocationCity',
    label: 'Secondary Location - City',
  },
  {
    key: 'SecondaryLocationPostalCode',
    label: 'Secondary Location - Zip Code',
  },
  {
    key: 'SecondaryLocationStreetAddress',
    label: 'Secondary Location - Street Address',
  },
  {
    key: 'SecondaryFax',
    label: 'Secondary Location - Fax',
  },
  {
    key: 'tertiaryLocationCountry',
    label: 'Tertiary Address - Country',
  },
  {
    key: 'tertiaryLocationState',
    label: 'Tertiary Address - State',
  },
  {
    key: 'tertiaryLocationCity',
    label: 'Tertiary Address - City',
  },
  {
    key: 'tertiaryLocationPostalCode',
    label: 'Tertiary Address - Zip Code',
    expanded: true,
    data: 'TertiaryLocationPostalCode',
  },
  {
    key: 'tertiaryLocationStreetAddress',
    label: 'Tertiary Address - Street Address',
  },
  {
    key: 'tertiaryLocationPhone',
    label: 'Tertiary Address - Phone',
  },
  {
    key: 'legalAddressStreetAddress',
    label: 'Legal Address - Street Address',
  },
  {
    key: 'legalAddressCityStateZip',
    label: 'Legal Address - City, State, Zipcode',
  },
  {
    key: 'duplicateReportsName',
    label: 'Duplicate Report Address - Name',
  },
  {
    key: 'duplicateReportsEmail',
    label: 'Duplicate Report Address - Email',
  },
  {
    key: 'duplicateReportsAddress',
    label: 'Duplicate Report Address - Address',
  },
  {
    key: 'duplicateReportsTelephone',
    label: 'Duplicate Report Address - Telephone',
  },
  {
    key: 'duplicateReportsFax',
    label: 'Duplicate Report Address - Fax',
  },
  {
    key: 'secondaryDuplicateReportsName',
    label: 'Secondary Duplicate Report Address - Name',
  },
  {
    key: 'secondaryDuplicateReportsEmail',
    label: 'Secondary Duplicate Report Address - Email',
  },
  {
    key: 'secondaryDuplicateReportsAddress',
    label: 'Secondary Duplicate Report Address - Address',
  },
  {
    key: 'secondaryDuplicateReportsTelephone',
    label: 'Secondary Duplicate Report Address - Telephone',
  },
  {
    key: 'secondaryDuplicateReportsFax',
    label: 'Secondary Duplicate Report Address - Fax',
  },
];
